import {
  Component,
  OnInit,
  Type,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  HostListener,
  ChangeDetectorRef,
  Renderer2,
  TemplateRef,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GeneralService } from 'src/app/services/General.service';
import { StorageService } from 'src/app/services/Storage.service';
import { ToastrService } from 'ngx-toastr';
import { SlotGameList, SlotBrandListRequest, SlotBrandListRequestV2 } from 'src/app/models/service.dtos';
import { CASINO, FAST_GAMES, LIVE_CASINO, SCRATCH, VIRTUAL_SPORTS } from '../../constants';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/config/app-config.service';
import { TranslateService } from 'src/app/services/Translate.service';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $: any;
@Component({
  selector: 'app-game-filter',
  templateUrl: './game-filter.component.html',
  styleUrls: ['./game-filter.component.scss'],
})
export class GameFilterComponent implements OnInit, OnDestroy {
  private searchSubject = new Subject<string>();
  CASINO = CASINO;
  searchControl: UntypedFormControl = new UntypedFormControl('');
  @Input('Type') Type: string;
  @Input('ActiveCategoryId') ActiveCategoryId: number;
  @Input('ActiveBrandId') ActiveBrandId: any[] = [];
  @Input('ActiveDropdown') ActiveDropdown: boolean;
  @Input('IsShowed') IsShowed: boolean;
  @Input('TypeStatus') TypeStatus: string;
  @Input('ShowCategory') ShowCategory: boolean;
  @Input('AlphabeticStatus') AlphabeticStatus: boolean;
  @Output('SelectedBrandId') SelectedBrandId: EventEmitter<number> = new EventEmitter();
  @Output('closeMobileMenu') closeMobileMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('gameFilter') gameFilter: EventEmitter<object> = new EventEmitter<object>();
  @Output('searchFilter') searchFilter: EventEmitter<string> = new EventEmitter<string>();
  @Output('resetFilter') resetFilter: EventEmitter<string> = new EventEmitter<string>();
  @Output('categoryFilter') categoryFilter: EventEmitter<number> = new EventEmitter<number>();
  @Output('showTypeEmitter') showTypeEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output('slotGameListEmitter') slotGameListEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output('sortByAlphaEmitter') sortByAlphaEmitter: EventEmitter<any> = new EventEmitter<any>();
  Brands: any[] = [];
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
  OrderByAlphabetic: boolean = false;
  showType: string = 'grid';
  showLeftArrow = false;
  showRightArrow = true;
  Categories: any[] = [];
  AllBrands: any[] = [];
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  @ViewChild('content') content: ElementRef;
  currentTranslateX = 0;
  Collapsed: boolean = false;
  Subscribe: Subscription = new Subscription();
  isCollapsed = false;
  Translations: any;
  FilterBrands: any = [];
  FilterCategories: number;
  FavoriteStatus: boolean = false;
  isBrandLoader = false;
  LastPlayedStatus: boolean = false;
  provider: any;
  visible: boolean = true;
  IsSearchActive: boolean = false;
  IsBrandSearchActive: boolean = false;
  BrandSearchFilter = '';
  SearchFilter = '';
  isDragging = false;
  startX: number;
  svgUrl: SafeResourceUrl;
  CategoryLoader: boolean = false;
  BrandLoader: boolean = false;
  ModalRef: BsModalRef;
  @ViewChild('Template')
  private TemplateCall: TemplateRef<any>;

  constructor(
    private Service: GeneralService,
    private TranslateService: TranslateService,
    private Router: Router,
    private ConfigService: AppConfigService,
    private StorageService: StorageService,
    public sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private location: Location,
    private ModalService: BsModalService
  ) {
    this.TranslateService.getObs(['casino.favorite.noresult', 'casino.lastplayed.noresult']).subscribe((res) => {
      this.Translations = res;
    });
  }

  ngOnInit(): void {
    this.CategoryLoader = true;
    this.BrandLoader = true;
    this.OrderByAlphabetic = this.AlphabeticStatus;
    this.showType = this.TypeStatus;
    if (!this.ActiveBrandId) {
      this.ConfigService.ActiveBrandId.subscribe((BrandId) => {
        if (this.ActiveBrandId === null) this.ActiveBrandId = [];
        if (!isNaN(BrandId)) this.ActiveBrandId.push(BrandId);
      });
    }
    if (this.ActiveBrandId) {
      if (typeof this.ActiveBrandId === 'string') {
        var tempString: string = this.ActiveBrandId;
        var tmpArray = tempString.split(',');
        this.FilterBrands = tmpArray.map((item) => parseInt(item));
      } else {
        this.FilterBrands = this.ActiveBrandId;
        this.cdr.detectChanges();
      }
    }

    this.FilterCategories = this.ActiveCategoryId;
    this.getBrandListV2();
    this.searchSubject.pipe(debounceTime(500)).subscribe((searchValue) => {
      this.searchFilter.emit(searchValue);
    });
    if (this.Type === 'live_casino') {
      this.Service.GetLiveCasinoCategories().subscribe((Response) => {
        if (Response.Success) {
          this.Categories = Response.Data;
          this.CategoryLoader = false;
        }
      });
    } else {
      this.Service.GetCasinoCategories().subscribe((Response) => {
        if (Response.Success) {
          this.Categories = Response.Data;
          this.CategoryLoader = false;
        }
      });
    }
    if (this.scrollContainer != undefined) {
      this.pointerScroll(this.scrollContainer.nativeElement);
    }
  }
  sortByAlpha() {
    this.OrderByAlphabetic = true;
    this.sortByAlphaEmitter.emit();
  }
  getSlotGameList() {
    this.slotGameListEmitter.emit();
  }
  setShowType(key: string) {
    this.showType = key;
    this.showTypeEmitter.emit(key);
  }
  private pointerScroll(elem: HTMLElement): void {
    let isDrag = false;

    const dragStart = () => (isDrag = true);
    const dragEnd = () => (isDrag = false);
    const drag = (ev: PointerEvent) => isDrag && (elem.scrollLeft -= ev.movementX);

    elem.addEventListener('pointerdown', dragStart);
    document.addEventListener('pointerup', dragEnd);
    document.addEventListener('pointermove', drag);
  }
  async loadSvg(url: string) {}

  filterCategory(game: any) {
    const currentUrl = this.Router.url.split('/')[1];
    const newUrl = `/${currentUrl}`;
    this.location.go(newUrl);
    this.isBrandLoader = true;
    this.FilterBrands = [];
    if (this.FilterCategories === game) {
      this.FilterCategories = 0;
      this.getBrandList();
    } else {
      this.FilterCategories = game;
      this.getBrandListV2();
    }

    this.isBrandLoader = false;
    this.cdr.detectChanges();

    this.categoryFilter.emit(this.FilterCategories);
  }

  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.pageX - this.scrollContainer.nativeElement.offsetLeft;
    this.scrollLeft = this.scrollContainer.nativeElement.scrollLeft;
    this.scrollContainer.nativeElement.classList.add('active');
  }

  onMouseLeave() {
    this.isDragging = false;
    this.scrollContainer.nativeElement.classList.remove('active');
  }

  onMouseUp() {
    this.isDragging = false;
    this.scrollContainer.nativeElement.classList.remove('active');
  }
  onDragOver(event) {
    // do something
    event.preventDefault();
  }
  onDragLeave(event) {
    // do something
    event.preventDefault();
  }

  onMouseMove(event: MouseEvent) {
    if (!this.isDragging) return;
    const x = event.pageX - this.scrollContainer.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; // Sürükleme hızını ayarlayabilirsiniz
    this.scrollContainer.nativeElement.scrollLeft = (this.scrollLeft as unknown as any) - walk;
  }
  getBrandListV2() {
    this.isBrandLoader = true;
    this.BrandLoader = true;

    let Request = new SlotBrandListRequestV2();
    Request.GameType = this.Type;

    Request.CategoryId = this.FilterCategories;
    if (this.FilterCategories === 9999 || this.FilterCategories === -1) {
      Request.CategoryId = null;
    }
    this.Subscribe.add(
      this.Service.getSlotBrandListV2(Request).subscribe((Response) => {
        this.Brands = Response;
        this.AllBrands = Response;
        this.BrandLoader = false;
        this.CategoryLoader = false;
      })
    );
    this.isBrandLoader = false;
  }

  isFilterCategory(game: any) {
    if (this.FilterCategories === game) {
      return true;
    } else {
      return false;
    }
  }
  changeFilterStatus() {
    this.IsSearchActive = !this.IsSearchActive;
    if (this.SearchFilter != '') {
      this.SearchFilter = '';
      this.searchSubject.next(this.SearchFilter);
    }
  }
  changeBrandFilterStatus() {
    this.IsBrandSearchActive = !this.IsBrandSearchActive;

    if (this.BrandSearchFilter != '') {
      this.BrandSearchFilter = '';
      this.getBrandList();
    }
  }
  searcher(value) {
    const inputElement = value.target as HTMLInputElement;
    var result = inputElement.value;
    if (result) {
      if (this.SearchFilter != result) {
        this.SearchFilter = result;
        this.searchSubject.next(this.SearchFilter);
      }
      if (this.SearchFilter === '') {
        this.SearchFilter = '';
        this.searchSubject.next(this.SearchFilter);
      }
    } else {
      this.SearchFilter = '';
      this.searchSubject.next(this.SearchFilter);
    }
  }
  getGames(BrandId: number) {
    const index = this.ActiveBrandId.indexOf(BrandId);
    if (index === -1) {
      this.ActiveBrandId.push(BrandId);
    } else {
      this.ActiveBrandId.splice(index, 1);
    }
    let Url = this.Type === LIVE_CASINO ? '/live-casino/' : '/casino/';
    if (this.IsMobile) {
      this.ConfigService.MobileMenuModal.next('close');
    }
    this.Router.navigate([Url, this.ActiveBrandId]);
  }

  getBrandList() {
    let Request = new SlotBrandListRequestV2();
    if (this.BrandSearchFilter) {
      Request.GameName = this.BrandSearchFilter;
    }
    Request.GameType =
      this.Type === 'live_casino'
        ? 'live_casino'
        : this.Type === CASINO
        ? 'slot_game'
        : this.Type === VIRTUAL_SPORTS
        ? 'virtual_game'
        : this.Type === SCRATCH
        ? 'scratch'
        : this.Type === FAST_GAMES
        ? 'fast_games'
        : this.Type === 'slot_game'
        ? 'slot_game'
        : null;
    this.Subscribe.add(
      this.Service.getSlotBrandListV2(Request).subscribe((Response) => {
        this.Brands = Response;
        this.CategoryLoader = false;
      })
    );
  }
  scrollLeft(): void {
    const container = this.scrollContainer.nativeElement;
    var newPosition = container.scrollLeft - container.clientWidth;
    if (newPosition < 50) newPosition = 0;
    container.scrollTo({
      left: newPosition,
      behavior: 'smooth',
    });

    setTimeout(() => this.checkArrows(), 500); // Animasyonun bitmesini bekle
  }

  scrollRight(): void {
    const container = this.scrollContainer.nativeElement;
    const newPosition = container.scrollLeft + container.clientWidth;

    container.scrollTo({
      left: newPosition,
      behavior: 'smooth',
    });

    setTimeout(() => this.checkArrows(), 100); // Animasyonun bitmesini bekle
  }

  checkArrows(): void {
    const container = this.scrollContainer.nativeElement;
    this.showLeftArrow = container.scrollLeft > 10;
    this.showRightArrow = container.scrollLeft + container.clientWidth < container.scrollWidth;
  }
  shouldRemoveMargin(item: any): boolean {
    return item.Id === this.Categories[this.Categories.length - 1].Id;
  }
  resetGames() {
    this.FilterCategories = null;
    this.FilterBrands = [];
    this.SearchFilter = '';
    this.BrandSearchFilter = '';
    this.IsBrandSearchActive = false;
    this.IsSearchActive = false;
    this.getBrandList();
    this.resetFilter.emit();
  }
  getFavoriteGames() {
    // -1 Favorite
    this.ActiveBrandId = [-1];
    let Url = this.Type === LIVE_CASINO ? '/live-casino/' : '/casino/';
    if (this.IsMobile) {
      this.ConfigService.MobileMenuModal.next('close');
    }
    this.Router.navigate([Url, this.ActiveBrandId]);
  }
  toggleSearchActive() {
    this.IsSearchActive = true;
  }
  toggleBrandSearchActive() {
    this.IsBrandSearchActive = true;
  }
  getLastPlayedGames() {
    // -2 Last Played
    this.ActiveBrandId = [-2];
    let Url = this.Type === LIVE_CASINO ? '/live-casino/' : '/casino/';
    if (this.IsMobile) {
      this.ConfigService.MobileMenuModal.next('close');
    }
    this.Router.navigate([Url, this.ActiveBrandId]);
  }
  Reset() {
    this.FilterBrands = [];
    this.ModalRef.hide();
    const currentUrl = this.Router.url.split('/')[1];
    let result = this.FilterBrands.join(',');
    const newUrl = `${currentUrl}/${result}`; // Array'i 'current/result' formatında birleştir ve yeni URL'yi oluştur
    this.location.go(newUrl);
    this.gameFilter.emit(this.FilterBrands);
  }
  Close() {
    this.ModalRef.hide();
  }
  Apply() {
    this.ModalRef.hide();
  }
  openModal() {
    this.ModalRef = this.ModalService.show(this.TemplateCall, {
      class: 'modal-dialog modal-fullscreen-lg-down casino-modal',
      ignoreBackdropClick: false,
    });
  }
  providerSearcher(providerName) {
    const inputElement = event.target as HTMLInputElement;

    if (this.BrandSearchFilter != inputElement.value) {
      this.BrandSearchFilter = inputElement.value;
      this.Brands = this.AllBrands.filter((brand) =>
        brand.Name.toLowerCase().includes(this.BrandSearchFilter.toLowerCase())
      );

      //this.getBrandList();
    }
  }
  getPopularSlots() {
    // -3 Popular
    this.ActiveBrandId = [-3];
    let Url = this.Type === LIVE_CASINO ? '/live-casino/' : '/casino/';
    if (this.IsMobile) {
      this.ConfigService.MobileMenuModal.next('close');
    }
    this.Router.navigate([Url, this.ActiveBrandId]);
  }
  filterGames(game: any) {
    this.isBrandLoader = true;
    const index = this.FilterBrands.indexOf(game);
    if (index === -1) {
      if (!Array.isArray(this.FilterBrands)) {
        var tempArr = this.FilterBrands.split(',');

        this.FilterBrands = [];
        tempArr.forEach((element) => {
          this.FilterBrands.push(parseInt(element));
        });
      }
      this.FilterBrands.push(game);
    } else {
      if (!Array.isArray(this.FilterBrands)) {
        var tempArr = this.FilterBrands.split(',');
        this.FilterBrands = [];
        tempArr.forEach((element) => {
          this.FilterBrands.push(parseInt(element));
        });
      }
      this.FilterBrands.splice(index, 1);
    }
    if (this.FilterBrands.length === 0 && this.SearchFilter === null) {
      this.getPopularSlots();
    }
    let Url = this.Type === LIVE_CASINO ? '/live-casino/' : '/casino/';
    if (this.IsMobile) {
      this.ConfigService.MobileMenuModal.next('close');
    }
    let result = this.FilterBrands.join(',');

    this.isBrandLoader = false;
    if (this.FilterBrands.length === 0 && this.FilterCategories == null && this.SearchFilter != null) {
      this.searchSubject.next(this.SearchFilter);
    }
    //this.Router.navigate([Url, result]);
    const currentUrl = this.Router.url.split('/')[1];
    const newUrl = `${currentUrl}/${result}`; // Array'i 'current/result' formatında birleştir ve yeni URL'yi oluştur
    this.location.go(newUrl);
    this.gameFilter.emit(this.FilterBrands);
  }

  isFilterBrand(game: any) {
    if (this.FilterBrands.indexOf(game) === -1) {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.Subscribe.unsubscribe();
  }

  get IsMobile() {
    const ua = (window.navigator.userAgent || '').toLowerCase();
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 4)
      );
    return isMobile;
  }

  @ViewChild('search') searchElement: ElementRef;
  ngAfterViewInit() {
    if (!this.IsMobile) {
      this.showSearch();
    }
  }
  showSearch() {
    setTimeout(() => {
      //this.searchElement?.nativeElement.focus();
    }, 0);
  }

  searchProvider(event: any) {
    let searchString = $(event.target).val();
    $('.p-list ul li').each(function (index, value) {
      var currentName = $(value).text();
      if (currentName.toUpperCase().indexOf(searchString.toUpperCase()) > -1) {
        $(value).show();
      } else {
        $(value).hide();
      }
    });
  }

  onScrollContainer() {
    let scrollPercentage = this.containerScrollPercentage();

    this.showLeftArrow = scrollPercentage > 2;
    this.showRightArrow = scrollPercentage < 100;
  }

  containerScrollPercentage(): number {
    const container = this.scrollContainer.nativeElement;

    // Container scroll değerinin yüzdesini verir
    return Number(
      (
        (100 * container.scrollLeft) /
        container.scrollWidth /
        (1 - container.clientWidth / container.scrollWidth)
      ).toFixed(2)
    );
  }
  public resetCategory() {
    this.FilterCategories = null;
  }

  get IsIOS() {
    let isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    return isIOS;
  }

  protected readonly SCRATCH = SCRATCH;
  protected readonly VIRTUAL_SPORTS = VIRTUAL_SPORTS;
  protected readonly FAST_GAMES = FAST_GAMES;
}
