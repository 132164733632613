<div class="maintenance">
  <div class="mt-content" *ngIf="IsPermissionError">
    <div class="title">
      {{ 'general.permission.error.header' | translate }}
    </div>
    <div class="text mb-5">
      {{ 'general.permission.error.detail' | translate }}
    </div>
    <a [routerLink]="['/']" class="btn base mt-btn">{{ 'button.navigate-homepage' | translate }}</a>
  </div>
  <div class="mt-content" *ngIf="IsError">
    <div class="title">
      {{ 'general.sportsbook.error.header' | translate }}
    </div>
    <div class="text mb-5">
      {{ 'general.sportsbook.error.detail' | translate }}
    </div>
    <a [routerLink]="['/']" class="btn base mt-btn">{{ 'button.navigate-homepage' | translate }}</a>
  </div>
  <div class="mt-content" *ngIf="IsCurrencyError">
    <div class="title">
      {{ 'general.currency.error.header' | translate }}
    </div>
    <div class="text mb-5">
      {{ 'general.currency.error.detail' | translate }}
    </div>
    <a [routerLink]="['/']" class="btn base mt-btn">{{ 'button.navigate-homepage' | translate }}</a>
  </div>
  <div class="mt-content" *ngIf="IsOpenGameError">
    <div class="title">
      {{ OpenGameErrorDesc }}
      <!-- {{ 'general.sportsbook.error.header' | translate }} -->
    </div>
    <!-- <div class="text mb-5">
      Oyun açılamadı açıklama
      {{ 'general.sportsbook.error.detail' | translate }} 
    </div>-->
    <a [routerLink]="['/']" class="btn base mt-btn">{{ 'button.navigate-homepage' | translate }}</a>
  </div>
</div>
