import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { RouterModule } from '@angular/router';
import { LanguagesModule } from '../../components/languages/languages.module';
import { HomeHeaderUserComponent } from './home-header/home-header-user/home-header-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GameFilterModule } from 'src/app/components/game-filter/game-filter.module';
import { PipesModule } from 'src/app/helpers/pipes/pipes.module';
import { HeaderNotificationComponent } from 'src/app/components/header-notification/header-notification.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SharedModule } from '../../shared/shared.module'; // Import SharedModule instead of ComponentsModule

@NgModule({
  declarations: [HomeHeaderComponent, HomeLayoutComponent, HomeHeaderUserComponent, HeaderNotificationComponent],
  imports: [
    BsDropdownModule.forRoot(),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LanguagesModule,
    GameFilterModule,
    PipesModule,
    NgxIntlTelInputModule,
    SharedModule, // Use SharedModule instead of ComponentsModule
  ],
  exports: [HomeHeaderComponent, HomeLayoutComponent, HomeHeaderUserComponent, HeaderNotificationComponent],
})
export class HomeModule {}
