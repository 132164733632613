import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StickyService {
  private stickyState = new BehaviorSubject<boolean>(false);
  isSticky$ = this.stickyState.asObservable();

  setSticky(isSticky: boolean) {
    this.stickyState.next(isSticky);
  }
}
