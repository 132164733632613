<div class="b-widget">
  <div class="b-header">
    <div class="start">
      <span class="icon-box me-2">
        <i class="fa fa-gift"></i>
      </span>
      <span class="color-theme">
        {{ 'mainmenu.promotions' | translate }}
      </span>
    </div>
    <div class="end">
      <div class="inner">
        <a routerLink="/promotions" class="btn btn-base">{{ 'btn.more' | translate }}</a>
        <!-- <a href="#" class="btn btn-base btn-icon d-none d-md-inline-block me-1 ms-1">
          <i class="fa-solid fa-chevron-left" (click)="scrollLeft()"></i>
        </a>
        <a href="#" (click)="scrollRight()" class="btn btn-base btn-icon d-none d-md-inline-block"><i class="fa-solid fa-chevron-right"></i></a> -->
      </div>
    </div>
  </div>
  <div class="b-content bonus-content" data-bs-spy="scroll" data-bs-smooth-scroll="true" tabindex="0">
    <div class="b-bonus main" *ngIf="!IsPromotionLoader; else loadingTemplate">
      <div class="b-bonus-box" *ngFor="let p of Promotions">
        <div class="bonus-media" (click)="promotionDetail(p, staticPromoModal)">
          <img [lazyLoad]="p.Image" alt="" />
        </div>
        <div class="bonus-info" *ngIf="!IsMobile">
          <div class="kind">
            <div class="title w-100 my-1">
              <h5>
                <i class="bw-i-promotions me-1"></i>
                {{ p.Title }}
              </h5>
            </div>
            <div class="base w-100">
              <span class="w-100">
                {{ 'promotions.category' | translate }} :
                <em class="text-normal text-primary">{{ p.Category }}</em>
              </span>

              <span class="w-100">
                {{ 'promotions.tags' | translate }} :
                <em class="text-primary">
                  <ng-container *ngFor="let t of p.SplittedTags; let i = index" class="text-primary">
                    {{ t }}
                    <em *ngIf="i < p.SplittedTags.length - 1">,</em>
                  </ng-container>
                </em>
              </span>
            </div>
          </div>
          <a (click)="promotionDetail(p, staticPromoModal)" class="btn btn-base mt-1">{{ 'btn.more' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #staticPromoModal>
  <div class="modal-content">
    <div class="modal-header d-flex justify-content-between">
      <div class="start">
        <i class="fad fa-gift mr-2"></i>
        {{ ActiveBonus.Title }}
      </div>
      <div class="end">
        <button
          type="button"
          class="btn btn-close ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="modalRef.hide()">
          <i class="fa fa-close"></i>
        </button>
      </div>
    </div>
    <div class="modal-body">
      <div class="row g-3">
        <div class="col-12 col-md-4">
          <img [src]="ActiveBonus.Image" alt="" class="w-100" />
          <div class="kind my-3">
            <div class="d-flex w-100 mt-1">{{ 'promotions.category' | translate }} : {{ ActiveBonus.Category }}</div>
            <div class="d-flex w-100 mb-1">{{ 'promotions.tags' | translate }} : {{ ActiveBonus.SplittedTags }}</div>
          </div>
          <a
            *ngIf="ActiveBonus.CanBeRequested"
            href="javascript:void(0)"
            (click)="applyPromotion(ActiveBonus.PromotionId)"
            class="btn btn-base m-hide {{ buttonDisabled[ActiveBonus.PromotionId] ? 'disabled' : '' }}">
            {{ 'promotions.button.apply' | translate }}
          </a>
          <a
            *ngIf="ActiveBonus.CanBeActivatedAutomatically"
            (click)="applyPromotion(ActiveBonus.PromotionId)"
            class="btn btn-base m-hide  {{ buttonDisabled[ActiveBonus.PromotionId] ? 'disabled' : '' }}">
            {{ 'promotions.buttons.active' | translate }}
          </a>
        </div>
        <div class="col">
          <div [innerHTML]="ActiveBonus.Description"></div>
        </div>
      </div>
    </div>
    <div class="modal-footer d-hide">
      <a
        *ngIf="ActiveBonus.CanBeRequested"
        href="javascript:void(0)"
        (click)="applyPromotion(ActiveBonus.PromotionId)"
        class="btn btn-base {{ buttonDisabled[ActiveBonus.PromotionId] ? 'disabled' : '' }}">
        {{ 'promotions.button.apply' | translate }}
      </a>
      <a
        *ngIf="ActiveBonus.CanBeActivatedAutomatically"
        (click)="applyPromotion(ActiveBonus.PromotionId)"
        class="btn btn-base  {{ buttonDisabled[ActiveBonus.PromotionId] ? 'disabled' : '' }}">
        {{ 'promotions.buttons.active' | translate }}
      </a>
    </div>
  </div>
</ng-template>
<ng-template #loadingTemplate>
  <div class="b-widget">
    <div class="b-content bonus-content">
      <div class="b-bonus main">
        <div class="b-bonus-box" *ngFor="let placeholder of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
          <div class="bonus-media">
            <div class="placeholder col-12 skeleton-main" style="height: 15rem"></div>
          </div>
          <div class="bonus-info">
            <div class="kind">
              <div class="title w-100 my-1">
                <h5 class="placeholder col-6"></h5>
              </div>
              <div class="base w-100">
                <span class="placeholder col-4"></span>
                <span class="placeholder col-8"></span>
              </div>
            </div>
            <a class="btn btn-base mt-1 placeholder col-6"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
