<div class="bs-winner-box">
  <!-- <div class="head d-block w-100">
        <div class="row">
            <div class="col-12 text-center w-col">
                <div class="row no-gutters">
                    <div class="col-2 pt-1 text-right">
                        <i class="glyph-icon icon-token icon-ch mr-2 reverse"></i>
                    </div>
                    <div class="col-8">
                        <span class="winner-title text-light">{{ 'casino.winnerGames' | translate }} <span class="d-inline-block font-weight-light">KAZANANLAR</span></span>
                    </div>
                    <div class="col-2 pt-1 text-left">
                        <i class="glyph-icon icon-token icon-ch ml-2"></i>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
  <div class="d-block w-100 winner-outer">
    <div class="row no-gutters winner-game-row">
      <div class="col-12 col-md-3 p-1" *ngFor="let W of Winners">
        <div class="custom-game-box">
          <div class="game-thumb p-1 col-4">
            <img [lazyLoad]="W.GameImg" alt=" " class="img-fluid h-100" style="object-fit: cover" />
          </div>
          <div class="game-informations col-8 pl-2 pr-2 p-2">
            <div class="overlay">
              <a href="javascript:void(0) " class="btn theme" (click)="OpenGame(W.GameId)">
                <span>{{ 'btn.playnow' | translate }}</span>
              </a>
            </div>
            <div class="row no-gutters">
              <div class="col-12">
                <span class="game-info">
                  <span class="limits">{{ W.GameName }}</span>
                  <small class="d-block"><a href="javascript:void(0) " class="btn-brand text-theme"></a></small>
                </span>
                <span class="game-info pt-1 text-light">{{ W.Username }}</span>
                <span class="game-info text-light">{{ W.Amount }} TRY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
