import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-iframe-listener',
  template: ``,
  styles: [],
})
export class IframeListenerComponent implements OnInit, OnDestroy {
  @Input() url: string;
  Iframe: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, private router: Router) {}

  ngOnInit(): void {
    this.Iframe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    window.addEventListener('message', this.receiveMessage, false);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.receiveMessage, false);
  }

  receiveMessage = (event: MessageEvent): void => {
    console.log('Received message:', event.data);

    if (event.data?.name === 'gameQuit') {
      const currentUrl = this.router.url;
      const segments = currentUrl.split('/');
      if (segments.length > 2 && segments[0] === '') {
        const newUrl = `/${segments[1]}/${segments[3]}`;
        this.router.navigateByUrl(newUrl);
      }
    }
  };
}
