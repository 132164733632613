import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'localTime',
})
export class LocalTimePipe implements PipeTransform {
  transform(value: any, format: any): any {
    let newdate = moment.utc(value).toDate();
    return moment(newdate).local().format(format);
  }
}
