<div
  (click)="Open()"
  class="bw-game-item"
  [ngClass]="showType ? showType : ''"
  [class.live]="
    Type === Types.LIVE_CASINO || Type === Types.FAST_GAMES || Type === Types.SCRATCH || Type === Types.VIRTUAL_SPORTS
  ">
  <img [lazyLoad]="Game.ImageUrl ? Game.ImageUrl : GetLiveCasinoImage()" alt="{{ Game.Name }}" class="bw-game-media" />
  <div class="bw-game-content">
    <div class="game-content">
      <a href="javascript:void(0)" (click)="SetFavorite()" class="btn-fav">
        <i [class.far]="!FavActive" [class.fas]="FavActive" class="fa-star"></i>
      </a>
      <a class="btn btn-brand" style="cursor: default">
        {{ 'brandnames.' + Game.BrandName.toLowerCase() | translate }}
      </a>
      <h3>{{ Game.Name }}</h3>
    </div>
    <div class="game-actions d-flex justify-content-center">
      <a
        href="javascript:void(0)"
        (click)="OpenGame()"
        class="btn btn-play align-self-center m-1"
        data-bs-dismiss="offcanvas"
        [appBsConditionalDismiss]="canDismiss">
        {{ 'btn.playnow' | translate }}
      </a>
      <a
        href="javascript:void(0)"
        (click)="OpenDemoGame()"
        *ngIf="Game.HasDemo"
        class="btn btn-play demo align-self-center m-1 d-none">
        {{ 'text.demogame.header' | translate }}
      </a>
    </div>
  </div>
</div>

<ng-template #mobileGameModal>
  <div class="modal-header d-flex justify-content-between">
    <div class="start">
      <img [routerLink]="['/']" src="assets/uploads/brand/logo.png" alt="" class="logo" />
    </div>
    <div class="end">
      <a href="javascript:void(0)" (click)="Close()" class="btn btn-close"><i class="fa fa-close"></i></a>
    </div>
  </div>
  <div class="modal-body">
    <div class="game-section">
      <div class="w-100 img mb-2">
        <img
          class="w-100"
          *ngIf="Type === Types.LIVE_CASINO"
          [src]="Game.ImageUrl ? Game.ImageUrl : GetLiveCasinoImage()"
          alt="" />
        <img
          class="w-100"
          *ngIf="Type !== Types.LIVE_CASINO"
          [src]="Game.ImageUrl ? Game.ImageUrl : GetLiveCasinoImage()"
          [lazyLoad]="Game.ImageUrl ? Game.ImageUrl : GetLiveCasinoImage()"
          alt="" />
      </div>
      <div class="w-100 d-flex justify-content-between">
        <div class="text-theme">
          <h5 (click)="OpenGame()" (click)="Close()">{{ Game.Name }}</h5>
        </div>
        <div class="">
          <a href="javascript:void(0)" (click)="SetFavorite()" class="btn-fav">
            <i [class.far]="!FavActive" [class.fas]="FavActive" class="fa-star"></i>
          </a>
        </div>
      </div>
      <div class="w-100 buttons">
        <div class="col-12 text-left mb-3">
          <h6 *ngIf="Game.LiveCasinoData">
            {{ 'main.text.limits' | translate }} {{ Game.LiveCasinoData.minAmount }} -
            {{ Game.LiveCasinoData.maxAmount }} {{ Game.LiveCasinoData.currency | currencySymbol }}
          </h6>
          <span class="provider">{{ 'brandnames.' + Game.BrandName.toLowerCase() | translate }}</span>
        </div>
        <div class="w-100">
          <a (click)="OpenGame()" (click)="Close()" class="btn btn-theme play w-100 py-2">
            {{ 'btn.playnow' | translate }}
          </a>
        </div>
        <div class="col-5 pl-0 d-none">
          <a
            href="javascript:void(0)"
            class="btn theme w-100 solid px-0 py-2"
            (click)="OpenDemoGame()"
            *ngIf="Game.HasDemo">
            {{ 'text.demogame.header' | translate }}
          </a>
          <a href="/demo-game" *ngIf="!Game.HasDemo" class="btn theme w-100 solid px-0 py-2">
            {{ 'text.demogame.header' | translate }}
          </a>
        </div>
      </div>
    </div>
    <div class="popular-games-section d-none">
      <div class="pgs-header">
        <i class="icon-set icon-PopularGames mr-2"></i>
        <span>{{ 'casino.popularGames' | translate }}</span>
      </div>
      <div class="games-history">
        <ul class="glist">
          <li *ngFor="let Game of PopularSlots">
            <div class="popular-game-thumb" (click)="ChangeGame(Game)">
              <div class="thumb" [ngStyle]="{ background: 'url(' + Game.ImageUrl + ')' }"></div>
              <div class="text">{{ Game.Name }}</div>
              <!-- img class="mo-populars" [src]="Game.ImageUrl ? Game.ImageUrl : GetLiveCasinoImage()" alt="" -->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a href="javascript:void(0)" class="btn btn-base" data-bs-dismiss="modal" (click)="Close()">
      {{ 'btn.close' | translate }}
    </a>
    <a href="javascript:void(0)" class="btn btn-success" (click)="openMobileFab()">
      <i class="fa-duotone fa-comments-question me-2"></i>
      {{ 'general.contact.support' | translate }}
    </a>
  </div>
</ng-template>
