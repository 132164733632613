<ng-template #RegisterTemplate>
  <div [@inOutAnimation] class="preloader" *ngIf="Loader"></div>
  <div class="modal-content auth-v2">
    <div class="modal-header d-flex justify-content-between">
      <div class="start">
        <img [routerLink]="['/']" src="assets/uploads/brand/logo.png" alt="" class="logo" />
      </div>
      <div class="end">
        <a href="javascript:void(0)" (click)="openLoginModal()" class="btn btn-register p-1 px-2 me-2 ms-auto">
          {{ 'btn.login' | translate }}
        </a>
        <a href="javascript:void(0)" (click)="Close()" class="btn btn-close"><i class="fa fa-close"></i></a>
      </div>
    </div>
    <div class="modal-body" *ngIf="!Loader">
      <tabset *ngIf="DefaultRegisterStep == 0">
        <tab *ngIf="AuthSettings.DefaultRegistrationEnabled" (selectTab)="tabChanged('DefaultRegister')">
          <div>
            <div>
              <ng-template #Template tabHeading>{{ 'btn.register' | translate }}</ng-template>
              <div class="inner" [hidden]="step !== 1">
                <form [formGroup]="Form" name="Form" class="text-left register-form" (ngSubmit)="Register()" class="">
                  <div class="row">
                    <div class="col-12 py-1" *ngIf="AuthSettings.NameVisible">
                      <div class="input-group has-validation">
                        <div
                          class="form-floating"
                          [class.is-invalid]="f.Name.invalid && (f.Name.dirty || f.Name.touched || RegisterFormClicked)"
                          [class.filled]="!!Model.Name">
                          <input
                            formControlName="Name"
                            type="text"
                            [(ngModel)]="Model.Name"
                            class="form-control is-invalid"
                            [class.is-invalid]="
                              f.Name.invalid && (f.Name.dirty || f.Name.touched || RegisterFormClicked)
                            "
                            [class.filled]="!!Model.Name"
                            id="floatingInputGroup2"
                            placeholder="{{ 'account.form.label.name' | translate }}"
                            required />
                          <label for="floatingInputGroup2">{{ 'account.form.label.name' | translate }}</label>
                        </div>
                        <div
                          *ngIf="f.Name.invalid && (f.Name.dirty || f.Name.touched || RegisterFormClicked)"
                          class="invalid-feedback">
                          {{ 'general.text.required' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 py-1" *ngIf="AuthSettings.SurnameVisible">
                      <div
                        class="form-floating"
                        [class.is-invalid]="
                          f.Surname.invalid && (f.Surname.dirty || f.Surname.touched || RegisterFormClicked)
                        "
                        [class.filled]="!!Model.Surname">
                        <input
                          formControlName="Surname"
                          type="text"
                          [(ngModel)]="Model.Surname"
                          class="form-control is-invalid"
                          [class.is-invalid]="
                            f.Surname.invalid && (f.Surname.dirty || f.Surname.touched || RegisterFormClicked)
                          "
                          [class.filled]="!!Model.Surname"
                          id="surname"
                          placeholder="{{ 'account.form.label.surname' | translate }}"
                          required />
                        <label for="Surname">{{ 'account.form.label.surname' | translate }}</label>
                      </div>

                      <div
                        *ngIf="f.Surname.invalid && (f.Surname.dirty || f.Surname.touched || RegisterFormClicked)"
                        class="invalid-feedback">
                        {{ 'general.text.required' | translate }}
                      </div>
                    </div>
                    <div class="col-12 py-1" *ngIf="AuthSettings.BirthDateVisible">
                      <div class="input-group has-validation">
                        <div
                          class="form-floating"
                          [class.is-invalid]="
                            birthdayLessThanAllowed ||
                            birthdayMoreThanAllowed ||
                            (f.BirthDate.invalid && (f.BirthDate.dirty || f.BirthDate.touched || RegisterFormClicked))
                          "
                          [class.filled]="!!Model.BirthDate">
                          <input
                            [class.is-invalid]="
                              birthdayLessThanAllowed ||
                              birthdayMoreThanAllowed ||
                              (f.BirthDate.invalid && (f.BirthDate.dirty || f.BirthDate.touched || RegisterFormClicked))
                            "
                            [class.filled]="!!Model.BirthDate"
                            type="date"
                            class="form-control"
                            id="birth-date"
                            formControlName="BirthDate"
                            [(ngModel)]="Model.BirthDate"
                            (ngModelChange)="dateChanged($event)"
                            [min]="bsMinDate | date : 'yyyy-MM-dd'"
                            [max]="bsMaxDate | date : 'yyyy-MM-dd'"
                            required />
                          <label for="birth-date">{{ 'account.form.label.birthdate' | translate }}</label>
                        </div>
                        <div
                          *ngIf="
                            f.BirthDate.invalid && (f.BirthDate.dirty || f.BirthDate.touched || RegisterFormClicked)
                          "
                          class="invalid-feedback">
                          {{ 'general.text.required' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 py-1" *ngIf="AuthSettings.CountryVisible">
                      <div
                        class="form-floating"
                        [class.is-invalid]="
                          f.Country.invalid && (f.Country.dirty || f.Country.touched || RegisterFormClicked)
                        "
                        [class.filled]="!!Model.Country">
                        <select
                          class="form-select"
                          id="Country"
                          name="Country"
                          (ngModelChange)="CountryChange($event)"
                          formControlName="Country"
                          [(ngModel)]="Model.Country"
                          [class.is-invalid]="
                            f.Country.invalid && (f.Country.dirty || f.Country.touched || RegisterFormClicked)
                          "
                          [class.filled]="!!Model.Country">
                          <option *ngFor="let Country of Countries" [value]="Country.Id">{{ Country.Name }}</option>
                        </select>
                        <label for="Country">{{ 'account.form.label.country' | translate }}</label>
                      </div>
                    </div>
                    <div class="col-12 py-1" *ngIf="AuthSettings.CityVisible">
                      <div
                        class="form-floating"
                        [ngClass]="{
                          'is-invalid': f.City.invalid && (f.City.dirty || f.City.touched || RegisterFormClicked),
                          filled: !!Model.City
                        }">
                        <ng-container *ngIf="Model.Country == 'TUR'">
                          <select
                            class="form-select"
                            name="City"
                            formControlName="City"
                            [(ngModel)]="Model.City"
                            [ngClass]="{
                              'is-invalid': f.City.invalid && (f.City.dirty || f.City.touched || RegisterFormClicked),
                              filled: !!Model.City
                            }">
                            <option *ngFor="let City of Cities" [value]="City.Id">{{ City.Name }}</option>
                          </select>
                          <label>{{ 'account.form.label.city' | translate }}</label>
                        </ng-container>
                        <ng-container *ngIf="Model.Country != 'TUR'">
                          <input
                            class="form-control"
                            [(ngModel)]="Model.City"
                            formControlName="City"
                            type="text"
                            required
                            [ngClass]="{
                              'is-invalid': f.City.invalid && (f.City.dirty || f.City.touched || RegisterFormClicked),
                              filled: !!Model.City
                            }" />
                          <label>{{ 'account.form.label.city' | translate }}</label>
                        </ng-container>
                      </div>
                      <div
                        *ngIf="f.City.invalid && (f.City.dirty || f.City.touched || RegisterFormClicked)"
                        class="invalid-feedback">
                        {{ 'general.text.required' | translate }}
                      </div>
                    </div>
                    <div class="col-12 py-1" *ngIf="AuthSettings.GenderVisible">
                      <div
                        class="form-floating"
                        [class.is-invalid]="
                          f.Gender.invalid && (f.Gender.dirty || f.Gender.touched || RegisterFormClicked)
                        "
                        [class.filled]="!!Model.Gender">
                        <ng-container>
                          <select
                            class="form-select"
                            placeholder="Choose"
                            formControlName="Gender"
                            [(ngModel)]="Model.Gender"
                            [class.is-invalid]="
                              f.Gender.invalid && (f.Gender.dirty || f.Gender.touched || RegisterFormClicked)
                            "
                            [class.filled]="!!Model.Gender">
                            <option value="-1" [selected]="true">Seçiniz</option>
                            <option value="M">{{ 'account.form.label.man' | translate }}</option>
                            <option value="F">{{ 'account.form.label.woman' | translate }}</option>
                          </select>
                          <label
                            [class.is-invalid]="
                              f.Gender.invalid && (f.Gender.dirty || f.Gender.touched || RegisterFormClicked)
                            "
                            [class.filled]="!!Model.Gender">
                            {{ 'account.form.label.sex' | translate }}
                          </label>
                        </ng-container>
                        <div
                          *ngIf="f.Gender.invalid && (f.Gender.dirty || f.Gender.touched || RegisterFormClicked)"
                          class="invalid-feedback">
                          {{ 'general.text.required' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 py-1" *ngIf="AuthSettings.IdentityNumberVisible">
                      <div
                        class="form-floating"
                        [class.is-invalid]="!identityCheckModel"
                        [class.filled]="!!Model.IdentityNumber">
                        <input
                          id="IdentityNumber"
                          class="form-control"
                          formControlName="IdentityNumber"
                          mask="00000000000"
                          [(ngModel)]="Model.IdentityNumber"
                          (ngModelChange)="IdentityControl($event)"
                          type="text"
                          [class.is-invalid]="!identityCheckModel"
                          [class.filled]="!!Model.IdentityNumber" />
                        <label for="IdentityNumber">{{ 'account.label.ID' | translate }}</label>
                      </div>
                      <div
                        *ngIf="
                          !identityCheckModel &&
                          !(
                            f.IdentityNumber.invalid &&
                            (f.IdentityNumber.dirty || f.IdentityNumber.touched || RegisterFormClicked)
                          )
                        "
                        class="invalid-feedback">
                        {{ 'register.identitymessage' | translate }}
                      </div>

                      <div
                        *ngIf="
                          f.IdentityNumber.invalid &&
                          (f.IdentityNumber.dirty || f.IdentityNumber.touched || RegisterFormClicked)
                        "
                        class="invalid-feedback">
                        {{ 'general.text.required' | translate }}
                      </div>
                    </div>
                    <div
                      class="col-12 py-1"
                      *ngIf="AuthSettings.GsmVisible && (!OneClickFormRegisterClicked || this.step != 4)">
                      <div
                        class="w-100"
                        [class.is-invalid]="f.Gsm.invalid && (f.Gsm.dirty || f.Gsm.touched || RegisterFormClicked)"
                        [class.filled]="!!Model.Gsm">
                        <label class="form-label" for="Gsm">
                          <small>{{ 'account.label.phone' | translate }}</small>
                        </label>
                        <ngx-intl-tel-input
                          [preferredCountries]="[CountryISO.Turkey]"
                          [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="false"
                          [searchCountryFlag]="false"
                          [selectedCountryISO]="CountryISO.Turkey"
                          [maxLength]="10"
                          [phoneValidation]="AuthSettings.GsmRequired"
                          [separateDialCode]="true"
                          name="Gsm"
                          id="Gsm"
                          [cssClass]="
                            (f.Gsm.invalid && (f.Gsm.dirty || f.Gsm.touched || RegisterFormClicked)) ||
                            (!gsmCheckModel && RegisterFormClicked)
                              ? 'is-invalid form-control'
                              : 'form-control'
                          "
                          formControlName="Gsm"
                          [(ngModel)]="Model.Gsm"></ngx-intl-tel-input>

                        <div
                          *ngIf="f.Gsm.invalid && (f.Gsm.dirty || f.Gsm.touched || RegisterFormClicked)"
                          class="invalid-feedback"
                          style="display: block">
                          {{ 'general.text.gsm-format' | translate }}
                        </div>
                        <div
                          *ngIf="
                            (Model.Gsm === undefined || Model.Gsm === '' || Model.Gsm === null) &&
                            AuthSettings.GsmRequired &&
                            RegisterFormClicked
                          "
                          class="invalid-feedback is-invalid"
                          style="display: block">
                          {{ 'general.text.required' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 py-1">
                      <div
                        class="form-floating"
                        [class.is-invalid]="
                          f.Currency.invalid && (f.Currency.dirty || f.Currency.touched || RegisterFormClicked)
                        "
                        [class.filled]="!!Model.Currency">
                        <select
                          class="form-select"
                          formControlName="Currency"
                          [(ngModel)]="Model.Currency"
                          name="currencies"
                          id=""
                          [class.is-invalid]="
                            f.Currency.invalid && (f.Currency.dirty || f.Currency.touched || RegisterFormClicked)
                          "
                          [class.filled]="!!Model.Currency">
                          <option *ngFor="let C of Currencies" [value]="C.Currency">{{ C.Name }}</option>
                        </select>
                        <label>{{ 'account.form.label.currency' | translate }}</label>
                        <div
                          *ngIf="f.Currency.invalid && (f.Currency.dirty || f.Currency.touched || RegisterFormClicked)"
                          class="invalid-feedback">
                          {{ 'general.text.required' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 py-1">
                      <div
                        class="form-floating"
                        [class.is-invalid]="
                          f.Language.invalid && (f.Language.dirty || f.Language.touched || RegisterFormClicked)
                        "
                        [class.filled]="!!Model.Language">
                        <select
                          class="form-select"
                          formControlName="Language"
                          [(ngModel)]="Model.Language"
                          name="languages"
                          [class.is-invalid]="
                            f.Language.invalid && (f.Language.dirty || f.Language.touched || RegisterFormClicked)
                          "
                          [class.filled]="!!Model.Language">
                          <option *ngFor="let L of Languages" [value]="L.Language">{{ L.Name }}</option>
                        </select>
                        <label>{{ 'account.form.label.language' | translate }}</label>
                      </div>
                      <div
                        *ngIf="f.Language.invalid && (f.Language.dirty || f.Language.touched || RegisterFormClicked)"
                        class="invalid-feedback">
                        {{ 'general.text.required' | translate }}
                      </div>
                    </div>
                    <div class="col-12 py-1">
                      <div
                        class="form-floating"
                        [class.is-invalid]="
                          f.Username.invalid && (f.Username.dirty || f.Username.touched || RegisterFormClicked)
                        "
                        [class.filled]="!!Model.Username">
                        <input
                          class="form-control"
                          formControlName="Username"
                          [(ngModel)]="Model.Username"
                          type="text"
                          [class.is-invalid]="
                            f.Username.invalid && (f.Username.dirty || f.Username.touched || RegisterFormClicked)
                          "
                          [class.filled]="!!Model.Username"
                          required />
                        <label>{{ 'account.form.label.username' | translate }}</label>
                      </div>
                      <div
                        *ngIf="
                          f.Username.invalid &&
                          f.Username.errors?.required &&
                          (f.Username.dirty || f.Username.touched || RegisterFormClicked)
                        "
                        class="invalid-feedback">
                        {{ 'general.text.required' | translate }}
                      </div>
                      <div
                        *ngIf="
                          f.Username.invalid &&
                          f.Username.errors?.pattern &&
                          (f.Username.dirty || f.Username.touched || RegisterFormClicked)
                        "
                        class="invalid-feedback">
                        {{ 'general.text.incorrect.username' | translate }}
                      </div>
                    </div>
                    <div class="col-12 py-1">
                      <div
                        class="form-floating"
                        [class.is-invalid]="
                          f.Password.invalid && (f.Password.dirty || f.Password.touched || RegisterFormClicked)
                        "
                        [class.filled]="!!Model.Password">
                        <input
                          class="form-control"
                          type="password"
                          formControlName="Password"
                          [(ngModel)]="Model.Password"
                          [class.is-invalid]="
                            f.Password.invalid && (f.Password.dirty || f.Password.touched || RegisterFormClicked)
                          "
                          [class.filled]="!!Model.Password"
                          required />
                        <label>{{ 'input.password' | translate }}</label>
                      </div>
                      <div
                        *ngIf="f.Password.invalid && (f.Password.dirty || f.Password.touched || RegisterFormClicked)"
                        class="invalid-feedback">
                        {{ 'general.text.required' | translate }}
                      </div>
                      <password-strength-meter
                        [minPasswordLength]="6"
                        [password]="Model.Password"></password-strength-meter>
                    </div>
                    <div class="col-12 py-1">
                      <div
                        class="form-floating"
                        [class.is-invalid]="
                          f.Email.invalid && (f.Email.dirty || f.Email.touched || RegisterFormClicked)
                        "
                        [class.filled]="!!Model.Email">
                        <input
                          class="form-control"
                          type="email"
                          formControlName="Email"
                          [(ngModel)]="Model.Email"
                          [class.is-invalid]="
                            f.Email.invalid && (f.Email.dirty || f.Email.touched || RegisterFormClicked)
                          "
                          [class.filled]="!!Model.Email"
                          required />
                        <label>{{ 'account.form.label.email' | translate }}</label>
                      </div>
                      <div
                        *ngIf="
                          f.Email.invalid &&
                          f.Email.errors?.required &&
                          (f.Email.dirty || f.Email.touched || RegisterFormClicked)
                        "
                        class="invalid-feedback">
                        {{ 'general.text.required' | translate }}
                      </div>
                      <div
                        *ngIf="
                          f.Email.invalid &&
                          f.Email.errors?.pattern &&
                          (f.Email.dirty || f.Email.touched || RegisterFormClicked)
                        "
                        class="invalid-feedback">
                        {{ 'general.text.incorrect.email' | translate }}
                      </div>
                    </div>
                    <div class="col-12 py-1">
                      <div class="form-floating" [class.filled]="!!Model.PromotionKey">
                        <input
                          id="PromotionKey"
                          class="form-control"
                          type="text"
                          formControlName="PromotionKey"
                          [class.filled]="!!Model.PromotionKey"
                          [(ngModel)]="Model.PromotionKey" />
                        <label for="PromotionKey">{{ 'register.form.promotionkey' | translate }}</label>
                      </div>
                    </div>
                    <div class="col-12 py-1">
                      <div
                        class="form-check"
                        [class.is-invalid]="
                          f.Agreement.invalid && (f.Agreement.dirty || f.Agreement.touched || RegisterFormClicked)
                        ">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="Agreement"
                          formControlName="Agreement"
                          [checked]="true"
                          [class.is-invalid]="
                            f.Agreement.invalid && (f.Agreement.dirty || f.Agreement.touched || RegisterFormClicked)
                          " />
                        <label class="form-check-label" for="Agreement">
                          {{ 'account.form.label.over18' | translate }}
                        </label>
                      </div>
                      <div
                        *ngIf="f.Agreement.invalid && (f.Agreement.dirty || f.Agreement.touched || RegisterFormClicked)"
                        class="invalid-feedback">
                        {{ 'general.text.required' | translate }}
                      </div>
                    </div>
                    <div class="col-12 py-1">
                      <div class="form-check" *ngIf="isEUCountry">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="PoliticalPerson"
                          formControlName="PoliticalPerson"
                          id="checkbox-politicalperson" />
                        <label class="form-check-label" for="PoliticalPerson">
                          {{ 'register.political.question' | translate }}
                        </label>
                      </div>
                      <div class="invalid-feedback">
                        {{ 'general.text.required' | translate }}
                      </div>
                    </div>
                    <div class="col-12 py-1 text-center">
                      <button type="submit" class="btn btn-base">
                        <span *ngIf="!Loader">{{ 'btn.confirm' | translate }}</span>
                        <div *ngIf="Loader" class="spinner-border" role="status"></div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div *ngIf="step === 2" class="register-step d-flex justify-content-center align-items-center">
            <div>
              <div>
                <label>{{ 'register.creation.message' | translate }}</label>
              </div>

              <div class="spinner-border register-spinner-modal spinner-step" role="status"></div>
            </div>
          </div>
          <div *ngIf="step === 3" class="register-step d-flex justify-content-center align-items-center">
            <div *ngIf="registerStatus === true">
              <div>
                <label>{{ successMessage }}</label>
              </div>
              <div class="text-center">
                <div class="screenAlert-icon screenAlert-success animate">
                  <span class="screenAlert-line screenAlert-tip animateSuccessTip"></span>
                  <span class="screenAlert-line screenAlert-long animateSuccessLong"></span>
                  <div class="screenAlert-placeholder"></div>
                  <div class="screenAlert-fix"></div>
                </div>
              </div>
            </div>
            <div *ngIf="registerStatus === false">
              <div class="text-center">
                <label>{{ errorMessage }}</label>
                <div class="screenAlert-icon screenAlert-error animate">
                  <span class="screenAlert-x-mark">
                    <span class="screenAlert-line screenAlert-left animateXLeft"></span>
                    <span class="screenAlert-line screenAlert-right animateXRight"></span>
                  </span>
                  <div class="screenAlert-placeholder"></div>
                  <div class="screenAlert-fix"></div>
                </div>
                <button type="submit" (click)="returnStep()" class="btn btn-warning btn-register-back">
                  {{ 'btn.register.back' | translate }}
                </button>
              </div>
            </div>
          </div>
        </tab>
        <tab *ngIf="AuthSettings.FastRegistrationEnabled" (selectTab)="tabChanged('FastRegister')">
          <ng-template tabHeading>{{ 'btn.fastregister' | translate }}</ng-template>
          <div class="inner" [hidden]="step !== 1">
            <form
              [formGroup]="OneClickForm"
              name="OneClickForm"
              class="one-click-register-form"
              (ngSubmit)="OneClickRegister()">
              <div class="row p-3">
                <div class="col-12 py-1">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      formControlName="Username"
                      [(ngModel)]="Model.Username"
                      placeholder="{{ 'account.form.label.username' | translate }}"
                      type="text" />
                    <label
                      [class.is-invalid]="
                        fFast.Username.invalid &&
                        (fFast.Username.dirty || fFast.Username.touched || OneClickFormRegisterClicked)
                      "
                      [class.filled]="!!Model.Username">
                      {{ 'account.form.label.username' | translate }}
                    </label>
                  </div>
                  <div
                    *ngIf="
                      fFast.Username.invalid &&
                      fFast.Username.errors?.required &&
                      (fFast.Username.dirty || fFast.Username.touched || OneClickFormRegisterClicked)
                    "
                    class="invalid-feedback">
                    {{ 'general.text.required' | translate }}
                  </div>
                  <div
                    *ngIf="
                      fFast.Username.invalid &&
                      fFast.Username.errors?.pattern &&
                      (fFast.Username.dirty || fFast.Username.touched || OneClickFormRegisterClicked)
                    "
                    class="invalid-feedback">
                    {{ 'general.text.incorrect.username' | translate }}
                  </div>
                </div>
                <div class="col-12 py-1">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      name=""
                      formControlName="Country"
                      [(ngModel)]="Model.Country"
                      (ngModelChange)="CountryChange($event)">
                      <option *ngFor="let Country of Countries" [value]="Country.Id">{{ Country.Name }}</option>
                    </select>
                    <label
                      class="w-100"
                      [class.is-invalid]="
                        fFast.Country.invalid &&
                        (fFast.Country.dirty || fFast.Country.touched || OneClickFormRegisterClicked)
                      "
                      [class.filled]="!!Model.Country">
                      {{ 'account.form.label.country' | translate }}
                    </label>
                  </div>
                  <div
                    *ngIf="
                      fFast.Country.invalid &&
                      (fFast.Country.dirty || fFast.Country.touched || OneClickFormRegisterClicked)
                    "
                    class="invalid-feedback">
                    {{ 'general.text.required' | translate }}
                  </div>
                </div>
                <div class="col-12 py-1">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      formControlName="Currency"
                      [(ngModel)]="Model.Currency"
                      name="currencies"
                      id="">
                      <option *ngFor="let C of Currencies" [value]="C.Currency">{{ C.Name }}</option>
                    </select>
                    <label
                      [class.is-invalid]="
                        fFast.Currency.invalid &&
                        (fFast.Currency.dirty || fFast.Currency.touched || OneClickFormRegisterClicked)
                      "
                      [class.filled]="!!Model.Currency">
                      {{ 'account.form.label.currency' | translate }}
                    </label>

                    <div
                      *ngIf="
                        fFast.Currency.invalid &&
                        (fFast.Currency.dirty || fFast.Currency.touched || OneClickFormRegisterClicked)
                      "
                      class="invalid-feedback">
                      {{ 'general.text.required' | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-12 py-1">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="{{ 'register.form.promotionkey' | translate }}"
                      formControlName="PromotionKey"
                      [(ngModel)]="Model.PromotionKey" />
                    <label class="" [class.filled]="!!Model.PromotionKey">
                      {{ 'register.form.promotionkey' | translate }}
                    </label>
                  </div>
                </div>
                <div class="col-12 py-2">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      formControlName="Agreement"
                      id="customCheck1"
                      checked="checked"
                      class="form-check-input" />
                    <label class="form-check-label" for="customCheck1">
                      {{ 'account.form.label.over18' | translate }}
                    </label>
                  </div>
                  <div class="form-group mt-2 pl-2 pr-2 mb-0">
                    <div
                      class="custom-control pr-1 custom-checkbox"
                      [class.is-invalid]="
                        fFast.Agreement.errors &&
                        (fFast.Agreement.dirty || fFast.Agreement.touched || OneClickFormRegisterClicked)
                      "></div>
                    <div
                      *ngIf="
                        fFast.Agreement.errors &&
                        (fFast.Agreement.dirty || fFast.Agreement.touched || OneClickFormRegisterClicked)
                      "
                      class="invalid-feedback">
                      {{ 'general.text.required' | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="recaptcha-v3 mt-4">
                    <div class="inner">
                      Bu site
                      <strong>reCAPTCHA</strong>
                      ve
                      <strong>Google</strong>
                      tarafından korunmaktadır.
                      <a href="https://policies.google.com/privacy" target="_blank" trans="">Privacy Policy</a>
                      and
                      <a href="https://policies.google.com/terms" target="_blank" trans="">Terms of Service</a>
                      .
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mt-3 text-center">
                    <button type="submit" class="btn btn-custom theme btn-primary w-50 d-inline-block">
                      <span *ngIf="!Loader">{{ 'btn.register' | translate }}</span>
                      <div *ngIf="Loader" class="spinner-border" role="status"></div>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="step === 2" class="register-step d-flex justify-content-center align-items-center">
            <div>
              <div>
                <label>{{ 'register.creation.message' | translate }}</label>
              </div>

              <div class="spinner-border register-spinner-modal spinner-step" role="status"></div>
            </div>
          </div>
          <div *ngIf="step === 3" class="register-step d-flex justify-content-center align-items-center">
            <div *ngIf="registerStatus === true">
              <div>
                <label>{{ successMessage }}</label>
              </div>
              <div class="text-center">
                <div class="screenAlert-icon screenAlert-success animate">
                  <span class="screenAlert-line screenAlert-tip animateSuccessTip"></span>
                  <span class="screenAlert-line screenAlert-long animateSuccessLong"></span>
                  <div class="screenAlert-placeholder"></div>
                  <div class="screenAlert-fix"></div>
                </div>
              </div>
            </div>
            <div *ngIf="registerStatus === false">
              <div class="text-center">
                <label>{{ errorMessage }}</label>
                <div class="screenAlert-icon screenAlert-error animate">
                  <span class="screenAlert-x-mark">
                    <span class="screenAlert-line screenAlert-left animateXLeft"></span>
                    <span class="screenAlert-line screenAlert-right animateXRight"></span>
                  </span>
                  <div class="screenAlert-placeholder"></div>
                  <div class="screenAlert-fix"></div>
                </div>
                <button type="submit" (click)="returnStep()" class="btn btn-warning btn-register-back">
                  {{ 'btn.register.back' | translate }}
                </button>
              </div>
            </div>
          </div>
          <form
            [formGroup]="OneClickForm"
            name="OneClickForm"
            class="one-click-register-form"
            (ngSubmit)="OneClickRegister()">
            <div class="hub-overlay" style="padding-top: unset" *ngIf="step == 4 && OneClickFormRegisterClicked">
              <form
                [formGroup]="FastRegisterForm"
                name="FastRegisterForm"
                class="one-click-register-form"
                (ngSubmit)="OneClickRegister()">
                <div
                  class="w-100"
                  [class.is-invalid]="f.Gsm.invalid && (f.Gsm.dirty || f.Gsm.touched || OneClickFormRegisterClicked)"
                  [class.filled]="!!Model.Gsm">
                  <label class="form-label" for="Gsm">
                    <small>{{ 'account.label.phone' | translate }}</small>
                  </label>

                  <ngx-intl-tel-input
                    [preferredCountries]="[CountryISO.Turkey]"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="false"
                    [searchCountryFlag]="false"
                    [selectedCountryISO]="CountryISO.Turkey"
                    [maxLength]="10"
                    [phoneValidation]="AuthSettings.GsmRequired"
                    [separateDialCode]="true"
                    name="Gsm"
                    id="Gsm"
                    formControlName="Gsm"
                    [(ngModel)]="Model.Gsm"></ngx-intl-tel-input>
                  <div
                    *ngIf="f.Gsm.invalid && (f.Gsm.dirty || f.Gsm.touched || OneClickFormRegisterClicked)"
                    class="invalid-feedback"
                    style="display: block">
                    {{ 'general.text.gsm-format' | translate }}
                  </div>
                  <div
                    *ngIf="
                      (Model.Gsm === undefined || Model.Gsm === '' || Model.Gsm === null) &&
                      AuthSettings.GsmRequired &&
                      IsGsmClicked
                    "
                    class="invalid-feedback is-invalid"
                    style="display: block">
                    {{ 'general.text.required' | translate }}
                  </div>
                </div>
              </form>
            </div>
          </form>
          <div class="mt-2" *ngIf="step == 6">
            <div class="text-center">
              <label>{{ errorMessage }}</label>
              <div class="screenAlert-icon screenAlert-error animate">
                <span class="screenAlert-x-mark">
                  <span class="screenAlert-line screenAlert-left animateXLeft"></span>
                  <span class="screenAlert-line screenAlert-right animateXRight"></span>
                </span>
                <div class="screenAlert-placeholder"></div>
                <div class="screenAlert-fix"></div>
              </div>
              <button type="submit" (click)="returnStep()" class="btn btn-warning btn-register-back">
                {{ 'btn.register.back' | translate }}
              </button>
            </div>
          </div>
          <div class="nub-overlay" style="padding-top: unset" *ngIf="step == 5">
            <div class="nub" style="min-height: unset; max-width: unset">
              <div class="text-left register-form">
                <div class="row">
                  <div class="col-8 offset-2">
                    <div class="form-group mt-3 text-center">
                      <label>{{ gsmVerifyDescription }}</label>
                    </div>
                  </div>
                </div>
                <form [formGroup]="GsmVerifyForm" name="GsmVerifyForm">
                  <div class="row">
                    <div class="col-8 offset-2">
                      <div class="form-floating mb-3 mt-3 text-center">
                        <input
                          type="text"
                          class="form-control"
                          formControlName="ActivationCode"
                          [(ngModel)]="GsmActivationCode" />
                        <label>{{ 'account.label.code' | translate }}</label>
                      </div>
                      <span>
                        <button *ngIf="CountDown !== 0" class="btn btn-base text-light w-100" type="button">
                          {{ CountDown }}sn
                        </button>
                        <button
                          *ngIf="CountDown === 0"
                          class="btn btn-base text-light w-100"
                          type="button"
                          (click)="ReSendGsmVerification()">
                          {{ 'btn.sendagain' | translate }}
                        </button>
                      </span>
                    </div>
                  </div>
                </form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group mt-3 text-center">
                      <a
                        href="javascript:void(0)"
                        class="mr-3 btn btn-custom theme btn-primary w-50 d-inline-block"
                        (click)="CheckGsmForFastRegister($event)">
                        <span *ngIf="!gsmLoader">{{ 'btn.register' | translate }}</span>
                        <div *ngIf="gsmLoader" class="spinner-border" role="status"></div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group mt-3 text-center">
                      <button type="button" class="mr-3 btn btn-base d-inline-block w-50" (click)="GsmVerifyGoBack()">
                        <span *ngIf="!Loader">{{ 'btn.register.goback' | translate }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
      </tabset>
      <div class="nub-overlay" style="padding-top: unset" *ngIf="step == 4">
        <span>{{ gsmVerifyDescription }}</span>
        <br />
        <div class="nub" style="min-height: unset; max-width: unset">
          <div class="text-left register-form">
            <div class="row" *ngIf="DefaultRegisterStep == 2">
              <div class="col-8 offset-2">
                <div class="form-group mt-3 text-center">
                  <label>{{ gsmVerifyApproveDescription }}</label>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group mt-3 text-center">
                        <button
                          type="button"
                          class="mr-3 btn base d-inline-block w-50"
                          (click)="ReSendGsmVerification()">
                          <span *ngIf="!gsmLoader">{{ 'btn.sendcode' | translate }}</span>
                          <div *ngIf="gsmLoader" class="spinner-border" role="status"></div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form [formGroup]="GsmVerifyForm" name="GsmVerifyForm" *ngIf="DefaultRegisterStep == 1">
              <div class="row">
                <div class="col-8 offset-2">
                  <div class="form-floating mb-3 mt-3 text-center">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="ActivationCode"
                      [(ngModel)]="GsmActivationCode" />
                    <label>{{ 'account.label.code' | translate }}</label>
                  </div>
                  <span>
                    <button *ngIf="CountDown !== 0" class="btn btn-base text-light w-100" type="button">
                      {{ CountDown }}sn
                    </button>
                    <button
                      *ngIf="CountDown === 0"
                      class="btn btn-base text-light w-100"
                      type="button"
                      (click)="ReSendGsmVerification()">
                      {{ 'btn.sendagain' | translate }}
                    </button>
                  </span>
                </div>
              </div>
            </form>
            <div class="row" *ngIf="DefaultRegisterStep == 1">
              <div class="col-12">
                <div class="form-group mt-3 text-center">
                  <a
                    href="javascript:void(0)"
                    class="mr-3 btn btn-custom theme btn-primary w-50 d-inline-block"
                    (click)="CheckGsmForRegister($event)">
                    <span *ngIf="!gsmLoader">{{ 'btn.register' | translate }}</span>
                    <div *ngIf="gsmLoader" class="spinner-border" role="status"></div>
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group mt-3 text-center">
                  <button type="button" class="mr-3 btn btn-base d-inline-block w-50" (click)="GsmVerifyGoBack()">
                    <span *ngIf="!Loader">{{ 'btn.register.goback' | translate }}</span>
                  </button>
                  <button
                    type="button"
                    class="mr-3 btn btn-base d-inline-block w-50"
                    *ngIf="this.step == 4 && this.OneClickFormRegisterClicked"
                    (click)="SendFastRegisterGsm()">
                    <span *ngIf="!Loader">{{ 'btn.register.save' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="openMobileFab()">
        <i class="bw-i-live-chat me-1"></i>
        {{ 'btn.support' | translate }}
      </button>
      <button type="button" class="btn btn-base" data-bs-dismiss="modal" (click)="Close()">
        {{ 'btn.close' | translate }}
      </button>
    </div>
  </div>
</ng-template>
