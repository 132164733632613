import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countSlider',
})
export class CountSliderPipe implements PipeTransform {
  numbers: any = [];

  transform(value: number, ...args: unknown[]): unknown {
    this.numbers = [];
    for (let i = 1; i <= 10; i++) {
      if (value + i) {
        this.numbers.push((value + i).toString());
      }
    }
    var returnNumber: string = '';
    for (var el of this.numbers) {
      if (el !== 'NAN' && el !== '' && el !== undefined)
        if (el >= 10) {
          el = el.substring(1, 2);
        }
      returnNumber = returnNumber + el + '\n';
    }

    return returnNumber;
  }
}
