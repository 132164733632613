<ng-template #Template>
  <div class="modal-content">
    <div class="modal-header d-flex justify-content-between">
      <div class="start">
        {{ 'resetmodal.title' | translate }}
      </div>
      <div class="end">
        <a href="javascript:void(0)" (click)="Close()" class="btn btn-close"><i class="fa fa-close"></i></a>
      </div>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="!ConfirmActive">
        <form [formGroup]="ResetForm" class="login-form p-3" (submit)="ResetPassword()">
          <p class="notify">{{ 'resetmodal.text' | translate }}</p>
          <div class="form-floating py-1" [class.is-invalid]="f.Email.invalid && (f.Email.dirty || f.Email.touched)">
            <input
              type="email"
              class="form-control"
              formControlName="Email"
              id="Email"
              placeholder="{{ 'account.form.label.email' | translate }}"
              [class.is-invalid]="f.Email.invalid && (f.Email.dirty || f.Email.touched)" />
            <label for="Email">{{ 'account.form.label.email' | translate }}</label>
          </div>
          <div *ngIf="f.Email.invalid && (f.Email.dirty || f.Email.touched)" class="invalid-feedback">
            {{ 'general.text.required' | translate }}
          </div>
          <div class="w-100 text-center2">
            <button type="submit" [disabled]="Loader" class="btn btn-theme mt-3">
              <span>{{ 'btn.send' | translate }}</span>
            </button>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="ConfirmActive">
        <form
          class="login-form p-3 d-none"
          [formGroup]="ConfirmForm"
          class="login-form p-3"
          (submit)="ConfirmPassword()">
          <div class="form-group">
            <label for="">{{ 'account.label.code' | translate }}</label>
            <input
              [class.is-invalid]="cf.Code.invalid && (cf.Code.dirty || cf.Code.touched)"
              type="text"
              class="form-control"
              id="code"
              formControlName="Code"
              placeholder="{{ 'account.label.code' | translate }}" />
          </div>
          <div class="form-group">
            <label for="">{{ 'account.label.newpassword' | translate }}</label>
            <input
              [class.is-invalid]="cf.Password.invalid && (cf.Password.dirty || cf.Password.touched)"
              type="password"
              class="form-control"
              id="password"
              formControlName="Password"
              placeholder="{{ 'account.label.newpassword' | translate }}" />
          </div>
          <button [disabled]="ConfirmForm.invalid" type="submit" class="btn btn-block base rounded-custom x3 text-md">
            <span>{{ 'btn.send' | translate }}</span>
          </button>
        </form>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="openMobileFab()">
        <i class="bw-i-live-chat me-1"></i>
        {{ 'btn.support' | translate }}
      </button>
      <button type="button" class="btn btn-base" data-bs-dismiss="modal">
        {{ 'btn.close' | translate }}
      </button>
    </div>
  </div>
</ng-template>
