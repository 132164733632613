<div *ngFor="let brand of list; index as i" class="popular-games">
  <div class="title-v1 my-2">
    <div class="title-v1-left">
      <i class="icon-set icon-PopularGames mr-2"></i>
      <span>{{ 'common.title.popularslots' | translate }} {{ brand.BrandName }}</span>
    </div>
    <div class="title-v1-right">
      <a [routerLink]="['/casino/', brand.BrandId]" class="btn-more btn btn-dark mr-2">
        {{ 'common.seeall' | translate }}
      </a>
      <div class="left-arrow btn btn-dark" (click)="scrollStepByPixels(i, -310 - 5)">
        <i class="fas fa-chevron-left"></i>
      </div>
      <div class="right-arrow btn btn-dark" (click)="scrollStepByPixels(i, 310 + 5)">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
  </div>

  <ul #listRefs (scroll)="onScrollContainer(i)" [ngStyle]="getListStyles(brand)">
    <li *ngFor="let game of brand.Games">
      <img src="{{ game.GameImage }}" alt="" />
      <div class="game-footer">
        <span>{{ game.GameName }}</span>
        <i>X</i>
      </div>
      <a [routerLink]="['/casino/', brand.BrandId]" class="btn-brand">
        {{ 'brandnames.' + brand.BrandName.toLowerCase() | translate }}
      </a>
      <div class="play-buttons">
        <a class="play-now" href="javascript:void(0)" (click)="OpenGame()">
          {{ 'btn.playnow' | translate }}
        </a>
        <a class="demo-game" href="javascript:void(0)" (click)="OpenDemoGame()" *ngIf="game">
          {{ 'text.demogame.header' | translate }}
        </a>
      </div>
    </li>
  </ul>
</div>
