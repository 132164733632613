import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AppConfigService } from 'src/app/config/app-config.service';
import { Config } from 'src/app/config';
import { IStylesType } from 'src/app/config/config.interface';
import {
  DbContentListResponse,
  GetContentListRequest,
  SocialMediaResponse,
  PromotionsImagesAndTextsRequest,
  SlotBrandListRequest,
  SlotGameList,
  SlotGameListRequest,
  PlayerSessionData,
  SiteLanguagesRequest,
  SiteLanguage,
  DbMenuResponse,
  GetPlayerContactIncomingMessagesRequest,
  DbPlayerContactIncomingMessagesResponse,
  GetPlayerContactIncomingMessagesRequest_v2,
} from 'src/app/models/service.dtos';
import { GeneralService } from 'src/app/services/General.service';
import { ActivatedRoute, Router, Event } from '@angular/router';
import { NavigationStart, NavigationError, NavigationEnd } from '@angular/router';

import { BsModalService } from 'ngx-bootstrap/modal';
import * as Types from 'src/app/constants';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { WindowRefService } from '../../../services/WindowRef.service';
import { StorageService } from '../../../services/Storage.service';
import { createSupportButtons } from 'src/app/utils/FloatingActionButtons';
import { CASINO } from 'src/app/constants';
import { StickyService } from 'src/app/services/Header.service';
declare var $: any;
@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
})
export class HomeHeaderComponent implements OnInit {
  @Input('ActiveBrandId') ActiveBrandId: number;
  notificationMessages$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  Messages: DbPlayerContactIncomingMessagesResponse[] = [];
  SystemNotifications: DbPlayerContactIncomingMessagesResponse[] = [];
  NotifyCount = 0;
  MessageCount = 0;
  IsNotificationSubscribed = false;
  ContentList: DbContentListResponse[] = [];
  Subscribe: Subscription = new Subscription();
  Title: string = 'footer.title.livecasino';
  Menus: DbMenuResponse[] = Config.Menu;
  AllGames: SlotGameList[] = [];
  Promotions: any = [];
  Categories: { GroupName: string }[] = [];
  SelectedBrandId: number = 0;
  TimeNow = new Date();
  IsLoggedIn: boolean = false;
  ActiveCategory: string = '';
  public Types = Types;
  ContentListRight: DbContentListResponse[] = [];
  ContentListLeft: DbContentListResponse[] = [];
  SocialMedias: SocialMediaResponse[] = [];
  ClientId: number = Config.settings.ClientId;
  mobilNavbar: boolean = false;
  mobilNavbarProfile: boolean = false;
  Languages: SiteLanguage[];
  ActiveLanguages: string = localStorage.getItem('lang');
  callForm!: UntypedFormGroup;
  isSubmit: boolean = false;
  isAlert: boolean = true;
  AuthSettings = Config.AuthenticationSettings;
  mobileSupportButtons = createSupportButtons(
    (s) => s.IsActive && s.ShowType != 1 && s.IsSupport === true,
    '#mobileFloatButton > ul'
  );
  hiddenMenus: any[] = [];
  IsSportsbookActive: boolean = Config.Menu.filter((s) => s.IsActive && s.UrlRoute.includes('sportsbook')).length > 0;
  SportsbookTicketUrls: string[] = Config.Menu.filter((s) => s.UrlRoute.includes('sportsbook') && s.IsActive)
    .map(function (item) {
      return item.UrlRoute.replace('/live', '') + '/tickets';
    })
    .filter((value, index, array) => array.indexOf(value) === index);
  @ViewChild('navContainer', { static: false }) navContainer!: ElementRef;
  desktopSupportButtons = createSupportButtons(
    (s) => s.IsActive && s.ShowType != 2 && s.IsSupport === true,
    '#desktopFloatButton > ul'
  );
  notVisibleCount: number = 0;
  isSticky: boolean = false;
  private destroy$ = new Subject<void>();
  activeOffCanvasTab: number = 0;
  @ViewChild('favoriteGames') favoriteGames: any;
  @ViewChild('lastPlayedGames') lastPlayedGames: any;
  constructor(
    private ConfigService: AppConfigService,
    public Router: Router,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private Service: GeneralService,
    private activatedRoute: ActivatedRoute,
    private WindowService: WindowRefService,
    private localStorageService: StorageService,
    private renderer: Renderer2,
    private stickyService: StickyService
  ) {
    this.isAlert =
      localStorage.getItem('hideSiteNotificationBar') === null
        ? true
        : !(localStorage.getItem('hideSiteNotificationBar') == 'true');
    this.Router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.menuActive();
      }
    });
    setInterval(() => {
      this.TimeNow = new Date();
    }, 1000);

    Router.events.subscribe((val: any) => {
      while (val.url) {
        this.accordionShower = val.url;
        break;
      }
    });
    this.ConfigService.MobileMenuModal.subscribe((res: any) => {
      if (res == 'close') {
        this.closeMobileMenu();
      }
    });
  }
  ngAfterViewInit() {
    if (this.IsMobile) {
      return;
    }
    this.calculateVisibleMenus();
  }

  @HostListener('window:resize')
  onResize() {
    if (this.IsMobile) {
      return;
    }
    this.calculateVisibleMenus();
  }

  calculateVisibleMenus() {
    this.notVisibleCount = 0;
    this.hiddenMenus = [];

    if (this.navContainer) {
      const container = this.navContainer.nativeElement;
      const containerWidth = container.clientWidth;

      const navMenus = container.querySelectorAll('ul.nav-menu > li');

      let totalWidth = 0;
      let lastVisibleIndex = -1;

      navMenus.forEach((navMenu: any, index: number) => {
        if (!navMenu.classList.contains('bw-nav-more')) {
          this.renderer.setStyle(navMenu, 'visibility', 'visible');
          this.renderer.setStyle(navMenu, 'position', 'static');
          this.renderer.setStyle(navMenu, 'display', 'block');
        }

        totalWidth += navMenu.offsetWidth;

        if (totalWidth <= containerWidth) {
          lastVisibleIndex = index;
        }
      });

      if (lastVisibleIndex < navMenus.length - 1) {
        navMenus.forEach((navMenu: any, index: number) => {
          if (index > lastVisibleIndex) {
            if (!navMenu.classList.contains('bw-nav-more')) {
              this.hiddenMenus.push(this.Menus[index]);
              this.renderer.setStyle(navMenu, 'display', 'none');
              this.notVisibleCount += 1;
            }
          } else {
            const itemRightEdge = navMenu.getBoundingClientRect().right;
            const containerRightEdge = container.getBoundingClientRect().right;
            if (containerRightEdge - itemRightEdge < 40) {
              this.hiddenMenus.push(this.Menus[index]);
              this.renderer.setStyle(navMenu, 'display', 'none');
              this.notVisibleCount += 1;
            }
          }
        });
      }
    } else {
      console.error('Container is undefined');
    }
  }
  closeAlert(id = null) {
    this.isAlert = false;
    localStorage.setItem('hideSiteNotificationBar_' + id, 'true');
    $('#gameFrame').addClass('hide');
  }

  mobileMenu() {
    this.mobilNavbar = !this.mobilNavbar;
    this.mobilNavbarProfile = false;
    if (this.mobilNavbar && window.innerWidth <= 992) {
      $('body').addClass('fixed');
    } else if (!this.mobilNavbar && window.innerWidth <= 992) {
      $('body').removeClass('fixed');
    }
  }

  mobileMenuProfile() {
    this.mobilNavbarProfile = !this.mobilNavbarProfile;
    this.mobilNavbar = false;
    if (this.mobilNavbarProfile && window.innerWidth <= 992) {
      $('body').addClass('fixed');
    } else if (!this.mobilNavbarProfile && window.innerWidth <= 992) {
      $('body').removeClass('fixed');
    }
  }

  navbarMenu() {
    this.mobilNavbarProfile = !this.mobilNavbarProfile;
  }
  openDesktopFab() {
    if (this.desktopSupportButtons.length > 1) {
      document
        .querySelector('#desktopFloatButton > ul')
        .setAttribute(
          'data-mfb-state',
          document.querySelector('#desktopFloatButton > ul').getAttribute('data-mfb-state') === 'open'
            ? 'closed'
            : 'open'
        );
    } else {
      this.desktopSupportButtons[0].onClick();
    }
  }
  openMobileFab = () => {
    if (!this.IsMobile) {
      this.openDesktopFab();
      return;
    }
    if (this.mobileSupportButtons.length > 1) {
      document
        .querySelector('#mobileFloatButton > ul')
        .setAttribute(
          'data-mfb-state',
          document.querySelector('#mobileFloatButton > ul').getAttribute('data-mfb-state') === 'open'
            ? 'closed'
            : 'open'
        );
    } else {
      this.mobileSupportButtons[0].onClick();
    }
  };
  getPlayerMessages() {
    let Request = new GetPlayerContactIncomingMessagesRequest();
    this.Service.GetPlayerContactIncomingMessages(Request).subscribe((Response) => {
      this.ConfigService.PlayerNotifications.next(Response);
    });
  }

  getPlayerSystemNotifications() {
    let Request = new GetPlayerContactIncomingMessagesRequest();
    this.Service.GetPlayerSystemNotifications(Request).subscribe((Response) => {
      this.ConfigService.PlayerSystemNotifications.next(Response);
    });
  }

  NotificationCount() {
    let x = this.Messages.filter((x) => x.IsNew).length;
    this.MessageCount = x;
  }

  SystemNotificationCount() {
    let x = this.SystemNotifications.filter((x) => x.IsNew).length;
    this.NotifyCount = x;
  }

  ngOnInit(): void {
    this.stickyService.isSticky$.pipe(takeUntil(this.destroy$)).subscribe((isSticky) => {
      this.isSticky = isSticky;
    });
    const casinoHistory = this.localStorageService.get(`${CASINO}History`);

    this.getPlayerNotifications();
    this.ConfigService.LoginStatus.subscribe((Status) => {
      this.IsLoggedIn = Status;
      if (this.IsLoggedIn && !this.IsNotificationSubscribed) {
        this.Subscribe.add(
          this.ConfigService.PlayerNotifications.subscribe((Response) => {
            this.Messages = Response;
            this.NotificationCount();
          })
        );
        this.Subscribe.add(
          this.ConfigService.PlayerSystemNotifications.subscribe((Response) => {
            this.Messages = Response;
            this.SystemNotificationCount();
          })
        );
        this.IsNotificationSubscribed = true;
        this.getPlayerMessages();
        this.getPlayerSystemNotifications();
      }
    });
    this.getPromotions();
    this.initData();
    if (!this.ActiveBrandId) {
      this.ConfigService.ActiveBrandId.subscribe((BrandId) => {
        this.ActiveBrandId = BrandId;
      });
    }
    function dropdownMenuMove() {
      let dropdownChild = document.querySelector('.desktop-menu-dropdown');
      let dropdownChildMenu = document.querySelector('.desktop-menu .navbar-nav');
      dropdownChild.querySelectorAll('li').forEach(function (item) {
        dropdownChildMenu.appendChild(item).className = 'nav-item';
      });
    }
    async function menuResize() {
      // let menu_left = document.querySelector('.desktop-menu .left-navbar');
      // let menu_left_items = menu_left.querySelectorAll('li');
      // let dropdown = document.querySelector('.desktop-menu-dropdown');
      // let dropdown_body = document.querySelector('.extended-dropdown');
      // let dropdownCounterText = document.querySelector('.extended-counter');
      // let menu_json = [];
      // let old_w_width = menu_left.clientWidth + 40 + (dropdown_body.clientWidth != 0 ? dropdown_body.clientWidth : 0);
      // let isScroll = false;
      // await menu_left_items.forEach((item) => {
      //   let item_width = item.clientWidth;
      //   menu_json.push({
      //     element: item,
      //     width: item_width,
      //   });
      // });
      //
      // async function calculateMenu() {
      //   let window_width =
      //     menu_left.clientWidth + 40 + (dropdown_body.clientWidth != 0 ? dropdown_body.clientWidth : 0);
      //   let width = 0;
      //   let resize_way = old_w_width >= window_width ? true : false;
      //   menu_json.forEach(async (item) => {
      //     let item_width = item.width;
      //     width += item_width;
      //     if (resize_way && width + item_width > window_width) {
      //       await dropdown.appendChild(item.element);
      //     }
      //     if (!resize_way && width + item_width <= window_width) {
      //       await menu_left.appendChild(item.element);
      //     }
      //   });
      //   dropdownCounterText.innerHTML =
      //     dropdown.querySelectorAll('li').length > 0
      //       ? `+${dropdown.querySelectorAll('li').length.toString()} <i class="fa fa-bars">`
      //       : '';
      //   old_w_width = window_width;
      // }
      // calculateMenu();
      // window.addEventListener('resize', () => {
      //   calculateMenu();
      //   calculateMenu();
      // });
      // window.addEventListener('scroll', async () => {
      //   var st = window.pageYOffset || document.documentElement.scrollTop;
      //   if (!isScroll && st > 40) {
      //     await $('#betDesktop').addClass('fixed-top navbar-blur');
      //     await $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
      //     await calculateMenu();
      //     await calculateMenu();
      //     isScroll = true;
      //   } else if (st <= 40) {
      //     $('#betDesktop').removeClass('fixed-top navbar-blur');
      //     $('body').css('padding-top', '0');
      //     await calculateMenu();
      //     await calculateMenu();
      //     isScroll = false;
      //   }
      // });
    }
    async function menuActive() {
      $('.mobile-menu-item.active').removeClass('active');
      $('.mobile-menu-collapse-item').addClass('collapsed');
      $('.mobile-menu-collapse-sub-item').addClass('collapse');
      $.each($('.mobile-menu-item'), function () {
        if (
          $(this).attr('ng-reflect-router-link') === window.location.pathname ||
          $(this).attr('href') === window.location.pathname
        ) {
          if ($(this).parents('.nav-item').length > 0) {
            $($(this).parents('.nav-item')[0]).addClass('active');
          }
          $(this).addClass('active');
          if ($(this).parents('.mobile-menu-main-item').length > 0) {
            $($(this).parents('.mobile-menu-main-item')[0]).addClass('active');
            $($($(this).parents('.mobile-menu-main-item')[0]).find('.mobile-menu-collapse-item')).removeClass(
              'collapsed'
            );
            $($($(this).parents('.mobile-menu-main-item')[0]).find('.mobile-menu-collapse-sub-item')).removeClass(
              'collapse'
            );
          }
        }
      });
    }

    $(document).ready(function () {
      menuResize();
      menuActive();
    });
    this.createInfoForms();

    this.getSocialMedia();
    // this.getContentList();
    // this.getContentListHeader();

    // this.getBrandList();
    this.getSlotGameList();
    this.getCasinoCategories();
    this.getSiteLanguages();
  }

  get f() {
    return this.callForm.controls;
  }
  menuActive() {
    $('.mobile-menu-item.active').removeClass('active');
    $('.mobile-menu-main-item.active').removeClass('active');
    $.each($('.mobile-menu-collapse-item'), function () {
      $(this).addClass('collapsed');
    });
    $.each($('.mobile-menu-collapse-sub-item'), function () {
      $(this).addClass('collapse');
      $(this).removeClass('show');
    });
    // $('.mobile-menu-collapse-item').addClass('collapsed');
    // $('.mobile-menu-collapse-sub-item').addClass('collapse');
    $.each($('.mobile-menu-item'), function () {
      if (
        $(this).attr('ng-reflect-router-link') === window.location.pathname ||
        $(this).attr('href') === window.location.pathname
      ) {
        if ($(this).parents('.nav-item').length > 0) {
          $($(this).parents('.nav-item')[0]).addClass('active');
        }
        $(this).addClass('active');
        if ($(this).parents('.mobile-menu-main-item').length > 0) {
          $($(this).parents('.mobile-menu-main-item')[0]).addClass('active');
          $($($(this).parents('.mobile-menu-main-item')[0]).find('.mobile-menu-collapse-item')).removeClass(
            'collapsed'
          );
          $($($(this).parents('.mobile-menu-main-item')[0]).find('.mobile-menu-collapse-sub-item')).removeClass(
            'collapse'
          );
        }
      }
    });
  }

  getSiteLanguages() {
    let Request = new SiteLanguagesRequest();
    this.Service.siteLanguages(Request).subscribe((Response) => {
      this.Languages = Response;
    });
  }

  createInfoForms() {
    this.callForm = this.formBuilder.group({
      fullName: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.required]),
    });
  }

  callRequest() {
    this.isSubmit = true;

    if (this.callForm.invalid) {
      return;
    }

    if (this.callForm.valid) {
      let formData = Object.assign({}, this.callForm.value);
    }
  }

  setLanguage(lang) {
    localStorage.setItem('lang', lang.Language);
    this.ActiveLanguages = lang.Language;
    window.location.reload();
  }

  get LanguageName() {
    let x = (this.Languages || []).filter((x) => x.Language === this.ActiveLanguages);
    if (x.length) return x[0].Language.toUpperCase();
  }

  private _time$: Observable<Date> = timer(0, 1000).pipe(
    map((tick) => new Date()),
    shareReplay(1)
  );

  get time() {
    return this._time$;
  }

  getSlotGameList() {
    this.getSlots(this.SelectedBrandId);
  }
  getCasinoCategories() {
    this.Service.GetLiveCasinoCategories().subscribe((Response) => {
      if (Response.Success) {
        this.Categories = Response.Data;
      }
    });
  }

  closeMobileMenu() {
    this.mobilNavbar = false;
    this.mobilNavbarProfile = false;
    $('body').removeClass('fixed');
    this.cdr.markForCheck();
  }

  LoginModal() {
    this.ConfigService.LoginModal.next('on');
    this.mobilNavbar = false;
    this.mobilNavbarProfile = false;
  }

  RegisterModal() {
    this.ConfigService.RegisterModal.next('on');
    this.mobilNavbar = false;
    this.mobilNavbarProfile = false;
  }

  getContentList() {
    let Request = new GetContentListRequest();
    Request.Type = 2;
    this.Subscribe.add(
      this.Service.GetContentList(Request).subscribe((Response) => {
        if (Response.Success) {
          this.ContentListRight = Response.Data;
        }
      })
    );
  }
  getPromotions() {
    let req = new PromotionsImagesAndTextsRequest();
    this.Service.PromotionsImagesAndTexts_v20(req).subscribe((res) => {
      if (res) {
        this.Promotions = res;
      }
    });
  }

  searchProvider(event: any) {
    let searchString = $(event.target).val();
    $('.p-list ul li').each(function (index, value) {
      var currentName = $(value).text();
      if (currentName.toUpperCase().indexOf(searchString.toUpperCase()) > -1) {
        $(value).show();
      } else {
        $(value).hide();
      }
    });
  }

  getSlots(BrandId: number, CategoryId?: string) {
    if (CategoryId) {
      this.ActiveCategory = CategoryId;
    } else {
      this.ActiveCategory = null;
    }
    this.SelectedBrandId = BrandId;
    let Request = new SlotGameListRequest();
    Request.GameType = 'live_casino';
    Request.BrandId = this.SelectedBrandId;
    Request.Mobile = this.IsMobile;
    Request.LiveCasinoCategory = CategoryId;
    this.Service.SlotRepoGameList(Request).subscribe((Response) => {
      this.Title = 'footer.title.livecasino';
      this.AllGames = Response;
    });
  }

  getCategoryGames(CId: string) {
    this.getSlots(null, CId);
  }

  get IsMobile() {
    const ua = (window.navigator.userAgent || '').toLowerCase();
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 4)
      );
    return isMobile;
  }

  getContentListHeader() {
    let Request = new GetContentListRequest();
    Request.Type = 1;
    this.Subscribe.add(
      this.Service.GetContentList(Request).subscribe((Response) => {
        if (Response.Success) {
          this.ContentListLeft = Response.Data;
        }
      })
    );
  }

  menuActiver: boolean = false;
  openGameHeader() {
    this.menuActiver = !this.menuActiver;
  }
  openMenu() {
    var menu = document.querySelector('.mobile-menu-button');
    var menuContent = document.querySelector('.mobile-menu-layout');
    menu.classList.toggle('active');
    menuContent.classList.toggle('active');
  }

  accordionShower: string;
  showAccordion(route: string) {
    if (this.accordionShower === route) {
      this.accordionShower = '';
    } else {
      this.accordionShower = route;
    }
  }

  getSocialMedia() {
    this.Service.GetSocialMedia().subscribe((Response) => {
      this.SocialMedias = Response;
    });
  }

  eventText = '';

  onSwipe(evt) {
    const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    const y = Math.abs(evt.deltaY) > 1 ? (evt.deltaY > 0 ? 'down' : 'up') : '';
    if (x == 'left') {
      this.openMenu();
    }

    var links = $('.mobile-menu-links');
    if (y == 'up') {
      if (evt.deltaY > -200) {
        links.animate({ scrollTop: links.scrollTop() + 500 }, 100);
      } else if (evt.deltaY > -300) {
        links.animate({ scrollTop: links.scrollTop() + 1000 }, 100);
      } else {
        links.animate({ scrollTop: links.scrollTop() + 3000 }, 100);
      }
    } else {
      if (evt.deltaY < 200) {
        links.animate({ scrollTop: links.scrollTop() - 500 }, 100);
      } else if (evt.deltaY < 300) {
        links.animate({ scrollTop: links.scrollTop() - 1000 }, 100);
      } else {
        links.animate({ scrollTop: links.scrollTop() - 3000 }, 100);
      }
    }
  }

  keypress(evt) {}

  CallMeModal() {
    this.ConfigService.CallMeModal.next('on');
  }

  Player: PlayerSessionData;

  BalanceHide: any = false;

  playerBalance: any;
  playerBonusBalance: any;

  initData() {
    this.Subscribe.add(
      this.ConfigService.Player.subscribe((Player) => {
        if (Player) {
          this.Player = Player;
          if (Player.Balance.toString().split('.')[1]) {
            this.playerBalance =
              Player.Balance.toString().split('.')[0] + '.' + Player.Balance.toString().split('.')[1]?.substring(0, 2);
          } else {
            this.playerBalance = Player.Balance.toString();
          }
          if (Player.BonusBalance.toString().split('.')[1]) {
            this.playerBonusBalance =
              Player.BonusBalance.toString().split('.')[0] +
              '.' +
              Player.BonusBalance.toString().split('.')[1]?.substring(0, 2);
          } else {
            this.playerBonusBalance = Player.BonusBalance.toString();
          }
        }
      })
    );

    this.Subscribe.add(
      this.ConfigService.BalanceHide.subscribe((Status) => {
        this.BalanceHide = Status;
      })
    );
  }

  HideBalance() {
    if (this.BalanceHide) {
      localStorage.setItem('BalanceHide', 'true');
    } else {
      localStorage.removeItem('BalanceHide');
    }
    this.ConfigService.BalanceHide.next(this.BalanceHide);
  }

  ngOnDestroy() {
    this.Subscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  Logout() {
    this.ConfigService.Logout();
  }

  getTickets() {
    this.Router.navigateByUrl('/sportsbook/tickets');
  }

  getPlayerNotifications() {
    let Request = new GetPlayerContactIncomingMessagesRequest_v2();
    this.Service.GetTopNotifications(Request).subscribe((Response) => {
      let messages = [];
      for (let i = 0; i < Response.length; i++) {
        const control = localStorage.getItem('hideSiteNotificationBar_' + Response[i].Id);
        if (control == null) {
          messages.push({
            Id: Response[i].Id.toString(),
            TextContent: Response[i].TextContent.toString(),
          });
        }
      }
      this.notificationMessages$.next(messages);
    });
  }
  setOffCanvasTab(tabId) {
    this.activeOffCanvasTab = tabId;
    switch (this.activeOffCanvasTab) {
      case 0:
        this.favoriteGames.openModal();
        break;
      case 1:
        this.lastPlayedGames.openModal();
        break;
    }
  }
}
