<div
  class="new-game-card p-3 d-none"
  [class.live]="
    Type === Types.LIVE_CASINO || Type === Types.FAST_GAMES || Type === Types.SCRATCH || Type === Types.VIRTUAL_SPORTS
  ">
  <div class="game-thumb">
    <ng-container *ngIf="Type === Types.LIVE_CASINO">
      <img class="img-fluid" [lazyLoad]="Game.ImageUrl ? Game.ImageUrl : GetLiveCasinoImage()" alt="" />
    </ng-container>
    <ng-container *ngIf="Type !== Types.LIVE_CASINO">
      <img class="img-fluid" [lazyLoad]="Game.ImageUrl ? Game.ImageUrl : GetLiveCasinoImage()" alt="" />
    </ng-container>
    <div
      class="labels"
      *ngIf="
        Type === Types.LIVE_CASINO ||
        Type === Types.FAST_GAMES ||
        Type === Types.SCRATCH ||
        Type === Types.VIRTUAL_SPORTS
      ">
      <div class="float-right" *ngIf="Game.LiveCasinoData">
        <a href="javascript:void(0)" class="label bg-dark">
          <i class="far fa-user"></i>
          <span>{{ Game.LiveCasinoData.playerCount }}</span>
        </a>
      </div>
    </div>
    <div class="overlay">
      <a href="javascript:void(0)" class="btn theme">
        <span>{{ 'btn.playnow' | translate }}</span>
      </a>
    </div>
  </div>
  <div class="game-informations pl-2 pr-2 p-2">
    <div class="row no-gutters">
      <div class="col-9">
        <ng-container *ngIf="Type === Types.CASINO">
          <span class="game-info pt-1">
            {{ Game.Name }}
            <small class="d-block mt-1">
              <a style="cursor: default" class="btn-brand text-theme">
                {{ 'brandnames.' + Game.BrandName.toLowerCase() | translate }}
              </a>
            </small>
          </span>
        </ng-container>
        <ng-container *ngIf="Type !== Types.CASINO">
          <span class="game-info">
            {{ Game.Name }}
            <span class="limits" *ngIf="Game.LiveCasinoData">
              {{ Game.LiveCasinoData.minAmount }} - {{ Game.LiveCasinoData.maxAmount }}
              {{ Game.LiveCasinoData.currency | currencySymbol }}
            </span>
            <small class="d-block">
              <a href="javascript:void(0)" class="btn-brand text-theme">
                {{ 'brandnames.' + Game.BrandName.toLowerCase() | translate }}
              </a>
            </small>
          </span>
        </ng-container>
      </div>
      <div class="col-3 text-right">
        <a class="add-fav" href="javascript:void(0)" (click)="SetFavorite()">
          <i [class.off]="!FavActive" [class.active]="FavActive" class="heart"></i>
        </a>
      </div>
    </div>
  </div>
</div>
