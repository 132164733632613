import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../services/Storage.service';
import { PlayerSessionData, DbPlayerContactIncomingMessagesResponse } from '../models/service.dtos';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  public ActiveSubLink: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public ActiveBrandId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public SportsbookUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public PlayerNotifications: BehaviorSubject<DbPlayerContactIncomingMessagesResponse[]> = new BehaviorSubject<
    DbPlayerContactIncomingMessagesResponse[]
  >([]);
  public PlayerSystemNotifications: BehaviorSubject<DbPlayerContactIncomingMessagesResponse[]> = new BehaviorSubject<
    DbPlayerContactIncomingMessagesResponse[]
  >([]);
  public Player = new BehaviorSubject<PlayerSessionData | null>(this.StorageService.getUser());
  public LoginStatus = new BehaviorSubject<any>(this.StorageService.isLoggedIn());

  public Loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public BottomMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public AppLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public providerDropdown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public bsFrame: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public GameContainerLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public IsPromoCodeUsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public BalanceHide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    localStorage.getItem('BalanceHide') ? true : false
  );

  // Modal
  public LoginModal: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public RegisterModal: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public ResetModal: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public MobileMenuModal: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public FeedbackModal: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public CallMeModal: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public UsePromoKeyModal: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public UserInfoModal: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private StorageService: StorageService) {}

  Logout() {
    if (localStorage.getItem('userInfo') !== null) {
      localStorage.removeItem('userInfo');
    }
    this.StorageService.remove('user');
    this.LoginStatus.next(false);
    this.Player.next(null);
    window.location.href = '/';
  }

  generateSportsbookUrl(sportsbookUrl: string, hashes: { key: string; value: string }[]) {
    let SPORTSBOOK_URL = new URL(sportsbookUrl);

    for (let hash in hashes) {
      SPORTSBOOK_URL.searchParams.set(hashes[hash].key, hashes[hash].value);
    }
    this.SportsbookUrl.next(SPORTSBOOK_URL.href);
  }
}
