import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppConfigService } from '../../config/app-config.service';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PlayerLoginRequest } from '../../models/user.dtos';
import { Config } from '../../config';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';
import { NavigationEnd, Router } from '@angular/router';
import { WindowRefService } from 'src/app/services/WindowRef.service';
import { AngularDeviceInformationService } from 'angular-device-information';
import { createSupportButtons } from 'src/app/utils/FloatingActionButtons';

declare var $: any;
@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  @Input('Status') Status: boolean;

  @ViewChild('Template')
  private Template: TemplateRef<any>;
  ModalRef: BsModalRef;
  AuthSettings = Config.AuthenticationSettings;
  mobileSupportButtons = createSupportButtons(
    (s) => s.IsActive && s.ShowType != 1 && s.IsSupport === true,
    '#mobileFloatButton > ul'
  );
  desktopSupportButtons = createSupportButtons(
    (s) => s.IsActive && s.ShowType != 2 && s.IsSupport === true,
    '#desktopFloatButton > ul'
  );
  LoginForm: UntypedFormGroup;
  Model: PlayerLoginRequest = new PlayerLoginRequest();

  Subscribe: Subscription;
  isAccessible: boolean;
  IsLoggedIn: boolean;
  isOpenByCasino: boolean = false;
  constructor(
    private ModalService: BsModalService,
    private ConfigService: AppConfigService,
    private formBuilder: UntypedFormBuilder,
    private Notify: ToastrService,
    private Socket: Socket,
    private router: Router,
    private deviceDetector: AngularDeviceInformationService
  ) {
    this.Model.SiteId = Config.settings.ClientId;
    this.Model.Lang = localStorage.getItem('lang');
    this.Model.Device =
      (this.deviceDetector.isDesktop()
        ? 'desktop'
        : this.deviceDetector.isMobile()
        ? 'mobile'
        : this.deviceDetector.isTablet()
        ? 'tablet'
        : 'unknown') +
      ' - ' +
      this.deviceDetector.getDeviceInfo().os;
    this.Model.OS = this.deviceDetector.getDeviceInfo().os;
    this.Model.Browser = this.deviceDetector.getDeviceInfo().browser;
    this.Model.DeviceVersion = this.deviceDetector.getDeviceInfo().osVersion.toString();
    this.Model.BrowserVersion = this.deviceDetector.getDeviceInfo().browserVersion;
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let page = val.url.split('/');
        if (page[1].indexOf('login') !== -1) {
          this.ConfigService.LoginStatus.subscribe((Status) => {
            this.IsLoggedIn = Status;
            if (Status) {
              this.isAccessible = true;
              this.router.navigate(['/']);
            } else {
              this.isAccessible = false;
            }
          });
        } else if (page[1].indexOf('register') !== -1) {
          this.isAccessible = false;
        } else {
          this.isAccessible = true;
        }
      }
    });
  }

  get IsMobile() {
    const ua = (window.navigator.userAgent || '').toLowerCase();
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 4)
      );
    return isMobile;
  }

  ngOnInit(): void {
    this.ConfigService.LoginStatus.subscribe((Status) => {
      this.IsLoggedIn = Status;
    });
    this.Subscribe = this.ConfigService.LoginModal.subscribe((Type: string) => {
      if (Type === 'on') {
        this.Open();
      } else if (Type === 'close') {
        this.Close();
      }
    });
    this.LoginForm = this.formBuilder.group({
      Username: ['', Validators.required],
      Password: ['', Validators.required],
    });
  }

  Open() {
    this.LoginForm.reset();
    this.ModalRef = this.ModalService.show(this.Template, {
      class: 'modal-dialog modal-fullscreen-lg-down modal-dialog-centered mdl-login',
      backdrop: 'static',
      keyboard: false,
    });
    if (this.IsMobile) {
      $('body').css('overflow', 'hidden');
      $('html').css('overflow', 'hidden');
    }
  }
  openDesktopFab() {
    if (this.desktopSupportButtons.length > 1) {
      document
        .querySelector('#desktopFloatButton > ul')
        .setAttribute(
          'data-mfb-state',
          document.querySelector('#desktopFloatButton > ul').getAttribute('data-mfb-state') === 'open'
            ? 'closed'
            : 'open'
        );
    } else {
      this.desktopSupportButtons[0].onClick();
    }
  }
  openMobileFab = () => {
    if (!this.IsMobile) {
      this.openDesktopFab();
      return;
    }
    if (this.mobileSupportButtons.length > 1) {
      document
        .querySelector('#mobileFloatButton > ul')
        .setAttribute(
          'data-mfb-state',
          document.querySelector('#mobileFloatButton > ul').getAttribute('data-mfb-state') === 'open'
            ? 'closed'
            : 'open'
        );
    } else {
      this.mobileSupportButtons[0].onClick();
    }
  };
  Close() {
    this.LoginForm.reset();
    if (this.IsMobile) {
      $('body').css('overflow', 'auto');
      $('html').css('overflow', 'auto');
    }
    const previousPageIsOnSameDomain =
      document.referrer != '' && document.referrer.indexOf(window.location.host) !== -1;

    if (!this.IsLoggedIn) {
      if (
        (window.location.href.includes('/game/') || window.location.href.includes('/menu-game/')) &&
        previousPageIsOnSameDomain &&
        history.length > 1
      ) {
        history.go(-1);
      } else if (
        (window.location.href.includes('/game/') || window.location.href.includes('/menu-game/')) &&
        (document.referrer === '' || history.length == 1)
      ) {
        window.location.href = location.protocol + '//' + location.host;
      }
    }
    this.ModalRef?.hide();
    this.ConfigService.LoginModal.next('');
  }

  get f() {
    return this.LoginForm.controls;
  }
  OpenRegisterModal() {
    this.LoginForm.reset();
    this.ModalRef.hide();
    this.ConfigService.RegisterModal.next('on');
  }
  ResetModal() {
    if (this.IsMobile) {
      $('body').css('overflow', 'auto');
    }
    this.ConfigService.ResetModal.next('on');
  }

  Login() {
    if (!this.LoginForm.invalid) {
      this.Socket.emit('login-v3', this.Model);
    }
  }
  @ViewChild('uname') searchElement: ElementRef;
  ngAfterViewInit() {
    this.focusUsername();
  }
  focusUsername() {
    setTimeout(() => {
      this.searchElement?.nativeElement.focus();
      this.searchElement?.nativeElement.remove();
    }, 0);
  }
  logoClick() {
    this.router.navigate(['/']);
  }
}
