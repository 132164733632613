import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

declare var bootstrap: any;

@Directive({
  selector: '[appBsConditionalDismiss]',
})
export class BsConditionalDismissDirective implements OnChanges {
  @Input() appBsConditionalDismiss = false;
  private originalOnclick: any;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appBsConditionalDismiss']) {
      this.updateDismissBehavior();
    }
  }

  private updateDismissBehavior() {
    const element = this.el.nativeElement;

    if (this.appBsConditionalDismiss) {
      // Store the original onclick if it hasn't been stored yet
      if (!this.originalOnclick) {
        this.originalOnclick = element.onclick;
      }

      element.onclick = (event: Event) => {
        event.preventDefault();
        const dismissible = element.closest('.offcanvas');
        if (dismissible) {
          const bsOffcanvas = bootstrap.Offcanvas.getInstance(dismissible);
          if (bsOffcanvas) {
            bsOffcanvas.hide();
          }
        }
      };
    } else {
      // Restore the original onclick or set to null if there wasn't one
      element.onclick = this.originalOnclick || null;
    }
  }
}
