import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'game-error',
  templateUrl: './game-error.component.html',
  styleUrls: ['./game-error.component.scss'],
})
export class GameErrorComponent implements OnInit {
  constructor(private Router: Router) {}
  @Input('IsPermissionError') IsPermissionError: boolean;
  @Input('IsError') IsError: boolean;
  @Input('IsOpenGameError') IsOpenGameError: boolean;
  @Input('OpenGameErrorDesc') OpenGameErrorDesc: string;
  @Input('IsCurrencyError') IsCurrencyError: boolean;

  ngOnInit(): void {}
}
