import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-update-check',
  templateUrl: './update-check.component.html',
  styleUrls: ['./update-check.component.scss'],
})
export class UpdateCheckComponent {
  @Input() show = false;

  constructor(public router: Router) {}
  reload() {
    document.location.reload();
  }

  closeModal() {
    this.show = false;
  }
}
