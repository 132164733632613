import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LastPlayedGamesComponent } from '../components/last-played-games/last-played-games.component';
import { LiveCasinoGameComponent } from '../components/live-casino-game/live-casino-game.component';
import { PipesModule } from '../helpers/pipes/pipes.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RouterLink } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DirectivesModule } from '../directives/directives.module';
import { FavoriteGamesComponent } from '../components/favorite-games/favorite-games.component';

@NgModule({
  declarations: [LastPlayedGamesComponent, FavoriteGamesComponent, LiveCasinoGameComponent],
  imports: [CommonModule, InfiniteScrollModule, PipesModule, LazyLoadImageModule, RouterLink, DirectivesModule],
  exports: [LastPlayedGamesComponent, FavoriteGamesComponent, LiveCasinoGameComponent],
  providers: [BsModalRef],
})
export class SharedModule {}
