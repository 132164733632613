<div class="f-categories mt-1" *ngIf="IsMobile">
  <div class="casino-select-provider-button-block-bc">
    <div class="accordion-items-container" [ngClass]="{ collapsed: this.visible }">
      <div class="accordion-items">
        <div class="accordion-items-resize">
          <div *ngFor="let brand of Brands">
            <span title="{{ brand.Name }}">
              <button
                class="btn btn-base px-3"
                style="align-items: center"
                (click)="filterGames(brand.Id)"
                [ngClass]="{ current: isFilterBrand(brand.Id) }"
                type="button"
                data-badge="Jackpot">
                <svg-icon
                  *ngIf="brand.SvgPath"
                  [stretch]="true"
                  [svgStyle]="{ 'min-width': '4rem', width: '100%' }"
                  src="{{ brand.SvgPath }}"></svg-icon>

                <span *ngIf="!brand.SvgPath">{{ brand.Name }}</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <i
    *ngIf="this.visible === false"
    (click)="this.visible = true"
    class="accordion-items-container-arrow fa fa-chevron-up"></i>
  <i
    *ngIf="this.visible === true"
    (click)="this.visible = false"
    class="accordion-items-container-arrow fa fa-chevron-down"></i>
</div>
