<div class="dropdown lang float-right d-none" dropdown placement="bottom left">
  <button
    class="btn-sm dropdown-toggle"
    type="button"
    id="button-animated"
    dropdownToggle
    aria-controls="dropdown-animated">
    <i class="fa fa-cog"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu role="menu" aria-labelledby="button-animated">
    <div class="head">
      <i class="fa fa-cog mr-2"></i>
      Ayarlar
    </div>
    <div class="inner">
      <span class="head">Ekran Genişliği</span>
      <a href="#">
        <i class="fa fa-th-large mr-2"></i>
        <span>Ortala</span>
      </a>
      <a href="#">
        <i class="fa fa-th mr-2"></i>
        <span>Geniş</span>
      </a>
      <span class="head">Ekran Aydınlatması</span>
      <a href="#">
        <i class="far fa-moon mr-2"></i>
        <span>Gece Modu</span>
      </a>
      <a href="#">
        <i class="fa fa-sun mr-2"></i>
        <span>Gündüz Modu</span>
      </a>
    </div>
  </div>
</div>
