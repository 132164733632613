import { Component, HostListener, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/config/app-config.service';
import {
  DbContentListResponse,
  DbMenuResponse,
  GetContentListRequest,
  SocialMediaResponse,
} from 'src/app/models/service.dtos';
import { GeneralService } from 'src/app/services/General.service';
import { Config } from 'src/app/config';
import { Router } from '@angular/router';
import { ISocialMedias } from 'src/app/config/config.interface';
import { CustomHtmlResponse, CustomHtmlService } from 'src/app/services/CustomHtml.service';
import { CustomHtmlContentPagesEnum } from 'src/app/constants/customhtmlenum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  FooterContent: any;
  SocialMedias: SocialMediaResponse[] = [];
  isButtonVisible: boolean = false;
  ContentList: DbContentListResponse[] = [];
  Menu: DbMenuResponse[] = Config.Menu;
  customHtml: any;
  SportsMenu: DbMenuResponse[] = Config.Menu;
  CasinoMenu: DbMenuResponse[] = Config.Menu;
  OtherMenu: DbMenuResponse[] = Config.Menu;

  ShouldRenderSports = true;
  ShouldRenderCasino = true;
  ShouldRenderOther = true;
  constructor(
    private ConfigService: AppConfigService,
    public router: Router,
    private Service: GeneralService,
    private CustomHtmlService: CustomHtmlService
  ) {}

  ngOnInit(): void {
    this.getContentList();
    this.getHtml();
    this.SportsMenu = Config.Menu.filter((x) => x.FooterGroupId == 1 && x.IsActive);
    this.CasinoMenu = Config.Menu.filter((x) => x.FooterGroupId == 2 && x.IsActive);
    this.OtherMenu = Config.Menu.filter((x) => x.FooterGroupId == 3 && x.IsActive);

    this.ShouldRenderSports = this.SportsMenu && this.SportsMenu.length > 0;
    this.ShouldRenderCasino = this.CasinoMenu && this.CasinoMenu.length > 0;
    this.ShouldRenderOther = this.CasinoMenu && this.OtherMenu.length > 0;
  }

  FeedbackModal() {
    this.ConfigService.FeedbackModal.next('on');
  }
  getHtml() {
    var request;
    this.CustomHtmlService.getCustomHtml(request).subscribe((response: CustomHtmlResponse) => {
      this.customHtml = response.Data.filter((item) => item.Page === CustomHtmlContentPagesEnum.Footer);
      const areas = [0];
      const sanitizedContents = areas.map((area) =>
        this.customHtml
          .filter((obj) => obj.Area === area)
          .map((obj) => obj.Content)
          .join('')
      );

      this.FooterContent = (this.FooterContent || '') + sanitizedContents[0];
      this.FooterContent = this.FooterContent.replace(
        /\[router="([^"]+)"\]/g,
        'data-route="$1" (click)="navigateTo($event)"'
      );

      console.log(this.FooterContent, this.customHtml);
    });
  }
  getContentList() {
    let Request = new GetContentListRequest();
    Request.Type = 2;
    this.Service.GetContentList(Request).subscribe((Response) => {
      if (Response.Success) {
        this.ContentList = Response.Data;
      }
    });
  }
  get IsMobile() {
    const ua = (window.navigator.userAgent || '').toLowerCase();
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 6)
      );
    return isMobile;
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    // Eğer sayfa belirli bir miktar kaydırılmışsa buton görünür olacak
    this.isButtonVisible = window.pageYOffset > 100;
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
