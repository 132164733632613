<div *ngIf="onlineStatus == OnlineStatusType.OFFLINE" class="alert alert-warning alert-no-internet">
  {{ 'no.internet.connection.alert' | translate }}
</div>
<app-header *ngIf="isAccessible"></app-header>
<router-outlet></router-outlet>
<app-loader *ngIf="isAccessible"></app-loader>
<app-footer *ngIf="!Router.url.startsWith('/sportsbook') && isAccessible"></app-footer>
<app-login-modal></app-login-modal>
<app-user-info-modal></app-user-info-modal>
<app-register-modal></app-register-modal>
<app-reset-modal></app-reset-modal>
<app-feedback-modal></app-feedback-modal>
<app-use-promo-key-modal *ngIf="isAccessible"></app-use-promo-key-modal>
<app-call-me-modal *ngIf="isAccessible"></app-call-me-modal>
<div class="custom-float d-none" *ngIf="IsMobile && !IsLoggedIn && isAccessible">
  <a href="javascript:void(0)" (click)="LoginModal()" class="btn theme light me-2">{{ 'btn.login' | translate }}</a>
  <a href="javascript:void(0)" (click)="RegisterModal()" class="btn theme">{{ 'btn.register' | translate }}</a>
</div>
<app-update-check [show]="updateAvailable"></app-update-check>
<app-notification-modal></app-notification-modal>
