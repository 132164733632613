<ng-template #Template>
  <div class="modal-header d-flex justify-content-between">
    <div class="start">
      {{ 'account.tab.contact' | translate }}
    </div>
    <div class="end">
      <a href="javascript:void(0)" (click)="Close()" class="btn btn-close"><i class="fa fa-close"></i></a>
    </div>
  </div>
  <div class="modal-body">
    <p [innerHTML]="'messages.description' | translate"></p>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="Form" (submit)="CreateContactMessage()" class="login-form">
          <div class="form-floating">
            <select
              class="form-select"
              id="exampleFormControlSelect1"
              (change)="changeCategory($event)"
              formControlName="CategoryId">
              <option [value]="i" *ngFor="let item of ContactCategories; let i = index">
                {{ item.translation | translate }}
              </option>
            </select>
            <label for="exampleFormControlSelect1">{{ 'contact.category' | translate }}</label>
          </div>
          <div class="form-floating py-2">
            <select class="form-select" id="exampleFormControlSelect1" formControlName="IssueId">
              <option [value]="i" *ngFor="let item of ContactIssues; let i = index">
                {{ item.translation | translate }}
              </option>
            </select>
            <label for="exampleFormControlSelect1">{{ 'contact.issue' | translate }}</label>
          </div>
          <div class="form-floating py-2">
            <input
              type="text"
              class="form-control"
              formControlName="Username"
              placeholder="{{ 'account.form.label.username' | translate }}"
              [class.is-invalid]="f.Username.invalid && (f.Username.dirty || f.Username.touched)" />
            <label>{{ 'account.form.label.username' | translate }}</label>
            <div
              *ngIf="f.Username.invalid && (f.Username.dirty || f.Username.touched)"
              class="invalid-feedback text-left">
              {{ 'general.text.required' | translate }}
            </div>
          </div>
          <div class="form-floating py-2">
            <input
              type="text"
              formControlName="Email"
              class="form-control"
              id=""
              placeholder="{{ 'account.form.label.email' | translate }}"
              [class.is-invalid]="f.Email.invalid && (f.Email.dirty || f.Email.touched)" />
            <label>{{ 'account.form.label.email' | translate }}</label>
            <div *ngIf="f.Email.invalid && (f.Email.dirty || f.Email.touched)" class="invalid-feedback text-left">
              {{ 'general.text.required' | translate }}
            </div>
          </div>
          <div class="form-floating py-2">
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              formControlName="Description"
              rows="3"
              style="height: 150px"
              [class.is-invalid]="f.Description.invalid && (f.Description.dirty || f.Description.touched)"></textarea>
            <label for="exampleFormControlTextarea1">{{ 'support.form.label.message' | translate }}</label>

            <div
              *ngIf="f.Description.invalid && (f.Description.dirty || f.Description.touched)"
              class="invalid-feedback text-left">
              {{ 'general.text.required' | translate }}
            </div>
          </div>
          <button type="submit" [disabled]="Form.invalid" (click)="ModalRef.hide()" class="btn btn-base w-100">
            <span>{{ 'btn.send' | translate }}</span>
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a href="javascript:void(0)" class="btn btn-base" data-bs-dismiss="modal" (click)="Close()">
      {{ 'btn.close' | translate }}
    </a>
  </div>
</ng-template>
