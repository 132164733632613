import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GeneralService } from 'src/app/services/General.service';
import type { GamesByOrderingList } from 'src/app/models/service.dtos';
import { Subscription } from 'rxjs';
import { Config } from 'src/app/config';

@Component({ selector: 'app-featured-game-area', templateUrl: './featured-game-area.component.html' })
export class FeaturedGameAreaComponent implements OnInit, OnDestroy {
  @ViewChildren('listRefs') listRefs!: QueryList<ElementRef>;

  constructor(private Service: GeneralService) {
    // component ismi değiştirilecek -> featuredgamearea
    //
  }

  list: GamesByOrderingList[] = Config.PopularGames;
  arrows = {};

  // subscriptions: Subscription = new Subscription()

  ngOnInit(): void {
    // this.subscriptions.add(
    //     this.Service.GamesByOrdering({siteId: '135', lang: 'tr', sessionid: '', Mobile: false}).subscribe(response => {
    //         this.list = response;
    //     })
    // )
  }

  ngOnDestroy() {
    // this.subscriptions.unsubscribe();
  }

  OpenGame() {
    // if (!this.IsLoggedIn) {
    //     this.notLoginRoute = [this.Type + '/game/', this.Game.BrandId, this.Game.Id];
    //     this.ConfigService.LoginModal.next('on');
    //     return false;
    // } else {
    //     this.setToHistory();
    //     this.Router.navigate([this.Type + '/game/', this.Game.BrandId, this.Game.Id]);
    // }
  }

  OpenDemoGame() {
    // if (!this.IsLoggedIn) {
    //     this.notLoginRoute = [this.Type + '/demo-game/', this.Game.BrandId, this.Game.Id];
    //     this.ConfigService.LoginModal.next('on');
    //     return false;
    // } else {
    //     this.setToHistory();
    //     this.Router.navigate([this.Type + '/demo-game/', this.Game.BrandId, this.Game.Id]);
    //     this.Close();
    // }
  }

  getListScrollPercentage(index: number): number {
    const ref = this.getElementInRefs(index);

    // Değerinin yüzdesini verir
    return Number(((100 * ref.scrollLeft) / ref.scrollWidth / (1 - ref.clientWidth / ref.scrollWidth)).toFixed(2));
  }

  getListStyles(brand: GamesByOrderingList) {
    return {
      'grid-auto-flow': brand.Games.length < 9 || brand.Games.length / 5 < 2 ? 'row' : 'column',
      'grid-template-rows': `repeat(${brand.LineHeight ? brand.LineHeight : '1'}, 1fr)`,
    };
  }

  onScrollContainer(index: number) {
    let scrollPercentage = this.getListScrollPercentage(index);

    console.log(scrollPercentage);
    this.arrows[`showLeftArrow_${index}`] = scrollPercentage > 2;
    this.arrows[`showRightArrow_${index}`] = scrollPercentage < 100;
  }

  scrollStepByPercentage(index: number, percentage: number = 25) {
    let element = this.getElementInRefs(index);
    let elementScrollWidth = element.scrollWidth - element.clientWidth;
    let currentScrollPosition = element.scrollLeft;
    let scrollAmount = elementScrollWidth * (Math.abs(percentage) / 100);
    let newScrollPosition = currentScrollPosition + (percentage < 0 ? -scrollAmount : scrollAmount);

    let currentScrollPercentage = this.getListScrollPercentage(index);

    if (currentScrollPercentage > 90 || newScrollPosition < 50) newScrollPosition = 0;

    element.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth',
    });
  }

  scrollStepByPixels(index: number, pixels: number) {
    let element = this.getElementInRefs(index);
    let currentScrollPosition = element.scrollLeft;
    let newScrollPosition = currentScrollPosition + pixels;
    let currentScrollPercentage = this.getListScrollPercentage(index);

    if (currentScrollPercentage > 90) newScrollPosition = 0;

    element.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth',
    });
  }

  showRightArrow(index: number) {
    return this.arrows[`showRightArrow_${index}`] ?? true;
  }

  showLeftArrow(index: number) {
    return this.arrows[`showLeftArrow_${index}`] ?? true;
  }

  getElementInRefs(index: number) {
    return this.listRefs.find((e, i) => i === index).nativeElement;
  }
}
