import { Component, OnInit } from '@angular/core';
import { SiteLanguage, SiteLanguagesRequest } from 'src/app/models/service.dtos';
import { GeneralService } from '../../services/General.service';
import { filter } from 'rxjs/internal/operators';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  Languages: SiteLanguage[];
  ActiveLanguages: string = localStorage.getItem('lang');

  constructor(private Service: GeneralService) {}

  ngOnInit(): void {
    this.getSiteLanguages();
  }

  getSiteLanguages() {
    let Request = new SiteLanguagesRequest();
    this.Service.siteLanguages(Request).subscribe((Response) => {
      this.Languages = Response;
    });
  }

  setLanguage(lang) {
    localStorage.setItem('lang', lang.Language);
    this.ActiveLanguages = lang.Language;
    window.location.reload();
  }

  get LanguageName() {
    let x = (this.Languages || []).filter((x) => x.Language === this.ActiveLanguages);
    if (x.length) return x[0].Language.toUpperCase();
  }
}
