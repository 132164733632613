import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguagesComponent } from './languages.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RouterModule } from '@angular/router';
import { HammerModule } from '@angular/platform-browser';
import { PipesModule } from 'src/app/helpers/pipes/pipes.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SocketIoModule } from 'ngx-socket-io';

@NgModule({
  declarations: [LanguagesComponent],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    CarouselModule.forRoot(),
    RouterModule,
    HammerModule,
    PipesModule,
    LazyLoadImageModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    MomentModule,
    TooltipModule.forRoot(),
    SocketIoModule,
  ],
  exports: [LanguagesComponent],
})
export class LanguagesModule {}
