// services/custom-html.service.ts
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, interval } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GeneralService } from './General.service';

export interface CustomHtmlResponse {
  Data: {
    Page: number;
    Area: number;
    Content: string;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class CustomHtmlService implements OnDestroy {
  private storedData: CustomHtmlResponse | null = null;
  private clearInterval: any;

  constructor(private apiService: GeneralService) {
    this.clearInterval = setInterval(() => {
      this.clearData();
    }, 60000);
  }

  getCustomHtml(request?: any): Observable<CustomHtmlResponse> {
    console.log(this.storedData);
    if (this.storedData) {
      return of(this.storedData);
    }
    return this.apiService.GetCustomHtml(request).pipe(
      tap((response) => {
        this.storedData = response;
      })
    );
  }

  clearData(): void {
    this.storedData = null;
  }

  ngOnDestroy() {
    if (this.clearInterval) {
      clearInterval(this.clearInterval);
    }
  }
}
