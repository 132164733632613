import { Injectable } from '@angular/core';
import { BaseService } from './Base.service';
import {
  LiveCasinoGamesRequest,
  LiveCasinoGameResponse,
  LiveCasinoOpenGameRequest,
  DbCasinoOpenResult,
  LiveCasinoGameListRequest,
  LiveCasinoOpenGameRepoRequest,
  SlotGameListRequest,
  SlotGameList,
  SlotBrandListRequest,
  IdName,
  SlotPopularGameListRequest,
  SlotOpenGameRequest,
  SlotRepoGameListRequest,
  SlotRepoPopularGameListRequest,
  SlotRepoOpenGameRequest,
  PlayerTransactionHistoryListRequest,
  PlayerTransactionHistoryListResponse,
  ChangePasswordRequest,
  CheckTombalaActivePlayerRequest,
  DepositProviders,
  DepositProvidersRequest,
  WithdrawProviders,
  WithdrawProvidersRequest,
  SiteBanks,
  SiteBanksRequest,
  SiteBankAccount,
  SiteBankAccountRequest,
  AddPlayerBankAccountRequest,
  DepositRequestBankTransferRequest,
  OpenWithdrawRequest,
  PlayerBankList,
  PlayerBankListRequest,
  CepbankBankListRequest,
  CepbankBankListResponse,
  DepositRequestCepbankTransferDto,
  GetCitiesRequest,
  GetCountriesRequest,
  Countries,
  SiteCurrenciesRequest,
  SiteCurrency,
  SiteLanguagesRequest,
  SiteLanguage,
  CheckPlayerInfoExistRequest,
  LoginPlayerRequest,
  PlayerSessionResponse,
  RegisterPlayerRequest,
  PlayerRegisterResponse,
  FrontendMobileMenuRequest,
  MobileMenuResponse,
  FrontendMatchSearchRequest,
  MatchSearch,
  LogoutRequest,
  LiveGamesGenerateSignRequest,
  PlayerBalanceRequest,
  PlayerBalance,
  WithdrawRequestBankTransfer,
  GetNotificationsRequest,
  PlayerPromotionByPlayerRequest,
  PlayerPromotionByPlayer,
  DbBannerResponse,
  BannersRequest,
  SendMessageRequest,
  PlayerMessagesRequest,
  PlayerMessage,
  GetPlayerUnreadMessagesCountRequest,
  PromotionsImagesAndTextsRequest,
  DbPromotionsImagesTextsResponse,
  DbPaparaListResponse,
  SitePaparaList,
  DepositPapara,
  WithdrawPapara,
  SubmitCustomBankForm,
  WithdrawSubmitCustomBankForm,
  CreateContactMessageRequest,
  DbContactIssueResponse,
  DbContactCategoryResponse,
  GetContentListRequest,
  DbContentListResponse,
  GetContentDetails,
  OpenDepositRequests,
  CancelDepositRequest,
  OpenWithdrawRequests,
  CancelWithdraw,
  PlayerInfoListRequest,
  PlayerDeactivateRequest,
  PlayerInfoList,
  MernisCheckRequest,
  DepositRequestJetonTransfer,
  WithdrawRequestJetonTransfer,
  DepositRequestRocketTransfer,
  RocketWithdrawTransferRequest,
  RocketBankListRequest,
  RocketBank,
  EnvoyWithdrawTransferRequest,
  EnvoyAviableAmountsAllBanksListDTO,
  EnvoyAviableAmountsAllBanksRequest,
  EnvoyDepositQrTransferRequest,
  EnvoyDepositCpTransferRequest,
  EnvoyAviableAmountsByBankRequest,
  EnvoyAviableAmountsSingleBankDTO,
  EnvoyQrBankListRequest,
  EnvoyBankDTO,
  EnvoyCpBankListRequest,
  ForgetPasswordMailResetRequest,
  ForgetPasswordResetRequest,
  PlayerActivateMail,
  DepositCmt,
  SiteCmtList,
  MobileBannersRequest,
  DbMobileBannerResponse,
  TvBetGameUrl,
  GetWithdrawRequestsByPlayerIdRequest,
  DbWithdrawRequestsByPlayerIdResponse,
  GetDepositRequestsByPlayerIdRequest,
  DbDepositRequestsByPlayerIdResponse,
  AdminContactSendMessageRequest,
  GetPlayerContactSentMessagesRequest,
  DbPlayerContactSentMessagesResponse,
  GetPlayerContactIncomingMessagesRequest,
  DepositRequestPagoEfectivoTransfer,
  DbPlayerContactIncomingMessagesResponse,
  FormProperty,
  GetDynamicFormPropsRequest,
  PlayerDocumentUploadRequest,
  PlayerDocumentListResponse,
  PlayerDocumentListRequest,
  PlayerDocumentTypeRequest,
  PlayerDocumentTypeResponse,
  AccountingPromotionListRequest,
  AccountingPromotionListResponse,
  AddPlayerPromotionRequestsRequest,
  TournamentGroupsRequest,
  TournamentGroupResponse,
  TournamentGroupDetailRequest,
  TournamentGroupDetailResponse,
  GetLiveMatchInPrematch,
  CheckSessionRequest,
  SocketResponse,
  PlayerSessionData,
  FixtureTableRequest,
  SiteFrontendSettingsRequest,
  CheckTombalaActiveRequest,
  LastWinnersRequest,
  TopAndLastWinners,
  TopWinnersRequest,
  HomePageGamesRequest,
  PlayerPromotionHistory,
  AccountingPlayerIPHistoryRequest,
  AccountingPlayerIPHistoryResponse,
  SocialMediaResponse,
  AffiliateRequest,
  AnindaResponse,
  NotificationResponse,
  DbMenuResponse,
  MenuRequest,
  ValidateIdentityNumberRequest,
  BottomMenuRequest,
  GetPlayerContactIncomingMessagesRequest_v2,
  DbPlayerContactIncomingMessagesResponse_v2,
  SlotBrandListRequestV2,
  BrandListResponse,
  SlotRepoGameListRequestV2,
  PlayerPromotionRequest,
  GamesByOrderingRequest,
  GamesByOrderingList,
  CancelBonus,
  // BrandListResponse,
  // SlotRepoGameListRequest,
  // SlotBrandListRequestV2,
} from '../models/service.dtos';
import { Observable } from 'rxjs';
import { AccentPayDepositRequest } from './../models/service.dtos';
import { IResponse } from '../models/IResponse.dtos';

@Injectable({
  providedIn: 'root',
})
export class GeneralService extends BaseService {
  // getJackpots
  public getJackpots(request): Observable<any> {
    return this.post<Array<SlotGameList>>('/Site/GetJackpotData', request);
  }
  // Slot
  public getSlotGames(request: SlotGameListRequest): Observable<Array<SlotGameList>> {
    return this.post<Array<SlotGameList>>('/Games', request);
  }

  public getSlotBrandList(request: SlotBrandListRequest): Observable<Array<IdName>> {
    return this.post<Array<IdName>>('/Games/GetBrands', request);
  }

  public getSlotBrandListV2(request: SlotBrandListRequestV2): Observable<Array<BrandListResponse>> {
    return this.post<Array<BrandListResponse>>('/Games/GetBrandsV2', request);
  }

  public SlotPopularGameList(request: SlotPopularGameListRequest): Observable<Array<SlotGameList>> {
    return this.post<Array<SlotGameList>>('/Games/GetPopularGames', request);
  }
  public GetCustomHtml(request: any): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Site/CustomHtml', request);
  }

  public SlotOpenGame(request: SlotOpenGameRequest): Observable<Array<DbCasinoOpenResult>> {
    return this.post<Array<DbCasinoOpenResult>>('/Games/OpenGame', request);
  }

  public SlotOpenSportsbook(request: SlotOpenGameRequest): Observable<any> {
    return this.post<any>('/Games/OpenGame', request);
  }

  public SlotRepoGameList(request: SlotRepoGameListRequest): Observable<Array<SlotGameList>> {
    return this.post<Array<SlotGameList>>('/Games', request);
  }

  public GamesByOrdering(request: GamesByOrderingRequest): Observable<GamesByOrderingList[]> {
    return this.post<GamesByOrderingList[]>('/Games/GetGamesByOrdering', request);
  }

  public SlotRepoGameListV2(request: SlotRepoGameListRequestV2): Observable<Array<SlotGameList>> {
    return this.post<Array<SlotGameList>>('/Games/GamesByBrand', request);
  }

  public GetLiveCasinoCategories(): Observable<IResponse<{ GroupName: string }[]>> {
    return this.post<IResponse<Array<{ GroupName: string }>>>('/Games/GetLiveCasinoCategories', {});
  }

  public GetCasinoCategories(): Observable<IResponse<{ Name: string; Id: number; Icon: string }[]>> {
    return this.post<IResponse<{ Name: string; Id: number; Icon: string }[]>>('/Games/GetCasinoCategories', {});
  }

  public GetWithdrawRequestsByPlayerId(
    request: GetWithdrawRequestsByPlayerIdRequest
  ): Observable<DbWithdrawRequestsByPlayerIdResponse[]> {
    return this.post<DbWithdrawRequestsByPlayerIdResponse[]>('/getwithdrawrequestsbyplayerid', request);
  }

  public GetDepositRequestsByPlayerId(
    request: GetDepositRequestsByPlayerIdRequest
  ): Observable<DbDepositRequestsByPlayerIdResponse[]> {
    return this.post<DbDepositRequestsByPlayerIdResponse[]>('/getdepositrequestsbyplayerid', request);
  }

  public GetContentList(request: GetContentListRequest): Observable<IResponse<DbContentListResponse[]>> {
    return this.post<IResponse<DbContentListResponse[]>>('/Site/GetContents', request);
  }

  public GetContentDetails(request: GetContentDetails): Observable<DbContentListResponse> {
    return this.post<DbContentListResponse>('/Site/GetContentDetails', request);
  }

  // Player

  public setDeactivePlayer(request: PlayerDeactivateRequest): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Account/SetDisabled', request);
  }

  public getPlayerInfo(request: PlayerInfoListRequest): Observable<PlayerInfoList> {
    return this.post<PlayerInfoList>('/Account/GetInfo', request);
  }
  public editPlayerInfo(request: any): Observable<PlayerInfoList> {
    return this.post<PlayerInfoList>('/Account/UpdatePlayerProfileInfo', request);
  }

  public editPlayerInfoV2(request: any): Observable<PlayerInfoList> {
    return this.post<PlayerInfoList>('/Account/UpdatePlayerProfileInfo', request);
  }

  public resetPassword(request: any): Observable<any> {
    return this.post<any>('/Account/ChangePlayerPassword', request);
  }

  public PlayerTransactionHistoryList(
    request: PlayerTransactionHistoryListRequest
  ): Observable<Array<PlayerTransactionHistoryListResponse>> {
    return this.post<Array<PlayerTransactionHistoryListResponse>>('/Account/GetTransactionHistory', request);
  }

  public ChangePassword(request: ChangePasswordRequest): Observable<string> {
    return this.post<string>('/player/changepassword', request);
  }

  public CheckTombalaActivePlayer(request: CheckTombalaActivePlayerRequest): Observable<boolean> {
    return this.post<boolean>('/player/checktombalaactive', request);
  }

  public CheckTombalaActive(request: CheckTombalaActiveRequest): Observable<boolean> {
    return this.post<boolean>('/tombala/checkactive', request);
  }

  // Finance

  public EnvoyWithdrawTransfer(request: EnvoyWithdrawTransferRequest): Observable<string> {
    return this.post<string>('/envoy/withdrawcreate', request);
  }
  public EnvoyAviableAmountsAllBanks(
    request: EnvoyAviableAmountsAllBanksRequest
  ): Observable<EnvoyAviableAmountsAllBanksListDTO> {
    return this.post<EnvoyAviableAmountsAllBanksListDTO>('/envoy/getallamounts', request);
  }
  public EnvoyAviableAmountsByBank(
    request: EnvoyAviableAmountsByBankRequest
  ): Observable<EnvoyAviableAmountsSingleBankDTO> {
    return this.post<EnvoyAviableAmountsSingleBankDTO>('/envoy/getamountsbybank', request);
  }
  public EnvoyDepositCpTransfer(request: EnvoyDepositCpTransferRequest): Observable<string> {
    return this.post<string>('/envoy/depositrequestenvoycptransfer', request);
  }
  public EnvoyDepositQrTransfer(request: EnvoyDepositQrTransferRequest): Observable<string> {
    return this.post<string>('/envoy/depositrequestenvoyqrtransfer', request);
  }
  public EnvoyQrBankList(request: EnvoyQrBankListRequest): Observable<EnvoyBankDTO[]> {
    return this.post<EnvoyBankDTO[]>('/envoygetqrbanks', request);
  }
  public EnvoyCpBankList(request: EnvoyCpBankListRequest): Observable<EnvoyBankDTO[]> {
    return this.post<EnvoyBankDTO[]>('/envoygetcpbanks', request);
  }

  public DepositRequestRocketTransfer(request: DepositRequestRocketTransfer): Observable<string> {
    return this.post<string>('/rocket/depositrequestrockettransfer', request);
  }

  public RocketWithdrawTransferRequest(request: RocketWithdrawTransferRequest): Observable<string> {
    return this.post<string>('/rocket/withdrawrequestrockettransfer', request);
  }

  public DepositProviders(request: DepositProvidersRequest): Observable<Array<DepositProviders>> {
    return this.post<Array<DepositProviders>>('/Finance/GetDeposites', request);
  }

  public SetPayfixDepositRequest(request: { Amount: number }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetPayfixDepositRequest', request);
  }
  public SetHemenodeDepositRequest(request: {
    Amount: number;
    Type: number;
  }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetHemenodeDepositRequest', request);
  }
  public SetScashMoneyDepositRequest(request: { Amount: number; Type: number }): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Finance/SetScashMoneyDepositRequest', request);
  }
  public SetMinipayDepositRequest(request: {
    Amount: number;
    Type: number;
  }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetMinipayDepositRequest', request);
  }
  public SetVevoDepositRequest(request: { Amount: number; Type: number }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetVevoDepositRequest', request);
  }
  public SetDepositRequest(request: {
    Amount: number;
    Type: number;
    BankID: string;
    ReturnUrl: string;
  }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetDepositRequest', request);
  }

  public SetEnHizliDoneRequest(request: {
    Amount: number;
    Type: number;
    BankID: string;
    KartId: string;
  }): Observable<IResponse<{ data: any }>> {
    return this.post<IResponse<{ data: any }>>('/Finance/SetEnHizliDoneRequest', request);
  }

  public SetBankInfoRequest(request: {
    Amount: number;
    Type: number;
    BankID: string;
  }): Observable<IResponse<{ data: any }>> {
    return this.post<IResponse<{ data: any }>>('/Finance/SetBankInfoRequest', request);
  }
  public SetHemenodeWitdrawRequest(request: {
    Amount: number;
    Type: number;
    BankID: string;
    AccountNumber: string;
  }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetHemenodeWithdrawRequest', request);
  }
  public SetScashMoneyWitdrawRequest(request: {
    Amount: number;
    IBAN: string;
    BanksID?: any;
    Type: number;
  }): Observable<IResponse<any>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetScashMoneyWithdrawRequest', request);
  }
  public SetMinipayWithdrawRequest(request: {
    Amount: number;
    AccountNumber: string;
    Type: number;
  }): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Finance/SetMinipayWithdrawRequest', request);
  }
  public SetVevoWithdrawRequest(request: { Amount: number; IBAN: string; Type: number }): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Finance/SetVevoWithdrawRequest', request);
  }
  public SetWithdrawRequest(request: {
    Amount: number;
    AccountNumber: string;
    BankID: string;
    Type: number;
    Bankname: string;
  }): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Finance/SetWithdrawRequest', request);
  }

  public GetScashMoneyBankList(request: { Type: number }): Observable<IResponse<any>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/GetScashMoneyBankList', request);
  }
  public GetProviderBanksList(request: { Type: number }): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Finance/GetProviderBanksList', request);
  }
  public GetAnindaCryptoList(request: { Type: number }): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Finance/GetAnindaCryptoList', request);
  }
  public SetFastproDepositRequest(request: {
    Amount: number;
    Type: number;
    ReturnUrl: string;
  }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetFastproDepositRequest', request);
  }
  public SetFastproWitdrawRequest(request: {
    Amount: number;
    Type: number;
    BankID: string;
    BankName: string;
    AccountNumber: string;
    ReturnUrl: string;
  }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetFastproWithdrawRequest', request);
  }

  public SetEnpayDepositRequest(request: { Amount: number }): Observable<IResponse<{ Data: string }>> {
    return this.post<IResponse<{ Data: string }>>('/Finance/SetPaparaEnpayDepositRequest', request);
  }

  public SetFavexDepositRequest(request: { Amount: number }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetFavexDepositRequest', request);
  }

  public SetFavexWithdrawRequest(request: {
    Amount: number;
    AccountNumber: string;
  }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetFavexWithdrawRequest', request);
  }

  public SetPayfixWithdrawRequest(request: { Amount: number; PayfixId: string }): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Finance/SetPayfixWithdrawRequest', request);
  }

  public SetPaparaEnpayWithdrawRequest(request: { Amount: number; AccountNumber: string }): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Finance/SetPaparaEnpayWithdrawRequest', request);
  }

  public SetPayturkaWithdrawRequest(request: {
    Amount: number;
    Type: string;
    WalletId: string;
  }): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Finance/SetPayturkaWithdrawRequest', request);
  }

  public SetAnindaWithdrawRequest(request: {
    Amount: number;
    IBAN: string;
    Type: number;
    BanksID?: any;
  }): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Finance/SetAnindaWithdrawRequest', request);
  }

  public SetAnindaDepositRequest(request: { Amount: number; Type: number }): Observable<IResponse<AnindaResponse>> {
    return this.post<IResponse<AnindaResponse>>('/Finance/SetAnindaDepositRequest', request);
  }

  public SetPayturkaDepositRequest(request: {
    Amount: number;
    Type: string;
  }): Observable<IResponse<{ Iframe: string }>> {
    return this.post<IResponse<{ Iframe: string }>>('/Finance/SetPayturkaDepositRequest', request);
  }

  public OpenDeposit(request: OpenDepositRequests): Observable<Array<OpenDepositRequests[]>> {
    return this.post<Array<OpenDepositRequests[]>>('/finance/opendepositrequests', request);
  }

  public OpenWithdraw(request: OpenWithdrawRequests): Observable<Array<OpenWithdrawRequest[]>> {
    return this.post<Array<OpenWithdrawRequest[]>>('/finance/openwithdraw', request);
  }

  public CancelDeposit(request: CancelDepositRequest): Observable<string> {
    return this.post<string>('/finance/canceldepositrequest', request);
  }

  public CancelWithdraw(request: CancelWithdraw): Observable<string> {
    return this.post<string>('/finance/cancelwithdraw', request);
  }

  public WithdrawBankTransfer(request: WithdrawRequestBankTransfer): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Finance/SetWithdrawBank', request);
  }

  public WithdrawProviders(request: WithdrawProvidersRequest): Observable<Array<WithdrawProviders>> {
    return this.post<Array<WithdrawProviders>>('/Finance/GetWithdraw', request);
  }

  public SiteBanks(request: SiteBanksRequest): Observable<Array<SiteBanks>> {
    return this.post<Array<SiteBanks>>('/Finance/GetBanks', request);
  }

  public SiteBankAccount(request: SiteBankAccountRequest): Observable<SiteBankAccount> {
    return this.post<SiteBankAccount>('/finance/sitebankaccount', request);
  }

  public AddPlayerBankAccount(request: AddPlayerBankAccountRequest): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Account/SetBankAccount', request);
  }

  public DepositRequestBankTransfer(request: DepositRequestBankTransferRequest): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Finance/SetDepositBank', request);
  }

  public AccentPayDeposit(request: AccentPayDepositRequest): Observable<string> {
    return this.post<string>('/accentpay/depositrequest', request);
  }

  public PlayerBankList(request: PlayerBankListRequest): Observable<PlayerBankList> {
    return this.post<PlayerBankList>('/Account/GetBanks', request);
  }

  public CepbankBankList(request: CepbankBankListRequest): Observable<Array<CepbankBankListResponse>> {
    return this.post<Array<CepbankBankListResponse>>('/Finance/GetCepbanks', request);
  }

  public DepositRequestCepbankTransfer(request: DepositRequestCepbankTransferDto): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Finance/SetDepositCepbank', request);
  }

  public SitePaparaList(request: SitePaparaList): Observable<DbPaparaListResponse[]> {
    return this.post<DbPaparaListResponse[]>('/Finance/GetPaparaAccounts', request);
  }

  public PaparaDeposit(request: DepositPapara): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Finance/SetDepositPapara', request);
  }

  public PaparaWithdraw(request: WithdrawPapara): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Finance/SetWithdrawPapara', request);
  }

  public JetonDeposit(request: DepositRequestJetonTransfer): Observable<string> {
    return this.post<string>('/jeton/depositrequestjetontransfer', request);
  }

  public JetonWithdraw(request: WithdrawRequestJetonTransfer): Observable<string> {
    return this.post<string>('/jeton/withdrawrequestjetontransfer', request);
  }

  public DepositRocketTransfer(request: DepositRequestRocketTransfer): Observable<string> {
    return this.post<string>('/rocket/depositrequestrockettransfer', request);
  }

  public RocketWithdrawTransfer(request: RocketWithdrawTransferRequest): Observable<any> {
    return this.post<any>('/rocket/withdrawrequestrockettransfer', request);
  }

  public RocketBankList(request: RocketBankListRequest): Observable<RocketBank[]> {
    return this.post<RocketBank[]>('/rocket/getbanks', request);
  }

  public FormProperty(request: GetDynamicFormPropsRequest): Observable<IResponse<FormProperty[]>> {
    return this.post<IResponse<FormProperty[]>>('/Finance/GetDynamicFormProps', request);
  }

  public CustomFormDeposit(request: SubmitCustomBankForm): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Finance/SetDepositCustomBankForm', request);
  }

  public WithdrawCustomFormDeposit(request: WithdrawSubmitCustomBankForm): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Finance/SetWithdrawCustomBankForm', request);
  }

  public DepositRequestPagoEfectivoTransfer(request: DepositRequestPagoEfectivoTransfer): Observable<any> {
    return this.post<any>('/pagoefectivo/depositrequestpagoefectivotransfer', request);
  }
  // Global

  public CheckSession(request: CheckSessionRequest): Observable<SocketResponse<PlayerSessionData>> {
    return this.post<SocketResponse<PlayerSessionData>>('/global/checksession', request);
  }

  public PromotionsImagesAndTexts(
    request: PromotionsImagesAndTextsRequest
  ): Observable<DbPromotionsImagesTextsResponse[]> {
    return this.post<DbPromotionsImagesTextsResponse[]>('/Site/GetPromotions', request);
  }
  public PromotionsImagesAndTexts_v20(
    request: PromotionsImagesAndTextsRequest
  ): Observable<DbPromotionsImagesTextsResponse[]> {
    return this.post<DbPromotionsImagesTextsResponse[]>('/Site/GetPromotionsV20', request);
  }
  public PromotionsImagesAndTexts_v0010(
    request: PromotionsImagesAndTextsRequest
  ): Observable<DbPromotionsImagesTextsResponse[]> {
    return this.post<DbPromotionsImagesTextsResponse[]>('/Site/GetPromotions_v0010', request);
  }

  public ApplyPromotion(promotionId: string, transactionId?: number): Observable<any> {
    return this.post<string>('/Account/ApplyPromotion', { PromotionId: promotionId, TransactionId: transactionId });
  }

  public UsePromoCode(promoCode: string): Observable<any> {
    return this.post<string>('/Account/UsePromoCode', { PromoCode: promoCode });
  }

  public GetTopNotifications(
    request: GetPlayerContactIncomingMessagesRequest_v2
  ): Observable<DbPlayerContactIncomingMessagesResponse_v2[]> {
    return this.post<DbPlayerContactIncomingMessagesResponse_v2[]>('/Account/GetNotifications_v2', request);
  }

  public Banners(request: BannersRequest): Observable<DbBannerResponse[]> {
    return this.post<DbBannerResponse[]>('/Site/GetBanners', request);
  }

  public Menu(request: MenuRequest): Observable<any[]> {
    return this.post<any[]>('/Site/GetMenu', request);
  }
  public BottomMenu(request: BottomMenuRequest): Observable<any[]> {
    return this.post<any[]>('/Site/GetBottomMenu', request);
  }
  public GetAuthenticationSettings(siteId): Observable<any> {
    return this.getBySiteApi<any[]>('/Site/GetAuthenticationSettings/' + siteId, {});
  }

  public SendMessage(request: SendMessageRequest): Observable<string> {
    return this.post<string>('/sendmessage', request);
  }

  public PlayerMessages(request: PlayerMessagesRequest): Observable<PlayerMessage[]> {
    return this.post<PlayerMessage[]>('/global/playermessages', request);
  }

  public GetPlayerUnreadMessagesCount(request: GetPlayerUnreadMessagesCountRequest): Observable<number> {
    return this.post<number>('/global/playerunreadmessagecount', request);
  }

  public Logout(request: LogoutRequest): Observable<boolean> {
    return this.post<boolean>('/logout', request);
  }

  public GetCountries(request: GetCountriesRequest): Observable<Array<Countries>> {
    return this.post<Array<Countries>>('/Site/GetCountries', request);
  }

  public SiteCurrencies(request: SiteCurrenciesRequest): Observable<Array<SiteCurrency>> {
    return this.post<Array<SiteCurrency>>('/Site/GetCurrencies', request);
  }

  public SiteLanguages(request: SiteLanguagesRequest): Observable<Array<SiteLanguage>> {
    return this.post<Array<SiteLanguage>>('/Site/GetLanguages', request);
  }

  public CheckPlayerInfoExist(request: CheckPlayerInfoExistRequest): Observable<boolean> {
    return this.post<boolean>('/global/checkplayerinfoexist', request);
  }

  public LoginPlayer(request: LoginPlayerRequest): Observable<PlayerSessionResponse> {
    return this.post<PlayerSessionResponse>('/global/login', request);
  }

  public RegisterPlayer(request: RegisterPlayerRequest): Observable<PlayerRegisterResponse> {
    return this.post<PlayerRegisterResponse>('/Account/Register', request);
  }

  public ValidateIdentityNumber(request: ValidateIdentityNumberRequest): Observable<any> {
    return this.post<any>('/Account/ValidateIdentityNumber', request);
  }
  public RegisterPlayerV2(request: RegisterPlayerRequest): Observable<PlayerRegisterResponse> {
    return this.post<PlayerRegisterResponse>('/Account/RegisterV2', request);
  }
  public RegisterPlayerV3(request: RegisterPlayerRequest): Observable<PlayerRegisterResponse> {
    return this.post<PlayerRegisterResponse>('/Account/RegisterPlayerV3', request);
  }

  public PlayerGsmActiveOnRegister(request: { ActivationCode: string; SMSId: string }): Observable<any> {
    return this.post<any>('/Account/PlayerGsmActiveOnRegister', request);
  }
  public PlayerGsmVerifyOnRegister(request: { Gsm: string; SiteId: number }): Observable<PlayerRegisterResponse> {
    return this.post<PlayerRegisterResponse>('/Account/PlayerGsmVerifyOnRegister', request);
  }

  public PreRegisterPlayer(request: RegisterPlayerRequest): Observable<PlayerRegisterResponse> {
    return this.post<PlayerRegisterResponse>('/Account/PreRegisterPlayer', request);
  }

  public PreFastRegisterPlayer(request: RegisterPlayerRequest): Observable<PlayerRegisterResponse> {
    return this.post<PlayerRegisterResponse>('/Account/PreFastRegisterPlayer', request);
  }

  public FastRegisterPlayer(request: RegisterPlayerRequest): Observable<PlayerRegisterResponse> {
    return this.post<PlayerRegisterResponse>('/Account/FastRegister', request);
  }

  public playerBalance(request: PlayerBalanceRequest): Observable<PlayerBalance> {
    return this.post<PlayerBalance>('/player/balance', request);
  }

  public GetIPHistory(request: AccountingPlayerIPHistoryRequest): Observable<AccountingPlayerIPHistoryResponse[]> {
    return this.post<AccountingPlayerIPHistoryResponse[]>('/Account/GetIPHistory', request);
  }

  public PlayerPromotionByPlayer(request: PlayerPromotionByPlayerRequest): Observable<PlayerPromotionByPlayer> {
    return this.post<PlayerPromotionByPlayer>('/player/promotionbyplayer', request);
  }

  public ForgetPasswordMailReset(request: ForgetPasswordMailResetRequest): Observable<IResponse<string | number>> {
    return this.post<IResponse<string | number>>('/Account/PlayerForgetPassword', request);
  }

  public ForgetPasswordReset(request: ForgetPasswordResetRequest): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Account/ForgetPasswordReset', request);
  }

  public PlayerActivateMail(request: PlayerActivateMail): Observable<Boolean> {
    return this.post<Boolean>('/global/activateMail', request);
  }

  public DepositCmt(request: DepositCmt): Observable<string> {
    return this.post<string>('/finance/depositcmt', request);
  }

  public SiteCmtList(request: SiteCmtList): Observable<DbPaparaListResponse[]> {
    return this.post<DbPaparaListResponse[]>('/finance/sitecmtlist', request);
  }

  public siteLanguages(request: SiteLanguagesRequest): Observable<SiteLanguage[]> {
    return this.post<SiteLanguage[]>('/Site/GetLanguages', request);
  }

  public CreateContactMessage(request: CreateContactMessageRequest): Observable<any> {
    return this.post<string>('/Site/SetContactMessage', request);
  }

  public GetContactIssues(request: any): Observable<DbContactIssueResponse[]> {
    return this.post<DbContactIssueResponse[]>('/Site/GetContactIssues', request);
  }

  public GetContactCategories(request: any): Observable<DbContactCategoryResponse[]> {
    return this.post<DbContactCategoryResponse[]>('/Site/GetContactCategories', request);
  }

  public MernisCheckRequest(request: MernisCheckRequest): Observable<boolean> {
    return this.post<boolean>('/mernischeck', request);
  }

  public MobileBanners(request: MobileBannersRequest): Observable<DbMobileBannerResponse[]> {
    return this.post<DbMobileBannerResponse[]>('/Site/GetMobileBanners', request);
  }
  public TvBet(request: TvBetGameUrl): Observable<string> {
    return this.post<string>('/gettvbetgameurl', request);
  }

  public AdminContactSendMessage(request: AdminContactSendMessageRequest): Observable<string> {
    return this.post<string>('/admincontactsendmessage', request);
  }

  public GetPlayerContactSentMessages(
    request: GetPlayerContactSentMessagesRequest
  ): Observable<DbPlayerContactSentMessagesResponse[]> {
    return this.post<DbPlayerContactSentMessagesResponse[]>('/getplayercontactsentmessages', request);
  }

  public GetPlayerContactIncomingMessages(
    request: GetPlayerContactIncomingMessagesRequest
  ): Observable<DbPlayerContactIncomingMessagesResponse[]> {
    return this.post<DbPlayerContactIncomingMessagesResponse[]>('/Account/GetNotifications', request);
  }
  public GetPlayerSystemNotifications(
    request: GetPlayerContactIncomingMessagesRequest
  ): Observable<DbPlayerContactIncomingMessagesResponse[]> {
    return this.post<DbPlayerContactIncomingMessagesResponse[]>('/Account/GetSystemNotifications', request);
  }

  // Player Document Upload
  public UploadPlayerDocument(request: PlayerDocumentUploadRequest): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Account/SetDocument', request);
  }

  public GetPlayerDocumentType(request: PlayerDocumentTypeRequest): Observable<PlayerDocumentTypeResponse[]> {
    return this.post<PlayerDocumentTypeResponse[]>('/Account/GetDocumentTypes', request);
  }

  public GetPLayerDocumentList(request: PlayerDocumentListRequest): Observable<PlayerDocumentListResponse[]> {
    return this.post<PlayerDocumentListResponse[]>('/Account/GetDocuments', request);
  }

  // Promotions Request
  public GetAccountingPromotionList(
    request: AccountingPromotionListRequest
  ): Observable<AccountingPromotionListResponse[]> {
    return this.post<AccountingPromotionListResponse[]>('/accountingpromotionlist', request);
  }

  public AddPlayerPromotionRequest(request: AddPlayerPromotionRequestsRequest): Observable<string> {
    return this.post<string>('/addplayerpromotionrequests', request);
  }

  public GetLiveMatchInPrematch(request: GetLiveMatchInPrematch): Observable<any> {
    return this.post<any>('/getlivematchinprematch', request);
  }

  public SiteSettingsRequests(request: SiteFrontendSettingsRequest): Observable<any> {
    return this.post<any>('/SiteFrontendSettings', request);
  }

  public LastWinners(request: LastWinnersRequest): Observable<TopAndLastWinners[]> {
    return this.post<any>('/Site/GetLastWinners', request);
  }

  public TopWinners(request: TopWinnersRequest): Observable<TopAndLastWinners[]> {
    return this.post<any>('/Site/GetTopWinners', request);
  }

  public HomePageGames(request: HomePageGamesRequest): Observable<Object[]> {
    return this.post<Object[]>('/Games/GetHomePageGames', request);
  }

  public PlayerPromotionHistory(Request: PlayerPromotionHistory): Observable<Object[]> {
    return this.post<Object[]>('/Account/GetPromotionHistoryV006', Request);
  }

  public PlayerPromotionHistoryV0010(Request: PlayerPromotionHistory): Observable<Object[]> {
    return this.post<Object[]>('/Account/GetPromotionHistoryV0010', Request);
  }

  public CancelBonus(Request: CancelBonus): Observable<Object[]> {
    return this.post<Object[]>('/Account/CancelPromotion', Request);
  }
  public PlayerPromotionRequest(Request: PlayerPromotionRequest): Observable<Object[]> {
    return this.post<Object[]>('/Account/GetPromotionRequest', Request);
  }

  public PrematchMenu(Request: any): Observable<Object[]> {
    return this.sportsbookPost<Object[]>('https://services.holla.best/p/menu', Request);
  }

  public LivematchMenu(Request: any): Observable<Object[]> {
    return this.sportsbookPost<Object[]>('https://services.holla.best/l/menu', Request);
  }

  public GetSocialMedia(): Observable<SocialMediaResponse[]> {
    return this.post<SocialMediaResponse[]>('/Site/GetSocialMedia', {});
  }

  public GetCities(request: GetCitiesRequest): Observable<{ Id: any; Name: string }[]> {
    return this.post<{ Id: any; Name: string }[]>('/Site/GetCities', request);
  }

  public ReadNotification(MessageId: number): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Account/SetReadNotification', { MessageId: MessageId });
  }
  public ReadSystemNotification(MessageId: number): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Account/SetReadSystemNotification', { MessageId: MessageId });
  }

  public SetAffiliateRequest(Request: AffiliateRequest): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Site/SetAffiliate', Request);
  }

  public PlayerGsmVerify(): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Account/PlayerGsmVerifyV2', {});
  }

  public PlayerGsmActive(Request: { ActivationCode: string }): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Account/PlayerGsmActiveV2', Request);
  }
  public PlayerGsmVerifyV3(): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Account/PlayerGsmVerifyV3', {});
  }
  public PlayerGsmVerifyV4(): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Account/PlayerGsmVerifyV4', {});
  }

  public PlayerGsmActiveV1(Request: { ActivationCode: string }): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Account/PlayerGsmActive', Request);
  }
  public PlayerGsmActiveV4(Request: { ActivationCode: string; SMSId: string }): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Account/PlayerGsmActiveV4', Request);
  }

  public PlayerEmailVerify(): Observable<IResponse<any>> {
    return this.post<IResponse<any>>('/Account/PlayerMailVerify', {});
  }

  public PlayerEmailActive(Request: { ActivationCode: string }): Observable<IResponse<string>> {
    return this.post<IResponse<string>>('/Account/PlayerMailActive', Request);
  }

  public NotificationList(): Observable<IResponse<NotificationResponse[]>> {
    return this.post<IResponse<NotificationResponse[]>>('/Site/GetNotifications', {});
  }
  public GetDeviceGameId(siteId: number, brandId: number, gameName: string, isMobile: boolean): Observable<any> {
    return this.post<any>('/Games/GetDeviceGameId', {
      SiteId: siteId,
      BrandId: brandId,
      GameName: gameName,
      IsMobile: isMobile,
    });
  }
  public PlayerHasPromotion(): Observable<IResponse<{ HasPromotion: boolean }>> {
    return this.post<any>('/Account/PlayerHasPromotion', {});
  }

  public GetAvailableDeposits(promotionId: number): Observable<any> {
    return this.post<string>('/Account/GetAvailableDeposits', { PromotionId: promotionId });
  }
  public PlayerAddRemoveFavoriteGame(gameId: number, gameType: string): Observable<IResponse<{ GameId: number }[]>> {
    return this.post<any>('/Account/PlayerAddRemoveFavoriteGame', { GameId: gameId, GameType: gameType });
  }
  public PlayerBulkInsertFavoriteGames(favoriteGames: any): Observable<IResponse<{ GameId: number }[]>> {
    return this.post<any>('/Account/PlayerBulkInsertFavoriteGames', { FavoriteGames: favoriteGames });
  }
  public PlayerFavoriteGameIdList(): Observable<IResponse<{ GameId: number }[]>> {
    return this.post<any>('/Account/PlayerFavoriteGameIdList', {});
  }
  public PlayerFavoriteGameList(gameType: string): Observable<IResponse<Array<SlotGameList>>> {
    return this.post<any>('/Account/PlayerFavoriteGameList', { GameType: gameType });
  }
}
