import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AppConfigService } from './config/app-config.service';
import { Event, NavigationEnd, NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { CheckForUpdateService } from './services/check-for-update.service';
import { DOCUMENT } from '@angular/common';

import { combineLatest } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';

declare var $: any;
import { Slick } from 'ngx-slickjs';
import { Config } from './config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CheckForUpdateService],
})
export class AppComponent implements OnInit {
  IsLoggedIn: false;
  updateAvailable = false;
  isAccessible: boolean;
  onlineStatus: OnlineStatusType;
  OnlineStatusType: any = OnlineStatusType;

  removeQueryString(url) {
    return url.split('?')[0];
  }

  constructor(
    private ConfigService: AppConfigService,
    public Router: Router,
    private Route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private CheckForUpdateService: CheckForUpdateService,
    private updates: SwUpdate,
    private onlineStatusService: OnlineStatusService,
    private renderer: Renderer2
  ) {
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      // Retrieve Online status Type
      this.onlineStatus = status;
    });
    this.updates.versionUpdates.subscribe((event) => {
      if (event.type === 'VERSION_READY') {
        this.updateAvailable = true;
      }
    });

    this.Router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });

    Router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let page = this.removeQueryString(val.url).split('/');

        if (page[1].indexOf('casino') !== -1) {
          this.ConfigService.ActiveSubLink.next(`/${page[1]}`);
          this.ConfigService.ActiveBrandId.next(Number(page[2]));
        } else {
          this.ConfigService.ActiveSubLink.next(val.url);
        }
        if (page[1].indexOf('login') !== -1) {
          if (
            typeof Config.settings.HasPlainAuthentication == 'undefined' ||
            !Config.settings.HasPlainAuthentication ||
            this.IsLoggedIn
          ) {
            this.isAccessible = true;
            Router.navigate(['/']);
          } else {
            this.isAccessible = false;
            this.LoginModal();
          }
        } else {
          this.isAccessible = true;
        }
        if (page[1].indexOf('register') !== -1) {
          page[1] = 'register';
          document.querySelector('body').style.overflow = 'auto';
          if (
            typeof Config.settings.HasPlainAuthentication == 'undefined' ||
            !Config.settings.HasPlainAuthentication ||
            this.IsLoggedIn
          ) {
            this.isAccessible = true;
          } else {
            this.isAccessible = false;
          }
        }

        if (page[2] && page[3]) {
          this.document.body.id = (page[2] ? page[1] + page[2] : page[1]) + 'pagex';
        } else {
          this.document.body.id = `${page[1]}pagex`;
        }
      }
    });

    this.ConfigService.LoginStatus.subscribe((Status) => {
      this.IsLoggedIn = Status;
    });
  }

  ngOnInit() {
    /**
     * Btag for affiliate user
     */
    combineLatest([this.Route.paramMap, this.Route.queryParamMap]).subscribe(([pathParams, queryParams]) => {
      let btag = queryParams.get('btag');
      let ConfirmPassword = queryParams.get('ConfirmPassword');
      if (btag) {
        localStorage.setItem('btag', btag);
      }
      let refCode = queryParams.get('ref');
      if (refCode) {
        localStorage.setItem('refCode', refCode);
      }
      let promoCode = queryParams.get('promo');
      if (promoCode) {
        localStorage.setItem('promoCode', promoCode);
      }
      if (ConfirmPassword) {
        this.ConfigService.ResetModal.next('on');
      }
    });
  }

  LoginModal() {
    this.ConfigService.LoginModal.next('on');
  }
  RegisterModal() {
    this.ConfigService.RegisterModal.next('on');
  }

  get IsMobile() {
    const ua = (window.navigator.userAgent || '').toLowerCase();
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 4)
      );
    return isMobile;
  }

  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.ConfigService.AppLoader.next(true);
      if (event.navigationTrigger === 'popstate') {
        let modal = document.querySelector('.modal.show:has(.confirm.modal-body)');
        let body = document.querySelector('body.modal-open');
        let modalBackdrop = document.querySelector('.modal-backdrop.fade.in.show');
        console.log(modal);
        if (modal) {
          modal.remove();
        }
        if (modalBackdrop) {
          modalBackdrop.remove();
        }
        if (body) {
          this.renderer.removeClass(body, 'modal-open');
        }
      }
      if (!this.Router.url.match('casino')) {
        window.scroll(0, 0);
      }
      // $(".mobile-menu-button").removeClass('active')
      // $(".mobile-menu-layout").removeClass('active')
      // $("body").css('overflow-y','scroll')
      // this.ConfigService.AppLoader.next(true);
    }

    if (event instanceof NavigationEnd) {
      let _this = this;
      setTimeout(function () {
        _this.ConfigService.AppLoader.next(false);
      }, 750);
      this.ConfigService.providerDropdown.next(false);
    }
  }
}
