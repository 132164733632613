<!--<ng-container *ngIf="!IsMobile">
  <nav *ngIf="DesktopBottomMenu.length > 0 && isCollapsed" class="navbar navbar-expand-lg navbar-dark navbar-bottom d-none" >
    <div class="container-fluid">
      <div class="desktop-menu">
        <ul class="navbar-nav left-navbar">
          <ng-container *ngFor="let link of DesktopBottomMenu | filter : { IsSupport: false }">
            <li routerLinkActive="active" class="nav-item">
              <a class="nav-link" role="button" (click)="handleMenuButtonClick(link)">
                <span>
                  <i class="{{ link.Icon }} mr-2"></i>
                  <span>{{ link.TranslationKey | translate }}</span>
                </span>
              </a>
            </li>
          </ng-container>
        </ul>
        <ul class="navbar-nav right-navbar">
          <li routerLinkActive="false" class="nav-item right-nav-item" *ngIf="desktopSupportButtons.length > 0">
            <a class="nav-link" role="button" (click)="openDesktopFab()">
              <span>
                <i class="fa fa-headset mr-2"></i>
                <span>{{ 'btn.support' | translate }}</span>
              </span>
            </a>
          </li>

          <li class="mobile-nav-item">
            <a (click)="openCloseMenu()" class="nav-item right-nav-item" role="button">
              <i class="fad fa-window-close"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <a
    class="bottom-nav-toolbar-btn"
    href="javascript:;"
    *ngIf="checkVisibilityShowButton() && !isCollapsed"
    (click)="openCloseMenu()">
    <i class="fas fa-ellipsis-v"></i>
  </a>
  <ng-floating-action-menu
    id="desktopFloatButton"
    placement="br"
    effect="mfb-slidein"
    iconClass="fas fa-headset"
    activeIconClass="fas fa-times"
    toggle="click"
    [buttons]="desktopSupportButtons"></ng-floating-action-menu>
</ng-container>
<ng-container *ngIf="IsMobile">
  <ul
    class="navbar-bottom-mobile "
    *ngIf="MobileBottomMenu.length > 0"
    [class.open]="isCollapsed == true"
    [class.is-animate]="checkVisibilityShowButton()"
    id="mobileBottomMenu"
    (touchstart)="onTouchStartBottomMenu($event)"
    (touchmove)="onTouchMoveBottomMenu($event)"
    (touchend)="onTouchEndBottomMenu()">
    <ng-container *ngFor="let link of MobileBottomMenu | filter : { IsSupport: false }; let i = index">
      <li class="mobile-nav-item" *ngIf="isShowMobileMenu(i)">
        <a class="mobile-nav-item-inner menu-block-small" role="button" (click)="handleMenuButtonClick(link)">
          <i class="{{ link.Icon }}"></i>
          <span>{{ link.TranslationKey | translate }}</span>
        </a>
      </li>
    </ng-container>
    <li class="mobile-nav-item" *ngIf="isShowMobileMenu(mobileButtonCount) && mobileSupportButtons.length > 0">
      <a class="mobile-nav-item-inner menu-block-small" role="button" (click)="openMobileFab()">
        <i class="fa fa-headset"></i>
        <span>{{ 'btn.support' | translate }}</span>
      </a>
    </li>
    <li
      class="mobile-nav-item"
      (touchstart)="onTouchStart($event)"
      (touchmove)="onTouchMove($event)"
      (touchend)="onTouchEnd()"
      *ngIf="checkVisibilityShowButton()">
      <a
        [class.active]="isCollapsed == true"
        (click)="openCloseMenu()"
        class="mobile-nav-item-inner menu-block-small"
        role="button">
        <i [ngClass]="isCollapsed ? 'bi bi-x-lg' : 'fas fa-ellipsis-v'"></i>
      </a>
      <div class="arrow" *ngIf="checkVisibilityShowButton()">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </li>
  </ul>
  <ng-floating-action-menu
    id="mobileFloatButton"
    placement="br"
    effect="mfb-slidein"
    iconClass="fas fa-headset"
    activeIconClass="fas fa-times"
    toggle="click"
    [buttons]="mobileSupportButtons"></ng-floating-action-menu>
</ng-container>-->

<div class="m-bottom-nav fixed-bottom" [ngClass]="{ show: isSticky }">
  <nav>
    <ng-container *ngIf="mobileSupportButtons.length > 0">
      <a class="item align-self-center" role="button" (click)="openMobileFab()">
        <i class="bw-i-live-chat"></i>
        <span>{{ 'btn.support' | translate }}</span>
      </a>
    </ng-container>
    <ng-container *ngFor="let link of DesktopBottomMenu | filter : { IsSupport: false }">
      <a class="item align-self-center" (click)="handleMenuButtonClick(link)">
        <i class="{{ link.Icon }} mr-2"></i>
        <span>{{ link.TranslationKey | translate }}</span>
      </a>
    </ng-container>

    <a
      href="javascript:void(0)"
      class="item align-self-center"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasNavbar"
      aria-controls="offcanvasNavbar"
      aria-label="Toggle navigation">
      <i class="bw-i-burger-menu"></i>
      <span class="">MENÜ</span>
    </a>
  </nav>
</div>

<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
  <div class="offcanvas-header">
    <img [routerLink]="['/']" src="assets/uploads/brand/logo.png" alt="" class="logo" />
    <a ref="javascript:void(0)" class="btn btn-close" data-bs-dismiss="offcanvas"><i class="fa fa-times"></i></a>
  </div>
  <div class="offcanvas-body">
    <div class="mobile-nav">
      <ul class="nav-menu">
        <li>
          <h6>MENÜ</h6>
        </li>
        <ng-container *ngFor="let Menu of Menus | filter : { Align: 'left' }; let i = index">
          <ng-container [ngTemplateOutlet]="userTemplate"></ng-container>
          <ng-template #userTemplate>
            <li>
              <a
                data-bs-dismiss="offcanvas"
                class="nav-link"
                [routerLink]="Menu.UrlRoute"
                *ngIf="Menu.IsActive"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="current">
                <i class="{{ Menu.MenuIcon }} nav-icon me-2"></i>
                <span>
                  {{ Menu.TranslationKey | translate }}
                  <em class="new" *ngIf="Menu.IsNew">{{ 'common.new' | translate }}</em>
                </span>
              </a>
            </li>
          </ng-template>
        </ng-container>
        <!--        <li>
          <a href="#">
            <i class="bw-i-prematch theme nav-icon me-2"></i>
            <span>BAHİS</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="bw-i-live nav-icon live me-2"></i>
            <span>CANLI BAHİS</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="bw-i-slots nav-icon casino me-2"></i>
            <span>SLOT CASINO</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="bw-i-livecasino nav-icon casino me-2"></i>
            <span>CANLI CASINO</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="bw-i-virtualbetting nav-icon games me-2"></i>
            <span>SANAL SPORLAR</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="bw-i-games nav-icon casino me-2"></i>
            <span>OYUNLAR</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="bw-i-promotions nav-icon promo me-2"></i>
            <span>PROMOSYONLAR</span>
          </a>
        </li>-->
      </ul>
      <ul class="nav-custom">
        <li>
          <h6>İLETİŞİM</h6>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="CallMeModal()" data-bs-dismiss="offcanvas">
            <i class="fa-sharp fa-solid fa-phone-volume me-2"></i>
            <span>{{ 'btn.call-me' | translate }}</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="FeedbackModal()">
            <i class="bw-i-message me-2"></i>
            <span>{{ 'footer.feedback' | translate }}</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" href="javascript:void(0)" (click)="openMobileFab()">
            <i class="bw-i-live-chat me-2"></i>
            <span>{{ 'btn.support' | translate }}</span>
          </a>
        </li>
      </ul>
      <ul class="nav-custom">
        <li>
          <h6>ÖZEL BAĞLANTILAR</h6>
        </li>
        <ng-container *ngFor="let item of SocialMedias">
          <li class="" *ngIf="item.DefaultName !== 'Whatsapp'; else Whatsapp" [ngClass]="item.DefaultName | lowercase">
            <a class="" target="_blank" href="{{ item.Url }}" [attr.data-media]="item.DefaultName | lowercase">
              <i class="{{ item.CssClass }} me-2"></i>
              {{ item.DefaultName }}
            </a>
          </li>
          <ng-template #Whatsapp>
            <li class="" [ngClass]="item.DefaultName | lowercase">
              <a
                class=""
                [attr.data-media]="item.DefaultName | lowercase"
                target="_blank"
                href="https://api.whatsapp.com/send?phone={{ item.Url }}">
                <i class="{{ item.CssClass }} me-2"></i>
                {{ item.DefaultName }}
              </a>
            </li>
          </ng-template>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
