<div class="navbar-collapse" id="betUserList">
  <div class="navbar-collapse-content">
    <div class="btn-group">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a href="#" role="button" class="nav-link nav-link-btn">
            <i class="bi bi-apple"></i>
            <div class="nav-link-btn-text">
              <h6 class="mb-0 fs-9 font-weight-bold">UYGULAMA İNDİR</h6>
              <p class="mb-0 fs-8">
                Tüm cihazlara uyumlu.
                <small class="d-block fs-7">Google Chrome ile kullanın.</small>
              </p>
            </div>
            <i class="bi bi-download ml-auto"></i>
          </a>
        </li>
        <li class="nav-item no-list mb-2">
          <a href="#" role="button" class="nav-link nav-link-btn w-100">
            <img src="assets/images/navbar/call.png" alt="*" class="img-fluid mr-2" />
            BENİ ARA
          </a>
        </li>
      </ul>
      <ul class="navbar-nav navbar-nav-bg">
        <li class="nav-item nav-head">
          <a class="nav-link">
            <i class="bi bi-person-fill"></i>
            HESABIM
            <div class="custom-control custom-switch ml-auto">
              <span class="custom-control-text">{{ 'rightmenu.hidebalance' | translate }}</span>
              <input
                type="checkbox"
                (change)="HideBalance()"
                [(ngModel)]="BalanceHide"
                class="custom-control-input"
                id="customSwitch1"
                checked />
              <label class="custom-control-label" for="customSwitch1"></label>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Hesap Bilgilerim</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Hesap Onayı</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Giriş Geçmişi</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Bilinçli Oyun</a>
        </li>
      </ul>
      <ul class="navbar-nav navbar-nav-bg">
        <li class="nav-item nav-head">
          <a class="nav-link">
            <i class="bi bi-wallet2"></i>
            BAKİYE
            <span class="text-success ml-auto">
              <ng-container *ngIf="BalanceHide">---</ng-container>
              <ng-container *ngIf="!BalanceHide">{{ playerBalance }}</ng-container>
              {{ Player.Currency | currencySymbol }}
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Para Yatır</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Para Çek</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Banka Hesaplarım</a>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="#">
            Bonus
            <span class="ml-auto">0,00TRY</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Hesap Hareketleri</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            href="#subList2"
            role="button"
            aria-expanded="false"
            aria-controls="subList2">
            Kuponlarım
            <i class="bi bi-plus-lg ml-auto"></i>
          </a>
          <div class="collapse" id="subList2">
            <ul class="list-unstyled nav-item-sub-list">
              <li class="nav-item">
                <a href="#" class="nav-link">ITEM 1</a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link">ITEM 2</a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link justify-content-end bg-transparent p-0 mb-0">
                  SAYFAYA GİT
                  <i class="bi bi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Mesajlarım</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Çıkış</a>
        </li>
      </ul>
    </div>
  </div>
</div>
