import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConfigService } from 'src/app/config/app-config.service';
import { Subscription } from 'rxjs';
import { PlayerSessionData } from 'src/app/models/service.dtos';
import { Config } from 'src/app/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss'],
})
export class HeaderUserComponent implements OnInit, OnDestroy {

  Player: PlayerSessionData;
  Subscribe: Subscription = new Subscription();

  BalanceHide: any = false;

  constructor(private ConfigService: AppConfigService, private Router: Router) {}

  playerBalance: any;
  playerBonusBalance: any;
  ngOnInit(): void {
    this.Subscribe.add(
      this.ConfigService.Player.subscribe((Player) => {
        if (Player) {
          this.Player = Player;
          if (Player.Balance.toString().split('.')[1]) {
            this.playerBalance =
              Player.Balance.toString().split('.')[0] + '.' + Player.Balance.toString().split('.')[1]?.substring(0, 2);
          } else {
            this.playerBalance = Player.Balance.toString();
          }
          if (Player.BonusBalance.toString().split('.')[1]) {
            this.playerBonusBalance =
              Player.BonusBalance.toString().split('.')[0] +
              '.' +
              Player.BonusBalance.toString().split('.')[1]?.substring(0, 2);
          } else {
            this.playerBonusBalance = Player.BonusBalance.toString();
          }
        }
      })
    );
    this.Subscribe.add(
      this.ConfigService.BalanceHide.subscribe((Status) => {
        this.BalanceHide = Status;
      })
    );
  }

  HideBalance() {
    if (this.BalanceHide) {
      localStorage.setItem('BalanceHide', 'true');
    } else {
      localStorage.removeItem('BalanceHide');
    }
    this.ConfigService.BalanceHide.next(this.BalanceHide);
  }

  ngOnDestroy() {
    this.Subscribe.unsubscribe();
  }

  Logout() {
    this.ConfigService.Logout();
  }

  getTickets() {
    this.Router.navigateByUrl('/sportsbook/tickets');
  }
}
