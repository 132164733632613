import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WindowRefService } from './WindowRef.service';

@Injectable({ providedIn: 'root' })
export class TranslateService {
  /**
   *
   */
  private browserLang: string;
  constructor(private winRef: WindowRefService) {
    this.browserLang = winRef.nativeWindow.navigator.language || winRef.nativeWindow.navigator.userLanguage;
  }
  getBrowserLang(): string {
    return this.browserLang;
  }

  private translations: {};

  public setTranslations(translations: { [key: string]: string } | Object) {
    this.translations = translations;
  }

  public get(param: string): string {
    return (this.translations && this.translations[param]) || param;
  }

  public getObs(params: string[]): Observable<{ [key: string]: string }> {
    let c: BehaviorSubject<{ [key: string]: string }> = new BehaviorSubject({});
    let res: { [key: string]: string } = {};
    for (const param of params) {
      res[param] = this.get(param);
      c.next(res);
    }
    return c.asObservable();
  }
}
