<div class="bw-game-item skeleton-main" [ngClass]="showType ? showType : ''">
  <img class="bw-game-media" />
  <div class="bw-game-content">
    <div class="game-content">
      <a href="javascript:void(0)" class="btn-fav">
        <i [class.far]="!FavActive" [class.fas]="FavActive" class="fa-star"></i>
      </a>

      <h3></h3>
    </div>
    <div class="game-actions d-flex justify-content-center">
      <a href="javascript:void(0)" class="btn btn-play align-self-center m-1">
        {{ 'btn.playnow' | translate }}
      </a>
      <a href="javascript:void(0)" *ngIf="Game.HasDemo" class="btn btn-play demo align-self-center m-1 d-none">
        {{ 'text.demogame.header' | translate }}
      </a>
    </div>
  </div>
</div>
