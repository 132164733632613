import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from 'src/app/services/General.service';
import { PromotionsImagesAndTextsRequest } from 'src/app/models/service.dtos';
import { Config } from 'src/app/config';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/config/app-config.service';
import { Slick } from 'ngx-slickjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-horizontal-promotion-list',
  templateUrl: './horizontal-promotion-list.component.html',
  styleUrls: ['./horizontal-promotion-list.component.scss'],
})
export class HorizontanPromotionListComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;
  ActiveBonus: any = {};
  IsPromotionLoader: boolean = true;
  Promotions: any = [];

  Subscriber: Subscription = new Subscription();
  LoginStatus: boolean = false;
  buttonDisabled: boolean[] = [];

  config: Slick.Config = {
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    mouseWheelMove: false,
    dots: true,
    draggable: true,
    centerMode: false,
    slidesToScroll: 3,
    autoplaySpeed: 3000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  config2: Slick.Config = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mouseWheelMove: false,
    dots: true,
    autoplaySpeed: 3000,
    draggable: true,
    autoplay: true,
  };
  IsLoggedIn: boolean;
  constructor(
    private Service: GeneralService,
    private modalService: BsModalService,
    private ConfigService: AppConfigService,
    private AlertService: ToastrService
  ) {}

  getPromotions() {
    let req = new PromotionsImagesAndTextsRequest();
    this.Service.PromotionsImagesAndTexts_v20(req).subscribe((res) => {
      if (res) {
        this.Promotions = res.map((promotion: any) => {
          promotion.ImagePath != null
            ? (promotion.Image = `${Config.settings.CdnUrl}/` + promotion.ImagePath)
            : (promotion.Image = `${Config.settings.Endpoints.Api}/Site/GetPromotions/${
                Config.settings.ClientId
              }/${localStorage.getItem('lang')}/${promotion.Id}.png`);
          promotion.Collapsed = false;
          if (promotion.Tags != null) {
            promotion.SplittedTags = promotion.Tags.split('|');
          }
          return promotion;
        });
        this.IsPromotionLoader = false;
      } else {
        this.IsPromotionLoader = false;
      }
    });
  }
  ngOnInit(): void {
    this.ConfigService.LoginStatus.subscribe((Status) => (this.IsLoggedIn = Status));
    this.getPromotions();
  }

  ngOnDestroy() {}

  promotionDetail(Promotion: any, template: TemplateRef<any>) {
    this.ActiveBonus = Promotion;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog modal-fullscreen-lg-down bonus-modal modal-xl',
      ignoreBackdropClick: false,
    });
  }
  applyPromotion(promotionId) {
    if (this.IsLoggedIn) {
      console.log(this.buttonDisabled[promotionId]);
      this.buttonDisabled[promotionId] = true;
      this.Service.ApplyPromotion(promotionId).subscribe((Response) => {
        if (Response.Message && Response.Success === false) {
          this.buttonDisabled[promotionId] = false;
          if (/\d/.test(Response.Message)) {
            this.AlertService.info(Response.Message);
          } else {
            this.AlertService.error(Response.Message);
          }
        } else {
          this.buttonDisabled[promotionId] = false;
          this.AlertService.success(Response.Message);
        }
      });
    } else {
      this.ConfigService.LoginModal.next('on');
    }
  }
  get IsMobile() {
    const ua = (window.navigator.userAgent || '').toLowerCase();
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 4)
      );
    return isMobile;
  }
}
