<div class="p-list" [ngClass]="IsIOS ? 'ios' : IsMobile ? 'other' : ''">
  <div class="page-view-more" style="margin-top: 20px" *ngIf="ShowCategory === true">
    <h3 class="view-title-more ellipsis">{{ 'slot.categories' | translate }}</h3>
  </div>
  <div class="scroll-container position-relative" *ngIf="ShowCategory === true">
    <div class="game-filter-arrow left-arrow" *ngIf="showLeftArrow && !this.CategoryLoader" (click)="scrollLeft()">
      <i class="fa-solid fa fa-chevron-left bw-left-arrow"></i>
    </div>
    <div class="scroll-inline-container" #scrollContainer (scroll)="onScrollContainer()">
      <div class="content" #content>
        <ng-container *ngIf="this.CategoryLoader">
          <button *ngFor="let _ of [].constructor(50)" class="item skeleton"></button>
        </ng-container>
        <ng-container *ngIf="!this.CategoryLoader">
          <button
            (click)="filterCategory(9999)"
            class="btn btn-base me-1"
            [ngClass]="{
              active: this.isFilterCategory(9999)
            }">
            <i class="bw-i-all-games me-2"></i>
            <span>{{ 'casino.allgames' | translate }}</span>
          </button>
          <button
            (click)="filterCategory(-1)"
            class="btn btn-base me-1"
            [ngClass]="{
              active: this.isFilterCategory(-1)
            }">
            <i class="bw-i-favorite me-2"></i>
            <span>{{ 'general.text.favorites' | translate }}</span>
          </button>
          <button
            (click)="filterCategory(item.Id)"
            class="btn btn-base me-1"
            [ngClass]="{ active: this.isFilterCategory(item.Id) }"
            *ngFor="let item of Categories">
            <i class="bw-i-default me-2" [ngClass]="item.Icon"></i>
            <span>{{ item.Name }}</span>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="game-filter-arrow" *ngIf="showRightArrow && !this.CategoryLoader" (click)="scrollRight()">
      <i class="fa-solid fa fa-chevron-right bw-right-arrow"></i>
    </div>
  </div>
  <div class="f-categories mt-1" *ngIf="IsMobile">
    <ng-container>
      <div
        class="w-100"
        style="justify-content: space-between; padding-right: 0 !important; padding-left: 0 !important">
        <div class="order-1 providers">
          <button class="btn btn-base mt-3" (click)="openModal()">{{ 'casino.chooseprovider' | translate }}</button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="casino-select-provider-button-block-bc">
    <div class="accordion-title" *ngIf="!IsMobile">
      <div class="page-view-more">
        <h3 class="view-title-more ellipsis" *ngIf="!this.IsBrandSearchActive">{{ 'text.providers' | translate }}</h3>
      </div>
      <span (click)="resetGames()" class="accordion-reset-button">{{ 'text.reset' | translate }}</span>
      <div class="sport-search-bc" [ngClass]="{ active: this.IsBrandSearchActive }">
        <div class="ss-icon-holder-bc" style="z-index: 7" (click)="this.changeBrandFilterStatus()">
          <i *ngIf="!this.IsBrandSearchActive" class="fas fa-search ss-icon-bc"></i>
          <i *ngIf="this.IsBrandSearchActive" class="fa fa-times ss-icon-bc"></i>
        </div>
        <input
          *ngIf="this.IsBrandSearchActive"
          autofocus
          type="text"
          class="ss-input-bc"
          placeholder="{{ 'search.provider' | translate }} ..."
          ng-model-options="{debounce: 1000}"
          (input)="providerSearcher($event)" />
      </div>
    </div>

    <ng-container *ngIf="!this.BrandLoader && !IsMobile">
      <div class="accordion-items-container" [ngClass]="{ collapsed: this.visible }">
        <div class="accordion-items">
          <div *ngIf="!isBrandLoader" class="accordion-items-resize">
            <div *ngFor="let brand of Brands">
              <button
                title="{{ brand.Name }}"
                class="btn btn-base px-3"
                style="align-items: center"
                (click)="filterGames(brand.Id)"
                [ngClass]="{ current: isFilterBrand(brand.Id) }"
                type="button"
                data-badge="Jackpot">
                <svg-icon
                  *ngIf="brand.SvgPath"
                  [stretch]="true"
                  [svgStyle]="{ 'min-width': '4rem', width: '100%' }"
                  src="{{ brand.SvgPath }}"></svg-icon>
                <span *ngIf="!brand.SvgPath">{{ brand.Name }}</span>
              </button>
            </div>
          </div>
        </div>
        <i
          *ngIf="this.visible === false"
          (click)="this.visible = true"
          class="accordion-items-container-arrow fa fa-chevron-up"></i>
        <i
          *ngIf="this.visible === true"
          (click)="this.visible = false"
          class="accordion-items-container-arrow fa fa-chevron-down"></i>
      </div>
    </ng-container>
    <ng-container *ngIf="this.BrandLoader && !IsMobile">
      <div class="accordion-items-container" [ngClass]="{ collapsed: this.visible }">
        <div class="accordion-items">
          <div *ngIf="!isBrandLoader" class="accordion-items-resize">
            <div *ngFor="let _ of [].constructor(50)">
              <span title="&nbsp;">
                <button
                  class="btn btn-base d-flex skeleton"
                  style="align-items: center"
                  type="button"
                  data-badge="Jackpot">
                  <span style="width: 5rem">&nbsp;</span>
                </button>
              </span>
            </div>
          </div>
        </div>
        <i
          *ngIf="this.visible === false && !this.BrandLoader"
          (click)="this.visible = true"
          class="accordion-items-container-arrow fa fa-chevron-up"></i>
        <i
          *ngIf="this.visible === true && !this.BrandLoader"
          (click)="this.visible = false"
          class="accordion-items-container-arrow fa fa-chevron-down"></i>
      </div>
    </ng-container>

    <div class="d-flex justify-content-between">
      <div class="casino-title-search col-3" style="margin-top: 0 !important">
        <div class="page-view-more">
          <h3 class="view-title-more ellipsis" *ngIf="!this.IsSearchActive && this.Type === 'slot_game'">
            {{ 'slot.games' | translate }}
          </h3>
          <h3 class="view-title-more ellipsis" *ngIf="!this.IsSearchActive && this.Type === 'live_casino'">
            {{ 'slot.livecasino.games' | translate }}
          </h3>
          <h3 class="view-title-more ellipsis" *ngIf="!this.IsSearchActive && this.Type === FAST_GAMES">
            {{ 'slot.fastgames.games' | translate }}
          </h3>
          <h3 class="view-title-more ellipsis" *ngIf="!this.IsSearchActive && this.Type === SCRATCH">
            {{ 'slot.scratch.games' | translate }}
          </h3>
          <h3 class="view-title-more ellipsis" *ngIf="!this.IsSearchActive && this.Type === VIRTUAL_SPORTS">
            {{ 'slot.virtual.games' | translate }}
          </h3>
        </div>

        <div class="sport-search-bc" [ngClass]="{ active: this.IsSearchActive }">
          <div class="ss-icon-holder-bc" style="z-index: 7" (click)="this.changeFilterStatus()">
            <i *ngIf="!this.IsSearchActive" class="fas fa-search ss-icon-bc"></i>
            <i *ngIf="this.IsSearchActive" class="fa fa-times ss-icon-bc"></i>
          </div>

          <input
            *ngIf="this.IsSearchActive"
            autofocus
            type="text"
            class="ss-input-bc"
            placeholder="{{ 'search.casino_game' | translate }}..."
            ng-model-options="{debounce: 1000}"
            (input)="searcher($event)" />
          <div class="ss-icon-holder-bc">
            <i (click)="this.IsSearchActive = !this.IsSearchActive" class="ss-icon-bc bc-i-close-remove"></i>
          </div>
        </div>
        <!-- <button class="btn show-allCategories" type="button">
          <i class="bc-i-show-all-categories"></i>
        </button> -->
      </div>

      <div class="d-flex ml-auto">
        <a href="javascript:void(0)" [class.active]="AlphabeticStatus" class="btn btn-filter" (click)="sortByAlpha()">
          <i class="fad fa-sort-alpha-down"></i>
        </a>
        <a
          href="javascript:void(0)"
          [class.active]="!AlphabeticStatus"
          class="btn btn-filter"
          (click)="getSlotGameList()">
          <i class="fad fa-sort-size-down"></i>
        </a>
        <a
          href="javascript:void(0)"
          [class.active]="showType == 'list'"
          (click)="setShowType('list')"
          class="btn btn-filter d-none">
          <i class="fad fa-list"></i>
        </a>

        <a
          href="javascript:void(0)"
          [class.active]="showType == 'grid'"
          (click)="setShowType('grid')"
          class="btn btn-filter">
          <i class="fas fa-th"></i>
        </a>
        <a
          href="javascript:void(0)"
          [class.active]="showType == 'half'"
          (click)="setShowType('half')"
          class="btn btn-filter d-none">
          <i class="fas fa-th-large"></i>
        </a>
      </div>
    </div>
  </div>
</div>
<ng-template #Template>
  <div class="modal-header d-flex justify-content-between">
    <div class="w-100">
      <div class="form-floating">
        <input
          type="text"
          id="searchProvider"
          (keyup)="searchProvider($event)"
          autocomplete="off"
          id="choose-provider"
          tabindex="1"
          #search
          autofocus="true"
          name="Search"
          [(ngModel)]="provider"
          class="input-search form-control"
          placeholder="{{ 'input.search-providers' | translate }}" />
        <label>{{ 'input.search-providers' | translate }}</label>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="p-list">
      <ul class="{{ IsShowed ? 'showed' : '' }}">
        <li class="game-brand w-100" *ngFor="let Brand of Brands">
          <a
            class="btn btn-base"
            href="javascript:void(0)"
            (click)="filterGames(Brand.Id)"
            [ngClass]="{ active: this.isFilterBrand(Brand.Id) }">
            <span class="p-icon">
              <i class="{{ Brand.Name.toLowerCase() }} "></i>
            </span>
            <span class="name">{{ Brand.Name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <div class="col-8">
      <a
        href="javascript:void(0)"
        class="btn btn-base me-1"
        style="text-transform: uppercase"
        data-bs-dismiss="modal"
        (click)="Close()">
        {{ 'btn.close' | translate }}
      </a>
      <a
        href="javascript:void(0)"
        class="btn btn-base me-1"
        style="text-transform: uppercase"
        data-bs-dismiss="modal"
        (click)="Reset()">
        {{ 'text.reset' | translate }}
      </a>
    </div>
    <div class="col justify-content-end d-flex">
      <a
        href="javascript:void(0)"
        class="btn btn-theme btn-apply"
        style="text-transform: uppercase"
        data-bs-dismiss="modal"
        (click)="Apply()">
        {{ 'btn.applyandclose' | translate }}
      </a>
    </div>
  </div>
</ng-template>
