<ng-template #Template>
  <div class="modal-header d-flex justify-content-between">
    <div class="start">
      <i class="bw-i-promo-code me-2"></i>
      {{ 'title.use.promotion.code' | translate }}
    </div>
    <div class="end">
      <a href="javascript:void(0)" (click)="Close()" class="btn btn-close"><i class="fa fa-close"></i></a>
    </div>
  </div>

  <div class="modal-body p-3 text-center">
    <div class="p-3 text-light" [innerHTML]="'use.promotion.code.modal.description' | translate"></div>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="Form" (submit)="UsePromoCode()" class="login-form">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              formControlName="PromoCode"
              placeholder="{{ 'register.form.promotionkey' | translate }}"
              [class.is-invalid]="f.PromoCode.invalid && (f.PromoCode.dirty || f.PromoCode.touched)" />
            <div
              *ngIf="f.PromoCode.invalid && (f.PromoCode.dirty || f.PromoCode.touched)"
              class="invalid-feedback text-left">
              {{ 'general.text.required' | translate }}
            </div>
          </div>
          <button type="submit" [disabled]="Form.invalid" (click)="ModalRef.hide()" class="btn btn-theme mt-3">
            <span>{{ 'btn.promotion.usecode' | translate }}</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</ng-template>
