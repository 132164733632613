import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppConfigService } from '../../config/app-config.service';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PlayerLoginRequest } from '../../models/user.dtos';
import { Config } from '../../config';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';
import { NavigationEnd, Router } from '@angular/router';
import { AngularDeviceInformationService } from 'angular-device-information';
import { TranslateService } from 'src/app/services/Translate.service';
import { createSupportButtons } from 'src/app/utils/FloatingActionButtons';
import {
  GetCountriesRequest,
  RegisterPlayerRequest,
  SiteLanguagesRequest,
  SiteCurrenciesRequest,
  GetCitiesRequest,
  ValidateIdentityNumberRequest,
} from '../../models/service.dtos';
import { GeneralService } from 'src/app/services/General.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { SocketService } from 'src/app/services/socket.service';
import { CountryISO } from 'ngx-intl-tel-input';
import { parseDate } from 'ngx-bootstrap/chronos';
import { formatDate } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss'],
})
export class RegisterModalComponent implements OnInit {
  @Input('Status') Status: boolean;
  selectedTab = 'DefaultRegister';
  CountryISO = CountryISO;
  Loader = false;
  step = 1;
  errorList = [];
  @ViewChild('RegisterTemplate')
  private Template: TemplateRef<any>;
  ModalRef: BsModalRef;
  bsMaxDate: Date = new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate());
  bsMinDate: Date = new Date(new Date().getFullYear() - 150, new Date().getMonth(), new Date().getDate());
  OneClickForm: UntypedFormGroup;
  Model: RegisterPlayerRequest = new RegisterPlayerRequest();
  LoginModel: PlayerLoginRequest = new PlayerLoginRequest();
  siteKey = Config.settings.ReCaptchaKey;
  FastRegistrationEnabled = Config.settings.FastRegistrationEnabled;
  Countries = [];
  registerStatus: boolean = false;
  Currencies = [];
  PhoneCode: number;
  AuthSettings = Config.AuthenticationSettings;
  successMessage: any;
  duplicateForm: any;
  Subscribe: Subscription;
  isAccessible: boolean;
  IsLoggedIn: boolean;
  EU_countries = [
    'DEU',
    'NLD',
    'AUT',
    'BEL',
    'BGR',
    'HRV',
    'CYP',
    'CZE',
    'DNK',
    'EST',
    'FIN',
    'GRC',
    'HUN',
    'IRL',
    'LVA',
    'LTU',
    'LUX',
    'MLT',
    'POL',
    'PRT',
    'ROM',
    'SVK',
    'SVN',
    'SWE',
    'ITA',
    'GBR',
    'FRA',
    'ESP',
  ];
  isEUCountry: boolean = false;
  Days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  Months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  Years = [];
  IsGsmClicked: boolean = false;
  identityCheckModel = true;
  gsmCheckModel = true;
  birthdayLessThanAllowed = false;
  birthdayMoreThanAllowed = false;
  IdentityMessage: string;
  ApiValidationError: string;
  errorMessage: any;
  gsmLoader = false;

  Cities: { Id: any; Name: string }[] = [];

  Languages = [];

  Agreement: boolean;

  SiteId = Config.settings.ClientId;

  Form: UntypedFormGroup;
  FastRegisterForm: any;
  OneClickFormRegisterClicked: boolean = false;
  RegisterFormClicked: boolean = false;
  DefaultRegisterStep: number = 0;
  Translations: any;
  SMSId: string;
  public GsmActivationCode: string;
  GsmVerifyForm: UntypedFormGroup;
  gsmVerifyDescription: string;
  gsmVerifyApproveDescription: string;
  CountDown: number = 180;
  mobileSupportButtons = createSupportButtons(
    (s) => s.IsActive && s.ShowType != 1 && s.IsSupport === true,
    '#mobileFloatButton > ul'
  );
  desktopSupportButtons = createSupportButtons(
    (s) => s.IsActive && s.ShowType != 2 && s.IsSupport === true,
    '#desktopFloatButton > ul'
  );
  constructor(
    private ModalService: BsModalService,
    private ConfigService: AppConfigService,
    private Service: GeneralService,
    private formBuilder: UntypedFormBuilder,
    private Notify: ToastrService,
    private xSocketService: SocketService,
    private TranslateService: TranslateService,
    private deviceDetector: AngularDeviceInformationService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private _cdr: ChangeDetectorRef
  ) {
    this.TranslateService.getObs(['register.identitymessage']).subscribe(
      (res) => (this.IdentityMessage = res['register.identitymessage'])
    );
    this.TranslateService.getObs(['errors.form.emptyform']).subscribe(
      (res) => (this.ApiValidationError = res['errors.form.emptyform'])
    );

    this.TranslateService.getObs([
      'sms.waitmessage',
      'sms.anyerrormessage',
      'sms.successmessage',
      'label.activationcodesenttonumber',
      'label.activationcodewillsenttonumber',
      'sms.verificationcode.wrong',
      'sms.invalidnumber',
    ]).subscribe((Response) => {
      this.Translations = Response;
    });
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let page = val.url.split('/');
        if (page[1].indexOf('register') !== -1) {
          this.ConfigService.LoginStatus.subscribe((Status) => {
            this.IsLoggedIn = Status;
            if (Status) {
              this.isAccessible = true;
              this.router.navigate(['/']);
            } else {
              this.isAccessible = false;
            }
          });
        } else if (page[1].indexOf('login') !== -1) {
          this.isAccessible = false;
        } else {
          this.isAccessible = true;
        }
      }
    });

    this.GsmVerifyForm = this.formBuilder.group({
      ActivationCode: ['', Validators.required],
    });
    this.FastRegisterForm = this.formBuilder.group({});
    this.Form = this.formBuilder.group({
      Username: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_]+$')]],
      Email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
          ),
        ],
      ],
      Password: ['', [Validators.required, Validators.minLength(6)]],
      Language: ['', Validators.required],
      Agreement: ['', Validators.requiredTrue],
      Currency: ['', Validators.required],
      PoliticalPerson: [''],
    });
    if (this.AuthSettings.NameVisible) {
      this.Form.addControl(
        'Name',
        new UntypedFormControl('', this.AuthSettings.NameRequired ? Validators.required : null)
      );
    }
    if (this.AuthSettings.SurnameVisible) {
      this.Form.addControl(
        'Surname',
        new UntypedFormControl('', this.AuthSettings.SurnameRequired ? Validators.required : null)
      );
    }
    if (this.AuthSettings.GenderVisible) {
      this.Form.addControl('Gender', new UntypedFormControl('', [Validators.required, Validators.min(0)]));
    }
    if (this.AuthSettings.BirthDateVisible) {
      this.Form.addControl(
        'BirthDate',
        new UntypedFormControl('', this.AuthSettings.BirthDateRequired ? [Validators.required] : null)
      );
    }
    if (this.AuthSettings.CountryVisible) {
      this.Form.addControl(
        'Country',
        new UntypedFormControl('', this.AuthSettings.CountryRequired ? Validators.required : null)
      );
    }
    if (this.AuthSettings.CityVisible) {
      this.Form.addControl(
        'City',
        new UntypedFormControl('', this.AuthSettings.CityRequired ? [Validators.required, Validators.min(0)] : null)
      );
    }
    if (this.AuthSettings.IdentityNumberVisible) {
      const validator = this.AuthSettings.IdentityNumberRequired ? Validators.required : null;
      this.Form.addControl('IdentityNumber', new UntypedFormControl('', validator));
    }
    if (this.AuthSettings.GsmVisible) {
      this.Form.addControl('Gsm', new UntypedFormControl(''));
      this.FastRegisterForm.addControl('Gsm', new UntypedFormControl(''));
    }
    this.Form.addControl('PromotionKey', new UntypedFormControl(''));
  }
  tabChanged(tabId) {
    this.selectedTab = tabId;
    this.IsGsmClicked = false;
  }
  createRandomPassword(length) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let password = '';
    for (var i = 0; i < length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      password += chars.substring(rnum, rnum + 1);
    }
    return password;
  }

  OneClickRegister() {
    this.OneClickFormRegisterClicked = true;
    if (this.OneClickForm.valid) {
      this.Model.Gsm = this.FastRegisterForm.get('Gsm').value.e164Number;
      this.step = 2;
      this.reCaptchaV3Service.execute(
        this.AuthSettings.ReCaptchaKey,
        'register',
        (token) => {
          this.Model.ReCaptcha = token;
          let uuid = `${[1e7, -1e3, -4e3, -8e3, -1e11].reduce((acc, val) => acc + val)}`
            .replace(/[018]/g, (c) =>
              (parseInt(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (parseInt(c) / 4)))).toString(16)
            )
            .substring(0, 11)
            .replace('-', '');
          // this.Model.Username = `user${Config.settings.ClientId}${uuid}`;
          this.Model.Password = this.createRandomPassword(8);
          this.Model.Email = this.Model.Username + '@mail.com';
          this.Model.Language = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'tr';
          if (localStorage.getItem('btag')) {
            this.Model.AffCode = localStorage.getItem('btag');
          }
          if (this.AuthSettings.ValidateGsmOnRegister) {
            //this.Loader = true;
            this.Service.PreFastRegisterPlayer(this.Model).subscribe((Response: any) => {
              this.Loader = false;
              if (Response.Success) {
                this.step = 4;
                this.gsmVerifyApproveDescription = this.Translations['label.activationcodewillsenttonumber'].replace(
                  '{_PHONE_NUMBER_}',
                  this.FastRegisterForm.get('Gsm').value.e164Number
                );
                this.registerStatus = true;
              } else {
                this.step = 4;
                this.errorMessage = Response.Message;
              }
            });
          } else {
            this.Service.FastRegisterPlayer(this.Model).subscribe(async (Response: any) => {
              if (Response.Email) {
                localStorage.setItem(
                  'userInfo',
                  JSON.stringify({ UserName: this.Model.Username, Password: this.Model.Password })
                );
                this.LoginModel.Lang = localStorage.getItem('lang');
                this.LoginModel.SiteId = Config.settings.ClientId;
                this.LoginModel.Username = this.Model.Username;
                this.LoginModel.Password = this.Model.Password;
                this.LoginModel.Device =
                  (this.deviceDetector.isDesktop()
                    ? 'desktop'
                    : this.deviceDetector.isMobile()
                    ? 'mobile'
                    : this.deviceDetector.isTablet()
                    ? 'tablet'
                    : 'unknown') +
                  ' - ' +
                  this.deviceDetector.getDeviceInfo().os;
                this.LoginModel.OS = this.deviceDetector.getDeviceInfo().os;
                this.LoginModel.Browser = this.deviceDetector.getDeviceInfo().browser;
                this.xSocketService.playerLogin(this.LoginModel);
                this.successMessage = Response.Message;
                this.registerStatus = true;
                this.step = 3;
                await this.delay(2000);
                this.goAfterRegisterPage(true);
              } else {
                this.step = 6;
                this.errorMessage = Response.Message;
              }
            });
          }
        },
        {
          useGlobalDomain: false,
        }
      );
    }
  }
  getCities(countryId: string) {
    let Request = new GetCitiesRequest();
    Request.CountryId = countryId;
    this.Service.GetCities(Request).subscribe((Response) => {
      if (Response.length > 0) {
        Response.splice(0, 0, { Id: -1, Name: 'Seçiniz' });
      }
      this.Cities = Response;
      if (!this.Cities.length) {
        this.Model.City = null;
      } else {
        this.Model.City = this.Cities[0].Id;
      }
    });
  }

  async IdentityControl(value, forceCheck = false) {
    if (!this.AuthSettings.IdentityNumberVisible || !this.AuthSettings.IdentityNumberRequired) return true;
    if (!value && forceCheck) {
      this.identityCheckModel = false;
      return false;
    }
    if (!value) return false;
    value = value.toString();

    if (this.Model.Country != 'TUR') {
      this.identityCheckModel = true;
      return true;
    }

    if (value.length > 2) {
      var isEleven = /^[0-9]{11}$/.test(value);
      var totalX = 0;
      for (var i = 0; i < 10; i++) {
        totalX += Number(value.substr(i, 1));
      }
      var isRuleX = totalX % 10 == value.substr(10, 1);
      var totalY1 = 0;
      var totalY2 = 0;
      for (var i = 0; i < 10; i += 2) {
        totalY1 += Number(value.substr(i, 1));
      }
      for (var i = 1; i < 10; i += 2) {
        totalY2 += Number(value.substr(i, 1));
      }
      var isRuleY = (totalY1 * 7 - totalY2) % 10 == value.substr(9, 0);
      let x = isEleven && isRuleX && isRuleY;
      if (x && this.AuthSettings.IdentityNumberValidation) {
        let validationRequest = new ValidateIdentityNumberRequest();
        validationRequest.Name = this.Model.Name;
        validationRequest.Surname = this.Model.Surname;
        validationRequest.IdentityNumber = value;
        validationRequest.BirthYear = parseDate(this.Model.BirthDate).getFullYear();
        let validateIdentityResult = await this.Service.ValidateIdentityNumber(validationRequest).toPromise();
        x = validateIdentityResult.Success;
      }
      this.identityCheckModel = x;
      return x;
    } else {
      return false;
    }
  }
  GSMControl(value, forceCheck = false) {
    if (value === undefined) {
      this.gsmCheckModel = false;
      return false;
    }
    if (!this.AuthSettings.IdentityNumberVisible || !this.AuthSettings.IdentityNumberRequired) return true;
    if (!value && forceCheck) {
      this.gsmCheckModel = false;
      return false;
    }
    if (!value) return false;
    value = value.toString();

    if (value.length > 0) {
      this.gsmCheckModel = true;
      return true;
    } else {
      this.gsmCheckModel = false;
      return false;
    }
  }
  CountryChange(event) {
    let val = event;
    this.getCities(val);
    const t: any = this.Countries.filter((c) => c.Id == val)[0];
    if (t) {
      this.PhoneCode = t.PhoneCode;
      const index = this.EU_countries.findIndex((item) => item === t.Id);
      index < 0 ? (this.isEUCountry = false) : (this.isEUCountry = true);
    }
  }
  GsmVerifyGoBack() {
    this.DefaultRegisterStep = 0;
    this.step = 1;
  }
  get IsMobile() {
    const ua = (window.navigator.userAgent || '').toLowerCase();
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 4)
      );
    return isMobile;
  }
  getCountries() {
    const req = new GetCountriesRequest();
    this.Service.GetCountries(req).subscribe((res) => {
      if (res) {
        this.Countries = res;
        this.Model.Country = res[0].Id;
        this.PhoneCode = res[0].PhoneCode;
      }
    });
  }
  getCurrencies() {
    let req = new SiteCurrenciesRequest();
    this.Service.SiteCurrencies(req).subscribe((res) => {
      this.Currencies = res;
      if (res) {
        this.Model.Currency = res[0].Currency;
      }
    });
  }
  getLanguages() {
    let req = new SiteLanguagesRequest();
    this.Service.siteLanguages(req).subscribe((res) => {
      this.Languages = res;
      if (res) {
        this.Model.Language = res[0].Language;
      }
    });
  }
  openLoginModal() {
    this.OneClickForm.reset();
    this.Form.reset();
    this.ModalRef.hide();
    this.ConfigService.LoginModal.next('on');
  }
  goAfterRegisterPage(openUserInfoModal = false) {
    // '1=main page, 2=deposit page, 3=user edit page'
    // switch (this.AuthSettings.RedirectToPageAfterLogin) {
    //   case 1:
    //     this.router.navigateByUrl('/');
    //     this.ModalRef.hide();
    //     this.ConfigService.RegisterModal.next('');
    //     return;
    //   case 2:
    //     this.router.navigateByUrl('/account/info/edit');
    //     this.ModalRef.hide();
    //     this.ConfigService.RegisterModal.next('');
    //     return;
    //   case 3:
    //     this.router.navigateByUrl('/account/info/deposit');
    //     this.ModalRef.hide();
    //     this.ConfigService.RegisterModal.next('');
    //     return;
    // }
    if (openUserInfoModal) {
      this.ModalRef.hide();
      this.ConfigService.RegisterModal.next('');
      this.ConfigService.UserInfoModal.next('on');
    } else {
      ('1=main page, 2=deposit page, 3=user edit page');
      switch (this.AuthSettings.RedirectToPageAfterLogin) {
        case 1:
          this.router.navigateByUrl('/');
          this.ModalRef.hide();
          this.ConfigService.RegisterModal.next('');
          return;
        case 2:
          this.router.navigateByUrl('/account/info/edit');
          this.ModalRef.hide();
          this.ConfigService.RegisterModal.next('');
          return;
        case 3:
          this.router.navigateByUrl('/account/info/deposit');
          this.ModalRef.hide();
          this.ConfigService.RegisterModal.next('');
          return;
      }
    }
  }
  ngOnInit(): void {
    this.getCountries();
    this.getCurrencies();
    this.getLanguages();
    this.Subscribe = this.ConfigService.RegisterModal.subscribe((Type: string) => {
      if (Type === 'on') {
        this.Open();
      } else if (Type === 'close') {
        this.Close();
      }
    });

    this.OneClickForm = this.formBuilder.group({
      Username: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_]+$')]],
      Country: ['', Validators.required],
      PoliticalPerson: ['0'],
      Currency: ['', Validators.required],
      Agreement: ['', Validators.requiredTrue],
      PromotionKey: [''],
    });
  }

  Open() {
    this.ModalRef = this.ModalService.show(this.Template, {
      class: 'modal-dialog modal-fullscreen-lg-down mdl-register',
      backdrop: 'static',
      keyboard: false,
    });
    if (this.IsMobile) {
      $('body').css('overflow', 'hidden');
    }
  }

  Close() {
    this.OneClickForm.reset();
    this.Form.reset();
    if (this.IsMobile) {
      $('body').css('overflow', 'auto');
    }
    const previousPageIsOnSameDomain =
      document.referrer != '' && document.referrer.indexOf(window.location.host) !== -1;

    if (!this.IsLoggedIn) {
      if (
        (window.location.href.includes('/game/') || window.location.href.includes('/menu-game/')) &&
        previousPageIsOnSameDomain &&
        history.length > 1
      ) {
        history.go(-1);
      } else if (
        (window.location.href.includes('/game/') || window.location.href.includes('/menu-game/')) &&
        (document.referrer === '' || history.length == 1)
      ) {
        window.location.href = location.protocol + '//' + location.host;
      }
    }
    this.ModalRef.hide();
    this.ConfigService.RegisterModal.next('');
  }

  get f() {
    return this.Form.controls;
  }
  get fFast() {
    return this.OneClickForm.controls;
  }
  ResetModal() {
    if (this.IsMobile) {
      $('body').css('overflow', 'auto');
    }
    this.ConfigService.ResetModal.next('on');
  }

  logoClick() {
    this.router.navigate(['/']);
  }

  startCountDown() {
    this.CountDown = 180;
    let CountDownInterval = setInterval(() => {
      if (this.CountDown === 0) {
        clearInterval(CountDownInterval);
      } else {
        this.CountDown -= 1;
      }
    }, 1000);
  }
  CheckGsmForFastRegister(event: Event) {
    //this.Loader = true;
    this.gsmLoader = true;
    this.Service.PlayerGsmActiveOnRegister({
      ActivationCode: this.GsmActivationCode,
      SMSId: this.SMSId,
    }).subscribe((Response: any) => {
      if (Response.Success) {
        // this.GsmActivationContainer = true;
        // this.Notify.success(this.Translations['sms.successmessage']);
        // this.SMSId = JSON.parse(Response.Message).SMSId;
        this.reCaptchaV3Service.execute(
          this.AuthSettings.ReCaptchaKey,
          'register',
          (token) => {
            this.Model.ReCaptcha = token;
            this.Model.IsGsmVerified = true;
            const gsmObj = JSON.parse(JSON.stringify(this.Model.Gsm));
            if (gsmObj.e164Number) {
              this.Model.Gsm = gsmObj.e164Number;
            }
            this.Service.FastRegisterPlayer(this.Model).subscribe(async (Response: any) => {
              if (Response.Email) {
                localStorage.setItem(
                  'userInfo',
                  JSON.stringify({ UserName: this.Model.Username, Password: this.Model.Password })
                );
                this.LoginModel.Lang = localStorage.getItem('lang');
                this.LoginModel.SiteId = Config.settings.ClientId;
                this.LoginModel.Username = this.Model.Username;
                this.LoginModel.Password = this.Model.Password;
                this.LoginModel.Device =
                  (this.deviceDetector.isDesktop()
                    ? 'desktop'
                    : this.deviceDetector.isMobile()
                    ? 'mobile'
                    : this.deviceDetector.isTablet()
                    ? 'tablet'
                    : 'unknown') +
                  ' - ' +
                  this.deviceDetector.getDeviceInfo().os;
                this.LoginModel.OS = this.deviceDetector.getDeviceInfo().os;
                this.LoginModel.Browser = this.deviceDetector.getDeviceInfo().browser;
                this.xSocketService.playerLogin(this.LoginModel);
                this.successMessage = Response.Message;
                this.registerStatus = true;
                this.step = 3;
                await this.delay(2000);
                this.goAfterRegisterPage(true);
              } else {
                this.step = 6;
                this.errorMessage = Response.Message;
              }
            });
          },
          {
            useGlobalDomain: false,
          }
        );
      } else {
        this.step == 4;
        this.Loader = false;
        this.gsmLoader = false;
        this.Notify.error(this.Translations['sms.verificationcode.wrong']);
      }
    });
  }
  CheckGsmForRegister(event: Event) {
    //this.Loader = true;
    this.gsmLoader = true;
    const gsmObj = JSON.parse(JSON.stringify(this.Model.Gsm));
    if (gsmObj.e164Number) {
      this.Model.Gsm = gsmObj.e164Number;
    }
    this.Service.PlayerGsmActiveOnRegister({
      ActivationCode: this.GsmActivationCode,
      SMSId: this.SMSId,
    }).subscribe((Response: any) => {
      if (Response.Success) {
        const gsmObj = JSON.parse(JSON.stringify(this.Model.Gsm));
        if (gsmObj.e164Number) {
          this.Model.Gsm = gsmObj.e164Number;
        }
        // this.GsmActivationContainer = true;
        // this.Notify.success(this.Translations['sms.successmessage']);
        // this.SMSId = JSON.parse(Response.Message).SMSId;
        this.Service.RegisterPlayerV3(this.Model).subscribe(async (Response: any) => {
          this.Loader = false;
          this.gsmLoader = false;
          if (Response.Email) {
            this.Notify.success(Response.Message);
            this.LoginModel.Lang = localStorage.getItem('lang');
            this.LoginModel.SiteId = Config.settings.ClientId;
            this.LoginModel.Username = this.Model.Username;
            this.LoginModel.Password = this.Model.Password;
            this.LoginModel.Device =
              (this.deviceDetector.isDesktop()
                ? 'desktop'
                : this.deviceDetector.isMobile()
                ? 'mobile'
                : this.deviceDetector.isTablet()
                ? 'tablet'
                : 'unknown') +
              ' - ' +
              this.deviceDetector.getDeviceInfo().os;
            this.LoginModel.OS = this.deviceDetector.getDeviceInfo().os;
            this.LoginModel.Browser = this.deviceDetector.getDeviceInfo().browser;
            this.xSocketService.playerLogin(this.LoginModel);
            await this.delay(2000);
            this.goAfterRegisterPage();
          } else {
            if (Response && Response.Data) {
              if (Response.Data instanceof Array) {
                Response.Data.forEach((element) => {
                  this.Form.get(element.toString()).setErrors({ required: true });
                  this._cdr.detectChanges();
                });
              }
            }
            this.step == 4;
            this.DefaultRegisterStep = 1;
            this.Loader = false;
            this.gsmLoader = false;
            this.Notify.error(Response.Message);
          }
        });
      } else {
        this.step == 4;
        this.DefaultRegisterStep = 1;
        this.Loader = false;
        this.gsmLoader = false;
        this.Notify.error(this.Translations['sms.verificationcode.wrong']);
      }
    });
  }
  SendFastRegisterGsm() {
    if (this.FastRegisterForm.valid) {
      this.Model.Gsm = this.FastRegisterForm.get('Gsm').value.e164Number;
      this.ReSendGsmVerification();
    }
  }
  ReSendGsmVerification() {
    this.IsGsmClicked = true;
    //this.Loader = true;
    this.gsmLoader = true;
    const gsmObj = JSON.parse(JSON.stringify(this.Model.Gsm));
    if (gsmObj.e164Number) {
      this.Model.Gsm = gsmObj.e164Number;
    }
    this.Service.PlayerGsmVerifyOnRegister({
      Gsm: this.Model.Gsm,
      SiteId: Config.settings.ClientId,
    }).subscribe((Response: any) => {
      this.Loader = false;
      this.gsmLoader = false;
      if (Response.Success) {
        this.startCountDown();
        // this.GsmActivationContainer = true;
        this.Notify.success(this.Translations['sms.successmessage']);
        this.SMSId = JSON.parse(Response.Message).SMSId;
        const gsmObj = JSON.parse(JSON.stringify(this.Model.Gsm));
        if (gsmObj.e164Number) {
          this.Model.Gsm = gsmObj.e164Number;
        }
        this.gsmVerifyDescription = this.Translations['label.activationcodesenttonumber'].replace(
          '{_PHONE_NUMBER_}',
          this.Model.Gsm
        );
        this.DefaultRegisterStep = 1;
        if (this.selectedTab == 'DefaultRegister') {
          this.step = 4;
        } else {
          this.DefaultRegisterStep = 0;
          this.step = 5;
        }
        this.gsmLoader = false;
      } else {
        switch (Response.Data) {
          case 3:
            this.Notify.error(this.Translations['sms.waitmessage']);
            break;
          case 2 || 0:
            this.Notify.error(this.Translations['sms.anyerrormessage']);
            break;
          case 4:
            this.Notify.error(this.Translations['sms.invalidnumber']);
          default:
            break;
        }
        this.gsmLoader = false;
      }
    });
  }

  delay(ms: number) {
    return new Promise((e) => setTimeout(e, ms));
  }
  async Register() {
    this.step = 2;
    await this.IdentityControl(this.Model.IdentityNumber, true);
    this.RegisterFormClicked = true;
    if (this.Form.valid) {
      if (this.AuthSettings.BirthDateVisible && this.AuthSettings.BirthDateRequired) {
        if (parseDate(this.Model.BirthDate).getTime() < this.bsMinDate.getTime()) {
          this.birthdayLessThanAllowed = true;
          return false;
        }

        if (parseDate(this.Model.BirthDate).getTime() > this.bsMaxDate.getTime()) {
          this.birthdayMoreThanAllowed = true;
          return false;
        }
      }
      if (
        this.AuthSettings.IdentityNumberVisible &&
        this.AuthSettings.IdentityNumberRequired &&
        this.AuthSettings.IdentityNumberValidation
      ) {
        if (!(await this.IdentityControl(this.Model.IdentityNumber))) {
          this.identityCheckModel = false;
          this.step = 3;
          this.errorMessage = this.IdentityMessage;
          // this.Notify.error(this.IdentityMessage);
          return false;
        }
      }
      // if (this.Form.get('BirthDate') && this.Form.get('BirthDate').value) {
      //   this.Model.BirthDate = `${this.Form.get('BirthDate').value.getFullYear()}/${
      //     this.Form.get('BirthDate').value.getMonth() + 1
      //   }/${this.Form.get('BirthDate').value.getDate()}`;
      // }
      if (this.Form.get('Gsm') && this.Form.get('Gsm').value) {
        this.Model.Gsm = this.Form.get('Gsm').value.e164Number;
      }
      if (this.AuthSettings.GsmVisible && this.AuthSettings.GsmRequired) {
        if (!this.GSMControl(this.Model.Gsm)) {
          this.gsmCheckModel = false;
          this.step = 1;
          return false;
        }
      }
      if (this.isEUCountry) {
        this.Model.PoliticsPerson = this.Form.controls.PoliticalPerson.value;
      }
      if (localStorage.getItem('btag')) {
        this.Model.AffCode = localStorage.getItem('btag');
      }
      if (this.AuthSettings.ValidateGsmOnRegister) {
        //this.Loader = true;

        this.reCaptchaV3Service.execute(
          this.AuthSettings.ReCaptchaKey,
          'register',
          (token) => {
            this.Model.ReCaptcha = token;

            const gsmObj = JSON.parse(JSON.stringify(this.Model.Gsm));
            if (gsmObj.e164Number) {
              this.Model.Gsm = gsmObj.e164Number;
            }
            this.Service.PreRegisterPlayer(this.Model).subscribe((Response: any) => {
              this.Loader = false;
              if (Response.Success) {
                const gsmObj = JSON.parse(JSON.stringify(this.Model.Gsm));
                if (gsmObj.e164Number) {
                  this.Model.Gsm = gsmObj.e164Number;
                }
                this.DefaultRegisterStep = 2;
                this.step = 4;
                this.gsmVerifyApproveDescription = this.Translations['label.activationcodewillsenttonumber'].replace(
                  '{_PHONE_NUMBER_}',
                  this.Model.Gsm
                );
                this.registerStatus = true;
              } else {
                this.step = 3;
                this.errorMessage = Response.Message;
              }
            });
          },
          {
            useGlobalDomain: false,
          }
        );
      } else {
        this.reCaptchaV3Service.execute(
          this.AuthSettings.ReCaptchaKey,
          'register',
          (token) => {
            this.Model.ReCaptcha = token;
            const gsmObj = JSON.parse(JSON.stringify(this.Model.Gsm));
            if (gsmObj.e164Number) {
              this.Model.Gsm = gsmObj.e164Number;
            }
            this.Service.RegisterPlayerV2(this.Model).subscribe(async (Response: any) => {
              if (Response.Email) {
                this.registerStatus = true;
                this.step = 3;
                this.successMessage = Response.Message;
                this.LoginModel.Lang = localStorage.getItem('lang');
                this.LoginModel.SiteId = Config.settings.ClientId;
                this.LoginModel.Username = this.Model.Username;
                this.LoginModel.Password = this.Model.Password;
                this.LoginModel.Device =
                  (this.deviceDetector.isDesktop()
                    ? 'desktop'
                    : this.deviceDetector.isMobile()
                    ? 'mobile'
                    : this.deviceDetector.isTablet()
                    ? 'tablet'
                    : 'unknown') +
                  ' - ' +
                  this.deviceDetector.getDeviceInfo().os;
                this.LoginModel.OS = this.deviceDetector.getDeviceInfo().os;
                this.LoginModel.Browser = this.deviceDetector.getDeviceInfo().browser;
                this.xSocketService.playerLogin(this.LoginModel);
                this.Loader = false;
                await this.delay(2000);
                this.goAfterRegisterPage();
              } else {
                if (Response && Response.Data && Response.Data instanceof Array) {
                  Response.Data.forEach((element) => {
                    this.Form.get(element.toString()).setErrors({ invalid: true });
                    if (element.toString() === 'IdentityNumber') {
                      this.identityCheckModel = false;
                    }
                  });

                  this._cdr.detectChanges();

                  this.step = 3;
                  this.errorMessage = Response.Message;
                } else {
                  this.step = 3;
                  this.errorMessage = Response.Message;

                  if (Response && Response.Data && Response.Data instanceof Array) {
                    Response.Data.forEach((element) => {
                      this.Form.get(element.toString()).setErrors({ required: true });
                      this._cdr.detectChanges();
                    });
                  }

                  this.Notify.error(Response.Message);
                }
              }
            });
          },
          {
            useGlobalDomain: false,
          }
        );
      }
    } else {
      this.step = 1;
    }
  }

  returnStep() {
    this.step = 1;
  }
  openDesktopFab() {
    if (this.desktopSupportButtons.length > 1) {
      document
        .querySelector('#desktopFloatButton > ul')
        .setAttribute(
          'data-mfb-state',
          document.querySelector('#desktopFloatButton > ul').getAttribute('data-mfb-state') === 'open'
            ? 'closed'
            : 'open'
        );
    } else {
      this.desktopSupportButtons[0].onClick();
    }
  }
  openMobileFab = () => {
    if (!this.IsMobile) {
      this.openDesktopFab();
      return;
    }
    if (this.mobileSupportButtons.length > 1) {
      document
        .querySelector('#mobileFloatButton > ul')
        .setAttribute(
          'data-mfb-state',
          document.querySelector('#mobileFloatButton > ul').getAttribute('data-mfb-state') === 'open'
            ? 'closed'
            : 'open'
        );
    } else {
      this.mobileSupportButtons[0].onClick();
    }
  };
  public dateChanged(event) {
    this.birthdayMoreThanAllowed = false;
    this.birthdayLessThanAllowed = false;

    if (event && event.currentTarget) {
      this.Model.BirthDate = event.currentTarget.value;
    }
    if (this.Model.BirthDate && parseDate(this.Model.BirthDate).getTime() > this.bsMaxDate.getTime()) {
      this.birthdayMoreThanAllowed = true;
    }
    if (this.Model.BirthDate && parseDate(this.Model.BirthDate).getTime() < this.bsMinDate.getTime()) {
      this.birthdayLessThanAllowed = true;
    }
  }
}
