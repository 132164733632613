<ng-container *ngFor="let message of notificationMessages$ | async">
  <div class="alert alert-dismissible fade show" role="alert">
    <div class="alert-inner alert-base text-center">
      {{ message.TextContent }}
      <button
        type="button"
        class="btn btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        (click)="closeAlert(message.Id)">
        <i class="fa fa-close"></i>
      </button>
    </div>
  </div>
</ng-container>
<header class="sticky-top" [ngClass]="{ 'show-header': isSticky }">
  <div class="inner">
    <div class="top d-flex align-items-center justify-content-between">
      <div class="column me-3">
        <a class="logo" [routerLink]="['/']">
          <img src="assets/uploads/brand/logo.png" alt="" />
        </a>
      </div>
      <div class="column m-hide">
        <ul class="btn-group-social">
          <ng-container *ngFor="let item of SocialMedias">
            <li
              class=""
              *ngIf="item.DefaultName !== 'Whatsapp'; else Whatsapp"
              [ngClass]="item.DefaultName | lowercase">
              <a
                class="btn btn-base"
                target="_blank"
                href="{{ item.Url }}"
                [attr.data-media]="item.DefaultName | lowercase">
                <i class="{{ item.CssClass }}"></i>
              </a>
            </li>
            <ng-template #Whatsapp>
              <li class="" [ngClass]="item.DefaultName | lowercase">
                <a
                  class="btn btn-base"
                  [attr.data-media]="item.DefaultName | lowercase"
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone={{ item.Url }}">
                  <i class="{{ item.CssClass }}"></i>
                </a>
              </li>
            </ng-template>
          </ng-container>
        </ul>
      </div>
      <div class="flex-grow-1 d-flex justify-content-end">
        <div class="d-flex align-items-center">
          <ng-container *ngIf="IsLoggedIn">
            <div class="b-links m-hide me-3">
              <a class="btn btn-base py-2 me-1" (click)="closeMobileMenu()" [routerLink]="['/account/info/deposit']">
                {{ 'btn.deposit' | translate }}
              </a>
              <a class="btn btn-base py-2" (click)="closeMobileMenu()" [routerLink]="['/account/info/withdraw']">
                {{ 'btn.withdraw' | translate }}
              </a>
            </div>
            <app-header-notification></app-header-notification>

            <div class="dropdown bw-custom">
              <a
                *ngIf="!IsMobile"
                class="btn balance"
                href="javascript:void(0)"
                role="button"
                id="bwbalance"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <span class="w-100 balance">
                  <ng-container *ngIf="BalanceHide">***</ng-container>
                  <ng-container *ngIf="!BalanceHide">{{ playerBalance }}</ng-container>
                  {{ Player?.Currency | currencySymbol }}
                </span>
                <span class="w-100 bonusbalance">
                  <ng-container *ngIf="BalanceHide">***</ng-container>
                  <ng-container *ngIf="!BalanceHide">
                    <span class="" [ngClass]="{ 'd-none': playerBonusBalance === 0 }">
                      {{ playerBonusBalance }} {{ Player?.Currency | currencySymbol }}
                    </span>
                  </ng-container>
                </span>
              </a>
              <a *ngIf="IsMobile" class="btn balance" [routerLink]="['/account/info/deposit']" role="button">
                <span class="w-100 balance">
                  <ng-container *ngIf="BalanceHide">***</ng-container>
                  <ng-container *ngIf="!BalanceHide">{{ playerBalance }}</ng-container>
                  {{ Player?.Currency | currencySymbol }}
                </span>
                <span class="w-100 bonusbalance">
                  <ng-container *ngIf="BalanceHide">***</ng-container>
                  <ng-container *ngIf="!BalanceHide">
                    <span class="" [ngClass]="{ 'd-none': playerBonusBalance === 0 }">
                      {{ playerBonusBalance }} {{ Player?.Currency | currencySymbol }}
                    </span>
                  </ng-container>
                </span>
              </a>

              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="bwbalance">
                <li>
                  <a class="dropdown-item" [routerLink]="['/account/info/deposit']">
                    <i class="bw-i-circle-dollar me-2"></i>
                    {{ 'btn.deposit' | translate }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['/account/info/withdraw']">
                    <i class="bw-i-withdraw me-2"></i>
                    {{ 'btn.withdraw' | translate }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['/account/info/accounts']">
                    <i class="bw-i-bank me-2"></i>
                    {{ 'btn.bankaccounts' | translate }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['/account/info/transactions']">
                    <i class="bw-i-history me-2"></i>
                    {{ 'account.tab.accounthistories' | translate }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="dropdown bw-custom">
              <a
                *ngIf="!IsMobile"
                class="btn custom"
                href="javascript:void(0)"
                role="button"
                id="bwUserMen"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="fa-solid fa-circle-user"></i>
              </a>
              <a *ngIf="IsMobile" class="btn custom" [routerLink]="['/account/info/edit']">
                <i class="fa-solid fa-circle-user"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="bwUserMen">
                <li>
                  <a class="dropdown-item" [routerLink]="['/account/info/edit']">
                    <i class="fa-solid fa-circle-user me-2"></i>
                    {{ 'general.text.myaccount' | translate }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['/account/info/bonus']">
                    <i class="bw-i-promotions me-2"></i>
                    {{ 'account.tab.bonus' | translate }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['/account/info/transactions']">
                    <i class="bw-i-history me-2"></i>
                    {{ 'account.tab.accounthistories' | translate }}
                  </a>
                </li>
                <li *ngIf="IsSportsbookActive && SportsbookTicketUrls.length == 1">
                  <a class="dropdown-item" [routerLink]="SportsbookTicketUrls[0]">
                    <i class="bw-i-betslip me-2"></i>
                    {{ 'my.betslip' | translate }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['/account/info/messages']">
                    <i class="bw-i-message me-2"></i>
                    {{ 'btn.messages' | translate }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item logout" (click)="Logout()">{{ 'btn.logout' | translate }}</a>
                </li>
              </ul>
            </div>
          </ng-container>
          <div class="b-visitor" *ngIf="!IsLoggedIn">
            <a (click)="LoginModal()" class="btn btn-login">
              {{ 'btn.login' | translate }}
            </a>
            <a
              (click)="RegisterModal()"
              *ngIf="AuthSettings.DefaultRegistrationEnabled || AuthSettings.FastRegistrationEnabled"
              class="btn btn-register">
              {{ 'btn.register' | translate }}
            </a>
          </div>
          <div class="dropdown bw-custom">
            <a
              class="btn btn-blank m-hide"
              href="javascript:void(0)"
              role="button"
              id="bwgadgets"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              {{ time | async | date : 'HH:mm' }}
            </a>
          </div>
          <div class="dropdown bw-custom">
            <a
              (click)="CallMeModal()"
              role="button"
              class="btn m-hide py-2"
              data-bs-toggle="tooltip"
              title="{{ 'btn.call-me' | translate }}">
              <i class="fas fa-phone"></i>
            </a>
          </div>
          <div class="dropdown bw-custom">
            <a
              class="btn py-2"
              href="javascript:void(0)"
              role="button"
              id="bwgadgets"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              <i class="bw-i-vertical-toggle transition-icon"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end sm-dropdown" aria-labelledby="bwgadgets">
              <ng-container *ngIf="IsLoggedIn">
                <li>
                  <a
                    class="dropdown-item"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#lastPlayed"
                    aria-controls="lastPlayed"
                    (click)="setOffCanvasTab(0)">
                    <i class="bw-i-favorite"></i>
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#lastPlayed"
                    aria-controls="lastPlayed"
                    (click)="setOffCanvasTab(1)">
                    <i class="bw-i-history"></i>
                  </a>
                </li>
              </ng-container>
              <li>
                <a class="dropdown-item" [routerLink]="['/account/info/system-notifications']">
                  <i class="bw-i-notification"></i>
                </a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/account/info/messages']">
                  <i class="bw-i-message"></i>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="javascript:void(0)" (click)="openMobileFab()">
                  <i class="bw-i-live-chat"></i>
                </a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/promotions']">
                  <i class="bw-i-promotions"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="nav" #navContainer id="desktopMenu" *ngIf="Menus.length > 0; else loadingTemplate">
      <ul class="nav-menu">
        <ng-container *ngFor="let Menu of Menus | filter : { Align: 'left' }; let i = index">
          <ng-container [ngTemplateOutlet]="userTemplate"></ng-container>
          <ng-template #userTemplate>
            <li>
              <a
                class="nav-link"
                [routerLink]="Menu.UrlRoute"
                *ngIf="Menu.IsActive && !Menu.IsApp"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="current">
                <i class="{{ Menu.MenuIcon }} nav-icon me-2"></i>
                <span>
                  {{ Menu.TranslationKey | translate }}
                  <em class="new" *ngIf="Menu.IsNew">{{ 'common.new' | translate }}</em>
                </span>
              </a>
              <a
                class="nav-link"
                href="{{ Menu.UrlRoute }}"
                *ngIf="Menu.IsActive && Menu.IsApp"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="current">
                <i class="{{ Menu.MenuIcon }} nav-icon me-2"></i>
                <span>
                  {{ Menu.TranslationKey | translate }}
                  <em class="new" *ngIf="Menu.IsNew">{{ 'common.new' | translate }}</em>
                </span>
              </a>
            </li>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="!IsMobile && notVisibleCount > 0">
          <li class="m-hide bw-nav-more">
            <div class="dropdown bw-custom">
              <a
                class="nav-link px-2"
                href="javascript:void(0)"
                role="button"
                id="bw-more-btn"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <span>
                  <small>+{{ notVisibleCount }}</small>
                </span>
                <i class="bw-i-burger ms-2 nav-icon color-theme"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end bw-more-content" aria-labelledby="bw-more-btn">
                <li *ngFor="let menu of hiddenMenus">
                  <a class="dropdown-item" [routerLink]="menu.UrlRoute">
                    <i class="{{ menu.className }}"></i>
                    {{ menu.TranslationKey | translate }}
                    <em class="new" *ngIf="menu.IsNew">{{ 'common.new' | translate }}</em>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
    <ng-template #loadingTemplate>
      <li class="loading-item">
        <a class="nav-link">
          <i class="skeleton-icon"></i>
          <span>
            <span class="skeleton-text"></span>
            <em class="skeleton-new"></em>
          </span>
        </a>
      </li>
    </ng-template>
    <!-- User Menu Loading Simulation -->
    <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
      <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
    </ng-container>
  </div>
</header>
<ng-container *ngIf="IsLoggedIn">
  <div
    class="offcanvas offcanvas-end last-played-games"
    tabindex="-1"
    id="lastPlayed"
    aria-labelledby="lastPlayedLabel">
    <div class="offcanvas-header d-flex">
      <h5 class="offcanvas-title" id="lastPlayedLabel">
        <ul class="tab-buttons" style="overflow: auto">
          <li class="d-inline-block" [class.active]="activeOffCanvasTab === 0" (click)="setOffCanvasTab(0)">
            <a class="btn btn-base p-2 me-1 mb-1" [class.current]="activeOffCanvasTab === 0">
              <i class="bw-i-favorite me-2"></i>
              {{ 'general.text.favorites' | translate }}
            </a>
          </li>
          <li class="d-inline-block" [class.active]="activeOffCanvasTab === 1" (click)="setOffCanvasTab(1)">
            <a class="btn btn-base p-2 me-1 mb-1" [class.current]="activeOffCanvasTab === 1">
              <i class="bw-i-history me-2"></i>
              {{ 'general.text.lastplayed' | translate }}
            </a>
          </li>
        </ul>
      </h5>
      <a ref="javascript:void(0)" class="btn btn-close" data-bs-dismiss="offcanvas"><i class="fa fa-times"></i></a>
    </div>
    <div class="offcanvas-body overflow-hidden">
      <div *ngIf="activeOffCanvasTab === 0" class="tab">
        <app-favorite-games #favoriteGames></app-favorite-games>
      </div>
      <div *ngIf="activeOffCanvasTab === 1" class="tab">
        <app-last-played-games #lastPlayedGames></app-last-played-games>
      </div>
    </div>
  </div>
</ng-container>
