import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppConfigService } from 'src/app/config/app-config.service';
import {
  DbContentListResponse,
  DbMenuResponse,
  GetContentListRequest,
  SocialMediaResponse,
} from 'src/app/models/service.dtos';
import { GeneralService } from 'src/app/services/General.service';
import { Config } from 'src/app/config';
import { NavigationEnd, Router } from '@angular/router';
import { FloatingActionButton } from 'ng-floating-action-menu';
import * as Types from '../../constants';
import { createSupportButtons } from 'src/app/utils/FloatingActionButtons';
import { StickyService } from 'src/app/services/Header.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss'],
})
export class BottomNavbarComponent implements OnInit {
  private destroy$ = new Subject<void>();
  isSticky: boolean = false;
  DesktopBottomMenu = Config.BottomMenu.filter((s) => s.IsActive && s.ShowType != 1 && s.IsSupport === false);
  MobileBottomMenu = Config.BottomMenu.filter((s) => s.IsActive && s.ShowType != 2 && s.IsSupport === false);
  isCollapsed = true;
  mobileButtonShowIndex = 4;
  mobileButtonLastIndexDiff = 0;
  liveSupportScript = '';
  Menus: DbMenuResponse[] = Config.Menu;
  SocialMedias: SocialMediaResponse[] = [];
  mobileButtonCount = Config.BottomMenu.filter((s) => s.ShowType != 1 && s.IsSupport === false).length + 1;
  mobileSupportButtons = createSupportButtons(
    (s) => s.IsActive && s.ShowType != 1 && s.IsSupport === true,
    '#mobileFloatButton > ul'
  );
  desktopSupportButtons = createSupportButtons(
    (s) => s.IsActive && s.ShowType != 2 && s.IsSupport === true,
    '#desktopFloatButton > ul'
  );
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private ConfigService: AppConfigService,
    public router: Router,
    private Service: GeneralService,
    private stickyService: StickyService
  ) {
    this.isCollapsed =
      localStorage.getItem('isBottomMenuCollapsed') === null
        ? true
        : localStorage.getItem('isBottomMenuCollapsed') === 'true';
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (
          (window.location.href.includes('/fast-games') ||
            window.location.href.includes('/live-casino') ||
            window.location.href.includes('/casino') ||
            window.location.href.includes('/scratch')) &&
          (window.location.href.includes('/demo-game') || window.location.href.includes('/game'))
        ) {
          this.isCollapsed = false;
        } else {
          this.isCollapsed =
            localStorage.getItem('isBottomMenuCollapsed') === null
              ? true
              : localStorage.getItem('isBottomMenuCollapsed') === 'true';
        }
      }
    });
  }
  handleMenuButtonClick(s) {
    let confParams = null;
    if (s.ConfigurationParameters != null) {
      confParams = JSON.parse(s.ConfigurationParameters);
    }
    switch (s.LinkType) {
      case 1: //Url
        window.location.href = s.UrlRoute;
        break;
      case 2: //Comm100
        window['Comm100API'].do('livechat.button.click', confParams.PlanId);
        // window['Comm100API'].open_chat_window(null, confParams.PlanId);
        break;
      case 3: //LiveChatInc
        if (document.querySelectorAll('iframe#chat-widget').length == 0) {
          eval(s.LiveChatWidgetScript.replace(new RegExp('_LICENSE_KEY_', 'g'), confParams.LicenseKey));
          window['LiveChatWidget'].call('maximize');
        } else {
          window['LiveChatWidget'].call('maximize');
        }
        break;
      case 4: //Whatsapp
        window.open(
          'https://api.whatsapp.com/send/?phone=' + confParams.PhoneNumber + '&text=' + confParams.HelpText,
          '_blank'
        );
        break;
      case 5: //Zendesk
        if (document.querySelectorAll('iframe#launcher').length == 0) {
          eval(s.ZendeskWidgetScript.replace(new RegExp('_ACCOUNT_KEY_', 'g'), confParams.AccountKey));
          setTimeout(() => {
            window['zEmbed'].activate();
          }, 1000);
        } else {
          window['zEmbed'].activate();
        }
        break;
      case 6: //Jivo
        if (window['jivo_api']) {
          window['jivo_api'].open();
        } else {
          eval(s.JivoWidgetScript.replace(new RegExp('_WIDGET_ID_', 'g'), confParams.WidgetId));
          setTimeout(() => {
            window['jivo_api'].open();
          }, 1000);
        }
        break;
      case 7: //Skype
        window.open(`skype:${confParams.SkypeAccountId}?chat`);
        break;
      case 8: //Tawk
        window['Tawk_API'].maximize();
        break;
      case 9:
      case 11:
        window['SBChat'].open();
        break;
      case 10:
        window['supsis']('open');
        break;
    }
  }
  get IsMobile() {
    const ua = (window.navigator.userAgent || '').toLowerCase();
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 4)
      );
    return isMobile;
  }
  checkVisibilityShowButton() {
    if (this.isCollapsed) {
      return true;
    }
    if (
      (window.location.href.includes('/fast-games') ||
        window.location.href.includes('/live-casino') ||
        window.location.href.includes('/casino') ||
        window.location.href.includes('/scratch')) &&
      (window.location.href.includes('/demo-game') || window.location.href.includes('/game'))
    ) {
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.stickyService.isSticky$.pipe(takeUntil(this.destroy$)).subscribe((isSticky) => {
      this.isSticky = isSticky;
    });
    this.ConfigService.BottomMenu.subscribe((Status) => {
      if (this.IsMobile) {
        this.isCollapsed = Status;
      }
    });
    this.getSocialMedia();
    const comm100MenuLinks = Config.BottomMenu.filter((s) => s.LinkType === 2 && s.IsActive == true);
    for (let link in comm100MenuLinks) {
      const confParams = JSON.parse(comm100MenuLinks[link].ConfigurationParameters);
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.appendChild(
        this.renderer.createText(
          comm100MenuLinks[link].Comm100WidgetScript.replace(new RegExp('_SITE_ID_', 'g'), confParams.SiteId).replace(
            new RegExp('_PLAN_ID_', 'g'),
            confParams.PlanId
          )
        )
      );
      this.renderer.appendChild(this.el.nativeElement, script);
    }

    const liveChatMenuLinks = Config.BottomMenu.filter((s) => s.LinkType === 3 && s.IsActive == true);
    for (let link in liveChatMenuLinks) {
      const confParams = JSON.parse(liveChatMenuLinks[link].ConfigurationParameters);
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.appendChild(
        this.renderer.createText(
          liveChatMenuLinks[link].LiveChatWidgetScript.replace(new RegExp('_LICENSE_KEY_', 'g'), confParams.LicenseKey)
        )
      );
      this.renderer.appendChild(this.el.nativeElement, script);
    }

    const zendeskMenuLinks = Config.BottomMenu.filter((s) => s.LinkType === 5 && s.IsActive == true);
    for (let link in zendeskMenuLinks) {
      const confParams = JSON.parse(zendeskMenuLinks[link].ConfigurationParameters);
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.appendChild(
        this.renderer.createText(
          zendeskMenuLinks[link].LiveChatWidgetScript.replace(new RegExp('_ACCOUNT_KEY_', 'g'), confParams.AccountKey)
        )
      );
      this.renderer.appendChild(this.el.nativeElement, script);
    }

    const jivoMenuLinks = Config.BottomMenu.filter((s) => s.LinkType === 6 && s.IsActive == true);
    for (let link in jivoMenuLinks) {
      const confParams = JSON.parse(jivoMenuLinks[link].ConfigurationParameters);
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.appendChild(
        this.renderer.createText(
          jivoMenuLinks[link].JivoWidgetScript.replace(new RegExp('_WIDGET_ID_', 'g'), confParams.WidgetId)
        )
      );
      this.renderer.appendChild(this.el.nativeElement, script);
    }

    const tawkMenuLinks = Config.BottomMenu.filter((s) => s.LinkType === 8 && s.IsActive == true);
    for (let link in tawkMenuLinks) {
      const confParams = JSON.parse(tawkMenuLinks[link].ConfigurationParameters);
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.appendChild(
        this.renderer.createText(
          tawkMenuLinks[link].TawkWidgetScript.replace(new RegExp('_ACCOUNT_KEY_', 'g'), confParams.AccountKey).replace(
            new RegExp('_WIDGET_ID_', 'g'),
            confParams.WidgetId
          ) + (this.IsMobile ? ' window.Tawk_API.onLoad = function(){window.Tawk_API.hideWidget();};' : '')
        )
      );
      this.renderer.appendChild(this.el.nativeElement, script);
    }
    const customMenuLink = Config.BottomMenu.filter((s) => s.LinkType === 9 && s.IsActive == true);
    for (let link in customMenuLink) {
      const confParams = JSON.parse(customMenuLink[link].ConfigurationParameters);
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.appendChild(
        this.renderer.createText(
          customMenuLink[link].CustomChatWidgetScript.replace(new RegExp('_WIDGET_ID_', 'g'), confParams.WidgetId)
        )
      );
      this.renderer.appendChild(this.el.nativeElement, script);
    }
    const supsisMenuLinks = Config.BottomMenu.filter((s) => s.LinkType === 10 && s.IsActive == true);
    for (let link in supsisMenuLinks) {
      const confParams = JSON.parse(supsisMenuLinks[link].ConfigurationParameters);
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.appendChild(
        this.renderer.createText(
          supsisMenuLinks[link].SupsisWidgetScript.replace(new RegExp('_SUBDOMAIN_', 'g'), confParams.Subdomain)
        )
      );
      this.renderer.appendChild(this.el.nativeElement, script);
    }

    const wisecommMenuLink = Config.BottomMenu.filter((s) => s.LinkType === 11 && s.IsActive);
    for (let link in wisecommMenuLink) {
      const confParams = JSON.parse(wisecommMenuLink[link].ConfigurationParameters);
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.appendChild(
        this.renderer.createText(
          wisecommMenuLink[link].WisecommWidgetScript.replace(new RegExp('_WIDGET_ID_', 'g'), confParams.WidgetId)
        )
      );
      this.renderer.appendChild(this.el.nativeElement, script);
    }
  }
  openCloseMenu() {
    this.isCollapsed = !this.isCollapsed;

    localStorage.setItem('isBottomMenuCollapsed', this.isCollapsed ? 'true' : 'false');
    if (!this.isCollapsed) {
      document.querySelector('#mobileFloatButton > ul').setAttribute('data-mfb-state', 'closed');
    }
  }
  swipeStartX: number | null = null;
  swipeStartXBottomMenu: number | null = null;
  deltaXBottomMenu = 0;

  onTouchStart(event: TouchEvent) {
    this.swipeStartX = event.touches[0].clientX;
  }

  onTouchMove(event: TouchEvent) {
    if (this.swipeStartX !== null) {
      const currentX = event.touches[0].clientX;
      const deltaX = currentX - this.swipeStartX;
      if (deltaX > 50) {
        this.isCollapsed = true;
        localStorage.setItem('isBottomMenuCollapsed', 'true');
        document.querySelector('#mobileFloatButton > ul').setAttribute('data-mfb-state', 'closed');
        // Add any additional actions you want to perform when swiping right.
      } else if (deltaX < -50) {
        this.isCollapsed = false;
        localStorage.setItem('isBottomMenuCollapsed', 'false');
        document.querySelector('#mobileFloatButton > ul').setAttribute('data-mfb-state', 'closed');
      }
    }
  }

  onTouchEnd() {
    this.swipeStartX = null;
  }
  onTouchEndBottomMenu() {
    this.swipeStartXBottomMenu = null;
    if (this.deltaXBottomMenu > 75) {
      this.mobileButtonShowIndex =
        this.mobileButtonShowIndex - 4 < 4 ? 4 : this.mobileButtonShowIndex - 4 - this.mobileButtonLastIndexDiff;
      this.mobileButtonLastIndexDiff = 0;
    } else if (this.deltaXBottomMenu < -75) {
      if (this.mobileButtonShowIndex + 4 > this.mobileButtonCount) {
        this.mobileButtonLastIndexDiff = this.mobileButtonCount - this.mobileButtonShowIndex;
        this.mobileButtonShowIndex = this.mobileButtonCount;
      } else {
        this.mobileButtonShowIndex = this.mobileButtonShowIndex + 4;
      }
    }
  }
  onTouchStartBottomMenu(event: TouchEvent) {
    this.swipeStartXBottomMenu = event.touches[0].clientX;
  }

  onTouchMoveBottomMenu(event: TouchEvent) {
    if (this.swipeStartXBottomMenu !== null) {
      const currentX = event.touches[0].clientX;
      this.deltaXBottomMenu = currentX - this.swipeStartXBottomMenu;
    }
  }

  isShowMobileMenu(index) {
    if (!this.isCollapsed) {
      return false;
    }
    return index >= this.mobileButtonShowIndex - 4 && index <= this.mobileButtonShowIndex;

    return true;
  }

  openMobileFab() {
    if (!this.IsMobile) {
      this.openDesktopFab();
      return;
    }
    if (this.mobileSupportButtons.length > 1) {
      document
        .querySelector('#mobileFloatButton > ul')
        .setAttribute(
          'data-mfb-state',
          document.querySelector('#mobileFloatButton > ul').getAttribute('data-mfb-state') === 'open'
            ? 'closed'
            : 'open'
        );
    } else {
      this.mobileSupportButtons[0].onClick();
    }
  }
  CallMeModal() {
    this.ConfigService.CallMeModal.next('on');
  }
  FeedbackModal() {
    this.ConfigService.FeedbackModal.next('on');
  }
  getSocialMedia() {
    this.Service.GetSocialMedia().subscribe((Response) => {
      this.SocialMedias = Response;
    });
  }
  openDesktopFab() {
    if (this.desktopSupportButtons.length > 1) {
      document
        .querySelector('#desktopFloatButton > ul')
        .setAttribute(
          'data-mfb-state',
          document.querySelector('#desktopFloatButton > ul').getAttribute('data-mfb-state') === 'open'
            ? 'closed'
            : 'open'
        );
    } else {
      this.desktopSupportButtons[0].onClick();
    }
  }

  protected readonly Types = Types;
}
