import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(currency: string): string {
    switch (currency) {
      case 'BWC':
        return '';
      case 'TRY':
        return `₺`;
      case 'EUR':
        return `€`;
      case 'USD':
        return `$`;
      case 'GBP':
        return `£`;
      case 'JPY':
        return `¥`;
      case 'CNY':
        return `CN¥`;
      case 'KZT':
        return `₸`;
    }

    return currency;
  }
}
