import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { Config } from './config';
import { HttpClientModule } from '@angular/common/http';
import { WindowRefService } from './services/WindowRef.service';
import { ErrorInterceptorProvider } from './interceptors/http-interceptor';
import { ToastrModule } from 'ngx-toastr';

import { SocketService } from './services/socket.service';
import { StorageService } from './services/Storage.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import localeTr from '@angular/common/locales/tr';
import localeSq from '@angular/common/locales/sq';
import localePt from '@angular/common/locales/pt-PT';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeFi from '@angular/common/locales/fi';
import localeEs from '@angular/common/locales/es';
import localeNb from '@angular/common/locales/nb';
import localeZh from '@angular/common/locales/zh';
import localeIt from '@angular/common/locales/it';
import loaceleRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { HomeModule } from './layouts/home/home.module';
import { PipesModule } from './helpers/pipes/pipes.module';
import { OnlineStatusModule } from 'ngx-online-status';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AngularSvgIconModule } from 'angular-svg-icon';
let SocketUrl = environment.Endpoints.Socket;
const config: SocketIoConfig = { url: SocketUrl, options: { transports: ['websocket'] } };

export function initializeApp(Config: Config) {
  return async () => await Config.Initialize();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    PipesModule,
    ToastrModule.forRoot({ progressBar: true }),
    SocketIoModule.forRoot(config),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    OnlineStatusModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    Config,
    WindowRefService,
    SocketService,
    StorageService,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [Config], multi: true },
    ErrorInterceptorProvider,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor() {
    let Lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : navigator.language.split('-')[0];
    switch (Lang) {
      case 'tr':
        registerLocaleData(localeTr);
        break;
      case 'sqi':
        registerLocaleData(localeSq);
        break;
      case 'ptb':
        registerLocaleData(localePt);
        break;
      case 'fr':
        registerLocaleData(localeFr);
        break;
      case 'de':
        registerLocaleData(localeDe);
        break;
      case 'fi':
        registerLocaleData(localeFi);
        break;
      case 'es':
        registerLocaleData(localeEs);
        break;
      case 'nb':
        registerLocaleData(localeNb);
        break;
      case 'zh':
        registerLocaleData(localeZh);
        break;
      case 'it':
        registerLocaleData(localeIt);
        break;
      case 'ru':
        registerLocaleData(loaceleRu);
        break;
    }
  }
}
