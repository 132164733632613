import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { StorageService } from '../../services/Storage.service';
import { CASINO, FAST_GAMES, LIVE_CASINO, SCRATCH, VIRTUAL_SPORTS } from '../../constants';

@Component({
  selector: 'app-last-played-games',
  templateUrl: './last-played-games.component.html',
  styleUrls: ['./last-played-games.component.html'],
})
export class LastPlayedGamesComponent implements OnInit {
  title: string;
  confirmLabel: string;
  declineLabel: string;
  GameList: any[];
  GameLoader = false;
  LOADER = false;
  showType = 'grid';
  Search: string;
  currentPage = 0;
  FetchNext = false;
  activeType: string = CASINO;
  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef, private storageService: StorageService) {}

  public ngOnInit(): void {
    this.onClose = new Subject();
    this.getGameList(this.activeType);
  }

  getGameList(type) {
    this.activeType = type;
    this.GameList = this.storageService.get(`${type}History`) || [];
    this.GameList = this.GameList.reverse();
  }

  public openModal() {
    this.getGameList(this.activeType);
  }

  public decline() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public confirm() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public dismiss() {
    // daha sonra geliştirilebilir şuanlıkl ihtiyaç olmadığından eklenmedi
    // this.bsModalRef.dismiss();
  }

  protected readonly VIRTUAL_SPORTS = VIRTUAL_SPORTS;
  protected readonly FAST_GAMES = FAST_GAMES;
  protected readonly SCRATCH = SCRATCH;
  protected readonly CASINO = CASINO;
  protected readonly LIVE_CASINO = LIVE_CASINO;

  onScroll() {
    if (this.GameList.length % 50 !== 0) return;
    if (this.FetchNext === false) return;
    this.GameLoader = true;
    this.currentPage += 1;
  }
}
