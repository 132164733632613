import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ConfirmationModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class ConfirmationModalService {
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) {}

  public confirm(options: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.modalRef = this.modalService.show(ConfirmationModalComponent, {
        class: 'modal-dialog',
        ignoreBackdropClick: options.ignoreBackdropClick,
      });
      this.modalRef.content.title = options.title;
      this.modalRef.content.confirmLabel = options.confirmLabel;
      this.modalRef.content.declineLabel = options.declineLabel;
      this.modalRef.content.onClose.subscribe((result: boolean) => {
        resolve(result);
      });
    });
  }
}
