import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner/banner.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [BannerComponent],
  imports: [LazyLoadImageModule, CommonModule, CarouselModule, RouterModule],
  exports: [BannerComponent],
})
export class BannerModule {}
