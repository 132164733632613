import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/config/app-config.service';
import { Config } from 'src/app/config';
import { IStylesType } from 'src/app/config/config.interface';
import {
  DbContentListResponse,
  GetContentListRequest,
  SocialMediaResponse,
  PromotionsImagesAndTextsRequest,
  SlotBrandListRequest,
  SlotGameList,
  SlotGameListRequest,
  DbMenuResponse,
} from 'src/app/models/service.dtos';
import { GeneralService } from 'src/app/services/General.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as Types from '../../constants';
import { Subscription } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  ContentList: DbContentListResponse[] = [];
  Subscribe: Subscription = new Subscription();
  Title: string = 'footer.title.livecasino';
  Menus: DbMenuResponse[] = Config.Menu;
  AllGames: SlotGameList[] = [];
  Promotions: any = [];
  Categories: { GroupName: string }[] = [];
  SelectedBrandId: number;
  TimeNow = new Date();
  IsLoggedIn: boolean = false;
  ActiveCategory: string = '';
  public Types = Types;
  ContentListRight: DbContentListResponse[] = [];
  ContentListLeft: DbContentListResponse[] = [];
  SocialMedias: SocialMediaResponse[] = [];
  ClientId: number = Config.settings.ClientId;

  constructor(
    private ConfigService: AppConfigService,
    public Router: Router,
    private ModalService: BsModalService,
    private Service: GeneralService
  ) {
    setInterval(() => {
      this.TimeNow = new Date();
    }, 1000);

    Router.events.subscribe((val: any) => {
      while (val.url) {
        this.accordionShower = val.url;
        break;
      }
    });
  }

  ngOnInit(): void {
    this.ConfigService.LoginStatus.subscribe((Status) => (this.IsLoggedIn = Status));
    // document.querySelectorAll('[data-trigger]').forEach(function (triggerBtn) {
    //   let offcanvas_id = triggerBtn.getAttribute('data-trigger');
    //   if (offcanvas_id) {
    //     triggerBtn.addEventListener('click', function (e) {
    //       if (triggerBtn.classList.contains('active')) {
    //         triggerBtn.classList.remove('active');
    //         document.querySelector(offcanvas_id).classList.toggle('show');
    //       }
    //       else if(document.querySelector(".navbar-collapse.show")){
    //         document.querySelector(".navbar-collapse.show").classList.remove('show');
    //         document.querySelector("a[data-trigger].active").classList.remove('active');

    //         triggerBtn.classList.add('active');
    //         document.querySelector(offcanvas_id).classList.toggle('show');
    //       }
    //       else {
    //         triggerBtn.classList.add('active');
    //         document.querySelector(offcanvas_id).classList.toggle('show');
    //       }
    //     });
    //   }
    // });
    // if ($(window).width() > 992) {
    //   $(window).scroll(function(){
    //     if ($(this).scrollTop() > 40) {
    //         $('#betDesktop').addClass("fixed-top navbar-blur");
    //         $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
    //       }else{
    //         $('#betDesktop').removeClass("fixed-top navbar-blur");
    //         $('body').css('padding-top', '0');
    //       }
    //   });
    // }
    // $(document).ready(function () {
    //   calcWidth();

    //   $(window).on('resize load', function () {
    //     if ($(window).width() > 991) {
    //       calcWidth();
    //     }
    //     else {
    //       $('header').removeClass('secondary')
    //     }

    //   });
    //   if ($(window).scrollTop() > 50) {
    //     $('header').addClass('secondary')
    //   } else {
    //     $('header').removeClass('secondary')
    //   }
    //   $(window).scroll(() => {
    //     calcWidth();
    //     if ($(window).width() > 900) {
    //       if ($(window).scrollTop() > 50) {
    //         $('header').addClass('secondary')
    //       } else {
    //         $('header').removeClass('secondary')
    //       }
    //     }
    //   })
    //   $(window).on('load', function () {
    //     if ($(window).width() > 900) {
    //       calcWidth();
    //     }
    //     else {
    //       $('header').removeClass('secondary')
    //     }

    //   });

    //   function calcWidth() {
    //     var navwidth = 0;
    //     var morewidth = $('#main .more').outerWidth(true);
    //     $('#main > li:not(.more)').each(function () {
    //       navwidth += $(this).outerWidth(true);
    //     });

    //     if ($('header').hasClass('secondary')) {
    //       var availablespace = $('#nav-main').width() - morewidth - 250;

    //     }
    //     else {
    //       var availablespace = $('#nav-main').width() - morewidth;
    //     }

    //     if (navwidth > availablespace) {
    //       var lastItem = $('#main > li:not(.more)').last();
    //       lastItem.attr('data-width', lastItem.outerWidth(true));
    //       lastItem.prependTo($('#main .more ul'));
    //       calcWidth();
    //     } else {

    //       var firstMoreElement = $('#main li.more li').first();
    //       if (navwidth + firstMoreElement.data('width') < availablespace) {
    //         firstMoreElement.insertBefore($('#main .more'));
    //       }
    //     }

    //     if ($('.more li').length > 0) {
    //       $('.more').css('display', 'block');
    //     } else {
    //       $('.more').css('display', 'none');
    //     }
    //   }
    // });

    this.getSocialMedia();
    //this.getContentList();
    //this.getContentListHeader();

    // this.getBrandList();
    this.getSlotGameList();
    this.getCasinoCategories();
  }

  getSlotGameList() {
    this.getSlots(this.SelectedBrandId);
  }
  getCasinoCategories() {
    this.Service.GetLiveCasinoCategories().subscribe((Response) => {
      if (Response.Success) {
        this.Categories = Response.Data;
      }
    });
  }

  getCategoriesIcon(id: string) {
    switch (id) {
      case 'Baccarat':
        return 'icon-poker';
      case 'Game Show':
        return 'icon-dice-4';
      case 'Blackjack':
        return 'icon-gambling';
      case 'Roulette':
        return 'icon-casino';
      case 'Poker':
        return 'icon-poker-2';
      default:
        break;
    }
  }

  // getBrandList() {
  //   let Request = new SlotBrandListRequest();
  //   Request.GameType = "turbo_games";
  //   this.Service.getSlotBrandList(Request).subscribe(Response => {
  //     if (Response.length) {
  //       this.getSlots(Response[0].Id);
  //     }
  //   })
  // }
  LoginModal() {
    this.ConfigService.LoginModal.next('on');
  }

  getContentList() {
    let Request = new GetContentListRequest();
    Request.Type = 2;
    this.Subscribe.add(
      this.Service.GetContentList(Request).subscribe((Response) => {
        if (Response.Success) {
          this.ContentListRight = Response.Data;
        }
      })
    );
  }
  getPromotions() {
    let req = new PromotionsImagesAndTextsRequest();
    this.Service.PromotionsImagesAndTexts_v20(req).subscribe((res) => {
      if (res) {
        this.Promotions = res;
      }
    });
  }

  getSlots(BrandId: number, CategoryId?: string) {
    if (CategoryId) {
      this.ActiveCategory = CategoryId;
    } else {
      this.ActiveCategory = null;
    }
    this.SelectedBrandId = BrandId;
    let Request = new SlotGameListRequest();
    Request.GameType = 'live_casino';
    Request.BrandId = this.SelectedBrandId;
    Request.Mobile = this.IsMobile;
    Request.LiveCasinoCategory = CategoryId;
    this.Service.SlotRepoGameList(Request).subscribe((Response) => {
      this.Title = 'footer.title.livecasino';
      this.AllGames = Response;
    });
  }
  getCategoryGames(CId: string) {
    this.getSlots(null, CId);
  }
  get IsMobile() {
    const ua = (window.navigator.userAgent || '').toLowerCase();
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 4)
      );
    return isMobile;
  }

  getContentListHeader() {
    let Request = new GetContentListRequest();
    Request.Type = 1;
    this.Subscribe.add(
      this.Service.GetContentList(Request).subscribe((Response) => {
        if (Response.Success) {
          this.ContentListLeft = Response.Data;
        }
      })
    );
  }

  menuActiver: boolean = false;
  openGameHeader() {
    this.menuActiver = !this.menuActiver;
  }
  openMenu() {
    var menu = document.querySelector('.mobile-menu-button');
    var menuContent = document.querySelector('.mobile-menu-layout');
    menu.classList.toggle('active');
    menuContent.classList.toggle('active');
  }

  accordionShower: string;
  showAccordion(route: string) {
    if (this.accordionShower === route) {
      this.accordionShower = '';
    } else {
      this.accordionShower = route;
    }
  }

  getSocialMedia() {
    this.Service.GetSocialMedia().subscribe((Response) => {
      this.SocialMedias = Response;
    });
  }

  eventText = '';

  onSwipe(evt) {
    const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    const y = Math.abs(evt.deltaY) > 1 ? (evt.deltaY > 0 ? 'down' : 'up') : '';
    if (x == 'left') {
      this.openMenu();
    }

    var links = $('.mobile-menu-links');
    if (y == 'up') {
      if (evt.deltaY > -200) {
        links.animate({ scrollTop: links.scrollTop() + 500 }, 100);
      } else if (evt.deltaY > -300) {
        links.animate({ scrollTop: links.scrollTop() + 1000 }, 100);
      } else {
        links.animate({ scrollTop: links.scrollTop() + 3000 }, 100);
      }
    } else {
      if (evt.deltaY < 200) {
        links.animate({ scrollTop: links.scrollTop() - 500 }, 100);
      } else if (evt.deltaY < 300) {
        links.animate({ scrollTop: links.scrollTop() - 1000 }, 100);
      } else {
        links.animate({ scrollTop: links.scrollTop() - 3000 }, 100);
      }
    }
  }

  keypress(evt) {}

  CallMeModal() {
    this.ConfigService.CallMeModal.next('on');
  }
}
