<div
  #carousel
  (touchstart)="onTouchStart($event)"
  (touchmove)="onTouchMove($event)"
  (touchend)="onTouchEnd($event)"
  *ngIf="!isLoading; else loadingTemplate"
  class="w-100 carousel-container">
  <carousel [isAnimated]="true" [interval]="myInterval" #carouselRef [(activeSlide)]="currentSlideIndex" class="w-100">
    <slide *ngFor="let Banner of images" class="w-100">
      <a [href]="Banner.Url" class="w-100">
        <img [src]="Banner.ImagePath" alt="Banner" class="w-100" />
      </a>
    </slide>
  </carousel>
  <div class="carousel-controls">
    <button (click)="carouselRef.previousSlide()" class="carousel-control left">
      <i class="fa fa-chevron-left"></i>
    </button>
    <span class="carousel-control-counts">{{ currentSlideIndex + 1 }}/{{ images.length }}</span>
    <button (click)="carouselRef.nextSlide()" class="carousel-control right">
      <i class="fa fa-chevron-right"></i>
    </button>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="w-100" *ngIf="!noBanner">
    <div class="carousel-loading w-100">
      <div class="slide-placeholder placeholder-glow w-100">
        <div class="placeholder col-12 skeleton" style="height: 9rem"></div>
      </div>
      <div class="slide-placeholder placeholder-glow w-100" *ngIf="!IsMobile">
        <div class="placeholder col-12 skeleton" style="height: 9rem"></div>
      </div>
      <div class="slide-placeholder placeholder-glow w-100" *ngIf="!IsMobile">
        <div class="placeholder col-12 skeleton" style="height: 9rem"></div>
      </div>
    </div>
  </div>
</ng-template>
