import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../services/Translate.service';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  /**
   *
   */
  constructor(private translateService: TranslateService) {}

  transform(value: string, ...args: unknown[]): string {
    let val = this.translateService.get(value);

    if (args && args.length > 0) {
      for (let index = 0; index < args.length; index++) {
        const element = args[index];
        val = val.replace(`{${index}}`, element.toString());
      }
    }

    return val;
  }
}
