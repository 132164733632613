import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.html'],
})
export class ConfirmationModalComponent implements OnInit {
  title: string;
  confirmLabel: string;
  declineLabel: string;
  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public decline() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public confirm() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public dismiss() {
    // daha sonra geliştirilebilir şuanlıkl ihtiyaç olmadığından eklenmedi
    // this.bsModalRef.dismiss();
  }
}
