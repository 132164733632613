<ng-template #Template>
  <div class="modal-header d-flex justify-content-between">
    <div class="start">
      <a routerLink="/" class="logo">
        <img src="/assets/uploads/brand/logo.png" alt="" class="logo" />
      </a>
    </div>
    <div class="end">
      <a href="javascript:void(0)" (click)="confirmClose()" class="btn btn-close"><i class="fa fa-close"></i></a>
    </div>
  </div>
  <ng-container *ngIf="!askClose">
    <div class="modal-body">
      <div class="">
        <div class="w-100" #userCreds>
          <div class="w-100">
            <span>{{ 'account.form.label.username' | translate }}</span>
            <h6 class="title p-4">{{ UserInfo.UserName }}</h6>
          </div>
          <div class="w-100">
            <span>{{ 'account.label.password' | translate }}</span>
            <h6 class="title p-4">{{ UserInfo.Password }}</h6>
          </div>
        </div>
        <div class="actions w-100 mt-3 text-center">
          <h6>
            {{ 'text.newuser.saveinfo' | translate }}
          </h6>
          <button class="btn btn-base me-1" type="button" (click)="copyUserCreds(userCreds)">
            <i class="fa fa-copy"></i>
          </button>
          <button class="btn btn-base me-1" type="button" (click)="downloadUserCredsJpg(userCreds)">
            <i class="fa fa-download"></i>
            .jpg
          </button>
          <button class="btn btn-base" type="button" (click)="downloadUserCredsTxt(userCreds)">
            <i class="fa fa-download"></i>
            .txt
          </button>
        </div>
        <div class="nub" *ngIf="askClose">
          <div class="secondary h-100 pt-3 mt-3">
            <span class="title">
              {{ 'text.newuser.areyousure' | translate }}
            </span>
            <br />
            <a role="button" (click)="closeModal()" class="btn btn-base me-2">{{ 'btn.yes' | translate }}</a>
            <a role="button" (click)="askClose = false" class="btn btn-base">
              {{ 'btn.cancel' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <a href="/account/info/deposit" class="btn btn-base me-1">{{ 'btn.deposit' | translate }}</a>
      <a href="/account/info/edit" class="btn btn-base me-1">{{ 'btn.edit.userinfo' | translate }}</a>
      <a role="button" (click)="confirmClose()" class="btn btn-base">{{ 'btn.close' | translate }}</a>
    </div>
  </ng-container>
</ng-template>
