import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GameFilterComponent } from './game-filter.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/helpers/pipes/pipes.module';
import { AngularSvgIconModule, SvgIconComponent } from 'angular-svg-icon';

@NgModule({
  declarations: [GameFilterComponent],
  imports: [CommonModule, FormsModule, PipesModule, AngularSvgIconModule.forRoot()],
  exports: [GameFilterComponent],
})
export class GameFilterModule {}
