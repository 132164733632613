<div class="w-100" *ngIf="Mega?.Value; else loadingTemplates">
  <div class="jackpots d-flex">
    <!-- mega -->
    <div class="jackpot mega flex-grow-1">
      <div class="inner">
        <span class="title">
          <i class="fad fa-coins me-2"></i>
          mega jackpot
        </span>
        <div class="d-inline-flex">
          <span class="value">{{ (Mega?.Value | number : '9.0-0')?.substring(0, 9) }}</span>
          <div class="jackpot-slider hour text-light">
            <div>
              <span class="d-block value">{{ +(Mega?.Value | number : '9.0-0')?.substring(9, 10) | countSlider }}</span>
            </div>
          </div>
          <div class="jackpot-slider minute text-light">
            <div>
              <span class="d-block value">
                {{ +(Mega?.Value | number : '9.0-0')?.substring(10, 11) | countSlider }}
              </span>
            </div>
          </div>
          <div class="text-light me-1">.</div>
          <div class="jackpot-slider second text-light">
            <div class="pt-2">
              <span class="d-block value">
                {{ +(Mega?.Value | number : '9.0-0')?.substring(13, 14) | countSlider }}
              </span>
            </div>
          </div>
          <div class="jackpot-slider msecond text-light">
            <div>
              <span class="d-block value">
                {{ +(Mega?.Value | number : '9.0-0')?.substring(14, 15) | countSlider }}
              </span>
            </div>
          </div>
        </div>
        <sub>{{ Currency }}</sub>
      </div>
    </div>
    <!-- grand -->
    <div class="jackpot grand">
      <div class="inner">
        <span class="title">
          <i class="fad fa-coins mr-2"></i>
          grand jackpot
        </span>
        <div class="d-inline-flex">
          <span class="value">{{ (Grand?.Value | number : '6.0-0')?.substring(0, 5) }}</span>
          <div class="jackpot-slider hour text-light">
            <div>
              <span class="d-block value">{{ +(Grand?.Value | number : '6.0-0')?.substring(4, 5) | countSlider }}</span>
            </div>
          </div>
          <div class="jackpot-slider minute text-light">
            <div>
              <span class="d-block value">{{ +(Grand?.Value | number : '6.0-0')?.substring(5, 6) | countSlider }}</span>
            </div>
          </div>
          <div class="text-light mr-1">.</div>
          <div class="jackpot-slider second text-light">
            <div class="pt-2">
              <span class="d-block value">{{ +(Grand?.Value | number : '6.0-0')?.substring(8, 9) | countSlider }}</span>
            </div>
          </div>
          <div class="jackpot-slider msecond text-light">
            <div>
              <span class="d-block value">
                {{ +(Grand?.Value | number : '6.0-0')?.substring(9, 10) | countSlider }}
              </span>
            </div>
          </div>
        </div>
        <sub>{{ Currency }}</sub>
      </div>
    </div>
    <div class="jackpot major">
      <div class="inner">
        <span class="title">
          <i class="fad fa-coins mr-2"></i>
          major jackpot
        </span>
        <div class="d-inline-flex">
          <span class="value">{{ (Major?.Value | number : '6.0-0')?.substring(0, 5) }}</span>
          <div class="jackpot-slider hour text-light">
            <div>
              <span class="d-block value">{{ +(Major?.Value | number : '6.0-0')?.substring(4, 5) | countSlider }}</span>
            </div>
          </div>
          <div class="jackpot-slider minute text-light">
            <div>
              <span class="d-block value">{{ +(Major?.Value | number : '6.0-0')?.substring(5, 6) | countSlider }}</span>
            </div>
          </div>
          <div class="text-light mr-1">.</div>
          <div class="jackpot-slider second text-light">
            <div class="pt-2">
              <span class="d-block value">{{ +(Major?.Value | number : '6.0-0')?.substring(8, 9) | countSlider }}</span>
            </div>
          </div>
          <div class="jackpot-slider msecond text-light">
            <div>
              <span class="d-block value">
                {{ +(Major?.Value | number : '6.0-0')?.substring(9, 10) | countSlider }}
              </span>
            </div>
          </div>
        </div>
        <sub>{{ Currency }}</sub>
      </div>
    </div>
    <div class="jackpot minor">
      <div class="inner">
        <span class="title">
          <i class="fad fa-coins mr-2"></i>
          minor jackpot
        </span>
        <div class="d-inline-flex">
          <span class="value">{{ (Minor?.Value | number : '6.0-0')?.substring(0, 5) }}</span>
          <div class="jackpot-slider hour text-light">
            <div>
              <span class="d-block value">{{ +(Minor?.Value | number : '6.0-0')?.substring(4, 5) | countSlider }}</span>
            </div>
          </div>
          <div class="jackpot-slider minute text-light">
            <div>
              <span class="d-block value">{{ +(Minor?.Value | number : '6.0-0')?.substring(5, 6) | countSlider }}</span>
            </div>
          </div>
          <div class="text-light mr-1">.</div>
          <div class="jackpot-slider second text-light">
            <div class="pt-2">
              <span class="d-block value">{{ +(Minor?.Value | number : '6.0-0')?.substring(8, 9) | countSlider }}</span>
            </div>
          </div>
          <div class="jackpot-slider msecond text-light">
            <div>
              <span class="d-block value">
                {{ +(Minor?.Value | number : '6.0-0')?.substring(9, 10) | countSlider }}
              </span>
            </div>
          </div>
        </div>
        <sub>{{ Currency }}</sub>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingTemplates>
  <div class="w-100">
    <div class="jackpots d-flex">
      <!-- mega -->
      <div class="jackpot mega flex-grow-1">
        <div class="inner">
          <span class="title">
            <i class="fad fa-coins me-2"></i>
            <div class="placeholder placeholder-glow skeleton-main" style="width: 150px; height: 20px"></div>
          </span>
          <div class="d-inline-flex">
            <div class="placeholder placeholder-glow skeleton-main" style="width: 80px; height: 30px"></div>
            <div class="jackpot-slider hour text-light">
              <div class="placeholder placeholder-glow skeleton-main" style="width: 30px; height: 30px"></div>
            </div>
            <div class="jackpot-slider minute text-light">
              <div class="placeholder placeholder-glow skeleton-main" style="width: 30px; height: 30px"></div>
            </div>
            <div class="text-light me-1">.</div>
            <div class="jackpot-slider second text-light">
              <div class="pt-2">
                <div class="placeholder placeholder-glow skeleton-main" style="width: 30px; height: 30px"></div>
              </div>
            </div>
            <div class="jackpot-slider msecond text-light">
              <div class="placeholder placeholder-glow skeleton-main" style="width: 30px; height: 30px"></div>
            </div>
          </div>
          <sub class="placeholder placeholder-glow skeleton-main" style="width: 40px; height: 20px"></sub>
        </div>
      </div>
      <!-- grand -->
      <div class="jackpot grand">
        <div class="inner">
          <span class="title">
            <i class="fad fa-coins mr-2"></i>
            <div class="placeholder placeholder-glow" style="width: 150px; height: 20px"></div>
          </span>
          <div class="d-inline-flex">
            <div class="placeholder placeholder-glow" style="width: 80px; height: 30px"></div>
            <div class="jackpot-slider hour text-light">
              <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
            </div>
            <div class="jackpot-slider minute text-light">
              <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
            </div>
            <div class="text-light mr-1">.</div>
            <div class="jackpot-slider second text-light">
              <div class="pt-2">
                <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
              </div>
            </div>
            <div class="jackpot-slider msecond text-light">
              <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
            </div>
          </div>
          <sub class="placeholder placeholder-glow" style="width: 40px; height: 20px"></sub>
        </div>
      </div>
      <!-- major -->
      <div class="jackpot major">
        <div class="inner">
          <span class="title">
            <i class="fad fa-coins mr-2"></i>
            <div class="placeholder placeholder-glow" style="width: 150px; height: 20px"></div>
          </span>
          <div class="d-inline-flex">
            <div class="placeholder placeholder-glow" style="width: 80px; height: 30px"></div>
            <div class="jackpot-slider hour text-light">
              <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
            </div>
            <div class="jackpot-slider minute text-light">
              <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
            </div>
            <div class="text-light mr-1">.</div>
            <div class="jackpot-slider second text-light">
              <div class="pt-2">
                <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
              </div>
            </div>
            <div class="jackpot-slider msecond text-light">
              <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
            </div>
          </div>
          <sub class="placeholder placeholder-glow" style="width: 40px; height: 20px"></sub>
        </div>
      </div>
      <!-- minor -->
      <div class="jackpot minor">
        <div class="inner">
          <span class="title">
            <i class="fad fa-coins mr-2"></i>
            <div class="placeholder placeholder-glow" style="width: 150px; height: 20px"></div>
          </span>
          <div class="d-inline-flex">
            <div class="placeholder placeholder-glow" style="width: 80px; height: 30px"></div>
            <div class="jackpot-slider hour text-light">
              <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
            </div>
            <div class="jackpot-slider minute text-light">
              <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
            </div>
            <div class="text-light mr-1">.</div>
            <div class="jackpot-slider second text-light">
              <div class="pt-2">
                <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
              </div>
            </div>
            <div class="jackpot-slider msecond text-light">
              <div class="placeholder placeholder-glow" style="width: 30px; height: 30px"></div>
            </div>
          </div>
          <sub class="placeholder placeholder-glow" style="width: 40px; height: 20px"></sub>
        </div>
      </div>
    </div>
  </div>
</ng-template>
