export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export class TicketOdds {
  public Id: number;
  public MatchId: number;
  public Market: string;
  public Outcome: string;
  public Special: string;
  public Value: number;
  public Active: boolean;
  public Live: boolean;
  public Banko: boolean;

  public constructor(init?: Partial<TicketOdds>) {
    (Object as any).assign(this, init);
  }
}

export class TicketOddsList extends Array<TicketOdds> {
  public constructor(init?: Partial<TicketOddsList>) {
    super();
    (Object as any).assign(this, init);
  }
}

export class TicketMatch {
  public Id: number;
  public Tournament: string;
  public Iso: string;
  public Teams: string;
  public Banko: boolean;
  public Odds: TicketOddsList;

  public constructor(init?: Partial<TicketMatch>) {
    (Object as any).assign(this, init);
  }
}

export class TicketMatchList extends Array<TicketMatch> {
  public constructor(init?: Partial<TicketMatchList>) {
    super();
    (Object as any).assign(this, init);
  }
}

export class TicketConfig {
  public HasFee: boolean;
  public FeeRate: number;
  public UseCombiBonus: boolean;

  public constructor(init?: Partial<TicketConfig>) {
    (Object as any).assign(this, init);
  }
}

export class DbCheckLimitsResponse {
  public MinBetSize: number;
  public MaxBetSize: number;
  public MinStake: number;
  public MaxStake: number;
  public MaxPayout: number;
  public Active: boolean;

  public constructor(init?: Partial<DbCheckLimitsResponse>) {
    (Object as any).assign(this, init);
  }
}

export class TicketCombinations {
  public System: number;
  public MatchCount: number;
  public BetCount: number;
  public MaxOdds: number;
  public Selected: boolean;

  public constructor(init?: Partial<TicketCombinations>) {
    (Object as any).assign(this, init);
  }
}

export class TicketCombinationList extends Array<TicketCombinations> {
  public constructor(init?: Partial<TicketCombinationList>) {
    super();
    (Object as any).assign(this, init);
  }
}

export class TicketHeader {
  public MaxOveralOdds: number;
  public NumberOfBet: number;
  public HasBonus: boolean;
  public HasFee: boolean;
  public FeeRate: number;
  public Bonus: number;
  public Live: boolean;

  public constructor(init?: Partial<TicketHeader>) {
    (Object as any).assign(this, init);
  }
}

export class TicketConfirmResult {
  public TicketId: number;
  public ShopId: number;
  public TicketDate: string;
  public TotalStake: number;
  public StakePerBet: number;
  public IsSystem: boolean;
  public IsCartesian: boolean;
  public Systems: string;
  public PaymentPin: string;
  public Matches: TicketMatchList;
  public Currency: string;
  public HasBonus: boolean;
  public Bonus: number;
  public MaxOdds: number;
  public PossiblePayout: number;
  public SiteId: number;
  public PartnerId: number;
  public MatchCount: number;
  public TicketCount: number;

  public constructor(init?: Partial<TicketConfirmResult>) {
    (Object as any).assign(this, init);
  }
}

export class Metadata {
  public DepositId: number;

  public constructor(init?: Partial<Metadata>) {
    (Object as any).assign(this, init);
  }
}

export class Local {
  public Amount: number;
  public Currency: string;

  public constructor(init?: Partial<Local>) {
    (Object as any).assign(this, init);
  }
}

export class Pricing {
  public Local: Local;

  public constructor(init?: Partial<Pricing>) {
    (Object as any).assign(this, init);
  }
}

export class CoinBaseCallBackData {
  public Code: string;
  public Metadata: Metadata;
  public Pricing: Pricing;

  public constructor(init?: Partial<CoinBaseCallBackData>) {
    (Object as any).assign(this, init);
  }
}

export class CoinBaseEvent {
  public Type: string;
  public Data: CoinBaseCallBackData;

  public constructor(init?: Partial<CoinBaseEvent>) {
    (Object as any).assign(this, init);
  }
}

export class CoinBaseCallBack {
  public Id: string;
  public Event: CoinBaseEvent;

  public constructor(init?: Partial<CoinBaseCallBack>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract(Name="StatusReportSVSCustomer", Namespace="")
export class EcopayzStatusReportSVSCustomer {
  // @DataMember(Name="IP", Order=1)
  public IP: string;

  // @DataMember(Name="PostalCode", Order=2)
  public PostalCode: string;

  // @DataMember(Name="Country", Order=3)
  public Country: string;

  // @DataMember(Name="LastName", Order=4)
  public LastName: string;

  // @DataMember(Name="FirstName", Order=5)
  public FirstName: string;

  public constructor(init?: Partial<EcopayzStatusReportSVSCustomer>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract(Name="StatusReport", Namespace="")
export class EcopayzStatusReport {
  // @DataMember(Name="StatusDescription", Order=1)
  public StatusDescription: string;

  // @DataMember(Name="Status", Order=2)
  public Status: number;

  // @DataMember(Name="SVSCustomer", Order=3, EmitDefaultValue=false)
  public SVSCustomer: EcopayzStatusReportSVSCustomer;

  public constructor(init?: Partial<EcopayzStatusReport>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract(Name="Request", Namespace="")
export class EcopayzRequest {
  // @DataMember(Name="MerchantFreeText", Order=1)
  public MerchantFreeText: string;

  // @DataMember(Name="CustomerIdAtMerchant", Order=2)
  public CustomerIdAtMerchant: string;

  // @DataMember(Name="MerchantAccountNumber", Order=3)
  public MerchantAccountNumber: string;

  // @DataMember(Name="Currency", Order=4)
  public Currency: string;

  // @DataMember(Name="Amount", Order=5)
  public Amount: number;

  // @DataMember(Name="TxBatchNumber", Order=6)
  public TxBatchNumber: string;

  // @DataMember(Name="TxID", Order=7)
  public TxID: string;

  public constructor(init?: Partial<EcopayzRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract(Name="Authentication", Namespace="")
export class EcopayzAuthentication {
  // @DataMember(Name="Checksum", Order=1)
  public Checksum: string;

  public constructor(init?: Partial<EcopayzAuthentication>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract(Name="SVSPurchaseStatusNotificationRequest", Namespace="")
export class EcopayzSVSPurchaseStatusNotificationRequest {
  // @DataMember(Name="StatusReport", Order=1, EmitDefaultValue=false)
  public StatusReport: EcopayzStatusReport;

  // @DataMember(Name="Request", Order=2, EmitDefaultValue=false)
  public Request: EcopayzRequest;

  // @DataMember(Name="Authentication", Order=3, EmitDefaultValue=false)
  public Authentication: EcopayzAuthentication;

  public constructor(init?: Partial<EcopayzSVSPurchaseStatusNotificationRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract(Name="TransactionResult", Namespace="")
export class EcopayzTransactionResult {
  // @DataMember(Name="Description", Order=1, EmitDefaultValue=false)
  public Description: string;

  // @DataMember(Name="Code", Order=2, EmitDefaultValue=false)
  public Code: number;

  public constructor(init?: Partial<EcopayzTransactionResult>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract(Name="SVSPurchaseStatusNotificationResponse", Namespace="")
export class EcopayzSVSPurchaseStatusNotificationResponse {
  // @DataMember(Name="TransactionResult", Order=1, EmitDefaultValue=false)
  public TransactionResult: EcopayzTransactionResult;

  // @DataMember(Name="Status", Order=2, EmitDefaultValue=false)
  public Status: string;

  // @DataMember(Name="Authentication", Order=3, EmitDefaultValue=false)
  public Authentication: EcopayzAuthentication;

  public constructor(init?: Partial<EcopayzSVSPurchaseStatusNotificationResponse>) {
    (Object as any).assign(this, init);
  }
}

export class EnvoyBaseErrors {
  public Errors: { [index: string]: string[] };

  public constructor(init?: Partial<EnvoyBaseErrors>) {
    (Object as any).assign(this, init);
  }
}

export class EnvoyBaseDTO extends EnvoyBaseErrors {
  public Status: boolean;
  public ErrorMessage: string;

  public constructor(init?: Partial<EnvoyBaseDTO>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class EnvoyAviableAmountDTO {
  public RefCode: string;
  public Amount: number;

  public constructor(init?: Partial<EnvoyAviableAmountDTO>) {
    (Object as any).assign(this, init);
  }
}

export class EnvoyBankDTO {
  public BankName: string;
  public Slug: string;
  public Photo: string;
  public MinDepositLimit: number;
  public MaxDepositLimit: number;
  public MinWithdrawLimit: number;
  public MaxWithdrawLimit: number;
  public IbanFormat: string;
  public AviableAmounts: EnvoyAviableAmountDTO[];

  public constructor(init?: Partial<EnvoyBankDTO>) {
    (Object as any).assign(this, init);
  }
}

export class DbPaparaListResponse {
  public Id: number;
  public AccountNumber: string;

  public constructor(init?: Partial<DbPaparaListResponse>) {
    (Object as any).assign(this, init);
  }
}

export class FormProps {
  public PropId: number;
  public Value: string;
  public IsAmount: boolean;

  public constructor(init?: Partial<FormProps>) {
    (Object as any).assign(this, init);
  }
}

export class DepositProviders {
  public Id: number;
  public ProviderName: string;
  public LogoUrl: string;
  public TranslationKey: string;
  public MinAmount: number;
  public MaxAmount: number;
  public Currency: string;
  public IsForm: boolean;
  public FormId: number;

  public constructor(init?: Partial<DepositProviders>) {
    (Object as any).assign(this, init);
  }
}

export class WithdrawProviders {
  public Id: number;
  public ProviderName: string;
  public LogoUrl: string;
  public TranslationKey: string;
  public MinAmount: number;
  public MaxAmount: number;
  public Currency: string;
  public IsForm: boolean;
  public FormId: number;

  public constructor(init?: Partial<WithdrawProviders>) {
    (Object as any).assign(this, init);
  }
}

export class SiteBanks {
  public Id: number;
  public Name: string;

  public constructor(init?: Partial<SiteBanks>) {
    (Object as any).assign(this, init);
  }
}

export class FormPropChildren {
  public Id: number;
  public ParentId: number;
  public TypeId: number;
  public Name: string;
  public Ordering: number;

  public constructor(init?: Partial<FormPropChildren>) {
    (Object as any).assign(this, init);
  }
}

export class FormProperty {
  public FormId: number;
  public Required: boolean;
  public TypeId: number;
  public Ordering: number;
  public Id: number;
  public Mask: string;
  public Name: string;
  public IsAmount: boolean;
  public Children: FormPropChildren[];

  public constructor(init?: Partial<FormProperty>) {
    (Object as any).assign(this, init);
  }
}

export class OpenWithdrawRequest {
  public Id: number;
  public RequestDate: string;
  public ProviderName: string;
  public RequetAmount: number;
  public Currency: string;
  public Status: string;
  public CanCancel: boolean;

  public constructor(init?: Partial<OpenWithdrawRequest>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerBankList {
  public Id: number;
  public BankName: string;
  public Iban: string;

  public constructor(init?: Partial<PlayerBankList>) {
    (Object as any).assign(this, init);
  }
}

export class CepbankBankListResponse {
  public Id: number;
  public BankName: string;

  public constructor(init?: Partial<CepbankBankListResponse>) {
    (Object as any).assign(this, init);
  }
}

export class VirtualGamesListResponse {
  public GameName: string;
  public ImageUrl: string;
  public TranslationKey: string;

  public constructor(init?: Partial<VirtualGamesListResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DbContactIssueResponse {
  public translation: string;

  public constructor(init?: Partial<DbContactIssueResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DbContactCategoryResponse {
  public translation: string;

  public constructor(init?: Partial<DbContactCategoryResponse>) {
    (Object as any).assign(this, init);
  }
}

export class Countries {
  public Id: string;
  public IsoCode: string;
  public Name: string;
  public PhoneCode: number;

  public constructor(init?: Partial<Countries>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerDocumentListResponse {
  public Id: number;
  public DocumentTypeId: number;
  public DocumentTypeName: string;
  public Document: string;
  public Verified: boolean;

  public constructor(init?: Partial<PlayerDocumentListResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerDocumentTypeResponse {
  public Id: number;
  public Name: string;

  public constructor(init?: Partial<PlayerDocumentTypeResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DbWithdrawRequestsByPlayerIdResponse {
  public Date: string;
  public Amount: number;
  public PaymentProvider: string;
  public Status: string;
  public CancelReason: string;

  public constructor(init?: Partial<DbWithdrawRequestsByPlayerIdResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DbDepositRequestsByPlayerIdResponse {
  public Date: string;
  public Amount: number;
  public PaymentProvider: string;
  public Status: string;
  public CancelReason: string;

  public constructor(init?: Partial<DbDepositRequestsByPlayerIdResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DbContentListResponse {
  public Id: number;
  public Title: string;
  public Slug: string;

  public constructor(init?: Partial<DbContentListResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DbBannerResponse {
  public Id: number;
  public Type: string;
  public ImagePath: string;

  public constructor(init?: Partial<DbBannerResponse>) {
    (Object as any).assign(this, init);
  }
}

export class MenuResponse {
  public Id: number;
  public SiteId: number;
  public MenuKey: string;
  public MenuText: string;
  public MenuIcon: string;
  public UrlRoute: string;
  public TranslationKey: string;
  public Align: string;
  public IsNew: boolean;
  public IsActive: boolean;
  public ParentId?: number;
  public OrderIndex: number;
  public IsUnderMaintenance: boolean;
  public FooterGroupId?: number;
}
export class DbMenuResponse {
  constructor() {
    this.Children = new Array<MenuResponse>();
  }
  public SiteId: number;
  public MenuKey: string;
  public MenuText: string;
  public MenuIcon: string;
  public UrlRoute: string;
  public TranslationKey: string;
  public Align: string;
  public IsNew: boolean;
  public IsActive: boolean;
  public Children: Array<MenuResponse>;
  public OrderIndex: number;
  public IsUnderMaintenance: boolean;
  public FooterGroupId?: number;
  public IsVisibleOnHomePage: boolean;
  public IsHiddenOnMobile: boolean;
  public IsApp: boolean;
}
export class DbBottomMenuResponse {
  Id: number;
  SiteId: number;
  ShowType: number;
  Name: string;
  TranslationKey: string;
  Icon: string;
  UrlRoute: string;
  ConfigurationParameters: string;
  IsSupport: boolean;
  LinkType: number;
  OrderIndex: number;
  IsActive: boolean;
  Comm100WidgetScript: string;
  ZendeskWidgetScript: string;
  JivoWidgetScript: string;
  LiveChatWidgetScript: string;
  TawkWidgetScript: string;
  CustomChatWidgetScript: string;
  SupsisWidgetScript: string;
  WisecommWidgetScript: string;
}
export class DbSiteAuthenticationSettingsResponse {
  public DefaultRegistrationEnabled: boolean;
  public FastRegistrationEnabled: boolean;
  public ReCaptchaKey: string;
  public ReCaptchaSecret: string;
  public NameVisible: boolean;
  public SurnameVisible: boolean;
  public GenderVisible: boolean;
  public BirthDateVisible: boolean;
  public CountryVisible: boolean;
  public CityVisible: boolean;
  public IdentityNumberVisible: boolean;
  public GsmVisible: boolean;
  public NameRequired: boolean;
  public SurnameRequired: boolean;
  public BirthDateRequired: boolean;
  public CountryRequired: boolean;
  public CityRequired: boolean;
  public IdentityNumberRequired: boolean;
  public GsmRequired: boolean;
  public IdentityNumberValidation: boolean;
  public RedirectToPageAfterLogin: number;
  public ValidateGsmOnRegister: boolean;
}

export class DbMobileBannerResponse {
  public Id: number;
  public Type: string;

  public constructor(init?: Partial<DbMobileBannerResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DbCasinoSliderBannerResponse {
  public Id: number;
  public Type: string;

  public constructor(init?: Partial<DbCasinoSliderBannerResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DbPromotionsImagesTextsResponse {
  public Title: string;
  public Id: number;
  public Description: string;
  public CanBeActivatedAutomatically: boolean;
  public CanBeRequested: boolean;
  public Category: string;
  public Tags: string;
  public PromotionId: number;
  public ImagePath: string;
  public AutoDepositBonus: boolean;
  public CategoryId: number;

  public constructor(init?: Partial<DbPromotionsImagesTextsResponse>) {
    (Object as any).assign(this, init);
  }
}

export class SiteCurrency {
  public Currency: string;
  public Name: string;

  public constructor(init?: Partial<SiteCurrency>) {
    (Object as any).assign(this, init);
  }
}

export class SiteLanguage {
  public Language: string;
  public Name: string;

  public constructor(init?: Partial<SiteLanguage>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerSessionData {
  public PlayerId: number;
  public SiteId: number;
  public CompanyId: number;
  public Gender: string;
  public FullName: string;
  public Email: string;
  public Username: string;
  public Currency: string;
  public Language: string;
  public Balance: number;
  public BonusBalance: number;
  public Gsm: string;
  public EmailVerified: boolean;
  public GsmVerified: boolean;
  public DocumentVerified: boolean;
  public HasFee: boolean;
  public FeeName: string;
  public FeeRate: number;
  public OpenBetCount: number;
  public CanUseTicketBonus: boolean;
  public OpenTicketcount: number;

  public constructor(init?: Partial<PlayerSessionData>) {
    (Object as any).assign(this, init);
  }
}

export class ResultMenuTournaments {
  public Id: number;
  public Name: string;
  public Count: number;

  public constructor(init?: Partial<ResultMenuTournaments>) {
    (Object as any).assign(this, init);
  }
}

export class ResultMenuCategory {
  public Id: number;
  public Name: string;
  public Iso: string;
  public Tournaments: ResultMenuTournaments[];

  public constructor(init?: Partial<ResultMenuCategory>) {
    (Object as any).assign(this, init);
  }
}

export class ResultMenuSports {
  public Id: number;
  public Name: string;
  public Categories: ResultMenuCategory[];

  public constructor(init?: Partial<ResultMenuSports>) {
    (Object as any).assign(this, init);
  }
}

export class ResultTournaments {
  public Id: number;
  public MatchDate: string;
  public RefId: number;
  public SportId: number;
  public CategoryId: number;
  public TournamentId: number;
  public HomeId: number;
  public Home: string;
  public AwayId: number;
  public Away: string;
  public Score: string;

  public constructor(init?: Partial<ResultTournaments>) {
    (Object as any).assign(this, init);
  }
}

export class Notification {
  public Id: number;
  public Title: string;
  public Content: string;
  public SiteId: number;

  public constructor(init?: Partial<Notification>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerMessage {
  public Id: number;
  public PlayerId: number;
  public AdminId: number;
  public Content: string;
  public UnRead: boolean;
  public SentDate: string;
  public IsAdmin: boolean;

  public constructor(init?: Partial<PlayerMessage>) {
    (Object as any).assign(this, init);
  }
}

export class VirtualGameError {
  public ErrorCode: number;
  public ErrorMsg: string;

  public constructor(init?: Partial<VirtualGameError>) {
    (Object as any).assign(this, init);
  }
}

export class AccountingPromotionListResponse {
  public Id: number;
  public Translation: string;
  public CombiOdds: number;
  public Minimum: number;
  public Maximum: number;
  public CumulativeAmount: number;
  public Percentage: number;
  public TicketCombiOdds: number;
  public TicketSingleOdds: number;
  public Type: number;
  public State: boolean;
  public SiteId: number;
  public MaxGain: number;
  public HasToDepositBeforeWithdraw: boolean;
  public HasToDepositBeforeBonus: boolean;
  public WelcomeBonus: boolean;
  public DepositBonus: boolean;
  public Position: number;
  public Total: number;

  public constructor(init?: Partial<AccountingPromotionListResponse>) {
    (Object as any).assign(this, init);
  }
}

export class TournamentGroupResponse {
  public Id: number;
  public GroupName: string;
  public Active: boolean;
  public Class: string;

  public constructor(init?: Partial<TournamentGroupResponse>) {
    (Object as any).assign(this, init);
  }
}

export class TournamentGroupDetailResponse {
  public GroupId: number;
  public TournamentId: number;
  public TournamentName: string;
  public Position: number;
  public Iso: string;
  public SportId: number;
  public CategoryName: string;
  public SportName: string;

  public constructor(init?: Partial<TournamentGroupDetailResponse>) {
    (Object as any).assign(this, init);
  }
}

export class BaseRequest {
  public Timestamp: string;
  public RequestId: string;
  public SiteId: number;
  public Fingerprint: string;

  public constructor(init?: Partial<BaseRequest>) {
    (Object as any).assign(this, init);
  }
}

export class GameBaseRequest extends BaseRequest {
  public SessionId: string;
  public GameId: number;
  public PlayerId: number;
  public Group: string;
  public Currency: string;

  public constructor(init?: Partial<GameBaseRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class TransactionBaseRequest extends GameBaseRequest {
  public GameCycle: string;
  public TransactionId: string;
  public TransactionAmount: number;
  public TransactionCategory: string;

  public constructor(init?: Partial<TransactionBaseRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class MatchLiveInfo {
  public Score: string;
  public MatchTime: string;
  public MatchStatus: string;
  public Status: number;

  public constructor(init?: Partial<MatchLiveInfo>) {
    (Object as any).assign(this, init);
  }
}

export class ResponseScoreDTO {
  public HomeTeam: string;
  public AwayTeam: string;

  public constructor(init?: Partial<ResponseScoreDTO>) {
    (Object as any).assign(this, init);
  }
}

export class LiveFlatMatch {
  public Id: number;
  public MatchDate: string;
  public RefId: number;
  public SportId: number;
  public SportName: string;
  public CategoryId: number;
  public CategoryName: string;
  public Iso: string;
  public TournamentId: number;
  public Tid: number;
  public TournamentName: string;
  public Rh: number;
  public Ra: number;
  public Yh: number;
  public Ya: number;
  public Ca: number;
  public Ch: number;
  public HomeId: number;
  public HomeName: string;
  public AwayId: number;
  public AwayName: string;
  public Info: MatchLiveInfo;
  public OddsCount: number;
  public StreamingId: string;
  public Token: string;
  public Markets: { [index: string]: LiveOdds[] }[];
  public Bets: { [index: number]: { [index: string]: LiveOdds } };
  public ExtraInfo: { [index: string]: string };
  public BetStopMessage: string;
  public SetScores: ResponseScoreDTO[];
  public GameScore: ResponseScoreDTO;

  public constructor(init?: Partial<LiveFlatMatch>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class LiveCategory {
  // @DataMember(Name="Id")
  public Id: number;

  // @DataMember(Name="N")
  public Name: string;

  // @DataMember(Name="I")
  public Iso: string;

  // @DataMember(Name="M")
  public Matches: LiveFlatMatch[];

  public constructor(init?: Partial<LiveCategory>) {
    (Object as any).assign(this, init);
  }
}

export class LiveSport {
  // @DataMember(Name="Id")
  public Id: number;

  // @DataMember(Name="N")
  public Name: string;

  // @DataMember(Name="C")
  public C: LiveCategory[];

  // @DataMember(Name="H")
  public H: { [index: string]: number };

  public constructor(init?: Partial<LiveSport>) {
    (Object as any).assign(this, init);
  }
}

export class LiveNextMatch {
  public Id: number;
  public RefId: number;
  public MatchDate: string;
  public SportId: number;
  public SportName: string;
  public CategoryId: number;
  public CategoryName: string;
  public Iso: string;
  public TournamentId: number;
  public TournamentName: string;
  public HomeId: number;
  public Home: string;
  public AwayId: number;
  public Away: string;
  public HasStatistics: boolean;
  public Odds: { [index: number]: Odds[] };

  public constructor(init?: Partial<LiveNextMatch>) {
    (Object as any).assign(this, init);
  }
}

export class Odds {
  public OutcomeId: number;
  public Id: number;
  public Active: boolean;
  public Name: string;
  public OddsValue: number;
  public Special: string;
  public Format: string;
  public ShortName: string;
  public IsSpecial: boolean;
  public UpDown: string;
  public IsScore: boolean;
  public IsPlayer: boolean;
  public IsHomePlayer: boolean;
  public IsAwayPlayer: boolean;
  public PlayerName: string;

  public constructor(init?: Partial<Odds>) {
    (Object as any).assign(this, init);
  }
}

export class LiveMatchDetailMarket {
  public Id: number;
  public Special: string;
  public Odds: Odds[];

  public constructor(init?: Partial<LiveMatchDetailMarket>) {
    (Object as any).assign(this, init);
  }
}

export class DbLiveSportsList {
  public Id: number;
  public Name: string;

  public constructor(init?: Partial<DbLiveSportsList>) {
    (Object as any).assign(this, init);
  }
}

export class LiveCasinoGames {
  public Id: number;
  public BrandId: number;
  public BrandName: string;
  public GameType: number;
  public GameTypeName: string;
  public Name: string;
  public ImageUrl: string;

  public constructor(init?: Partial<LiveCasinoGames>) {
    (Object as any).assign(this, init);
  }
}

export class CallBackData {
  public Cip: string;
  public Currency: string;
  public Amount: number;

  public constructor(init?: Partial<CallBackData>) {
    (Object as any).assign(this, init);
  }
}

export class DbPlayerContactIncomingMessagesResponse {
  public Id: number;
  public Subject: string;
  public Message: string;
  public Date: string;
  public IsNew: boolean;
  public Type: string;

  public constructor(init?: Partial<DbPlayerContactIncomingMessagesResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DbPlayerContactSentMessagesResponse {
  public Id: number;
  public Subject: string;
  public Message: string;
  public Date: string;

  public constructor(init?: Partial<DbPlayerContactSentMessagesResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerInfoList {
  public PlayerId: number;
  public Firstname: string;
  public Lastname: string;
  public Username: string;
  public BirthDate: string;
  public Address: string;
  public ZipCode: string;
  public Gsm: string;
  public GsmVerified: boolean;
  public EmailVerified: boolean;
  public Country: string;
  public City: string;
  public Gender: string;
  public Currency: string;
  public LastLoginDate: string;
  public IdentityNumber: string;

  public constructor(init?: Partial<PlayerInfoList>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MenuTournament {
  // @DataMember(Name="Id")
  public TournamentId: number;

  // @DataMember(Name="N")
  public TournamentName: string;

  // @DataMember(Name="C")
  public MatchCount: number;

  // @DataMember(Name="Slug")
  public Slug: string;

  // @DataMember(Name="T")
  public Type: string;

  public constructor(init?: Partial<MenuTournament>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Category {
  // @DataMember(Name="Id")
  public CategoryId: number;

  // @DataMember(Name="N")
  public CategoryName: string;

  // @DataMember(Name="I")
  public Iso: string;

  // @DataMember(Name="C")
  public MatchCount: number;

  // @DataMember(Name="Slug")
  public Slug: string;

  // @DataMember(Name="Trn")
  public Tournaments: MenuTournament[];

  public constructor(init?: Partial<Category>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Outcomes {
  // @DataMember(Name="On")
  public OtName: string;

  // @DataMember(Name="T")
  public Type: string;

  // @DataMember(Name="N")
  public Name: string;

  // @DataMember(Name="Hs")
  public HasSpecial: boolean;

  public constructor(init?: Partial<Outcomes>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Sport {
  // @DataMember(Name="Id")
  public SportId: number;

  // @DataMember(Name="N")
  public SportName: string;

  // @DataMember(Name="C")
  public MatchCount: number;

  // @DataMember(Name="Ct")
  public Categories: Category[];

  // @DataMember(Name="Go")
  public GlobalOutcomes: Outcomes[];

  // @DataMember(Name="Slug")
  public Slug: string;

  public constructor(init?: Partial<Sport>) {
    (Object as any).assign(this, init);
  }
}

export class DbOutrighTournamentResponse {
  public MatchId: number;
  public RefId: number;
  public MatchDate: string;
  public SportId: number;
  public CategoryId: number;
  public Tid: number;
  public TournamentId: number;
  public SportName: string;
  public CategoryName: string;
  public Iso: string;
  public TournamentName: string;
  public EventName: string;
  public OddsCount: number;
  public RgActive: number;
  public MatchActive: boolean;

  public constructor(init?: Partial<DbOutrighTournamentResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MobileMenuResponse {
  // @DataMember(Name="Id")
  public Id: number;

  // @DataMember(Name="N")
  public Name: string;

  // @DataMember(Name="I")
  public Iso: string;

  // @DataMember(Name="C")
  public Say: number;

  // @DataMember(Name="T")
  public Type: string;

  public constructor(init?: Partial<MobileMenuResponse>) {
    (Object as any).assign(this, init);
  }
}

export class MatchSearch {
  public Id: number;
  public MatchDate: string;
  public Teams: string;

  public constructor(init?: Partial<MatchSearch>) {
    (Object as any).assign(this, init);
  }
}

export class LuckWinnersOftheWeek {
  public Id: number;
  public Username: string;
  public Payout: number;
  public Currency: string;

  public constructor(init?: Partial<LuckWinnersOftheWeek>) {
    (Object as any).assign(this, init);
  }
}

export class TopAndLastWinners {
  public Username: string;
  public Amount: number;
  public GameName: string;
  public GameId: number;
  public GameImg: string;

  public constructor(init?: Partial<TopAndLastWinners>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SiteFavoriteTournaments {
  // @DataMember(Name="Id")
  public TournamentId: number;

  // @DataMember(Name="SId")
  public SportId: number;

  // @DataMember(Name="Iso")
  public Iso: string;

  // @DataMember(Name="S")
  public SportName: string;

  // @DataMember(Name="C")
  public CategoryName: string;

  // @DataMember(Name="T")
  public TournamentName: string;

  public constructor(init?: Partial<SiteFavoriteTournaments>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TournamentMatch {
  // @DataMember(Name="Sid")
  public SportId: number;

  // @DataMember(Name="Tid")
  public Tid: number;

  // @DataMember(Name="Id")
  public Id: number;

  // @DataMember(Name="Sn")
  public SportName: string;

  // @DataMember(Name="Ssl")
  public SportSlug: string;

  // @DataMember(Name="Cn")
  public CategoryName: string;

  // @DataMember(Name="Csl")
  public CategorySlug: string;

  // @DataMember(Name="Tn")
  public TournamentName: string;

  // @DataMember(Name="Tsl")
  public TournamentSlug: string;

  // @DataMember(Name="I")
  public Iso: string;

  // @DataMember(Name="Rid")
  public RefId: number;

  // @DataMember(Name="Md")
  public MatchDate: string;

  // @DataMember(Name="Hid")
  public HomeId: number;

  // @DataMember(Name="Aid")
  public AwayId: number;

  // @DataMember(Name="Hn")
  public HomeName: string;

  // @DataMember(Name="An")
  public AwayName: string;

  // @DataMember(Name="Ng")
  public NeutralGround: boolean;

  // @DataMember(Name="Hs")
  public HasStatistics: boolean;

  // @DataMember(Name="L")
  public Live: boolean;

  // @DataMember(Name="C")
  public OddsCount: number;

  // @DataMember(Name="McG")
  public MarketCount: number;

  // @DataMember
  public Markets: { [index: number]: Odds[] };

  // @DataMember
  public MarketDetails: Object;

  // @DataMember(Name="Msl")
  public Slug: string;

  public constructor(init?: Partial<TournamentMatch>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TodayMatchTournament {
  // @DataMember(Name="Tournament")
  public Tournament: string;

  // @DataMember(Name="Iso")
  public Iso: string;

  // @DataMember(Name="Tid")
  public Tid: number;

  // @DataMember(Name="Matches")
  public Matches: TournamentMatch[];

  public constructor(init?: Partial<TodayMatchTournament>) {
    (Object as any).assign(this, init);
  }
}

export class RocketBank {
  public BankId: number;
  public BankTitle: string;

  public constructor(init?: Partial<RocketBank>) {
    (Object as any).assign(this, init);
  }
}

export class IdName {
  public Id: number;
  public Name: string;

  public constructor(init?: Partial<IdName>) {
    (Object as any).assign(this, init);
  }
}

export class BrandListResponse {
  public Id: number;
  public Name: string;
  public SvgPath: string;

  public constructor(init?: Partial<IdName>) {
    (Object as any).assign(this, init);
  }
}

export class Links {
  public S: string;
  public M: string;
  public L: string;
  public XL: string;

  public constructor(init?: Partial<Links>) {
    (Object as any).assign(this, init);
  }
}

export class Thumbnails {
  public S: string;
  public M: string;
  public L: string;
  public XL: string;

  public constructor(init?: Partial<Thumbnails>) {
    (Object as any).assign(this, init);
  }
}

export class VideoSnapshots {
  public links: Links;
  public thumbnails: Thumbnails;

  public constructor(init?: Partial<VideoSnapshots>) {
    (Object as any).assign(this, init);
  }
}

export class OperationHours {
  public type: string;

  public constructor(init?: Partial<OperationHours>) {
    (Object as any).assign(this, init);
  }
}

export class Dealer {
  public dealerId: string;
  public name: string;

  public constructor(init?: Partial<Dealer>) {
    (Object as any).assign(this, init);
  }
}

export class Location {
  public column: number;
  public row: number;

  public constructor(init?: Partial<Location>) {
    (Object as any).assign(this, init);
  }
}

export class Road {
  public location: Location;
  public color: string;
  public score: number;
  public ties: number;
  public isOdd: boolean;
  public playerPair: boolean;
  public bankerPair: boolean;
  public natural: boolean;

  public constructor(init?: Partial<Road>) {
    (Object as any).assign(this, init);
  }
}

export class Datum {
  public gameId: number;
  public tableId: string;
  public name: string;
  public groupId: number;
  public groupName: string;
  public playerCount: number;
  public videoSnapshots: VideoSnapshots;
  public open: boolean;
  public seats: number;
  public betBehind: boolean;
  public gameType: string;
  public operationHours: OperationHours;
  public seatsLimit: number;
  public results: Object[];
  public currency: string;
  public symbol: string;
  public minAmount: number;
  public maxAmount: number;
  public dealer: Dealer;
  public history: string[];
  public road: Road[];
  public seatsTaken: Object[];
  public dealerHand: number[];

  public constructor(init?: Partial<Datum>) {
    (Object as any).assign(this, init);
  }
}

export class SlotGameList {
  public Id: number;
  public Name: string;
  public ImageUrl: string;
  public BrandId: number;
  public BrandName: string;
  public LiveCasinoData: Datum;
  public GId: number;
  public GameType: string;
  public HasDemo: boolean;
  public constructor(init?: Partial<SlotGameList>) {
    (Object as any).assign(this, init);
  }
}

export class BankInfo {
  public Holder: string;
  public AccountNumber: string;
  public BankCode: string;
  public BankName: string;
  public Bic: string;
  public Iban: string;
  public CountryCode: string;

  public constructor(init?: Partial<BankInfo>) {
    (Object as any).assign(this, init);
  }
}

export class TransactionDetails {
  public ProjectId: string;
  public Transaction: string;
  public Time: string;
  public Status: string;
  public StatusReason: string;
  public StatusModified: string;
  public PaymentModified: string;
  public LanguageCode: string;
  public Amount: number;
  public AmountRefunded: number;
  public CurrencyCode: string;
  public Sender: BankInfo;
  public Recipient: BankInfo;

  public constructor(init?: Partial<TransactionDetails>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PlayerTicketDetail {
  // @DataMember(Name="Ss")
  public SetScores: string;

  // @DataMember(Name="Mid")
  public MatchId: number;

  // @DataMember(Name="Rid")
  public RefId: number;

  // @DataMember(Name="Md")
  public MatchDate: string;

  // @DataMember(Name="Sid")
  public SportId: number;

  // @DataMember(Name="Sn")
  public SportName: string;

  // @DataMember(Name="Cid")
  public CategoryId: number;

  // @DataMember(Name="Cn")
  public CategoryName: string;

  // @DataMember(Name="I")
  public Iso: string;

  // @DataMember(Name="Tid")
  public TournamentId: number;

  // @DataMember(Name="Tn")
  public TournamentName: string;

  // @DataMember(Name="Hn")
  public Home: string;

  // @DataMember(Name="An")
  public Away: string;

  // @DataMember(Name="L")
  public Live: boolean;

  // @DataMember(Name="B")
  public Banko: boolean;

  // @DataMember(Name="Mr")
  public MatchResult: string;

  // @DataMember(Name="Ts")
  public TicketScore: string;

  // @DataMember(Name="Mt")
  public MatchTime: number;

  // @DataMember(Name="Tmt")
  public TicketMatchTime: number;

  // @DataMember(Name="Ms")
  public MatchStatus: string;

  // @DataMember(Name="Tms")
  public TicketMatchStatus: string;

  // @DataMember(Name="Mrk")
  public Market: string;

  // @DataMember(Name="Sp")
  public SpecialValue: string;

  // @DataMember(Name="Oc")
  public Outcome: string;

  // @DataMember(Name="Ov")
  public OddValue: number;

  // @DataMember(Name="Ds")
  public DetailStatus: number;

  // @DataMember(Name="Dsn")
  public DetailStatusName: string;

  // @DataMember
  public OddsId: number;

  // @DataMember
  public CornerHome: number;

  // @DataMember
  public CornerAway: number;

  // @DataMember
  public YellowCardHome: number;

  // @DataMember
  public YellowCardAway: number;

  // @DataMember
  public RedCardAway: number;

  // @DataMember
  public RedCardHome: number;

  public constructor(init?: Partial<PlayerTicketDetail>) {
    (Object as any).assign(this, init);
  }
}

export class TvpNumberType {
  public Id: number;

  public constructor(init?: Partial<TvpNumberType>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerTransactionHistoryListResponse {
  public Id: number;
  public TransactionDate: string;
  public TransactionType: string;
  public OperationType: string;
  public Currency: string;
  public Amount: number;
  public Balance: number;
  public Total: number;
  public TransactionTypeDetail: string;
  public TransactionStatus: string;
  public DepositStatus: number;
  public WithdrawStatus: number;
  public BrandName: string;
  public OperationTypeID: number;

  public constructor(init?: Partial<PlayerTransactionHistoryListResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TvBetBaseRequest {
  // @DataMember(Name="ti", Order=1)
  public Time: number;

  // @DataMember(Name="si", Order=2)
  public Sign: string;

  // @DataMember(Name="ed", Order=9, EmitDefaultValue=false)
  public Additional: Object;

  // @DataMember
  public SiteId: number;

  public constructor(init?: Partial<TvBetBaseRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TvBetMakePaymentRequest extends TvBetBaseRequest {
  // @DataMember(Name="to", Order=2)
  public Token: string;

  // @DataMember(Name="bid", Order=3)
  public BetId: number;

  // @DataMember(Name="tt", Order=4)
  public TransactionType: number;

  // @DataMember(Name="sm", Order=5)
  public Sum: string;

  public constructor(init?: Partial<TvBetMakePaymentRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TvBetGetUserDataRequest extends TvBetBaseRequest {
  // @DataMember(Name="to", Order=2)
  public Token: string;

  public constructor(init?: Partial<TvBetGetUserDataRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TvBetGetPaymentInfoRequest extends TvBetBaseRequest {
  // @DataMember(Name="tid", Order=2)
  public TransactionId: string;

  // @DataMember(Name="to", Order=3)
  public Token: string;

  public constructor(init?: Partial<TvBetGetPaymentInfoRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class WireCapitalCallbackError {
  public Code: number;
  public Message: string;
  public Advice: string;

  public constructor(init?: Partial<WireCapitalCallbackError>) {
    (Object as any).assign(this, init);
  }
}

export class WireCapitalCallbackResult {
  public Code: number;
  public Message: string;
  public ErrorId: number;
  public Error: WireCapitalCallbackError[];

  public constructor(init?: Partial<WireCapitalCallbackResult>) {
    (Object as any).assign(this, init);
  }
}

export class WireCapitalCallbackAmount {
  public Amount: number;
  public CurrencyCode: string;

  public constructor(init?: Partial<WireCapitalCallbackAmount>) {
    (Object as any).assign(this, init);
  }
}

export class WireCapitalCallbackRequest {
  public ResponseTime: string;
  public Result: WireCapitalCallbackResult;
  public Signature: string;
  public TxId: string;
  public TxType: string;
  public TxTypeId: number;
  public RecurrentTypeId: number;
  public RequestId: string;
  public OrderId: string;
  public Amount: WireCapitalCallbackAmount;
  public SourceAmount: WireCapitalCallbackAmount;
  public CcNumber: string;
  public CardId: string;

  public constructor(init?: Partial<WireCapitalCallbackRequest>) {
    (Object as any).assign(this, init);
  }
}

export class LiveOdds {
  public Id: number;
  public Active: boolean;
  public Outcome: number;
  public OddsValue: number;
  public Special: string;
  public Format: string;
  public ShortName: string;
  public IsSpecial: boolean;
  public UpDown: string;

  public constructor(init?: Partial<LiveOdds>) {
    (Object as any).assign(this, init);
  }
}

export class TicketJsonModel {
  public Matches: TicketMatchList;
  public Limits: DbCheckLimitsResponse;
  public FeeRate: number;
  public Combinations: TicketCombinationList;
  public Header: TicketHeader;
  public IsCartesian: boolean;
  public Success: boolean;
  public Message: string;
  public TicketId: number;
  public OddsChanged: boolean;
  public HighStake: boolean;

  public constructor(init?: Partial<TicketJsonModel>) {
    (Object as any).assign(this, init);
  }
}

export class PlaceTicketResponse {
  public Ticket: TicketJsonModel;
  public Result: TicketConfirmResult;
  public TicketId: number;
  public Success: boolean;
  public OddsChanged: boolean;
  public CanCouponControl: boolean;
  public HighStake: boolean;
  public Message: string;
  public Offer: number;

  public constructor(init?: Partial<PlaceTicketResponse>) {
    (Object as any).assign(this, init);
  }
}

export class GeneralResponse {
  public status: string;
  public balance: number;
  public bonus: number;

  public constructor(init?: Partial<GeneralResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/SiteFrontendSettings")
export class SiteFrontendSettingsRequest implements IReturn<SiteFrontendSettingsRequest> {
  public constructor(init?: Partial<SiteFrontendSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new SiteFrontendSettingsRequest();
  }
  public getTypeName() {
    return 'SiteFrontendSettingsRequest';
  }
}

export class EnvoyAviableAmountsAllBanksListDTO extends EnvoyBaseDTO {
  public PhotoPath: string;
  public Banks: EnvoyBankDTO[];

  public constructor(init?: Partial<EnvoyAviableAmountsAllBanksListDTO>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class EnvoyAviableAmountsSingleBankDTO extends EnvoyBaseDTO {
  public Draws: EnvoyAviableAmountDTO[];

  public constructor(init?: Partial<EnvoyAviableAmountsSingleBankDTO>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class SiteBankAccount {
  public BankId: number;
  public BankName: string;
  public AccountId: number;
  public BrancCode: string;
  public AccountCode: string;
  public Ibann: string;
  public AccountantName: string;

  public constructor(init?: Partial<SiteBankAccount>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerSessionResponse extends PlayerSessionData {
  public SessionId: string;
  public SessionStartDate: string;
  public SessionEndDate: string;

  public constructor(init?: Partial<PlayerSessionResponse>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class PlayerRegisterResponse {
  public Lang: string;
  public SiteUrl: string;
  public Id: number;
  public SiteId: number;
  public SiteName: string;
  public Gender: string;
  public Name: string;
  public Surname: string;
  public Email: string;
  public Gsm: string;
  public Username: string;
  public Language: string;
  public Currency: string;
  public RequiredEmailActivation: boolean;
  public RequiredGsmActivation: boolean;
  public EmailActivationCode: number;
  public GsmActivationCode: number;
  public Message: string;
  public PoliticsPerson: boolean;

  public constructor(init?: Partial<PlayerRegisterResponse>) {
    (Object as any).assign(this, init);
  }
}

export class ResultMenuResponse {
  public Sports: ResultMenuSports[];

  public constructor(init?: Partial<ResultMenuResponse>) {
    (Object as any).assign(this, init);
  }
}

export class SocketResponse<T> {
  public Success: boolean;
  public Data: T;
  public Error: string;

  public constructor(init?: Partial<SocketResponse<T>>) {
    (Object as any).assign(this, init);
  }
}

export class VirtualGameGetPlayerInfoResponse {
  public Balance: number;
  public PlayerID: number;
  public PlayerName: string;
  public Currency: string;
  public CurrencyShortName: string;
  public error: VirtualGameError;

  public constructor(init?: Partial<VirtualGameGetPlayerInfoResponse>) {
    (Object as any).assign(this, init);
  }
}

export class VirtualGameGetBalanceResponse {
  public Balance: number;
  public error: VirtualGameError;

  public constructor(init?: Partial<VirtualGameGetBalanceResponse>) {
    (Object as any).assign(this, init);
  }
}

export class VirtualGameWithdrawResponse {
  public Balance: number;
  public error: VirtualGameError;

  public constructor(init?: Partial<VirtualGameWithdrawResponse>) {
    (Object as any).assign(this, init);
  }
}

export class VirtualGameDepositResponse {
  public Balance: number;
  public error: VirtualGameError;

  public constructor(init?: Partial<VirtualGameDepositResponse>) {
    (Object as any).assign(this, init);
  }
}

export class VirtualGameRefundResponse {
  public Balance: number;
  public error: VirtualGameError;

  public constructor(init?: Partial<VirtualGameRefundResponse>) {
    (Object as any).assign(this, init);
  }
}

export class LiveNextMatches {
  public Header: { [index: string]: string[] };
  public Matches: LiveNextMatch[];

  public constructor(init?: Partial<LiveNextMatches>) {
    (Object as any).assign(this, init);
  }
}

export class LiveMatchDetailShort {
  public MatchId: number;
  public RefId: number;
  public BetStatus: boolean;
  public StreamingId: string;
  public Token: string;
  public Status: number;
  public Score: string;
  public CornerHome: number;
  public CornerAway: number;
  public RedCardHome: number;
  public RedCardAway: number;
  public YellowCardHome: number;
  public YellowCardAway: number;
  public Markets: LiveMatchDetailMarket[];

  public constructor(init?: Partial<LiveMatchDetailShort>) {
    (Object as any).assign(this, init);
  }
}

export class LiveMatchDetailMobile {
  public MatchId: number;
  public SportId: number;
  public Sport: string;
  public Category: string;
  public Iso: string;
  public Tournament: string;
  public MatchTime: string;
  public Home: string;
  public Away: string;
  public RefId: number;
  public BetStatus: boolean;
  public Status: number;
  public Score: string;
  public StreamId: string;
  public HasStatistics: boolean;
  public MatchStatus: string;
  public CornerHome: number;
  public CornerAway: number;
  public RedCardHome: number;
  public RedCardAway: number;
  public YellowCardHome: number;
  public YellowCardAway: number;
  public Markets: { [index: number]: LiveMatchDetailMarket[] };

  public constructor(init?: Partial<LiveMatchDetailMobile>) {
    (Object as any).assign(this, init);
  }
}

export class LiveMatchExtraBets {
  public Markets: LiveMatchDetailMarket[];

  public constructor(init?: Partial<LiveMatchExtraBets>) {
    (Object as any).assign(this, init);
  }
}

export class LiveCasinoGameResponse {
  public Games: LiveCasinoGames[];
  public Brands: IdName[];
  public GameTypes: IdName[];

  public constructor(init?: Partial<LiveCasinoGameResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DbCasinoOpenResult {
  public ApiUrl: string;
  public ApiUser: string;
  public ApiPassword: string;
  public GameId: number;
  public Currency: string;
  public Language: string;
  public Mobile: boolean;
  public PlayerId: number;
  public NickName: string;
  public Balance: number;

  public constructor(init?: Partial<DbCasinoOpenResult>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerNotification {
  public MatchId: number;
  public Subject: string;
  public Message: string;
  public Type: string;
  public Position: string;

  public constructor(init?: Partial<PlayerNotification>) {
    (Object as any).assign(this, init);
  }
}
export class PlayerBalance {
  public PlayerId: number;
  public Currency: string;
  public Balance: number;
  public BonusBalance: number;

  public constructor(init?: Partial<PlayerBalance>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerPromotionByPlayer {
  public StartDate: string;
  public EndDate: string;
  public CumulativeLeft: number;
  public Balance: number;
  public TicketCombiOdds: number;
  public TicketSingleOdds: number;
  public CombiOdds: number;

  public constructor(init?: Partial<PlayerPromotionByPlayer>) {
    (Object as any).assign(this, init);
  }
}

export class CheckLoginTwoFactorResponse {
  public TwoFactorAuthEnable: boolean;
  public PSK: string;

  public constructor(init?: Partial<CheckLoginTwoFactorResponse>) {
    (Object as any).assign(this, init);
  }
}

export class GenratePskResponse {
  public PSK: string;

  public constructor(init?: Partial<GenratePskResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PlayerGroup {
  public PlayerId: number;
  public Group: string;

  public constructor(init?: Partial<PlayerGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PreMatchMenu {
  // @DataMember(Name="S")
  public Sports: Sport[];

  public constructor(init?: Partial<PreMatchMenu>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Tournament {
  // @DataMember(Name="TournamentId")
  public Tid: number;

  // @DataMember(Name="Tid")
  public Id: number;

  // @DataMember(Name="Tn")
  public Name: string;

  // @DataMember(Name="Sid")
  public SportId: number;

  // @DataMember(Name="Sn")
  public SportName: string;

  // @DataMember(Name="Cid")
  public CategoryId: number;

  // @DataMember(Name="Cn")
  public CategoryName: string;

  // @DataMember(Name="I")
  public Iso: string;

  // @DataMember(Name="Ssl")
  public SportSlug: string;

  // @DataMember(Name="Csl")
  public CategorySlug: string;

  // @DataMember(Name="Tsl")
  public TournamentSlug: string;

  // @DataMember(Name="Matches")
  public Matches: TournamentMatch[];

  // @DataMember(Name="H")
  public Header: { [index: string]: string[] };

  public constructor(init?: Partial<Tournament>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ListMatch {
  // @DataMember(Name="Matches")
  public Matches: TournamentMatch[];

  // @DataMember(Name="H")
  public Header: { [index: number]: string[] };

  public constructor(init?: Partial<ListMatch>) {
    (Object as any).assign(this, init);
  }
}

export class OutrightMatch {
  public Id: number;
  public MatchDate: string;
  public RefId: number;
  public SportId: number;
  public CategoryId: number;
  public TournamentId: number;
  public SportName: string;
  public CategoryName: string;
  public TournamentName: string;
  public Iso: string;
  public Markets: { [index: number]: Odds[] };
  public EventName: string;

  public constructor(init?: Partial<OutrightMatch>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ListMatchTodaysMatch {
  // @DataMember(Name="Tournaments")
  public Tournaments: TodayMatchTournament[];

  // @DataMember(Name="H")
  public Header: { [index: string]: string[] };

  public constructor(init?: Partial<ListMatchTodaysMatch>) {
    (Object as any).assign(this, init);
  }
}

export class CsProOpenGameResponse {
  public iframe: boolean;
  public url: string;
  public embed: string;
  public runScript: string;
  public sessionId: string;
  public importCode: string;
  public UseBonusBalanceFailed: boolean;

  public constructor(init?: Partial<CsProOpenGameResponse>) {
    (Object as any).assign(this, init);
  }
}

export class TransactionDataResponse {
  public TransactionDetails: TransactionDetails[];

  public constructor(init?: Partial<TransactionDataResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PlayerTicketHeader {
  // @DataMember(Name="Ss")
  public SetScores: string;

  // @DataMember(Name="Id")
  public Id: number;

  // @DataMember(Name="Td")
  public TicketDate: string;

  // @DataMember(Name="Spb")
  public StakePerBet: number;

  // @DataMember(Name="Dbb")
  public DecreasedBonusBalance: number;

  // @DataMember(Name="S")
  public Stake: number;

  // @DataMember(Name="TicketAmount")
  public TicketAmount: number;

  // @DataMember(Name="C")
  public Currency: string;

  // @DataMember(Name="Mo")
  public MaxOdds: number;

  // @DataMember(Name="Mp")
  public MaxPayout: number;

  // @DataMember(Name="Il")
  public IsLive: boolean;

  // @DataMember(Name="Is")
  public IsSystem: boolean;

  // @DataMember(Name="St")
  public HeaderStatus: number;

  // @DataMember(Name="Stn")
  public HeaderStatusName: string;

  // @DataMember(Name="Bc")
  public BetCount: number;

  // @DataMember(Name="Sys")
  public System: string;

  // @DataMember(Name="Py")
  public Payout: number;

  // @DataMember(Name="T")
  public Total: number;

  // @DataMember(Name="HasFee")
  public HasFee: boolean;

  // @DataMember(Name="Fee")
  public Fee: number;

  // @DataMember(Name="Cancellable")
  public Cancellable: boolean;

  // @DataMember(Name="Cashout")
  public CanCashout: boolean;

  // @DataMember(Name="CashoutPrice")
  public CashoutPrice: number;

  // @DataMember(Name="Detail")
  public Detail: PlayerTicketDetail[];

  public constructor(init?: Partial<PlayerTicketHeader>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PlayerTicketHeaders {
  // @DataMember(Name="Ticket")
  public Tickets: PlayerTicketHeader[];

  // @DataMember(Name="T")
  public Total: number;

  public constructor(init?: Partial<PlayerTicketHeaders>) {
    (Object as any).assign(this, init);
  }
}

export class CashoutOffer {
  public CashoutPrice: number;
  public Currency: string;

  public constructor(init?: Partial<CashoutOffer>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/tvbet/{SiteId}/MakePayment")
// @DataContract
export class TvBetPaymentRequest extends TvBetMakePaymentRequest implements IReturn<TvBetPaymentRequest> {
  public constructor(init?: Partial<TvBetPaymentRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TvBetPaymentRequest();
  }
  public getTypeName() {
    return 'TvBetPaymentRequest';
  }
}

export class TvBetUserInfoResult {
  public UserId: number;
  public Currency: string;
  public Token: string;
  public Test: boolean;
  public ResultCode: number;

  public constructor(init?: Partial<TvBetUserInfoResult>) {
    (Object as any).assign(this, init);
  }
}

export class TvBetTransactionInfoResult {
  public TransactionTime: number;
  public BetId: number;
  public TransactionType: number;
  public UserId: string;
  public Sum: string;
  public ResultCode: number;

  public constructor(init?: Partial<TvBetTransactionInfoResult>) {
    (Object as any).assign(this, init);
  }
}

export class WireCapitalDepositResponse {
  public EncryptedRequestData: string;
  public DestinationUrl: string;
  public QueryParamName: string;

  public constructor(init?: Partial<WireCapitalDepositResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/accentpaycallback")
// @DataContract
export class AccentPayCallbackRequest {
  public constructor(init?: Partial<AccentPayCallbackRequest>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/accentpay/depositrequest")
export class AccentPayDepositRequest implements IReturn<string> {
  public Amount: number;
  public ForcePaymentMethod: string;

  public constructor(init?: Partial<AccentPayDepositRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'AccentPayDepositRequest';
  }
}

// @Route("/api/ticket/checkodds")
export class TicketCheckOddsRequest implements IReturn<TicketMatch> {
  public OddsId: number;

  public constructor(init?: Partial<TicketCheckOddsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TicketMatch();
  }
  public getTypeName() {
    return 'TicketCheckOddsRequest';
  }
}

// @Route("/api/betslip")
export class BetslipRequest implements IReturn<TicketJsonModel> {
  public Id: number;
  public Type: string;
  public Ticket: TicketMatchList;
  public Config: TicketConfig;

  public constructor(init?: Partial<BetslipRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TicketJsonModel();
  }
  public getTypeName() {
    return 'BetslipRequest';
  }
}

// @Route("/api/ticket/refreshticket")
export class RefreshTicketRequest implements IReturn<TicketJsonModel> {
  public Ticket: TicketMatchList;

  public constructor(init?: Partial<RefreshTicketRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TicketJsonModel();
  }
  public getTypeName() {
    return 'RefreshTicketRequest';
  }
}

// @Route("/api/ticket/placeticket")
export class PlaceTicketRequest implements IReturn<PlaceTicketResponse> {
  public Odds: TicketMatchList;
  public Config: TicketConfig;
  public Systems: number[];
  public Stake: number;
  public IsSystem: boolean;
  public IsLive: boolean;
  public AccepptOddsChanges: boolean;
  public CouponControl: boolean;
  public ClientId: string;

  public constructor(init?: Partial<PlaceTicketRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlaceTicketResponse();
  }
  public getTypeName() {
    return 'PlaceTicketRequest';
  }
}

// @Route("/api/casinopro/{SiteId}/callback/balance")
export class BalanceRequest implements IReturn<GeneralResponse> {
  public providerId: number;
  public userId: string;
  public currency: string;
  public SiteId: number;

  public constructor(init?: Partial<BalanceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GeneralResponse();
  }
  public getTypeName() {
    return 'BalanceRequest';
  }
}

// @Route("/api/casinopro/{SiteId}/callback/transaction")
export class TransactionRequest implements IReturn<GeneralResponse> {
  public type: string;
  public provider: number;
  public userId: string;
  public currency: string;
  public amount: number;
  public transactionId: number;
  public gameId: number;
  public gameName: string;
  public refId: string;
  public SiteId: number;

  public constructor(init?: Partial<TransactionRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GeneralResponse();
  }
  public getTypeName() {
    return 'TransactionRequest';
  }
}

// @Route("/api/coinbase/depositrequestcoinbasetransfer")
export class DepositRequestCoinBaseTransfer implements IReturn<string> {
  public Amount: number;

  public constructor(init?: Partial<DepositRequestCoinBaseTransfer>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'DepositRequestCoinBaseTransfer';
  }
}

// @Route("/api/coinbasecallback")
export class CoinBaseCallBackRequest extends CoinBaseCallBack implements IReturn<string> {
  public constructor(init?: Partial<CoinBaseCallBackRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'CoinBaseCallBackRequest';
  }
}

// @Route("/api/ecopayz/depositrequestecopayztransfer")
export class EcopayzDepositTransferRequest implements IReturn<string> {
  public Amount: number;

  public constructor(init?: Partial<EcopayzDepositTransferRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'EcopayzDepositTransferRequest';
  }
}

// @Route("/api/ecopayzcallback")
// @DataContract(Name="SVSPurchaseStatusNotificationRequest", Namespace="")
export class EcopayzCallbackRequest
  extends EcopayzSVSPurchaseStatusNotificationRequest
  implements IReturn<EcopayzSVSPurchaseStatusNotificationResponse[]>
{
  public constructor(init?: Partial<EcopayzCallbackRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<EcopayzSVSPurchaseStatusNotificationResponse>();
  }
  public getTypeName() {
    return 'EcopayzCallbackRequest';
  }
}

// @Route("/api/desktop.css")
export class DesktopStylesRequest implements IReturn<string> {
  public SiteId: number;
  public Lang: string;

  public constructor(init?: Partial<DesktopStylesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'DesktopStylesRequest';
  }
}

// @Route("/api/mobile.css")
export class MobileStylesRequest implements IReturn<string> {
  public SiteId: number;
  public Lang: string;

  public constructor(init?: Partial<MobileStylesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'MobileStylesRequest';
  }
}

// @Route("/api/desktop.js")
export class DesktopJSRequest implements IReturn<string> {
  public SiteId: number;
  public Lang: string;

  public constructor(init?: Partial<DesktopJSRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'DesktopJSRequest';
  }
}

// @Route("/api/mobile.js")
export class MobileJSRequest implements IReturn<string> {
  public SiteId: number;
  public Lang: string;

  public constructor(init?: Partial<MobileJSRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'MobileJSRequest';
  }
}

// @Route("/api/envoy/withdrawcreate")
export class EnvoyWithdrawTransferRequest implements IReturn<string> {
  public BankSlug: string;
  public Iban: string;
  public Amount: number;
  public Identity: string;

  public constructor(init?: Partial<EnvoyWithdrawTransferRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'EnvoyWithdrawTransferRequest';
  }
}

// @Route("/api/envoy/getallamounts")
export class EnvoyAviableAmountsAllBanksRequest implements IReturn<EnvoyAviableAmountsAllBanksListDTO> {
  public constructor(init?: Partial<EnvoyAviableAmountsAllBanksRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EnvoyAviableAmountsAllBanksListDTO();
  }
  public getTypeName() {
    return 'EnvoyAviableAmountsAllBanksRequest';
  }
}

// @Route("/api/envoy/getamountsbybank")
export class EnvoyAviableAmountsByBankRequest implements IReturn<EnvoyAviableAmountsSingleBankDTO> {
  public BankSlug: string;

  public constructor(init?: Partial<EnvoyAviableAmountsByBankRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EnvoyAviableAmountsSingleBankDTO();
  }
  public getTypeName() {
    return 'EnvoyAviableAmountsByBankRequest';
  }
}

// @Route("/api/envoy/depositrequestenvoycptransfer")
export class EnvoyDepositCpTransferRequest implements IReturn<string> {
  public BankSlug: string;
  public RefCode: string;
  public Amount: number;

  public constructor(init?: Partial<EnvoyDepositCpTransferRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'EnvoyDepositCpTransferRequest';
  }
}

// @Route("/api/envoy/depositrequestenvoyqrtransfer")
export class EnvoyDepositQrTransferRequest implements IReturn<string> {
  public BankSlug: string;
  public Identity: string;
  public Amount: number;

  public constructor(init?: Partial<EnvoyDepositQrTransferRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'EnvoyDepositQrTransferRequest';
  }
}

// @Route("/api/envoycallback")
// @DataContract
export class EnvoyCallbackRequest implements IReturn<string> {
  // @DataMember(Name="message")
  public Message: string;

  // @DataMember(Name="status")
  public Status: string;

  // @DataMember(Name="ref_code")
  public RefCode: string;

  // @DataMember(Name="amount")
  public Amount: number;

  // @DataMember(Name="type")
  public Type: string;

  // @DataMember(Name="provider_transaction_id")
  public TransactionId: string;

  public constructor(init?: Partial<EnvoyCallbackRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'EnvoyCallbackRequest';
  }
}

// @Route("/api/envoygetqrbanks")
export class EnvoyQrBankListRequest implements IReturn<EnvoyBankDTO[]> {
  public constructor(init?: Partial<EnvoyQrBankListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<EnvoyBankDTO>();
  }
  public getTypeName() {
    return 'EnvoyQrBankListRequest';
  }
}

// @Route("/api/envoygetcpbanks")
export class EnvoyCpBankListRequest implements IReturn<EnvoyBankDTO[]> {
  public constructor(init?: Partial<EnvoyCpBankListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<EnvoyBankDTO>();
  }
  public getTypeName() {
    return 'EnvoyCpBankListRequest';
  }
}

// @Route("/api/finance/depositcmt")
export class DepositCmt implements IReturn<string> {
  public SiteCmtId: number;
  public Amount: number;
  public SenderCmtId: string;
  public NoteText: string;

  public constructor(init?: Partial<DepositCmt>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'DepositCmt';
  }
}

// @Route("/api/finance/transactiontypes")
export class TransactionTypesReq implements IReturn<Object> {
  public constructor(init?: Partial<TransactionTypesReq>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'TransactionTypesReq';
  }
}

// @Route("/api/finance/depositpapara")
export class DepositPapara implements IReturn<string> {
  public SitePaparaId: number;
  public Amount: number;
  public SenderPaparaId: string;
  public NoteText: string;

  public constructor(init?: Partial<DepositPapara>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'DepositPapara';
  }
}

// @Route("/api/finance/withdrawpapara")
export class WithdrawPapara implements IReturn<string> {
  public PlayerPaparaId: string;
  public Amount: number;
  public NoteText: string;

  public constructor(init?: Partial<WithdrawPapara>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'WithdrawPapara';
  }
}

// @Route("/api/finance/withdrawqiwi")
export class WithdrawQiwi implements IReturn<string> {
  public PlayerQiwiId: string;
  public Amount: number;
  public NoteText: string;
  public IPAddress: string;
  public Interface_type_ID: number;

  public constructor(init?: Partial<WithdrawQiwi>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'WithdrawQiwi';
  }
}

// @Route("/api/finance/withdrawyandex")
export class WithdrawYandex implements IReturn<string> {
  public PlayerYandexId: string;
  public Amount: number;
  public NoteText: string;
  public IPAddress: string;
  public Interface_type_ID: number;

  public constructor(init?: Partial<WithdrawYandex>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'WithdrawYandex';
  }
}

// @Route("/api/finance/sitepaparalist")
export class SitePaparaList implements IReturn<DbPaparaListResponse[]> {
  public constructor(init?: Partial<SitePaparaList>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbPaparaListResponse>();
  }
  public getTypeName() {
    return 'SitePaparaList';
  }
}

// @Route("/api/finance/sitecmtlist")
export class SiteCmtList implements IReturn<DbPaparaListResponse[]> {
  public constructor(init?: Partial<SiteCmtList>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbPaparaListResponse>();
  }
  public getTypeName() {
    return 'SiteCmtList';
  }
}

// @Route("/api/finance/submitcustombankform")
export class SubmitCustomBankForm implements IReturn<string> {
  public FormId: number;
  public FormProps: FormProps[];

  public constructor(init?: Partial<SubmitCustomBankForm>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'SubmitCustomBankForm';
  }
}

// @Route("/api/finance/withdrawsubmitcustombankform")
export class WithdrawSubmitCustomBankForm implements IReturn<string> {
  public FormId: number;
  public FormProps: FormProps[];

  public constructor(init?: Partial<WithdrawSubmitCustomBankForm>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'WithdrawSubmitCustomBankForm';
  }
}

// @Route("/api/finance/depositproviders")
export class DepositProvidersRequest implements IReturn<DepositProviders[]> {
  public constructor(init?: Partial<DepositProvidersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DepositProviders>();
  }
  public getTypeName() {
    return 'DepositProvidersRequest';
  }
}

// @Route("/api/finance/withdrawproviders")
export class WithdrawProvidersRequest implements IReturn<WithdrawProviders[]> {
  public constructor(init?: Partial<WithdrawProvidersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<WithdrawProviders>();
  }
  public getTypeName() {
    return 'WithdrawProvidersRequest';
  }
}

// @Route("/api/finance/sitebanks")
export class SiteBanksRequest implements IReturn<SiteBanks[]> {
  public constructor(init?: Partial<SiteBanksRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<SiteBanks>();
  }
  public getTypeName() {
    return 'SiteBanksRequest';
  }
}

// @Route("/api/finance/sitebankaccount")
export class SiteBankAccountRequest implements IReturn<SiteBankAccount> {
  public BankId: number;

  public constructor(init?: Partial<SiteBankAccountRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new SiteBankAccount();
  }
  public getTypeName() {
    return 'SiteBankAccountRequest';
  }
}

export class AffiliateRequest implements IReturn<string> {
  public FullName: string;
  public Email: string;
  public Url: string;
  public Skype: string;
  public Telegram: string;
  public Gsm: string;
  public constructor(init?: Partial<AffiliateRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'AffiliateRequest';
  }
}

// @Route("/api/finance/addplayerbankaccount")
export class AddPlayerBankAccountRequest implements IReturn<string> {
  public BankId: number;
  public Iban: string;
  public BranchCode: string;
  public AccountNumber: string;

  public constructor(init?: Partial<AddPlayerBankAccountRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'AddPlayerBankAccountRequest';
  }
}

// @Route("/api/finance/depositrequestbanktransfer")
export class DepositRequestBankTransferRequest implements IReturn<string> {
  public SiteBankId: number;
  public Amount: number;
  public RequestHour: string;
  public NoteText: string;

  public constructor(init?: Partial<DepositRequestBankTransferRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'DepositRequestBankTransferRequest';
  }
}

// @Route("/api/finance/getdynamicformprops")
export class GetDynamicFormPropsRequest implements IReturn<FormProperty[]> {
  public FormId: number;

  public constructor(init?: Partial<GetDynamicFormPropsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<FormProperty>();
  }
  public getTypeName() {
    return 'GetDynamicFormPropsRequest';
  }
}

// @Route("/api/finance/canceldepositrequest")
export class CancelDepositRequest implements IReturn<string> {
  public RequestId: number;

  public constructor(init?: Partial<CancelDepositRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'CancelDepositRequest';
  }
}

// @Route("/api/finance/opendepositrequests")
export class OpenDepositRequests implements IReturn<OpenDepositRequests[]> {
  public constructor(init?: Partial<OpenDepositRequests>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<OpenDepositRequests>();
  }
  public getTypeName() {
    return 'OpenDepositRequests';
  }
}

// @Route("/api/finance/openwithdraw")
export class OpenWithdrawRequests implements IReturn<OpenWithdrawRequest[]> {
  public constructor(init?: Partial<OpenWithdrawRequests>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<OpenWithdrawRequest>();
  }
  public getTypeName() {
    return 'OpenWithdrawRequests';
  }
}

// @Route("/api/finance/withdrawrequestbanktransfer")
export class WithdrawRequestBankTransfer implements IReturn<string> {
  public PlayerBankId: number;
  public Amount: number;
  public IdentityNumber: string;
  public NoteText: string;
  public AccountNumber: string;
  public BranchCode: string;
  public IdGivenOrExpDate: string;

  public constructor(init?: Partial<WithdrawRequestBankTransfer>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'WithdrawRequestBankTransfer';
  }
}

// @Route("/api/finance/cancelwithdraw")
export class CancelWithdraw implements IReturn<string> {
  public RequestId: number;

  public constructor(init?: Partial<CancelWithdraw>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'CancelWithdraw';
  }
}

// @Route("/api/finance/cancelwithdraw")
export class CancelBonus implements IReturn<string> {
  public constructor(init?: Partial<CancelBonus>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'CancelBonus';
  }
}

// @Route("/api/finance/playerbanklist")
export class PlayerBankListRequest implements IReturn<PlayerBankList[]> {
  public constructor(init?: Partial<PlayerBankListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<PlayerBankList>();
  }
  public getTypeName() {
    return 'PlayerBankListRequest';
  }
}

// @Route("/api/finance/cepbanklist")
export class CepbankBankListRequest implements IReturn<CepbankBankListResponse[]> {
  public constructor(init?: Partial<CepbankBankListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<CepbankBankListResponse>();
  }
  public getTypeName() {
    return 'CepbankBankListRequest';
  }
}

// @Route("/api/finance/depositrequestcepbanktransfer")
export class DepositRequestCepbankTransferDto implements IReturn<string> {
  public Amount: number;
  public CepbankId: number;
  public IdentityNumber: string;
  public SenderGsm: string;
  public ReceiverGsm: string;
  public PinNumber: string;
  public BirthDate: string;
  public IdGivenDate: string;
  public ReceiverIdentitiy: number;
  public ReceiverBirthDate: string;
  public ReceiverIdGivenDate: string;

  public constructor(init?: Partial<DepositRequestCepbankTransferDto>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'DepositRequestCepbankTransferDto';
  }
}

// @Route("/api/global/contactmessage")
export class CreateContactMessageRequest implements IReturn<string> {
  public Username: string;
  public Email: string;
  public CategoryId: number;
  public IssueId: number;
  public Description: string;
  public Mobile: boolean;

  public constructor(init?: Partial<CreateContactMessageRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'CreateContactMessageRequest';
  }
}

// @Route("/api/getvirtualgamesList")
export class GetVirtualGamesList implements IReturn<VirtualGamesListResponse[]> {
  public constructor(init?: Partial<GetVirtualGamesList>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<VirtualGamesListResponse>();
  }
  public getTypeName() {
    return 'GetVirtualGamesList';
  }
}

// @Route("/api/createplayerpromotionrequest")
export class CreatePlayerPromotionRequest implements IReturn<boolean> {
  public SiteId: number;
  public PromotionId: number;

  public constructor(init?: Partial<CreatePlayerPromotionRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'CreatePlayerPromotionRequest';
  }
}

// @Route("/api/global/contactissues")
export class GetContactIssues implements IReturn<DbContactIssueResponse[]> {
  public Parent: string;

  public constructor(init?: Partial<GetContactIssues>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbContactIssueResponse>();
  }
  public getTypeName() {
    return 'GetContactIssues';
  }
}

// @Route("/api/global/contactcategories")
export class GetContactCategories implements IReturn<DbContactCategoryResponse[]> {
  public constructor(init?: Partial<GetContactCategories>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbContactCategoryResponse>();
  }
  public getTypeName() {
    return 'GetContactCategories';
  }
}

// @Route("/api/global/getcities")
export class GetCitiesRequest implements IReturn<string[]> {
  public CountryId: string;

  public constructor(init?: Partial<GetCitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<string>();
  }
  public getTypeName() {
    return 'GetCitiesRequest';
  }
}

// @Route("/api/global/getcountries")
export class GetCountriesRequest implements IReturn<Countries[]> {
  public constructor(init?: Partial<GetCountriesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<Countries>();
  }
  public getTypeName() {
    return 'GetCountriesRequest';
  }
}

// @Route("/api/banners/{Id}.{Type}")
export class BannersImagesRequest {
  public Id: number;
  public SiteId: number;
  public Lang: string;
  public Type: string;

  public constructor(init?: Partial<BannersImagesRequest>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/playerdocumentupload")
export class PlayerDocumentUploadRequest implements IReturn<boolean> {
  public Lang: string;
  public DocumentTypeId: number;
  public Document: string;

  public constructor(init?: Partial<PlayerDocumentUploadRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PlayerDocumentUploadRequest';
  }
}

// @Route("/api/playerdocumentlist")
export class PlayerDocumentListRequest implements IReturn<PlayerDocumentListResponse[]> {
  public constructor(init?: Partial<PlayerDocumentListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<PlayerDocumentListResponse>();
  }
  public getTypeName() {
    return 'PlayerDocumentListRequest';
  }
}

// @Route("/api/playerdocumenttype")
export class PlayerDocumentTypeRequest implements IReturn<PlayerDocumentTypeResponse[]> {
  public constructor(init?: Partial<PlayerDocumentTypeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<PlayerDocumentTypeResponse>();
  }
  public getTypeName() {
    return 'PlayerDocumentTypeRequest';
  }
}

// @Route("/api/livecasinoslider/{Id}.{Type}")
export class LiveCasinoSliderRequest {
  public Id: number;
  public SiteId: number;
  public Lang: string;
  public Type: string;

  public constructor(init?: Partial<LiveCasinoSliderRequest>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/sportsbettingbanners")
export class SportsBettingBannersRequest {
  public Id: number;
  public Type: string;

  public constructor(init?: Partial<SportsBettingBannersRequest>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/mobilebanners/{Id}.{Type}")
export class MobileBannersImagesRequest {
  public Id: number;
  public SiteId: number;
  public Type: string;
  public Lang: string;

  public constructor(init?: Partial<MobileBannersImagesRequest>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/getwithdrawrequestsbyplayerid")
export class GetWithdrawRequestsByPlayerIdRequest implements IReturn<DbWithdrawRequestsByPlayerIdResponse[]> {
  public PlayerId: number;

  public constructor(init?: Partial<GetWithdrawRequestsByPlayerIdRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbWithdrawRequestsByPlayerIdResponse>();
  }
  public getTypeName() {
    return 'GetWithdrawRequestsByPlayerIdRequest';
  }
}

// @Route("/api/getdepositrequestsbyplayerid")
export class GetDepositRequestsByPlayerIdRequest implements IReturn<DbDepositRequestsByPlayerIdResponse[]> {
  public PlayerId: number;

  public constructor(init?: Partial<GetDepositRequestsByPlayerIdRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbDepositRequestsByPlayerIdResponse>();
  }
  public getTypeName() {
    return 'GetDepositRequestsByPlayerIdRequest';
  }
}

// @Route("/api/casinosliderbanners/{Id}.{Type}")
export class CasinoSliderBannersImagesRequest {
  public Id: number;
  public SiteId: number;
  public Type: string;
  public Lang: string;

  public constructor(init?: Partial<CasinoSliderBannersImagesRequest>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/getcontentlist")
export class GetContentListRequest implements IReturn<DbContentListResponse[]> {
  public Type: number;
  public constructor(init?: Partial<GetContentListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbContentListResponse>();
  }
  public getTypeName() {
    return 'GetContentListRequest';
  }
}

// @Route("/api/content/{Slug}")
export class GetContentDetails implements IReturn<string> {
  public Slug: string;

  public constructor(init?: Partial<GetContentDetails>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetContentDetails';
  }
}

// @Route("/api/banners")
export class BannersRequest implements IReturn<DbBannerResponse[]> {
  public BannerTypeId: number;

  public constructor(init?: Partial<BannersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbBannerResponse>();
  }
  public getTypeName() {
    return 'BannersRequest';
  }
}

export class MenuRequest implements IReturn<DbMenuResponse[]> {
  public constructor(init?: Partial<MenuRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbMenuResponse>();
  }
  public getTypeName() {
    return 'MenuRequest';
  }
}
export class BottomMenuRequest implements IReturn<DbBottomMenuResponse[]> {
  public constructor(init?: Partial<MenuRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbBottomMenuResponse>();
  }
  public getTypeName() {
    return 'BottomMenuRequest';
  }
}
export class ValidateIdentityNumberRequest {
  Name: string;
  Surname: string;
  IdentityNumber: string;
  BirthYear: number;
}

// @Route("/api/homepagegames")
export class HomePageGamesRequest implements IReturn<Object[]> {
  public Mobile: boolean;

  public constructor(init?: Partial<HomePageGamesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<Object>();
  }
  public getTypeName() {
    return 'HomePageGamesRequest';
  }
}

// @Route("/api/mobilebanners")
export class MobileBannersRequest implements IReturn<DbMobileBannerResponse[]> {
  public BannerTypeId: number;
  public constructor(init?: Partial<MobileBannersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbMobileBannerResponse>();
  }
  public getTypeName() {
    return 'MobileBannersRequest';
  }
}

// @Route("/api/casinosliderbanners")
export class CasinoSliderBannerRequest implements IReturn<DbCasinoSliderBannerResponse[]> {
  public constructor(init?: Partial<CasinoSliderBannerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbCasinoSliderBannerResponse>();
  }
  public getTypeName() {
    return 'CasinoSliderBannerRequest';
  }
}

// @Route("/api/mernischeck")
export class MernisCheckRequest implements IReturn<boolean> {
  public Name: string;
  public Surname: string;
  public IdentityNumber: string;
  public BirthYear: string;

  public constructor(init?: Partial<MernisCheckRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'MernisCheckRequest';
  }
}

// @Route("/api/promotions/{Id}.{Type}")
export class PromotionImagesRequest {
  public Id: number;
  public SiteId: number;
  public Lang: string;
  public Type: string;

  public constructor(init?: Partial<PromotionImagesRequest>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/promotionsimagesandtexts")
export class PromotionsImagesAndTextsRequest implements IReturn<DbPromotionsImagesTextsResponse[]> {
  public constructor(init?: Partial<PromotionsImagesAndTextsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbPromotionsImagesTextsResponse>();
  }
  public getTypeName() {
    return 'PromotionsImagesAndTextsRequest';
  }
}

// @Route("/api/global/sitecurrencies")
export class SiteCurrenciesRequest implements IReturn<SiteCurrency[]> {
  public constructor(init?: Partial<SiteCurrenciesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<SiteCurrency>();
  }
  public getTypeName() {
    return 'SiteCurrenciesRequest';
  }
}

// @Route("/api/global/sitelanguages")
export class SiteLanguagesRequest implements IReturn<SiteLanguage[]> {
  public constructor(init?: Partial<SiteLanguagesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<SiteLanguage>();
  }
  public getTypeName() {
    return 'SiteLanguagesRequest';
  }
}

// @Route("/api/global/checkplayerinfoexist")
export class CheckPlayerInfoExistRequest implements IReturn<boolean> {
  public Field: string;
  public Value: string;

  public constructor(init?: Partial<CheckPlayerInfoExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'CheckPlayerInfoExistRequest';
  }
}

// @Route("/api/logout")
export class LogoutRequest implements IReturn<boolean> {
  public constructor(init?: Partial<LogoutRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'LogoutRequest';
  }
}

// @Route("/api/resetPassword")
export class ForgetPasswordResetRequest implements IReturn<string> {
  public Code: string;
  public Password: string;

  public constructor(init?: Partial<ForgetPasswordResetRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'ForgetPasswordResetRequest';
  }
}

// @Route("/api/mail/resetPassword")
export class ForgetPasswordMailResetRequest implements IReturn<boolean> {
  public Email: string;

  public constructor(init?: Partial<ForgetPasswordMailResetRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'ForgetPasswordMailResetRequest';
  }
}

// @Route("/api/global/activateGsm")
export class PlayerActivateGsm implements IReturn<boolean> {
  public ActivationCode: string;

  public constructor(init?: Partial<PlayerActivateGsm>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PlayerActivateGsm';
  }
}

// @Route("/api/global/activateMail")
export class PlayerActivateMail implements IReturn<boolean> {
  public ActivationCode: string;

  public constructor(init?: Partial<PlayerActivateMail>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PlayerActivateMail';
  }
}

// @Route("/api/global/login")
export class LoginPlayerRequest implements IReturn<PlayerSessionResponse> {
  public Username: string;
  public Password: string;

  public constructor(init?: Partial<LoginPlayerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlayerSessionResponse();
  }
  public getTypeName() {
    return 'LoginPlayerRequest';
  }
}

// @Route("/api/global/register")
export class RegisterPlayerRequest implements IReturn<PlayerRegisterResponse> {
  public Gender: string;
  public Name: string;
  public Surname: string;
  public Email: string;
  public Gsm: string;
  public Username: string;
  public Password: string;
  public BirthDate: string;
  public IdentityNumber: string;
  public Country: string;
  public City: string;
  public Address: string;
  public ZipCode: string;
  public Currency: string;
  public Language: string;
  public AffCode: string;
  public PoliticsPerson: boolean;
  public ReCaptcha: string;
  public PromotionKey: string;
  public IsGsmVerified: boolean = false;

  public constructor(init?: Partial<RegisterPlayerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlayerRegisterResponse();
  }
  public getTypeName() {
    return 'RegisterPlayerRequest';
  }
}

// @Route("/api/player/changepassword")
export class ChangePasswordRequest implements IReturn<string> {
  public OldPassword: string;
  public NewPassword: string;
  public ConfirmPassword: string;

  public constructor(init?: Partial<ChangePasswordRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'ChangePasswordRequest';
  }
}

// @Route("/api/player/checktombalaactive")
export class CheckTombalaActivePlayerRequest implements IReturn<boolean> {
  public constructor(init?: Partial<CheckTombalaActivePlayerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'CheckTombalaActivePlayerRequest';
  }
}

// @Route("/api/results/menu")
export class ResultsMenuRequest implements IReturn<ResultMenuResponse> {
  public constructor(init?: Partial<ResultsMenuRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ResultMenuResponse();
  }
  public getTypeName() {
    return 'ResultsMenuRequest';
  }
}

// @Route("/api/results/tournaments")
export class ResultsTournamentRequest implements IReturn<ResultTournaments[]> {
  public TournamentId: number;

  public constructor(init?: Partial<ResultsTournamentRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<ResultTournaments>();
  }
  public getTypeName() {
    return 'ResultsTournamentRequest';
  }
}

// @Route("/api/livegames/generatesign")
export class LiveGamesGenerateSignRequest implements IReturn<string> {
  public constructor(init?: Partial<LiveGamesGenerateSignRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'LiveGamesGenerateSignRequest';
  }
}

// @Route("/api/global/getnotifications")
export class GetNotificationsRequest implements IReturn<Notification[]> {
  public SiteId: number;

  public constructor(init?: Partial<GetNotificationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<Notification>();
  }
  public getTypeName() {
    return 'GetNotificationsRequest';
  }
}

// @Route("/api/global/checksession")
export class CheckSessionRequest implements IReturn<SocketResponse<PlayerSessionData>> {
  public SessionId: string;

  public constructor(init?: Partial<CheckSessionRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new SocketResponse<PlayerSessionData>();
  }
  public getTypeName() {
    return 'CheckSessionRequest';
  }
}

// @Route("/api/global/playerunreadmessagecount")
export class GetPlayerUnreadMessagesCountRequest implements IReturn<number> {
  public PlayerId: number;

  public constructor(init?: Partial<GetPlayerUnreadMessagesCountRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'GetPlayerUnreadMessagesCountRequest';
  }
}

// @Route("/api/global/playermessages")
export class PlayerMessagesRequest implements IReturn<PlayerMessage[]> {
  public PlayerId: number;

  public constructor(init?: Partial<PlayerMessagesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<PlayerMessage>();
  }
  public getTypeName() {
    return 'PlayerMessagesRequest';
  }
}

// @Route("/api/virtualgamegetplayerinfo")
export class VirtualGameGetPlayerInfoRequest implements IReturn<VirtualGameGetPlayerInfoResponse> {
  public Token: string;

  public constructor(init?: Partial<VirtualGameGetPlayerInfoRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new VirtualGameGetPlayerInfoResponse();
  }
  public getTypeName() {
    return 'VirtualGameGetPlayerInfoRequest';
  }
}

// @Route("/api/virtualgamegetbalance")
export class VirtualGameGetBalanceRequest implements IReturn<VirtualGameGetBalanceResponse> {
  public Token: string;

  public constructor(init?: Partial<VirtualGameGetBalanceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new VirtualGameGetBalanceResponse();
  }
  public getTypeName() {
    return 'VirtualGameGetBalanceRequest';
  }
}

// @Route("/api/virtualgamewithdraw")
export class VirtualGameWithdrawRequest implements IReturn<VirtualGameWithdrawResponse> {
  public PlayerID: number;
  public Token: string;
  public GameID: number;
  public GameRoundID: number;
  public TransactionID: number;
  public Amount: number;

  public constructor(init?: Partial<VirtualGameWithdrawRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new VirtualGameWithdrawResponse();
  }
  public getTypeName() {
    return 'VirtualGameWithdrawRequest';
  }
}

// @Route("/api/virtualgamedeposit")
export class VirtualGameDepositRequest implements IReturn<VirtualGameDepositResponse> {
  public PlayerID: number;
  public GameID: number;
  public GameRoundID: number;
  public TransactionID: number;
  public Amount: number;

  public constructor(init?: Partial<VirtualGameDepositRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new VirtualGameDepositResponse();
  }
  public getTypeName() {
    return 'VirtualGameDepositRequest';
  }
}

// @Route("/api/virtualgamerefund")
export class VirtualGameRefundRequest implements IReturn<VirtualGameRefundResponse> {
  public PlayerID: number;
  public GameID: number;
  public GameRoundID: number;
  public TransactionID: number;
  public Amount: number;

  public constructor(init?: Partial<VirtualGameRefundRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new VirtualGameRefundResponse();
  }
  public getTypeName() {
    return 'VirtualGameRefundRequest';
  }
}

// @Route("/api/player2faactive")
export class Player2FAActiveRequest implements IReturn<string> {
  public PlayerId: number;
  public Active: boolean;

  public constructor(init?: Partial<Player2FAActiveRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'Player2FAActiveRequest';
  }
}

// @Route("/api/addplayerpromotionrequests")
export class AddPlayerPromotionRequestsRequest implements IReturn<string> {
  public PlayerId: number;
  public PromotionId: number;
  public Amount: number;

  public constructor(init?: Partial<AddPlayerPromotionRequestsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'AddPlayerPromotionRequestsRequest';
  }
}

// @Route("/api/accountingpromotionlist")
export class AccountingPromotionListRequest implements IReturn<AccountingPromotionListResponse[]> {
  public Translation: string;
  public Start: number;
  public Limit: number;

  public constructor(init?: Partial<AccountingPromotionListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<AccountingPromotionListResponse>();
  }
  public getTypeName() {
    return 'AccountingPromotionListRequest';
  }
}

// @Route("/api/tournamentgroups")
export class TournamentGroupsRequest implements IReturn<TournamentGroupResponse[]> {
  public constructor(init?: Partial<TournamentGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<TournamentGroupResponse>();
  }
  public getTypeName() {
    return 'TournamentGroupsRequest';
  }
}

// @Route("/api/tournamentgroupdetail")
export class TournamentGroupDetailRequest implements IReturn<TournamentGroupDetailResponse[]> {
  public GroupId: number;

  public constructor(init?: Partial<TournamentGroupDetailRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<TournamentGroupDetailResponse>();
  }
  public getTypeName() {
    return 'TournamentGroupDetailRequest';
  }
}

// @Route("/api/fixturetable")
export class FixtureTableRequest implements IReturn<string> {
  public TournamentId: number;
  public Lang: string;

  public constructor(init?: Partial<FixtureTableRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'FixtureTableRequest';
  }
}

// @Route("/api/goldenrace/openlobby")
export class OpenLobbyRequest implements IReturn<string> {
  public Demo: boolean;
  public Mobile: boolean;

  public constructor(init?: Partial<OpenLobbyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'OpenLobbyRequest';
  }
}

// @Route("/api/goldenrace/login")
export class LoginRequest extends BaseRequest implements IReturn<Object> {
  public Token: string;
  public GameId: number;
  public ClientPlatform: string;
  public ClientIp: string;

  public constructor(init?: Partial<LoginRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'LoginRequest';
  }
}

// @Route("/api/goldenrace/debit")
export class DebitRequest extends TransactionBaseRequest implements IReturn<Object> {
  public constructor(init?: Partial<DebitRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'DebitRequest';
  }
}

// @Route("/api/goldenrace/credit")
export class CreditRequest extends TransactionBaseRequest implements IReturn<Object> {
  public GameCycleClosed: boolean;

  public constructor(init?: Partial<CreditRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'CreditRequest';
  }
}

// @Route("/api/goldenrace/rollback")
export class RollbackRequest extends TransactionBaseRequest implements IReturn<Object> {
  public TransactionType: string;

  public constructor(init?: Partial<RollbackRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'RollbackRequest';
  }
}

// @Route("/api/jeton/depositrequestjetontransfer")
export class DepositRequestJetonTransfer implements IReturn<string> {
  public Amount: number;
  public RequestHour: string;
  public NoteText: string;

  public constructor(init?: Partial<DepositRequestJetonTransfer>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'DepositRequestJetonTransfer';
  }
}

// @Route("/api/jeton/withdrawrequestjetontransfer")
export class WithdrawRequestJetonTransfer implements IReturn<string> {
  public PlayerWalletId: string;
  public Amount: number;
  public IdentityNumber: string;
  public NoteText: string;

  public constructor(init?: Partial<WithdrawRequestJetonTransfer>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'WithdrawRequestJetonTransfer';
  }
}

// @Route("/api/jetoncallback")
export class JetonCallbackRequest implements IReturn<string> {
  public PaymentId: number;
  public OrderId: string;
  public Type: string;
  public Customer: string;
  public Amount: number;
  public Currency: string;
  public Status: string;
  public Message: string;

  public constructor(init?: Partial<JetonCallbackRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'JetonCallbackRequest';
  }
}

// @Route("/api/live/stats")
export class LiveStatsRequest implements IReturn<string[]> {
  public constructor(init?: Partial<LiveStatsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<string>();
  }
  public getTypeName() {
    return 'LiveStatsRequest';
  }
}

// @Route("/api/live/flatlist")
export class LiveFlatListRequest implements IReturn<any> {
  public Mobile: boolean;
  public MarketId: number;
  public Favorites: number[];
  public SportId: number;
  public IsNewMobile: boolean;

  public constructor(init?: Partial<LiveFlatListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'LiveFlatListRequest';
  }
}

// @Route("/api/getlivematchinprematch")
export class GetLiveMatchInPrematch {
  public TournamentId: number;

  public constructor(init?: Partial<GetLiveMatchInPrematch>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/live/topmatches")
export class LiveTopMatches implements IReturn<any> {
  public constructor(init?: Partial<LiveTopMatches>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'LiveTopMatches';
  }
}

// @Route("/api/live/flatchange")
export class LiveFlatChangeRequest implements IReturn<LiveFlatMatch> {
  public MatchId: number;
  public Mobile: boolean;

  public constructor(init?: Partial<LiveFlatChangeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new LiveFlatMatch();
  }
  public getTypeName() {
    return 'LiveFlatChangeRequest';
  }
}

// @Route("/api/live/marketlistmobile")
export class LiveMarketListMobileRequest implements IReturn<number[]> {
  public SportId: number;

  public constructor(init?: Partial<LiveMarketListMobileRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<number>();
  }
  public getTypeName() {
    return 'LiveMarketListMobileRequest';
  }
}

// @Route("/api/prematch/marketlistmobile")
export class PrematchMarketListMobileRequest implements IReturn<number[]> {
  public SportId: number;

  public constructor(init?: Partial<PrematchMarketListMobileRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<number>();
  }
  public getTypeName() {
    return 'PrematchMarketListMobileRequest';
  }
}

// @Route("/api/live/nextmatches")
export class LiveNextMatchesRequest implements IReturn<LiveNextMatches> {
  public Hour: number;
  public SportId: number;

  public constructor(init?: Partial<LiveNextMatchesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new LiveNextMatches();
  }
  public getTypeName() {
    return 'LiveNextMatchesRequest';
  }
}

// @Route("/api/live/matchdetailshort")
export class LiveMatchDetailShortRequest implements IReturn<LiveMatchDetailShort> {
  public MatchId: number;

  public constructor(init?: Partial<LiveMatchDetailShortRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new LiveMatchDetailShort();
  }
  public getTypeName() {
    return 'LiveMatchDetailShortRequest';
  }
}

// @Route("/api/live/matchdetailmobile")
export class LiveMatchDetailMobileRequest implements IReturn<LiveMatchDetailMobile> {
  public MatchId: number;

  public constructor(init?: Partial<LiveMatchDetailMobileRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new LiveMatchDetailMobile();
  }
  public getTypeName() {
    return 'LiveMatchDetailMobileRequest';
  }
}

// @Route("/api/live/extrabets")
export class LiveExtraBetsRequest implements IReturn<LiveMatchExtraBets> {
  public MatchId: number;

  public constructor(init?: Partial<LiveExtraBetsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new LiveMatchExtraBets();
  }
  public getTypeName() {
    return 'LiveExtraBetsRequest';
  }
}

// @Route("/api/live/sportslist")
export class LiveSportsListRequest implements IReturn<DbLiveSportsList[]> {
  public constructor(init?: Partial<LiveSportsListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbLiveSportsList>();
  }
  public getTypeName() {
    return 'LiveSportsListRequest';
  }
}

// @Route("/api/livecasino/gamelist")
export class LiveCasinoGamesRequest implements IReturn<LiveCasinoGameResponse> {
  public Mobile: boolean;

  public constructor(init?: Partial<LiveCasinoGamesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new LiveCasinoGameResponse();
  }
  public getTypeName() {
    return 'LiveCasinoGamesRequest';
  }
}

// @Route("/api/livecasino/opengame")
export class LiveCasinoOpenGameRequest implements IReturn<DbCasinoOpenResult> {
  public Mobile: boolean;
  public GameId: number;

  public constructor(init?: Partial<LiveCasinoOpenGameRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DbCasinoOpenResult();
  }
  public getTypeName() {
    return 'LiveCasinoOpenGameRequest';
  }
}

// @Route("/api/livecasino/repo/gamelist")
export class LiveCasinoGameListRequest implements IReturn<LiveCasinoGameResponse> {
  public Mobile: boolean;

  public constructor(init?: Partial<LiveCasinoGameListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new LiveCasinoGameResponse();
  }
  public getTypeName() {
    return 'LiveCasinoGameListRequest';
  }
}

// @Route("/api/livecasino/repo/opengame")
export class LiveCasinoOpenGameRepoRequest implements IReturn<DbCasinoOpenResult> {
  public Mobile: boolean;
  public PlayerId: number;
  public GameId: number;

  public constructor(init?: Partial<LiveCasinoOpenGameRepoRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DbCasinoOpenResult();
  }
  public getTypeName() {
    return 'LiveCasinoOpenGameRepoRequest';
  }
}

// @Route("/api/pagoefectivo/depositrequestpagoefectivotransfer")
export class DepositRequestPagoEfectivoTransfer implements IReturn<string> {
  public Currency: string;
  public Amount: number;
  public UserEmail: string;

  public constructor(init?: Partial<DepositRequestPagoEfectivoTransfer>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'DepositRequestPagoEfectivoTransfer';
  }
}

// @Route("/api/pagoefectivocallback")
export class PagoEfectivoCallbackRequest implements IReturn<string> {
  public EventType: string;
  public OperationNumber: string;
  public Data: CallBackData;

  public constructor(init?: Partial<PagoEfectivoCallbackRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'PagoEfectivoCallbackRequest';
  }
}

// @Route("/api/getopenmatchids")
export class GetOpenMatchIdsRequest implements IReturn<number[]> {
  public constructor(init?: Partial<GetOpenMatchIdsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<number>();
  }
  public getTypeName() {
    return 'GetOpenMatchIdsRequest';
  }
}

// @Route("/api/player/promotionhistory")
export class PlayerPromotionHistory implements IReturn<Object[]> {
  public Category: number;
  public Start: number;
  public Limit: number;

  public constructor(init?: Partial<PlayerPromotionHistory>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<Object>();
  }
  public getTypeName() {
    return 'PlayerPromotionHistory';
  }
}

export class PlayerPromotionRequest implements IReturn<Object[]> {
  public PlayerId: number;

  public constructor(init?: Partial<PlayerPromotionRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<Object>();
  }
  public getTypeName() {
    return 'PlayerPromotionRequest';
  }
}

// @Route("/api/player/balance")
export class PlayerBalanceRequest implements IReturn<PlayerBalance> {
  public constructor(init?: Partial<PlayerBalanceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlayerBalance();
  }
  public getTypeName() {
    return 'PlayerBalanceRequest';
  }
}

// @Route("/api/sendmessage")
export class SendMessageRequest implements IReturn<string> {
  public Message: string;

  public constructor(init?: Partial<SendMessageRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'SendMessageRequest';
  }
}

// @Route("/api/admincontactsendmessage")
export class AdminContactSendMessageRequest implements IReturn<string> {
  public Subject: string;
  public Message: string;

  public constructor(init?: Partial<AdminContactSendMessageRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'AdminContactSendMessageRequest';
  }
}

// @Route("/api/getplayercontactincomingmessages")
export class GetPlayerContactIncomingMessagesRequest implements IReturn<DbPlayerContactIncomingMessagesResponse[]> {
  public constructor(init?: Partial<GetPlayerContactIncomingMessagesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbPlayerContactIncomingMessagesResponse>();
  }
  public getTypeName() {
    return 'GetPlayerContactIncomingMessagesRequest';
  }
}

// @Route("/api/getplayercontactsentmessages")
export class GetPlayerContactSentMessagesRequest implements IReturn<DbPlayerContactSentMessagesResponse[]> {
  public constructor(init?: Partial<GetPlayerContactSentMessagesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbPlayerContactSentMessagesResponse>();
  }
  public getTypeName() {
    return 'GetPlayerContactSentMessagesRequest';
  }
}

// @Route("/api/player/promotionbyplayer")
export class PlayerPromotionByPlayerRequest implements IReturn<PlayerPromotionByPlayer> {
  public PlayerId: number;

  public constructor(init?: Partial<PlayerPromotionByPlayerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlayerPromotionByPlayer();
  }
  public getTypeName() {
    return 'PlayerPromotionByPlayerRequest';
  }
}

// @Route("/api/player/deactivate")
export class PlayerDeactivateRequest implements IReturn<string> {
  public constructor(init?: Partial<PlayerDeactivateRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'PlayerDeactivateRequest';
  }
}

// @Route("/api/player/playerinfolist")
export class PlayerInfoListRequest implements IReturn<PlayerInfoList[]> {
  public constructor(init?: Partial<PlayerInfoListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<PlayerInfoList>();
  }
  public getTypeName() {
    return 'PlayerInfoListRequest';
  }
}

// @Route("/api/player2falogin")
export class Player2FaLoginRequest implements IReturn<boolean> {
  public AccessCode: string;
  public PlayerId: number;
  public PSK: string;

  public constructor(init?: Partial<Player2FaLoginRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'Player2FaLoginRequest';
  }
}

// @Route("/api/check2falogin")
export class CheckTwoFactorLoginRequest implements IReturn<CheckLoginTwoFactorResponse> {
  public Username: string;

  public constructor(init?: Partial<CheckTwoFactorLoginRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new CheckLoginTwoFactorResponse();
  }
  public getTypeName() {
    return 'CheckTwoFactorLoginRequest';
  }
}

// @Route("/api/twofactorenable")
export class TwoFactorEnableRequest implements IReturn<string> {
  public AccessCode: string;
  public PlayerId: number;
  public PSK: string;

  public constructor(init?: Partial<TwoFactorEnableRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'TwoFactorEnableRequest';
  }
}

// @Route("/api/generatepsk")
export class GeneratePskRequest implements IReturn<GenratePskResponse> {
  public PlayerId: number;

  public constructor(init?: Partial<GeneratePskRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GenratePskResponse();
  }
  public getTypeName() {
    return 'GeneratePskRequest';
  }
}

// @Route("/api/player/group")
export class PlayerGroupRequest implements IReturn<PlayerGroup> {
  public constructor(init?: Partial<PlayerGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlayerGroup();
  }
  public getTypeName() {
    return 'PlayerGroupRequest';
  }
}

// @Route("/api/prematch/menu")
export class PrematchMenuRequest implements IReturn<PreMatchMenu> {
  public Interval: number;

  public constructor(init?: Partial<PrematchMenuRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PreMatchMenu();
  }
  public getTypeName() {
    return 'PrematchMenuRequest';
  }
}

// @Route("/api/prematch/tournament")
export class PrematchTournamentRequest implements IReturn<Tournament> {
  public TournamentId: number;
  public Interval: number;
  public Mobile: boolean;
  public MarketId: number;

  public constructor(init?: Partial<PrematchTournamentRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Tournament();
  }
  public getTypeName() {
    return 'PrematchTournamentRequest';
  }
}

// @Route("/api/prematch/outright/tournament")
export class PrematchoutrightTournamentRequest implements IReturn<DbOutrighTournamentResponse[]> {
  public TournamentId: number;

  public constructor(init?: Partial<PrematchoutrightTournamentRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbOutrighTournamentResponse>();
  }
  public getTypeName() {
    return 'PrematchoutrightTournamentRequest';
  }
}

// @Route("/api/prematch/top_played")
export class PrematchTopPlayedRequest implements IReturn<Tournament> {
  public Mobile: boolean;
  public MarketId: number;

  public constructor(init?: Partial<PrematchTopPlayedRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Tournament();
  }
  public getTypeName() {
    return 'PrematchTopPlayedRequest';
  }
}

// @Route("/api/prematch/nextmatchesbyinterval")
export class PrematchNextMatchesIntervalRequest implements IReturn<ListMatch> {
  public SportId: number;
  public Interval: number;
  public Mobile: boolean;

  public constructor(init?: Partial<PrematchNextMatchesIntervalRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ListMatch();
  }
  public getTypeName() {
    return 'PrematchNextMatchesIntervalRequest';
  }
}

// @Route("/api/prematch/nextmatches")
export class PrematchNextMatchesRequest implements IReturn<ListMatch> {
  public SportId: number;
  public Count: number;
  public Mobile: boolean;
  public MarketId: number;
  public NewMobile: boolean;

  public constructor(init?: Partial<PrematchNextMatchesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ListMatch();
  }
  public getTypeName() {
    return 'PrematchNextMatchesRequest';
  }
}

// @Route("/api/prematch/outrightdetail")
export class PrematchOutrightDetailRequest implements IReturn<OutrightMatch> {
  public MatchId: number;

  public constructor(init?: Partial<PrematchOutrightDetailRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new OutrightMatch();
  }
  public getTypeName() {
    return 'PrematchOutrightDetailRequest';
  }
}

// @Route("/api/gethighlightmatches")
export class GetHighlightMatchesRequest {
  public SiteId: number;
  public TournamentId: number;

  public constructor(init?: Partial<GetHighlightMatchesRequest>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/prematch/matchdetail")
export class PrematchMatchDetailRequest implements IReturn<TournamentMatch> {
  public MatchId: number;
  public Mobile: boolean;

  public constructor(init?: Partial<PrematchMatchDetailRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TournamentMatch();
  }
  public getTypeName() {
    return 'PrematchMatchDetailRequest';
  }
}

// @Route("/api/Prematch/SingleMatch")
export class PrematchSingleMatchRequest implements IReturn<Tournament> {
  public MatchId: number;
  public Mobile: boolean;
  public MarketId: number;

  public constructor(init?: Partial<PrematchSingleMatchRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Tournament();
  }
  public getTypeName() {
    return 'PrematchSingleMatchRequest';
  }
}

// @Route("/api/mobilemenu")
export class FrontendMobileMenuRequest implements IReturn<MobileMenuResponse[]> {
  public Type: string;
  public Id: number;
  public Interval: number;

  public constructor(init?: Partial<FrontendMobileMenuRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MobileMenuResponse>();
  }
  public getTypeName() {
    return 'FrontendMobileMenuRequest';
  }
}

// @Route("/api/matchsearch")
export class FrontendMatchSearchRequest implements IReturn<MatchSearch[]> {
  public Search: string;

  public constructor(init?: Partial<FrontendMatchSearchRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MatchSearch>();
  }
  public getTypeName() {
    return 'FrontendMatchSearchRequest';
  }
}

// @Route("/api/luckywinnersoftheweek")
export class LuckyWinnersOfTheWeekRequest implements IReturn<LuckWinnersOftheWeek[]> {
  public constructor(init?: Partial<LuckyWinnersOfTheWeekRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<LuckWinnersOftheWeek>();
  }
  public getTypeName() {
    return 'LuckyWinnersOfTheWeekRequest';
  }
}

// @Route("/api/topwinners")
export class TopWinnersRequest implements IReturn<TopAndLastWinners[]> {
  public constructor(init?: Partial<TopWinnersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<TopAndLastWinners>();
  }
  public getTypeName() {
    return 'TopWinnersRequest';
  }
}

// @Route("/api/lastwinners")
export class LastWinnersRequest implements IReturn<TopAndLastWinners[]> {
  public constructor(init?: Partial<LastWinnersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<TopAndLastWinners>();
  }
  public getTypeName() {
    return 'LastWinnersRequest';
  }
}

// @Route("/api/favoritetournaments")
export class SiteFavoriteTournamentsRequest implements IReturn<SiteFavoriteTournaments[]> {
  public constructor(init?: Partial<SiteFavoriteTournamentsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<SiteFavoriteTournaments>();
  }
  public getTypeName() {
    return 'SiteFavoriteTournamentsRequest';
  }
}

// @Route("/api/prematch/todaymatches")
export class PrematchTodaysMatchesRequest implements IReturn<ListMatchTodaysMatch> {
  public SportId: number;
  public Count: number;
  public Mobile: boolean;
  public MarketId: number;

  public constructor(init?: Partial<PrematchTodaysMatchesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ListMatchTodaysMatch();
  }
  public getTypeName() {
    return 'PrematchTodaysMatchesRequest';
  }
}

// @Route("/api/rocket/depositrequestrockettransfer")
export class DepositRequestRocketTransfer implements IReturn<string> {
  public BankId: number;
  public Amount: number;

  public constructor(init?: Partial<DepositRequestRocketTransfer>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'DepositRequestRocketTransfer';
  }
}

// @Route("/api/rocket/withdrawrequestrockettransfer")
export class RocketWithdrawTransferRequest {
  public BankId: number;
  public Amount: number;
  public Iban: string;
  public IdentityNumber: string;
  public NoteText: string;

  public constructor(init?: Partial<RocketWithdrawTransferRequest>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/rocket/getbanks")
export class RocketBankListRequest implements IReturn<RocketBank[]> {
  public Limit: number;
  public Offset: number;

  public constructor(init?: Partial<RocketBankListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<RocketBank>();
  }
  public getTypeName() {
    return 'RocketBankListRequest';
  }
}

// @Route("/api/rocketcallback")
// @DataContract
export class RocketCallbackRequest implements IReturn<string> {
  // @DataMember(Name="process_id")
  public ProcessId: number;

  // @DataMember(Name="process_type")
  public ProcessType: string;

  // @DataMember(Name="process_user_id")
  public ProcessUserId: string;

  // @DataMember(Name="amount")
  public Amount: number;

  // @DataMember(Name="status")
  public Status: number;

  public constructor(init?: Partial<RocketCallbackRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'RocketCallbackRequest';
  }
}

// @Route("/api/slot/brandlist")
export class SlotBrandListRequest implements IReturn<IdName[]> {
  public GameType: string;

  public constructor(init?: Partial<SlotBrandListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<IdName>();
  }
  public getTypeName() {
    return 'SlotBrandListRequest';
  }
}
// @Route("/api/slot/brandlist")
export class SlotBrandListRequestV2 implements IReturn<IdName[]> {
  public GameType: string;
  public GameName: string;
  public CategoryId: number;

  public constructor(init?: Partial<SlotBrandListRequestV2>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<IdName>();
  }
  public getTypeName() {
    return 'SlotBrandListRequestV2';
  }
}

// @Route("/api/slot/gamelist")
export class SlotGameListRequest implements IReturn<SlotGameList[]> {
  public BrandId: number | null;
  public Mobile: boolean | null;
  public GameType: string;
  public LiveCasinoCategory: string;
  public CasinoCategory: number;

  public constructor(init?: Partial<SlotGameListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<SlotGameList>();
  }
  public getTypeName() {
    return 'SlotGameListRequest';
  }
}

export class GamesByOrderingList {
  public BrandId: number;
  public BrandName: string;
  public FriendlyName: string;
  public SvgPath: string;
  public LineHeight: number;
  public Games: {
    GameId: number;
    GameImage: string;
    GameType: string;
    GameCustomImage: string;
    GameUpdatedDate: string;
    GameActive: boolean;
    GameUserId: number;
    GameHomePageActive: boolean;
    BrandHomePageOrdering: number;
    GameHomePage: boolean;
    GameHitCount: number;
    GameHomePageOrdering: number;
    GameName: string;
  }[];
}

export class GamesByOrderingRequest {
  public Mobile: boolean;
  public lang?: string;
  public siteId?: string;
  public sessionid?: string;
}

// @Route("/api/slot/gamelist")
export class SlotGameListRequestV2 implements IReturn<SlotGameList[]> {
  public BrandId: string;
  public Mobile: boolean | null;
  public GameType: string;
  public LiveCasinoCategory: string;
  public CasinoCategory: number;
  public GameName: string;
  public Page: number;
  public constructor(init?: Partial<SlotGameListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<SlotGameList>();
  }
  public getTypeName() {
    return 'SlotGameListRequest';
  }
}

// @Route("/api/slot/populargamelist")
export class SlotPopularGameListRequest implements IReturn<SlotGameList[]> {
  public Mobile: boolean;

  public constructor(init?: Partial<SlotPopularGameListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<SlotGameList>();
  }
  public getTypeName() {
    return 'SlotPopularGameListRequest';
  }
}

// @Route("/api/slot/opengame")
export class SlotOpenGameRequest implements IReturn<CsProOpenGameResponse> {
  public Mobile: boolean;
  public GameId: number;
  public ReturnUrl: string;
  public IsDemo: boolean;

  public UseBonusBalance: boolean;
  public constructor(init?: Partial<SlotOpenGameRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new CsProOpenGameResponse();
  }
  public getTypeName() {
    return 'SlotOpenGameRequest';
  }
}

// @Route("/api/slot/brandlist")
export class SlotRepoBrandListRequest implements IReturn<Object[]> {
  public GameType: string;

  public constructor(init?: Partial<SlotRepoBrandListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<Object>();
  }
  public getTypeName() {
    return 'SlotRepoBrandListRequest';
  }
}

// @Route("/api/slot/repo/gamelist")
export class SlotRepoGameListRequest implements IReturn<SlotGameList[]> {
  public BrandId: number;
  public Mobile: boolean;
  public GameType: string;

  public constructor(init?: Partial<SlotRepoGameListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<SlotGameList>();
  }
  public getTypeName() {
    return 'SlotRepoGameListRequest';
  }
}

// @Route("/api/slot/repo/gamelist")
export class SlotRepoGameListRequestV2 implements IReturn<SlotGameList[]> {
  public BrandId: string;
  public Mobile: boolean;
  public GameType: string;
  public GameName: string;

  public constructor(init?: Partial<SlotRepoGameListRequestV2>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<SlotGameList>();
  }
  public getTypeName() {
    return 'SlotRepoGameListRequestV2';
  }
}

// @Route("/api/slot/repo/populargamelist")
export class SlotRepoPopularGameListRequest implements IReturn<SlotGameList[]> {
  public Mobile: boolean;

  public constructor(init?: Partial<SlotRepoPopularGameListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<SlotGameList>();
  }
  public getTypeName() {
    return 'SlotRepoPopularGameListRequest';
  }
}

// @Route("/api/slot/repo/opengame")
export class SlotRepoOpenGameRequest implements IReturn<DbCasinoOpenResult> {
  public Mobile: boolean;
  public PlayerId: number;
  public GameId: number;

  public constructor(init?: Partial<SlotRepoOpenGameRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DbCasinoOpenResult();
  }
  public getTypeName() {
    return 'SlotRepoOpenGameRequest';
  }
}

// @Route("/api/sofort/depositrequestsoforttransfer")
export class SofortGetPaymentUrlRequest implements IReturn<string> {
  public Amount: number;

  public constructor(init?: Partial<SofortGetPaymentUrlRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'SofortGetPaymentUrlRequest';
  }
}

// @Route("/api/sofort/statusnotification_success")
export class SofortStatusNotificationRequest implements IReturn<string> {
  public Transaction: string;
  public Time: string;

  public constructor(init?: Partial<SofortStatusNotificationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'SofortStatusNotificationRequest';
  }
}

// @Route("/api/sofort/transactiondata")
export class SofortTransactionDataRequest implements IReturn<TransactionDataResponse> {
  public Transaction: string;

  public constructor(init?: Partial<SofortTransactionDataRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TransactionDataResponse();
  }
  public getTypeName() {
    return 'SofortTransactionDataRequest';
  }
}

// @Route("/api/player/ticketsall")
export class PlayerTicketsAllRequest implements IReturn<PlayerTicketHeaders> {
  public StartDate: string;
  public EndDate: string;
  public Start: number;
  public Limit: number;

  public constructor(init?: Partial<PlayerTicketsAllRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlayerTicketHeaders();
  }
  public getTypeName() {
    return 'PlayerTicketsAllRequest';
  }
}

// @Route("/api/ticket/cashoutoffer")
export class CashoutOfferRequest implements IReturn<CashoutOffer> {
  public TicketId: number;

  public constructor(init?: Partial<CashoutOfferRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new CashoutOffer();
  }
  public getTypeName() {
    return 'CashoutOfferRequest';
  }
}

// @Route("/api/luckywinnerticketdetail")
export class LuckyWinnerTicketDetailRequest implements IReturn<PlayerTicketHeader> {
  public TicketId: number;

  public constructor(init?: Partial<LuckyWinnerTicketDetailRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlayerTicketHeader();
  }
  public getTypeName() {
    return 'LuckyWinnerTicketDetailRequest';
  }
}

// @Route("/api/ticket/cashout")
export class CashoutRequest implements IReturn<string> {
  public TicketId: number;
  public Prize: number;

  public constructor(init?: Partial<CashoutRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'CashoutRequest';
  }
}

// @Route("/api/ticket/cancelticket")
export class CancelTicketRequest implements IReturn<string> {
  public TicketId: number;

  public constructor(init?: Partial<CancelTicketRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'CancelTicketRequest';
  }
}

// @Route("/api/ticket/openticketmatches")
export class OpenTicketMatchesRequest implements IReturn<number[]> {
  public constructor(init?: Partial<OpenTicketMatchesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<number>();
  }
  public getTypeName() {
    return 'OpenTicketMatchesRequest';
  }
}

// @Route("/api/player/ticketsopen")
export class PlayerTicketsOpenRequest implements IReturn<PlayerTicketHeaders> {
  public StartDate: string;
  public EndDate: string;
  public Start: number;
  public Limit: number;

  public constructor(init?: Partial<PlayerTicketsOpenRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlayerTicketHeaders();
  }
  public getTypeName() {
    return 'PlayerTicketsOpenRequest';
  }
}

// @Route("/api/player/ticketsclosed")
export class PlayerTicketsClosedRequest implements IReturn<PlayerTicketHeaders> {
  public StartDate: string;
  public EndDate: string;
  public Start: number;
  public Limit: number;

  public constructor(init?: Partial<PlayerTicketsClosedRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlayerTicketHeaders();
  }
  public getTypeName() {
    return 'PlayerTicketsClosedRequest';
  }
}

// @Route("/api/player/lastticketsmatches")
export class PlayerLastTicketsMatchesRequest implements IReturn<number[]> {
  public constructor(init?: Partial<PlayerLastTicketsMatchesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<number>();
  }
  public getTypeName() {
    return 'PlayerLastTicketsMatchesRequest';
  }
}

// @Route("/api/player/transactionhistorylist")
export class PlayerTransactionHistoryListRequest implements IReturn<PlayerTransactionHistoryListResponse[]> {
  public StartDate: string;
  public EndDate: string;
  public Start: number;
  public Limit: number;
  public Types: TvpNumberType[];

  public constructor(init?: Partial<PlayerTransactionHistoryListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<PlayerTransactionHistoryListResponse>();
  }
  public getTypeName() {
    return 'PlayerTransactionHistoryListRequest';
  }
}

// @Route("/api/player/cancelticket")
export class PlayerCancelTicketRequest implements IReturn<string> {
  public TicketId: number;

  public constructor(init?: Partial<PlayerCancelTicketRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'PlayerCancelTicketRequest';
  }
}

// @Route("/api/player/ticketdetail")
export class PlayerTicketDetailRequest implements IReturn<PlayerTicketHeader> {
  public TicketId: number;

  public constructor(init?: Partial<PlayerTicketDetailRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PlayerTicketHeader();
  }
  public getTypeName() {
    return 'PlayerTicketDetailRequest';
  }
}

// @Route("/api/tombala/checkactive")
export class CheckTombalaActiveRequest implements IReturn<boolean> {
  public constructor(init?: Partial<CheckTombalaActiveRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'CheckTombalaActiveRequest';
  }
}

// @Route("/api/translation.js")
export class TranslationRequest implements IReturn<string> {
  public Lang: string;
  public SiteId: number;

  public constructor(init?: Partial<TranslationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'TranslationRequest';
  }
}

// @Route("/api/tvbet/{SiteId}/GetUserData")
// @DataContract
export class TvBetUserDataRequest extends TvBetGetUserDataRequest implements IReturn<TvBetUserInfoResult> {
  public constructor(init?: Partial<TvBetUserDataRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TvBetUserInfoResult();
  }
  public getTypeName() {
    return 'TvBetUserDataRequest';
  }
}

// @Route("/api/gettvbetgameurl")
export class TvBetGameUrl implements IReturn<string> {
  public constructor(init?: Partial<TvBetGameUrl>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'TvBetGameUrl';
  }
}

// @Route("/api/tvbet/{SiteId}/GetPaymentInfo")
// @DataContract
export class TvBetPaymentInfoRequest extends TvBetGetPaymentInfoRequest implements IReturn<TvBetTransactionInfoResult> {
  public constructor(init?: Partial<TvBetPaymentInfoRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TvBetTransactionInfoResult();
  }
  public getTypeName() {
    return 'TvBetPaymentInfoRequest';
  }
}

// @Route("/api/VivoGaming/Authenticate")
export class VivoGamingAuthenticateRequest implements IReturn<string> {
  public Token: string;
  public Hash: string;

  public constructor(init?: Partial<VivoGamingAuthenticateRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'VivoGamingAuthenticateRequest';
  }
}

// @Route("/api/VivoGaming/ChangeBalance")
export class VivoGamingChangeBalanceRequest implements IReturn<string> {
  public UserId: number;
  public Amount: number;
  public TransactionId: number;
  public TrnType: string;
  public TrnDescription: string;
  public RoundId: string;
  public GameId: string;
  public History: string;
  public IsRoundFinished: boolean;
  public Hash: string;

  public constructor(init?: Partial<VivoGamingChangeBalanceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'VivoGamingChangeBalanceRequest';
  }
}

// @Route("/api/VivoGaming/Status")
export class VivoGamingStatusRequest implements IReturn<string> {
  public UserId: number;
  public CasinoTransactionId: number;
  public Hash: string;

  public constructor(init?: Partial<VivoGamingStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'VivoGamingStatusRequest';
  }
}

// @Route("/api/VivoGaming/GetBalance")
export class VivoGamingGetBalanceRequest implements IReturn<string> {
  public UserId: number;
  public Hash: string;

  public constructor(init?: Partial<VivoGamingGetBalanceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'VivoGamingGetBalanceRequest';
  }
}

// @Route("/api/wirecapital/depositrequest")
export class WireCapitalDepositRequest implements IReturn<WireCapitalDepositResponse> {
  public Amount: number;
  public NoteText: string;

  public constructor(init?: Partial<WireCapitalDepositRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new WireCapitalDepositResponse();
  }
  public getTypeName() {
    return 'WireCapitalDepositRequest';
  }
}

export class AccountingPlayerIPHistoryResponse {
  public SessionStart: string;
  public PlayerIpAddress: string;
  public OsFamily: string;
  public DeviceFamily: string;
  public BrowserFamily: string;
  public Total: number;

  public constructor(init?: Partial<AccountingPlayerIPHistoryResponse>) {
    (Object as any).assign(this, init);
  }
}

export class SocialMediaResponse {
  public Id: number;
  public DefaultName: string;
  public CssClass: string;
  public Url: string;
  public SocialMediaId: number;

  public constructor(init?: Partial<SocialMediaResponse>) {
    (Object as any).assign(this, init);
  }
}

export class AccountingPlayerIPHistoryRequest implements IReturn<AccountingPlayerIPHistoryResponse> {
  public Start: number;
  public Limit: number;

  public constructor(init?: Partial<AccountingPlayerIPHistoryRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AccountingPlayerIPHistoryResponse();
  }
  public getTypeName() {
    return 'WireCapitalDepositRequest';
  }
}

export interface AnindaResponse {
  success: boolean;
  message: [];
  link: string;
}

export interface NotificationResponse {
  Id: number;
  Content: string;
  Title: string;
}

export class GetPlayerContactIncomingMessagesRequest_v2
  implements IReturn<DbPlayerContactIncomingMessagesResponse_v2[]>
{
  public constructor(init?: Partial<GetPlayerContactIncomingMessagesRequest_v2>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DbPlayerContactIncomingMessagesResponse_v2>();
  }
  public getTypeName() {
    return 'GetPlayerContactIncomingMessagesRequest_v2';
  }
}

export class DbPlayerContactIncomingMessagesResponse_v2 {
  public Id: number;
  public TextContent: string;
  public IsNew: Boolean;
  public NotificationType: number;

  public constructor(init?: Partial<DbPlayerContactIncomingMessagesResponse_v2>) {
    (Object as any).assign(this, init);
  }
}

export class GetAvailableDepositsResponse {
  public Amount: number;
  public TransactionDate: Date;
  public Id: number;

  public constructor(init?: Partial<GetAvailableDepositsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/api/getavailabledeposits")
export class GetAvailableDepositsRequest {
  public PromotionId: number;

  public constructor(init?: Partial<GetAvailableDepositsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetAvailableDepositsResponse();
  }
  public getTypeName() {
    return 'GetAvailableDepositsRequest';
  }
}
