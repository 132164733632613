import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators';
import { AppConfigService } from '../config/app-config.service';
import { StorageService } from '../services/Storage.service';
import { ToastrService } from 'ngx-toastr';

/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 */

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private Config: AppConfigService,
    private localStorageService: StorageService,
    private Notify: ToastrService
  ) {}

  /**
   * Intercepts an outgoing HTTP request, executes it and handles any error that could be triggered in execution.
   * @see HttpInterceptor
   * @param req the outgoing HTTP request
   * @param next a HTTP request handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error, caught) => {
        if (error.status === 429) {
          this.Notify.warning(error.error.Message);
        }
        if (error.status === 401) {
          this.Config.LoginStatus.next(false);
          this.localStorageService.remove('user');
          setTimeout(() => {
            window.location.href = '/';
          }, 200);
        }
        if (error.status === 500) {
          this.Notify.error(error.error.Message, 'Error');
        }
        if (error.status === 451) {
          this.Config.Loader.next(false);
          if (window.location.pathname !== '/country-blocked') {
            window.location.href = '/country-blocked';
          }
          return of(error.message);
        }
        this.handleAuthError(error);
        return of(error);
      }) as any
    );
  }

  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      // navigate /delete cookies or whatever
      // logOut();
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message);
    }
    return of(err);
  }
}

/**
 * Provider POJO for the interceptor
 */
export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
