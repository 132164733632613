import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SettingsComponent } from './settings/settings.component';
import { HeaderNotificationComponent } from './header-notification/header-notification.component';
import { HeaderUserComponent } from './header-user/header-user.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { RegisterModalComponent } from './register-modal/register-modal.component';
import { ResetModalComponent } from './reset-modal/reset-modal.component';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { CallMeModalComponent } from './call-me-modal/call-me-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';

import { RouterModule } from '@angular/router';
import { PipesModule } from '../helpers/pipes/pipes.module';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LiveCasinoGameComponent } from './live-casino-game/live-casino-game.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MomentModule } from 'ngx-moment';

import { GameContainerComponent } from './game-container/game-container.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SocketIoModule } from 'ngx-socket-io';
import { UpdateCheckComponent } from './update-check/update-check.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { LiveCasinoGameV2Component } from './live-casino-game-v2/live-casino-game-v2.component';
import { GameFilterV2Component } from './game-filter-v2/game-filter-v2.component';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { JackpotComponent } from './jackpot/jackpot.component';
import { LangLinksComponent } from './lang-links/lang-links.component';

import { HomeModule } from '../layouts/home/home.module';
import { LanguagesModule } from './languages/languages.module';
import { GameFilterModule } from './game-filter/game-filter.module';

import { CasinoWinnerComponent } from './casino-winner/casino-winner.component';
import { BottomNavbarComponent } from './bottom-navbar/bottom-navbar.component';
import { UserInfoModalComponent } from './user-info-modal/user-info-modal.component';
import { MaintenanceModeModule } from './maintenance-mode/maintenance-mode.module';

import { SkeletonMainComponent } from './skeleton-main/skeleton-main.component';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { GameErrorModule } from './game-error/game-error.module';

import { NgxCaptchaModule } from 'ngx-captcha';

import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { defineLocale, trLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgFloatingActionMenuModule } from 'ng-floating-action-menu';
import { AngularDeviceInformationService } from 'angular-device-information';

import { UsePromoKeyModalComponent } from './use-promo-key-modal/use-promo-key-modal.component';
import { HorizontanPromotionListComponent } from './horizontal-promotion-list/horizontal-promotion-list.component';
import { NgxSlickJsModule } from 'ngx-slickjs';

import { FeaturedGameAreaComponent } from './featured-game-area/featured-game-area.component';
import { IframeListenerComponent } from './game-event-listener/game-event-listener.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ConfirmationModalComponent } from './confirm-modal/confirm-modal.component';
import { ConfirmationModalService } from '../services/confirm-modal.service';
import { BannerModule } from './banner/banner.module';
import { SharedModule } from '../shared/shared.module';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { enable: true, direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SettingsComponent,
    // HeaderNotificationComponent,
    HeaderUserComponent,
    LoginModalComponent,
    RegisterModalComponent,
    ResetModalComponent,
    FeedbackModalComponent,
    CallMeModalComponent,
    GameContainerComponent,
    GameFilterV2Component,
    UpdateCheckComponent,
    NotificationModalComponent,
    LiveCasinoGameV2Component,
    JackpotComponent,
    LangLinksComponent,
    CasinoWinnerComponent,
    BottomNavbarComponent,
    UserInfoModalComponent,
    UsePromoKeyModalComponent,
    HorizontanPromotionListComponent,
    SkeletonMainComponent,
    FeaturedGameAreaComponent,
    IframeListenerComponent,
    ConfirmationModalComponent,
  ],
  imports: [
    GameFilterModule,
    HomeModule,
    CommonModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    RouterModule,
    HammerModule,
    PipesModule,
    LanguagesModule,
    LazyLoadImageModule,
    NgxSlickJsModule.forRoot({
      links: {
        jquery: 'https://code.jquery.com/jquery-3.4.0.min.js',
        slickJs: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js',
        slickCss: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css',
        slickThemeCss: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css',
      },
    }),
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    MomentModule,
    TooltipModule.forRoot(),
    SocketIoModule,
    MaintenanceModeModule,
    NgxCaptchaModule,
    PasswordStrengthMeterModule,
    NgxIntlTelInputModule,
    NgFloatingActionMenuModule,
    GameErrorModule,
    AngularSvgIconModule.forRoot(),
    BannerModule,
    SharedModule,
  ],
  exports: [
    LoaderComponent,
    FooterComponent,
    HeaderComponent,
    LoginModalComponent,
    RegisterModalComponent,
    ResetModalComponent,
    FeedbackModalComponent,
    CallMeModalComponent,
    SharedModule,
    GameContainerComponent,
    LiveCasinoGameV2Component,
    UpdateCheckComponent,
    JackpotComponent,
    NotificationModalComponent,
    GameFilterV2Component,
    CasinoWinnerComponent,
    BottomNavbarComponent,
    UserInfoModalComponent,
    UsePromoKeyModalComponent,
    HorizontanPromotionListComponent,
    SkeletonMainComponent,
    FeaturedGameAreaComponent,
    IframeListenerComponent,
    // PlayerBonusListComponent,
    // HeaderNotificationComponent,
  ],
  providers: [
    AngularDeviceInformationService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    ConfirmationModalService,
  ],
})
export class ComponentsModule {
  constructor(localeService: BsLocaleService) {
    defineLocale('tr', trLocale);
    localeService.use('tr');
  }
}
