import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { Config } from '../config';
import { StorageService } from './Storage.service';
import { TranslateService } from './Translate.service';

@Injectable()
export class BaseService {
  headers: HttpHeaders;
  userSession: any;
  constructor(
    private http: HttpClient,
    private localStorageService: StorageService,
    private translate: TranslateService
  ) {
    this.http = http;
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      Accept: '*/*',
      RequestUrl: window.location.pathname,
    });
    this.headers = headers;
  }

  addQueryParams(url: string): string {
    let data = {
      lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : this.translate.getBrowserLang(),
      siteId: Config.settings.ClientId.toString(),
      sessionid: this.localStorageService.get('user') ? this.localStorageService.get('user').SessionId : '',
    };
    let param = '';
    for (const key in data) {
      if ((url + param).indexOf('?') == -1) {
        param += `?${key}=${encodeURI(data[key])}`;
      } else {
        param += `&${key}=${encodeURI(data[key])}`;
      }
    }

    return url + param;
  }

  get<T>(url: string, request: any): Observable<T> {
    let param = '';
    for (const key in request) {
      if (typeof request[key] === 'function') continue;
      if (typeof request[key] === 'undefined') continue;
      if (typeof request[key] === 'object') {
        let data = JSON.stringify(request[key]);
        if (data !== 'undefined') {
          if (param.length == 0) {
            param += `?${key}=${encodeURI(JSON.stringify(request[key]))}`;
          } else {
            param += `&${key}=${encodeURI(JSON.stringify(request[key]))}`;
          }
        }
      } else {
        if (param.length == 0) {
          param += `?${key}=${encodeURI(request[key])}`;
        } else {
          param += `&${key}=${encodeURI(request[key])}`;
        }
      }
    }
    return this.http.get<T>(this.addQueryParams(url + param), { headers: this.headers });
  }
  getBySiteApi<T>(url: string, request: any): Observable<T> {
    let param = '';
    for (const key in request) {
      if (typeof request[key] === 'function') continue;
      if (typeof request[key] === 'undefined') continue;
      if (typeof request[key] === 'object') {
        let data = JSON.stringify(request[key]);
        if (data !== 'undefined') {
          if (param.length == 0) {
            param += `?${key}=${encodeURI(JSON.stringify(request[key]))}`;
          } else {
            param += `&${key}=${encodeURI(JSON.stringify(request[key]))}`;
          }
        }
      } else {
        if (param.length == 0) {
          param += `?${key}=${encodeURI(request[key])}`;
        } else {
          param += `&${key}=${encodeURI(request[key])}`;
        }
      }
    }
    return this.http.get<T>(this.addQueryParams(Config.settings.Endpoints.Api + url + param), {
      headers: this.headers,
    });
  }

  post<T>(url: string, request: any): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Accept: '*/*',
      RequestUrl: window.location.pathname,
    });
    this.headers = headers;
    let data = this.http.post<T>(this.addQueryParams(Config.settings.Endpoints.Api + url), request, {
      headers: this.headers,
    });
    return data;
  }

  sportsbookPost<T>(url: string, request: any): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',
      lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : this.translate.getBrowserLang().toString(),
      siteid: '90',
      timezone: '-180',
      sessionid: '',
    });
    let data = this.http.post<T>(url, request, { headers: headers });
    return data;
  }
}
