<div
  class="modal"
  id="exampleModalCenter"
  [class.show]="show"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-fullscreen-lg-down modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="update-alert">
        <i class="fa-thin fa-shield-check"></i>
        <h5>{{ 'alert.update.title' | translate }}</h5>
        <p>
          {{ 'alert.update.desc' | translate }}
          <br />
          <span class="sc">
            <strong>{{ 'alert.update.text' | translate }}</strong>
          </span>
        </p>
        <button class="btn btn-success btn-block mt-2" (click)="reload()">{{ 'btn.reload' | translate }}</button>
        <button *ngIf="router.url.match('game/')" type="button" class="btn btn-later mt-2" (click)="closeModal()">
          {{ 'alert.btn.later' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
