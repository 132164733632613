import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { AppConfigService } from 'src/app/config/app-config.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ display: 'block' }), animate('300ms ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('300ms ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LoaderComponent implements OnInit {
  ShowLoader: boolean = false;

  constructor(private ConfigService: AppConfigService) {}

  ngOnInit(): void {
    this.ConfigService.AppLoader.subscribe((Status) => {
      this.ShowLoader = Status;
    });
  }
}
