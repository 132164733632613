<div class="dropdown bw-custom" dropdown #dropdown="bs-dropdown">
  <a
    class="btn btn-noti m-hide py-2"
    dropdownToggle
    href="javascript:void(0)"
    role="button"
    id="bwuser"
    data-bs-toggle="dropdown"
    aria-expanded="false">
    <i class="bw-i-notification mx-1"></i>
  </a>
  <div
    *dropdownMenu
    class="dropdown-menu dropdown-menu-right dropdown-menu-end dropdown-notify"
    aria-labelledby="bwuser"
    data-bs-spy="scroll"
    data-bs-smooth-scroll="true"
    tabindex="0"
    role="menu">
    <ul>
      <li class="">
        <a class="" href="javascript:void(0)">
          <div class="d-flex justify-content-between w-100 p-2">
            <div class="column">
              <small>{{ 'btn.messages' | translate }} ({{ NotifyCount }})</small>
            </div>
            <div class="column">
              <a routerLink="/account/info/messages" class="btn-more">
                <small>{{ 'common.seeall' | translate }}</small>
              </a>
            </div>
          </div>
        </a>
      </li>
      <!--      <li >
        <div href="javascript:void(0)" class="bw-notify">
          <div class="content">
            <strong><i class="bw-i-promotions me-2"></i>Hesabınıza [Amount] tutarda bonus tanımlandı.</strong>
            <p>[Provider], [GameName] oyununda her biri 1 TRY tutarında 20 adet spin turu hesabınıza tanımlandı. </p>
          </div>
          <div class="action">
            <a href="#" class="btn btn-base">Oyunlara Git</a>
            <a href="javascript:void(0)" class="btn btn-base">Devamını Oku</a>
          </div>
        </div>
      </li>-->
      <li *ngIf="!Messages.length">
        <div href="javascript:void(0)" class="bw-notify">
          <div class="content">
            <a class="dropdown-item list-item" href="javascript:void(0)">
              <span class="text-center">{{ 'notification.noresult' | translate }}</span>
            </a>
          </div>
        </div>
      </li>
      <li *ngFor="let M of Messages | slice : 0 : 5">
        <div href="javascript:void(0)" class="bw-notify" [class.unread]="M.IsNew">
          <div class="content">
            <strong>
              <i class="bw-i-promotions me-2"></i>
              {{ M.Subject }}
            </strong>
            <p [innerHTML]="truncateText(M.Message)"></p>
          </div>
          <div class="action">
            <a href="javascript:void(0)" (click)="Open(M)" class="btn btn-base">Devamını Oku</a>
          </div>
        </div>
      </li>
      <li *ngIf="!Messages.length">
        <a class="dropdown-item list-item" href="javascript:void(0)">
          <span class="text-center">{{ 'notification.noresult' | translate }}</span>
        </a>
      </li>
    </ul>
  </div>
</div>

<ng-template #Template>
  <div class="modal-header d-flex justify-content-between">
    <div class="start">
      <i class="fa fa-envelope-open me-2"></i>
      {{ ActiveNotification.Subject }}
    </div>
    <div class="end">
      <a href="javascript:void(0)" (click)="ModalRef.hide()" class="btn btn-close">
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
  <div class="modal-body">
    <p [innerHTML]="ActiveNotification.Message"></p>
  </div>
  <div class="modal-footer">
    <a
      href="javascript:void(0)"
      (click)="ModalRef.hide()"
      routerLink="/account/info/messages"
      data-bs-dismiss="modal"
      class="btn btn-base me-1">
      {{ 'btn.showmore' | translate }}
    </a>
    <a href="javascript:void(0)" (click)="ModalRef.hide()" class="btn btn-base" data-bs-dismiss="modal">
      {{ 'btn.close' | translate }}
    </a>
  </div>
</ng-template>
