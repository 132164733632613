<!--<div [ngClass]="isOpen ? 'open' : ''" class="game-toolbar d-none">
  <a class="toolbar-btn" href="javascript:;" (click)="setToolbar()">
    <i class="fas fa-ellipsis-v"></i>
    <i class="fas fa-times"></i>
  </a>
  <div class="arrow">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="gm-toolbar">
    <div class="gm-logo">
      <a class="logo" (click)="CloseGame()"><img src="assets/uploads/brand/logo.png" alt="" /></a>
    </div>
    <div class="gm-buttons">
      <a class="btn btn-base p-2 py-2 me-1 d-none">
        <i class="bw-i-live-chat me-1"></i>
        {{ 'btn.support' | translate }}
      </a>
      <a class="btn btn-base py-2 me-1" href="/account/info/edit">
        <i class="bw-i-user me-md-1"></i>
        <span class="m-hide">{{ 'account.info' | translate }}</span>
      </a>
      <a class="btn btn-base p-2 py-2 me-1" href="/account/info/deposit">
        <i class="fad fa-coin me-1"></i>
        <span>{{ 'btn.deposit' | translate }}</span>
      </a>

      &lt;!&ndash; <a class="tool" (click)="openInNewWindow()" placement="bottom" tooltip="{{ 'casino.button.newwindow' | translate }}"><i class="fad fa-external-link-square "></i></a>&ndash;&gt;
      <a class="btn btn-base p-2 py-2" [href]="'/' + Type + '/' + BrandId">
        <i class="fad fa-home me-1"></i>
        <span>{{ 'casino.button.closegame' | translate }}</span>
      </a>
    </div>
  </div>
</div>-->
<div class="btn btn-game-tool" (click)="toggleHeader()" [ngClass]="{ bg: isHeaderVisible }">
  <i
    [ngClass]="{
      'fa-chevron-up': !isHeaderVisible,
      'fa-chevron-down': isHeaderVisible
    }"
    class="fa-solid"></i>
</div>
<div class="game-frame" *ngIf="!Error" [ngClass]="{ scale: isHeaderVisible }">
  <a
    class="btn-game-close"
    title="{{ 'casino.button.closegame' | translate }}"
    [ngClass]="{ show: isHeaderVisible }"
    [href]="'/' + Type + '/' + BrandId">
    <i class="fa-solid fa-xmark"></i>
  </a>
  <iframe [src]="Iframe" frameborder="0" allow="autoplay;" allow="geolocation" autoplay="" scrolling="yes">
    <app-iframe-listener></app-iframe-listener>
  </iframe>
</div>
<!-- <div
  *ngIf="!showAlert && (IsPermissionError === false || Error === true || IsCurrencyError === true)"
  class="container-wide d-flex justify-content-center">
  <div class="country-block">
    <div class="nub-overlay">
      <div class="nub">
        <img src="assets/uploads/brand/logo.png" alt="" class="brand" />
        <div class="actions">
          <i style="color: yellow" class="fa fa-exclamation-circle" aria-hidden="true"></i>

          <span class="title" *ngIf="IsPermissionError === false">
            {{ 'general.permission.error.header' | translate }}
          </span>
          <span class="title" *ngIf="Error === true">
            {{ 'general.gameload.global.error' | translate }}
          </span>
          <span class="title" *ngIf="IsCurrencyError === true">
            {{ 'general.currency.error' | translate }}
          </span>
          <a [routerLink]="rootSegment" class="btn base mt-btn">{{ 'button.navigate-games' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div> -->
<game-error
  *ngIf="Error"
  [IsPermissionError]="false"
  [IsCurrencyError]="false"
  [IsError]="false"
  [IsOpenGameError]="Error"
  [OpenGameErrorDesc]="OpenGameErrorDesc"></game-error>
<div *ngIf="showAlert" class="custom-game-alert">
  <div class="alert-content pt-5 pb-5">
    <img class="mb-2" src="assets/uploads/brand/loader.gif" alt="" />
    <h5>{{ 'text.game.loading' | translate }}</h5>
    <span>
      {{ 'text.game.loading.top.info' | translate }}
      <br />
      {{ 'text.game.loading.bottom.info' | translate }}
    </span>
  </div>
</div>
