import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/General.service';
import { StorageService } from 'src/app/services/Storage.service';
import { Config } from '../../config';

@Component({
  selector: 'app-jackpot',
  templateUrl: './jackpot.component.html',
  styleUrls: ['./jackpot.component.scss'],
})
export class JackpotComponent implements OnInit {
  @Input() gameStyle: boolean;
  User: any;
  megaMinutes: any;
  constructor(private http: HttpClient, private gs: GeneralService, private StorageService: StorageService) {
    // this.megaMinutes = Array(this.Mega?.Value.substring(8,9)).map((x,i)=>i); // [0,1,2,3,4]
  }

  ngOnInit(): void {
    this.getJackpots();
    this.User = this.StorageService.getUser();

    setInterval(() => {
      this.getJackpots();
    }, 120000);
  }

  jackpots: any;
  Mega: any;
  Grand: any;
  Major: any;
  Minor: any;
  Currency: any;
  getJackpots() {
    var data;
    this.gs.getJackpots(data).subscribe((res) => {
      this.Mega = res.Data.find((x: any) => x.Name == 'Mega');
      this.Grand = res.Data.find((x: any) => x.Name == 'Grand');
      this.Major = res.Data.find((x: any) => x.Name == 'Major');
      this.Minor = res.Data.find((x: any) => x.Name == 'Minor');
      if (this.User) {
        this.Currency = this.User.Currency;
      }
      if (Config.settings.ClientId == 40) {
        this.Currency = `USD`;
      } else {
        this.Currency = `TRY`;
      }
      let exchangeRate = 1;

      switch (this.Currency) {
        case `TRY`:
          exchangeRate = 1;
          break;
        case `USD`:
          this.Mega.Value = this.Mega.Value / 2.25;
          this.Grand.Value = this.Grand.Value / 2.5;
          this.Major.Value = this.Major.Value / 2;
          this.Minor.Value = this.Minor.Value / 1.5;
          break;
        case `EUR`:
          this.Mega.Value = this.Mega.Value / 2.1;
          this.Grand.Value = this.Grand.Value / 2.4;
          this.Major.Value = this.Major.Value / 1.9;
          this.Minor.Value = this.Minor.Value / 1.4;
          break;
        default:
          exchangeRate = 1;
      }
    });
  }
}
