export class PlayerLoginRequest {
  Username: string;
  Password: string;
  Lang: string;
  SiteId: number;
  OS: string;
  Device: string;
  Browser: string;
  DeviceVersion: string;
  BrowserVersion: string;
}
