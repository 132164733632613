<footer class="mt-3">
  <div class="footer-inner">
    <div class="b-container p-3">
      <div class="b-content">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="w-100">
              <app-lang-links></app-lang-links>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="w-100 d-flex justify-content-end flex-wrap">
              <a
                href="https://validator.globalgaminglicense.com/b2c/operators/id{{ 'id.url.license' | translate }}"
                target="_blank"
                class="gamcare p-3 py-1 btn btn-dark me-1 align-items-center"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="License">
                <img
                  src="https://validator.globalgaminglicense.com/assets/img/license/verified-gglicense.png"
                  alt="License Verified"
                  title="License Verified" />
              </a>
              <a class="gamcare p-3 py-1 btn btn-dark me-1 align-items-center" href="">
                <span class="age-restriction">18+</span>
              </a>
              <a
                class="gamcare p-3 py-1 btn btn-dark me-1 align-items-center"
                href="https://www.gamcare.org.uk/"
                target="_new">
                <img class="img-thumbnail" src="https://cdn.bookiewiseapi.com/common/responsible/gamcare.webp" alt="" />
              </a>
              <a
                class="gamcare p-3 py-1 btn btn-dark me-1 align-items-center"
                href="https://www.begambleaware.org/"
                target="_new">
                <img src="https://cdn.bookiewiseapi.com/common/responsible/gambleaware.webp" alt="" />
              </a>
              <a
                class="gamcare p-3 py-1 btn btn-dark align-items-center"
                href="https://www.gamblingtherapy.org/tr/"
                target="_new">
                <img src="https://cdn.bookiewiseapi.com/common/responsible/gambling_therapy.webp" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="b-content">
        <div class="w-100 mt-2">
          <ng-container *ngFor="let C of ContentList">
            <a
              class="btn btn-dark me-1 mb-1"
              style="text-transform: capitalize !important"
              [routerLink]="['/content/', C.Slug, C.Title]"
              routerLinkActive="active">
              {{ C.Title }}
            </a>
          </ng-container>
        </div>
      </div>
      <div class="b-content">
        <div class="footer-providers" data-bs-spy="scroll" data-bs-smooth-scroll="true" tabindex="0">
          <ul>
            <li><i class="prLogo prov13" title="Evolution"></i></li>
            <li><i class="prLogo prov49" title="EGT"></i></li>
            <li><i class="prLogo prov10" title="Microgaming"></i></li>
            <li><i class="prLogo prov11" title="Wazdan"></i></li>
            <li><i class="prLogo prov2" title="YGGDrasil"></i></li>
            <li><i class="prLogo prov3" title="AugustGaming"></i></li>
            <li><i class="prLogo prov14" title="Betgames"></i></li>
            <li><i class="prLogo prov29" title="Netent"></i></li>
            <li><i class="prLogo prov41" title="PragmaticPlay"></i></li>
            <li><i class="prLogo prov4" title="Ladyluck"></i></li>
            <li><i class="prLogo prov5" title="Kalamba"></i></li>
            <li><i class="prLogo prov6" title="IronDog"></i></li>
            <li><i class="prLogo prov7" title="IGT"></i></li>
            <li><i class="prLogo prov8" title="Igrosoft"></i></li>
            <li><i class="prLogo prov9" title="Merkur"></i></li>
            <li><i class="prLogo prov12" title="Booongo"></i></li>
            <li><i class="prLogo prov15" title="eBet"></i></li>
            <li><i class="prLogo prov16"></i></li>
            <li><i class="prLogo prov17"></i></li>
            <li><i class="prLogo prov18"></i></li>
            <li><i class="prLogo prov19"></i></li>
            <li><i class="prLogo prov20"></i></li>
            <li><i class="prLogo prov21"></i></li>
            <li><i class="prLogo prov22"></i></li>
            <li><i class="prLogo prov23"></i></li>
            <li><i class="prLogo prov24"></i></li>
            <li><i class="prLogo prov25"></i></li>
            <li><i class="prLogo prov26"></i></li>
            <li><i class="prLogo prov27"></i></li>
            <li><i class="prLogo prov28"></i></li>
            <li><i class="prLogo prov30"></i></li>
            <li><i class="prLogo prov31"></i></li>
            <li><i class="prLogo prov32"></i></li>
            <li><i class="prLogo prov33"></i></li>
            <li><i class="prLogo prov34"></i></li>
            <li><i class="prLogo prov35"></i></li>
            <li><i class="prLogo prov36"></i></li>
            <li><i class="prLogo prov37"></i></li>
            <li><i class="prLogo prov38"></i></li>
            <li><i class="prLogo prov39"></i></li>
            <li><i class="prLogo prov40"></i></li>
            <li><i class="prLogo prov42"></i></li>
            <li><i class="prLogo prov43"></i></li>
            <li><i class="prLogo prov44"></i></li>
            <li><i class="prLogo prov45"></i></li>
            <li><i class="prLogo prov46"></i></li>
            <li><i class="prLogo prov47"></i></li>
            <li><i class="prLogo prov48"></i></li>
            <li><i class="prLogo prov50"></i></li>
            <li><i class="prLogo prov51"></i></li>
            <li><i class="prLogo prov52"></i></li>
            <li><i class="prLogo prov53"></i></li>
            <li><i class="prLogo prov54"></i></li>
            <li><i class="prLogo prov1" title="DragonSoft"></i></li>
          </ul>
        </div>
      </div>
      <div
        class="b-content"
        id="footer-area"
        *ngIf="this.FooterContent != null && this.FooterContent != undefined && this.FooterContent != ''"
        [innerHTML]="FooterContent | safeHtml"></div>
      <div class="b-content p-2 pt-4">
        <small class="pt-3 mb-0 pb-3" [innerHTML]="'footer.text' | translate"></small>
      </div>
      <div class="b-content">
        <div class="d-flex justify-content-center mt-3 mb-2">
          <small>
            All right reserved.
            <i class="far fa-copyright"></i>
            {{ 'brand.copyright' | translate }} v.2
          </small>
        </div>
      </div>
    </div>

    <div class="container-wide d-none">
      <div class="row">
        <div class="col-12 col-md-8 order-3 order-md-1">
          <div class="footer-links text-center text-md-left">
            <div class="row">
              <div class="col-6 col-md-3 sport" *ngIf="ShouldRenderSports">
                <ul>
                  <li>
                    <div class="title">{{ 'footer.title.sports' | translate }}</div>
                  </li>
                  <li *ngFor="let M of SportsMenu">
                    <a [routerLink]="M.UrlRoute">{{ M.TranslationKey | translate }}</a>
                  </li>
                </ul>
              </div>
              <div class="col-6 col-md-3 casino" *ngIf="ShouldRenderCasino">
                <ul>
                  <li>
                    <div class="title">{{ 'footer.title.casino' | translate }}</div>
                  </li>
                  <li *ngFor="let M of CasinoMenu">
                    <a [routerLink]="M.UrlRoute">{{ M.TranslationKey | translate }}</a>
                  </li>
                </ul>
              </div>
              <div class="col-6 col-md-3 other" *ngIf="ShouldRenderOther">
                <ul>
                  <li>
                    <div class="title">{{ 'footer.title.othergames' | translate }}</div>
                  </li>
                  <li *ngFor="let M of OtherMenu">
                    <a [routerLink]="M.UrlRoute">{{ M.TranslationKey | translate }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 order-2 order-md-2 text-center text-md-right mb-2 mb-md-0">
          <div class="footer-buttons">
            <a href="javascript:void(0)" (click)="FeedbackModal()" class="btn btn-base me-2">
              <i class="fa fa-shield-alt me-2"></i>
              {{ 'footer.feedback' | translate }}
            </a>
            <a href="javascript:void(0)" (click)="scrollTop()" class="btn btn-base">
              <i class="fa fa-arrow-up me-2"></i>
              <span>{{ 'common.text.top' | translate }}</span>
            </a>
          </div>
          <div class="footer-license w-100">
            <img src="https://cdn.bookiewiseapi.com/sites/common/btg.png" alt="" class="" title="BTG Licensed Games" />
          </div>
          <div class="d-block w-100 gamcare">
            <span class="age-restriction">18+</span>
            <a href="https://www.gamcare.org.uk/" target="_new">
              <img src="https://cdn.bookiewiseapi.com/common/responsible/gamcare.webp" alt="" />
            </a>
            <a href="https://www.begambleaware.org/" target="_new">
              <img src="https://cdn.bookiewiseapi.com/common/responsible/gambleaware.webp" alt="" />
            </a>
            <a href="https://www.gamblingtherapy.org/tr/" target="_new">
              <img src="https://cdn.bookiewiseapi.com/common/responsible/gambling_therapy.webp" alt="" />
            </a>
          </div>
        </div>
        <div class="col-12 order-1 order-md-3 text-center text-md-left mb-2 mb-md-0">
          <div class="footer-langs"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-12"></div>
      </div>
      <div class="row">
        <div class="col-12 footer-text"></div>
      </div>
    </div>
    <div class="footer-last p-3 d-none">
      <div class="b-container"></div>
    </div>
  </div>
</footer>
<a
  href="javascript:void(0)"
  (click)="scrollTop()"
  id="scrollToTopBtn"
  class="btn btn-top"
  [ngClass]="{ show: isButtonVisible }">
  <i class="bw-i-double-arrow-top"></i>
</a>
<app-bottom-navbar></app-bottom-navbar>
