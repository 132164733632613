import { Directive, Inject, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appScroller]',
})
export class ScrollerDirective {
  isDown: boolean = false;
  startX: any;
  scrollLeft: any;
  private el: HTMLElement;

  constructor(@Inject(ElementRef) elementRef: ElementRef, private renderer: Renderer2) {
    this.el = elementRef.nativeElement;
  }

  @HostListener('mousedown', ['$event']) onMouseDown(e: MouseEvent) {
    this.isDown = true;
    this.renderer.addClass(this.el, 'active');
    this.startX = e.pageX - this.el.offsetLeft;
    this.scrollLeft = this.el.scrollLeft;
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave(e: MouseEvent) {
    this.isDown = false;
    this.renderer.removeClass(this.el, 'active');
  }

  @HostListener('mouseup', ['$event']) onMouseUp(e: MouseEvent) {
    this.isDown = false;
    this.renderer.removeClass(this.el, 'active');
  }

  @HostListener('mousemove', ['$event']) onMouseMove(e: MouseEvent) {
    if (!this.isDown) return;
    e.preventDefault();
    const x = e.pageX - this.el.offsetLeft;
    const walk = (x - this.startX) * 3; //scroll-fast
    this.el.scrollLeft = this.scrollLeft - walk;
  }
}
