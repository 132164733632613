// FloatingActionButtonUtils.ts

import { Config } from '../config';

export interface FloatingActionButton {
  iconClass: string;
  label: string;
  onClick: () => void;
}

export const createSupportButtons = (
  filterCondition: (s: any) => boolean,
  selector: string
): Array<FloatingActionButton> => {
  return Config.BottomMenu.filter(filterCondition).map((s) => {
    return {
      iconClass: s.Icon,
      label: s.Name,
      onClick: function () {
        //document.querySelector(selector).setAttribute('data-mfb-state', 'closed');
        let confParams = s.ConfigurationParameters ? JSON.parse(s.ConfigurationParameters) : null;
        switch (s.LinkType) {
          case 1: // Url
            window.location.href = s.UrlRoute;
            break;
          case 2: // Comm100
            window['Comm100API'].do('livechat.button.click', confParams.PlanId);
            // window['Comm100API'].open_chat_window(null, confParams.PlanId);
            break;
          case 3: // LiveChatInc
            if (document.querySelectorAll('iframe#chat-widget').length === 0) {
              eval(s.LiveChatWidgetScript.replace(/_LICENSE_KEY_/g, confParams.LicenseKey));
              window['LiveChatWidget'].call('maximize');
            } else {
              window['LiveChatWidget'].call('maximize');
            }
            break;
          case 4: // Whatsapp
            window.open(
              `https://api.whatsapp.com/send/?phone=${confParams.PhoneNumber}&text=${confParams.HelpText}`,
              '_blank'
            );
            break;
          case 5: // Zendesk
            if (document.querySelectorAll('iframe#launcher').length === 0) {
              eval(s.ZendeskWidgetScript.replace(/_ACCOUNT_KEY_/g, confParams.AccountKey));
              setTimeout(() => {
                window['zEmbed'].activate();
              }, 1000);
            } else {
              window['zEmbed'].activate();
            }
            break;
          case 6: // Jivo
            if (window['jivo_api']) {
              window['jivo_api'].open();
            } else {
              eval(s.JivoWidgetScript.replace(/_WIDGET_ID_/g, confParams.WidgetId));
              setTimeout(() => {
                window['jivo_api'].open();
              }, 1000);
            }
            break;
          case 7: // Skype
            window.open(`skype:${confParams.SkypeAccountId}?chat`);
            break;
          case 8: // Tawk
            window['Tawk_API'].maximize();
            break;
          case 9:
          case 11:
            window['SBChat'].open();
            break;
          case 10:
            window['supsis']('open');
            break;
        }
      },
    };
  });
};
