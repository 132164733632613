export enum CustomHtmlContentPagesEnum {
  HomePage = 0,
  Affiliate = 5,
  Pwa = 6,
  Footer = 7,
  Slot = 8,
  LiveCasino = 9,
  FastGames = 10,
  Scratch = 11,
  Virtual = 12,
  Promotions = 13,
  Area1 = 1,
  Area2 = 2,
  Area3 = 3,
  Area4 = 4,
  Android = 0,
  IOS = 1,
  Desktop = 2,
}
