import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaintenanceModeComponent } from './maintenance-mode.component';
import { PipesModule } from 'src/app/helpers/pipes/pipes.module';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [MaintenanceModeComponent],
  imports: [CommonModule, PipesModule, RouterModule],
  exports: [MaintenanceModeComponent],
})
export class MaintenanceModeModule {}
