import { StorageService } from './Storage.service';
import { AppConfigService } from '../config/app-config.service';
import {
  SocketResponse,
  PlayerSessionData,
  PlayerBalance,
  PlayerNotification,
  DbPlayerContactIncomingMessagesResponse,
} from '../models/service.dtos';
import { PlayerLoginRequest } from '../models/user.dtos';
import { Injectable, Injector } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { WindowRefService } from './WindowRef.service';
import { GeneralService } from './General.service';
import { CASINO, LIVE_CASINO, FAST_GAMES, SCRATCH, VIRTUAL_SPORTS } from '../constants';

@Injectable()
export class SocketService {
  GameTypes = [CASINO, LIVE_CASINO, FAST_GAMES, SCRATCH, VIRTUAL_SPORTS];
  constructor(
    private Socket: Socket,
    private ConfigService: AppConfigService,
    private StorageService: StorageService,
    private injector: Injector,
    private Router: Router,
    private generalService: GeneralService
  ) {}

  public get Notify(): ToastrService {
    return this.injector.get(ToastrService);
  }

  public socketInitialize() {
    this.PlayerReSign();
    this.ListenPlayerStatus();
    this.LoginCheck();
    this.playerBalanceCheck();
    this.playerBalanceUpdate();
    this.PlayerLogout();
    this.listenUserNotification();
  }

  public playerLogin(Model: PlayerLoginRequest) {
    this.Socket.emit('login-v3', Model);
  }

  private playerBalanceCheck() {
    this.Socket.on('balanceChange', (Response: SocketResponse<PlayerBalance>) => {
      if (Response.Success) {
        let User = this.StorageService.get('user');
        User.Balance = Response.Data.Balance;
        User.BonusBalance = Response.Data.BonusBalance;
        this.StorageService.set('user', User);
        this.ConfigService.Player.next(User);
      }
    });
  }
  private playerBalanceUpdate() {
    this.Socket.on('notifyBalanceChange', (Response: SocketResponse<any>) => {
      if (Response && Response.Data && Response.Data.length > 0) {
        this.Notify.show(
          Response.Data[0].Message,
          Response.Data[0].Subject,
          { positionClass: Response.Data[0].Position },
          Response.Data[0].Type
        ).onTap.subscribe(() => {
          this.Router.navigateByUrl(Response.Data[0].Url);
        });
      }
    });
  }
  private LoginCheck() {
    this.Socket.on('LoginCheck', (Response: SocketResponse<PlayerSessionData>) => {
      if (Response.Error == 'redirect') {
        window.location.href = Response.Data['SessionId'];
        return;
      }
      if (Response.Success) {
        this.StorageService.set('user', Response.Data);
        if (window['clarity']) {
          window['clarity']('consent');
          window['clarity'](
            'identify',
            Response.Data.Username,
            Response.Data['SessionId'],
            null,
            Response.Data.Username
          );
        }
        this.ConfigService.LoginStatus.next(true);
        this.ConfigService.LoginModal.next('close');
        this.ConfigService.Player.next(Response.Data);
        this.ConfigService.GameContainerLogin.next(true);
        const favGames = [];
        this.GameTypes.forEach((gameType) => {
          let games = this.StorageService.get(`${gameType}Favorites`) || [];
          if (games && games.length) {
            favGames.push(
              ...games.map((item) => {
                return { GameId: item.Id, GameType: gameType };
              })
            );
            this.StorageService.remove(`${gameType}Favorites`);
            // this.generalService.PlayerBulkInsertFavoriteGames(games.map((item) => item.Id).join('|'),gameType).subscribe((response) => {
            //   if (response && response.Data && response.Data.length > 0) {
            //     this.StorageService.set(`Favorites`, response.Data);
            //     this.StorageService.remove(`${gameType}Favorites`);
            //   }
            // });
          }
        });
        if (favGames.length > 0) {
          this.generalService.PlayerBulkInsertFavoriteGames(favGames).subscribe((response) => {
            if (response && response.Data && response.Data.length > 0) {
              this.StorageService.set(`Favorites`, response.Data);
            }
          });
        } else {
          this.generalService.PlayerFavoriteGameIdList().subscribe((response) => {
            if (response && response.Data && response.Data.length > 0) {
              this.StorageService.set(`Favorites`, response.Data);
            }
          });
        }
        if (window.location.pathname.indexOf('register') !== -1) window.location.href = '/';
      } else {
        this.Notify.error(Response.Error);
      }
    });
  }

  private listenUserNotification() {
    this.Socket.on('notifyMessage', (Response: SocketResponse<DbPlayerContactIncomingMessagesResponse[]>) => {
      if (Response.Success) {
        let LastNotification: DbPlayerContactIncomingMessagesResponse = Response.Data[0];
        this.Notify.show(
          LastNotification.Message,
          LastNotification.Subject,
          { timeOut: 15000, extendedTimeOut: 0, closeButton: true },
          LastNotification.Type
        );
        this.ConfigService.PlayerNotifications.next(Response.Data);
      }
    });
  }
  private ListenPlayerStatus() {
    this.Socket.on('checkSession', (Response: SocketResponse<PlayerSessionData>) => {
      if (Response.Success) {
        this.StorageService.set('user', Response.Data);
        this.ConfigService.Player.next(Response.Data);
        this.ConfigService.GameContainerLogin.next(true);
      } else {
        this.StorageService.remove('user');
        this.ConfigService.LoginStatus.next(false);
        this.ConfigService.Player.next(null);
      }
    });
  }

  private PlayerReSign() {
    let User = this.StorageService.getUser();
    if (User) {
      this.Socket.emit('checkSession', User.SessionId);
    }
  }

  private PlayerLogout() {
    this.Socket.on('userLoggedOut', (Response: string) => {
      if (Response) {
        if (localStorage.getItem('userInfo') !== null) {
          localStorage.removeItem('userInfo');
        }
        this.StorageService.remove('user');
        this.ConfigService.LoginStatus.next(false);
        this.ConfigService.Player.next(null);
        window.location.href = '/';
      }
    });
  }
}
