import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalTimePipe } from './local-time.pipe';
import { SearchPipe } from './search.pipe';
import { FilterPipe } from './filter.pipe';
import { CountSliderPipe } from './count-slider.pipe';
import { LiveCategoriesIconPipe } from './live-categories-icon.pipe';
import { TranslatePipe } from './translate.pipe';
import { TranslateService } from 'src/app/services/Translate.service';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [
    LocalTimePipe,
    SearchPipe,
    FilterPipe,
    CountSliderPipe,
    LiveCategoriesIconPipe,
    TranslatePipe,
    CurrencySymbolPipe,
    SafeHtmlPipe,
  ],
  imports: [CommonModule],
  exports: [
    LocalTimePipe,
    SearchPipe,
    CountSliderPipe,
    FilterPipe,
    LiveCategoriesIconPipe,
    TranslatePipe,
    CurrencySymbolPipe,
    SafeHtmlPipe,
  ],
  providers: [],
})
export class PipesModule {}
