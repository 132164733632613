import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollerDirective } from './scroller.directive';
import { BsConditionalDismissDirective } from './bs-conditional-dismiss.directive';

@NgModule({
  declarations: [ScrollerDirective, BsConditionalDismissDirective],
  imports: [CommonModule],
  exports: [ScrollerDirective, BsConditionalDismissDirective],
})
export class DirectivesModule {}
