import { Component, OnInit, Input, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/config/app-config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/services/General.service';
import { CreateContactMessageRequest } from 'src/app/models/service.dtos';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-info-modal',
  templateUrl: './user-info-modal.component.html',
  styleUrls: ['./user-info-modal.component.scss'],
})
export class UserInfoModalComponent implements OnInit, OnDestroy {
  @ViewChild('Template')
  private TemplateUserInfo: TemplateRef<any>;
  AuthSettings = Config.AuthenticationSettings;

  ModalRef: BsModalRef;
  Subscribe: Subscription = new Subscription();
  askClose: boolean = false;
  newUser: boolean = false;
  UserInfo: any = null;
  constructor(private ModalService: BsModalService, private ConfigService: AppConfigService, private router: Router) {}

  ngOnInit(): void {
    this.Subscribe.add(
      this.ConfigService.UserInfoModal.subscribe((Type: string) => {
        if (Type === 'on') {
          this.Open();
        } else if (Type === 'close') {
          this.Close();
        }
      })
    );
  }

  ngOnDestroy() {
    this.Subscribe.unsubscribe();
  }

  Open() {
    this.UserInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.ModalRef = this.ModalService.show(this.TemplateUserInfo, {
      class: 'modal-dialog modal-fullscreen-lg-down',
      backdrop: 'static',
      keyboard: false,
    });
  }

  Close() {
    this.ModalRef.hide();
    this.ConfigService.UserInfoModal.next('');
  }
  goAfterRegisterPage() {
    // '1=main page, 2=deposit page, 3=user edit page'
    switch (this.AuthSettings.RedirectToPageAfterLogin) {
      case 1:
        this.router.navigateByUrl('/');
        this.ModalRef.hide();
        this.ConfigService.RegisterModal.next('');
        return;
      case 2:
        this.router.navigateByUrl('/account/info/edit');
        this.ModalRef.hide();
        this.ConfigService.RegisterModal.next('');
        return;
      case 3:
        this.router.navigateByUrl('/account/info/deposit');
        this.ModalRef.hide();
        this.ConfigService.RegisterModal.next('');
        return;
    }
  }
  copyUserCreds(userInfoElem) {
    var range = document.createRange();
    range.selectNode(userInfoElem);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
  }
  downloadUserCredsJpg(userInfoElem) {
    var range = document.createRange();
    range.selectNode(userInfoElem);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    let userInfo = window.getSelection().toString();
    const canvas = document.createElement('canvas');
    canvas.style.backgroundColor = 'white';
    canvas.height = 50;
    canvas.width = userInfo.length * 10;
    const ctx = canvas.getContext('2d');
    ctx.font = '20px Georgia';
    ctx.fillStyle = 'white';
    ctx.fillText(userInfo, 10, 25);
    // ctx.fillStyle="white";
    // ctx.fillRect(0, 0, canvas.width, canvas.height);

    canvas.toBlob(
      (blob) => {
        let data = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = data;
        link.download = this.UserInfo.UserName + '_userInfo.jpeg';
        link.click();
      },
      'image/jpeg',
      '1'
    );
  }
  downloadUserCredsTxt(userInfoElem) {
    var range = document.createRange();
    range.selectNode(userInfoElem);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    let userInfo = window.getSelection().toString();
    let data = window.URL.createObjectURL(
      new Blob([userInfo], {
        type: 'text/plain',
      })
    );
    let link = document.createElement('a');
    link.href = data;
    link.download = this.UserInfo.UserName + '_userInfo.txt';
    link.click();
  }
  closeModal() {
    localStorage.removeItem('userInfo');
    this.goAfterRegisterPage();
    this.Close();
  }
  confirmClose() {
    this.askClose = true;
    this.closeModal();
  }
}
