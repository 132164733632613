import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'liveCategoriesIcon',
})
export class LiveCategoriesIconPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case 'Baccarat':
        return 'icon-poker';
      case 'Game Show':
        return 'icon-dice-4';
      case 'Blackjack':
        return 'icon-gambling';
      case 'Roulette':
        return 'icon-casino';
      case 'Poker':
        return 'icon-poker-2';
      default:
        break;
    }
  }
}
