<ng-template #Template>
  <div class="modal-header d-flex justify-content-between">
    <div class="start">
      <img [routerLink]="['/']" src="assets/uploads/brand/logo.png" alt="" class="logo" />
    </div>
    <div class="end">
      <a
        href="javascript:void(0)"
        *ngIf="AuthSettings.DefaultRegistrationEnabled || AuthSettings.FastRegistrationEnabled"
        (click)="OpenRegisterModal()"
        class="btn btn-register p-1 px-2 ms-auto me-2">
        {{ 'btn.register' | translate }}
      </a>
      <a href="javascript:void(0)" (click)="Close()" class="btn btn-close"><i class="fa fa-close"></i></a>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="LoginForm" (ngSubmit)="Login()">
      <h6>{{ 'general.text.have.account' | translate }}</h6>
      <h5 class="mb-3">{{ 'general.text.login.right.now' | translate }}</h5>

      <div class="form-floating mb-3">
        <input
          type="text"
          #uname
          name="username"
          class="form-control"
          id="Username"
          [(ngModel)]="Model.Username"
          formControlName="Username"
          autocomplete="off"
          value=""
          required
          [class.is-invalid]="f.Username.invalid && (f.Username.dirty || f.Username.touched)"
          [class.filled]="!!Model.Username" />
        <label for="Username">{{ 'account.form.label.username' | translate }}</label>
      </div>
      <div class="invalid-feedback">
        {{ 'general.text.required' | translate }}
      </div>
      <div class="form-floating">
        <input
          type="password"
          name="current-password"
          class="form-control"
          id="password"
          [(ngModel)]="Model.Password"
          formControlName="Password"
          value=""
          required
          [class.is-invalid]="f.Password.invalid && (f.Password.dirty || f.Password.touched)"
          [class.filled]="!!Model.Password" />
        <label for="password">{{ 'input.password' | translate }}</label>
      </div>
      <div class="invalid-feedback">
        {{ 'general.text.required' | translate }}
      </div>

      <div class="form-check my-3">
        <input class="form-check-input" type="checkbox" value="" id="rememberMe" />
        <label class="form-check-label" for="rememberMe">
          {{ 'input.remember' | translate }}
        </label>
      </div>
      <button
        [disabled]="!LoginForm.valid"
        type="submit"
        [ngClass]="{
          'btn-theme': LoginForm.valid,
          'btn-base': !LoginForm.valid
        }"
        class="w-100 btn">
        {{ 'btn.login' | translate }}
      </button>
    </form>
    <div class="after w-100 text-center pt-3">
      <a href="javascript:void(0)" (click)="ResetModal(); ModalRef.hide()" class="btn btn-base">
        {{ 'btn.forgot-pass' | translate }}
      </a>
    </div>
    <div class="slider mt-5">
      <div id="sliderMobile2" class="carousel" data-bs-touch="false">
        <app-banner [BannerTypeId]="9"></app-banner>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a href="javascript:void(0)" class="btn btn-success" (click)="openMobileFab()">
      <i class="bw-i-live-chat me-1"></i>
      {{ 'general.contact.support' | translate }}
    </a>
    <a href="javascript:void(0)" class="btn btn-base" data-bs-dismiss="modal" (click)="Close()">
      {{ 'btn.close' | translate }}
    </a>
  </div>
</ng-template>

<form [formGroup]="LoginForm" (ngSubmit)="Login()" class="d-none">
  <div class="mb-2 d-flex align-items-center">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="rememberMe" />
      <label class="custom-control-label" for="rememberMe">{{ 'input.remember' | translate }}</label>
    </div>
  </div>
  <div>
    <button [disabled]="!LoginForm.valid" type="submit" class="btn btn-block btn-success">
      <span>{{ 'btn.login' | translate }}</span>
    </button>
  </div>
</form>
