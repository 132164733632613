import { Injectable } from '@angular/core';
import { IAppConfig } from './config.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SocketService } from '../services/socket.service';
import { WindowRefService } from '../services/WindowRef.service';
import * as moment from 'moment';
import { TranslateService } from '../services/Translate.service';
import {
  DbMenuResponse,
  MenuRequest,
  SiteLanguage,
  SiteLanguagesRequest,
  DbSiteAuthenticationSettingsResponse,
  BottomMenuRequest,
  DbBottomMenuResponse,
  GamesByOrderingList,
} from '../models/service.dtos';
import { GeneralService } from '../services/General.service';

@Injectable()
export class Config {
  static settings: IAppConfig;
  Languages: SiteLanguage[];
  static Menu: DbMenuResponse[] = [];
  static BottomMenu: DbBottomMenuResponse[] = [];
  static AuthenticationSettings: DbSiteAuthenticationSettingsResponse;
  static PopularGames: GamesByOrderingList[];

  constructor(
    private http: HttpClient,
    private xSocketService: SocketService,
    private WinRef: WindowRefService,
    private TranslateService: TranslateService,
    private Service: GeneralService
  ) {}

  async Initialize() {
    // if (environment.name == 'prod') {
    //   Config.settings = this.WinRef.nativeWindow.bsconfig;
    //   this.xSocketService.socketInitialize();
    //   await this.MenuInitialize();
    //   await this.TranslationInitialize();
    //   return;
    // }

    Config.settings = {
      Environment: environment.name,
      ClientId: environment.ClientId,
      CdnUrl: environment.CdnUrl,
      PwaUrl: environment.PwaUrl,
      Endpoints: {
        Api: environment.Endpoints.Api,
        Socket: environment.Endpoints.Socket,
        ApiDomain: environment.Endpoints.ApiDomain,
        GrossSportsbookDomain: environment.Endpoints.GrossSportsbookDomain,
      },
      ReCaptchaKey: environment.ReCaptchaKey,
      DefaultRegistrationEnabled: environment.DefaultRegistrationEnabled,
      FastRegistrationEnabled: environment.FastRegistrationEnabled,
      HasPlainAuthentication: false,
      IsPrivateSite: false,
    };

    this.WinRef.nativeWindow.bsconfig = Config.settings;
    this.xSocketService.socketInitialize();

    await Promise.all([
      this.MenuInitialize(),
      this.BottomMenuInitialize(),
      this.TranslationInitialize(),
      this.StyleInitialize(),
      this.BackgroundStyleInitialize(),
      this.ScriptInitialize(),
      this.ClarityScriptInitialize(),
      this.AuthenticationSettingsInitialize(),
      //this.GamesByOrderingInitialize(),
    ]);
  }

  private determineSiteLanguage() {
    let lang = Config.settings.ClientId !== 142 ? 'tr' : 'en';

    lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : Config.settings.ClientId !== 142 ? 'tr' : 'en';

    return lang;
  }

  public TranslationInitialize = () =>
    new Promise<boolean>(async (resolve: (res: boolean) => void) => {
      let Request = new SiteLanguagesRequest();
      this.Service.siteLanguages(Request).subscribe((Response) => {
        this.Languages = Response;

        let lang = this.determineSiteLanguage();
        localStorage.setItem('lang', lang);
        moment.locale(lang);
        this.http
          .get(`${Config.settings.Endpoints.Api}/Site/Translation/${lang}`, {
            headers: {
              SiteId: Config.settings.ClientId.toString(),
            },
          })
          .toPromise()
          .then((x) => {
            this.TranslateService.setTranslations(x);
            return resolve(true);
          });
      });
    });
  bubbleSort(arr) {
    const n = arr.length;

    for (let i = 0; i < n - 1; i++) {
      for (let j = 0; j < n - i - 1; j++) {
        if (arr[j].OrderIndex > arr[j + 1].OrderIndex) {
          let temp = arr[j];
          arr[j] = arr[j + 1];
          arr[j + 1] = temp;
        }
      }
    }
    return arr;
  }

  public MenuInitialize = () =>
    new Promise<boolean>(async (resolve: (res: boolean) => void) => {
      let Request = new MenuRequest();
      this.Service.Menu(Request).subscribe((Response) => {
        Config.Menu = this.bubbleSort(Response);
        resolve(true);
      });
    });
  public BottomMenuInitialize = () =>
    new Promise<boolean>(async (resolve: (res: boolean) => void) => {
      let Request = new BottomMenuRequest();
      this.Service.BottomMenu(Request).subscribe((Response) => {
        Config.BottomMenu = this.bubbleSort(Response);
        resolve(true);
      });
    });
  public AuthenticationSettingsInitialize = () =>
    new Promise<boolean>(async (resolve: (res: boolean) => void) => {
      this.Service.GetAuthenticationSettings(Config.settings.ClientId).subscribe((Response) => {
        Config.AuthenticationSettings = Response.Data;
        resolve(true);
      });
    });

  public GamesByOrderingInitialize = () =>
    new Promise<boolean>((resolve) => {
      this.Service.GamesByOrdering({ Mobile: false }).subscribe((response) => {
        Config.PopularGames = response;
      });
      resolve(true);
    });

  public StyleInitialize = () =>
    new Promise<boolean>((resolve: (res: boolean) => void) => {
      let fileref = document.createElement('link');
      fileref.setAttribute('rel', 'stylesheet');
      fileref.setAttribute('type', 'text/css');
      fileref.setAttribute(
        'href',
        `${Config.settings.Endpoints.Api}/Site/Styles/${Config.settings.ClientId}/base.css?rand=${Math.random()
          .toString(36)
          .substring(10)}`
      );
      if (typeof fileref != 'undefined') {
        document.body.appendChild(fileref);
      }
      resolve(true);
    });
  public BackgroundStyleInitialize = () =>
    new Promise<boolean>((resolve: (res: boolean) => void) => {
      let fileref = document.createElement('link');
      fileref.setAttribute('rel', 'stylesheet');
      fileref.setAttribute('type', 'text/css');
      fileref.setAttribute(
        'href',
        `${Config.settings.Endpoints.Api}/Site/Styles/${Config.settings.ClientId}/background.css?rand=${Math.random()
          .toString(36)
          .substring(10)}`
      );
      if (typeof fileref != 'undefined') {
        document.body.appendChild(fileref);
      }
      resolve(true);
    });

  public ScriptInitialize = () =>
    new Promise<boolean>((resolve: (res: boolean) => void) => {
      let fileref = document.createElement('script');
      fileref.setAttribute('type', 'text/javascript');
      fileref.setAttribute(
        'src',
        `${Config.settings.Endpoints.Api}/Site/Scripts/${Config.settings.ClientId}/base.js?rand=${Math.random()
          .toString(36)
          .substring(10)}`
      );
      if (typeof fileref != 'undefined') {
        document.body.appendChild(fileref);
      }
      resolve(true);
    });
  public ClarityScriptInitialize = () =>
    new Promise<boolean>((resolve: (res: boolean) => void) => {
      let fileref = document.createElement('script');
      fileref.setAttribute('type', 'text/javascript');
      fileref.setAttribute(
        'src',
        `${Config.settings.Endpoints.Api}/Site/Scripts/${Config.settings.ClientId}/clarity.js?rand=${Math.random()
          .toString(36)
          .substring(10)}`
      );
      if (typeof fileref != 'undefined') {
        document.body.appendChild(fileref);
      }
      resolve(true);
    });
}
