import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { GeneralService } from 'src/app/services/General.service';
import { WindowRefService } from 'src/app/services/WindowRef.service';
import { DOCUMENT } from '@angular/common';
import { StorageService } from 'src/app/services/Storage.service';
import { SlotGameList, SlotOpenGameRequest } from 'src/app/models/service.dtos';
import { NavigationEnd, Router } from '@angular/router';
import { LIVE_CASINO, CASINO } from 'src/app/constants';
import postscribe from 'postscribe';
import { AppConfigService } from 'src/app/config/app-config.service';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/config';
import { TranslateService } from 'src/app/services/Translate.service';
import { ConfirmationModalService } from 'src/app/services/confirm-modal.service';
import { HomeHeaderComponent } from 'src/app/layouts/home/home-header/home-header.component';
import { StickyService } from 'src/app/services/Header.service';
import { filter } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-game-container',
  templateUrl: './game-container.component.html',
  styleUrls: ['./game-container.component.scss'],
})
export class GameContainerComponent implements OnInit {
  FullScreenStatus: boolean = false;

  @Input('GameId') GameId: number;
  @Input('Game') Game: any;
  @Input('BrandId') BrandId: number;
  @Input('Type') Type: string;
  @Input('GameName') GameName: string;
  @Input('IsDemo') IsDemo: boolean;
  IsError: boolean = false;
  IsLoggedIn: boolean = false;
  notLoginRoute = [];
  IsPermissionError: boolean = true;
  IsCurrencyError: boolean = false;
  Iframe: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
  Embed: any;
  Url: string;
  isOpen: boolean;
  Error: boolean = false;
  RunScript: boolean = false;
  isHeaderVisible = false;
  private ps2Brands = [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76,
    77, 78, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 113, 114, 116, 117, 181, 182, 184, 185, 201,
    202, 203, 204, 246, 247,
  ];
  Translations: any;
  OpenGameErrorDesc: any;
  constructor(
    private Service: GeneralService,
    private cs: AppConfigService,
    private StorageService: StorageService,
    private WindowService: WindowRefService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: any,
    private Router: Router,
    private ngxBootstrapConfirmService: ConfirmationModalService,
    private TranslateService: TranslateService,
    private stickyService: StickyService
  ) {
    this.Router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd) // Filter NavigationEnd events
      )
      .subscribe((event: NavigationEnd) => {
        // Get the current URL
        const url = event.url;
        if (this.isGameRoute(url)) {
          this.CheckLogin();
        }
      });
    this.TranslateService.getObs([
      'modal.content.use.bonus.balance',
      'modal.content.btn.use.bonus',
      'modal.content.btn.use.balance',
      'game.container.use.bonus.balance.failed',
      'game.container.error.account.locked',
      'game.container.error.game.not.active',
      'game.container.error.currency.is.null',
    ]).subscribe((res) => {
      this.Translations = res;
    });
  }

  showAlert: boolean = false;
  rootSegment: string;
  isGameRoute(url: string): boolean {
    const gameRoutes = ['casino/game', 'live-casino/game', 'virtual-sports/game', 'fast-games/game', 'scratch/game'];
    return gameRoutes.some((route) => url.includes(route));
  }
  showHeader() {
    // $('.sticky-top').toggleClass('show-header');
    // $('.game-frame').toggleClass('scale');
    // $('.m-bottom-nav').toggleClass('show');
    // $('.btn-game-tool').toggleClass('bg');
    // $('.btn-game-close').toggleClass('show');

    // if ($('.game-icon').hasClass('bw-i-othergames')) {
    //   $('.game-icon').removeClass('bw-i-othergames').addClass('bw-i-close-remove');
    // } else if ($('.game-icon').hasClass('bw-i-close-remove')) {
    //   $('.game-icon').removeClass('bw-i-close-remove').addClass('bw-i-othergames');
    // } else {
    //   $('.game-icon').addClass('bw-i-othergames');
    // }
    this.isHeaderVisible = !this.isHeaderVisible;
  }
  ngOnInit(): void {
    //modal.content.use.bonus.balance
    this.stickyService.setSticky(this.isHeaderVisible);
    const urlParts = this.Router.url.split('/');
    if (urlParts.length > 1) {
      this.rootSegment = '/' + urlParts[1];
    }
    window.scroll(0, 0);
    this.cs.LoginStatus.subscribe((Status) => {
      this.IsLoggedIn = Status;
      if (Status && this.notLoginRoute.length > 0) {
        this.Router.navigate(this.notLoginRoute);
        this.notLoginRoute = [];
        return;
      }
    });

    this.cs.GameContainerLogin.subscribe((Status) => {
      this.StartGame();

      if (!Status) {
        return;
      }
      if (this.ps2Brands.indexOf(this.BrandId) > -1) {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 8000);
      } else {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
      }
    });
    if (this.IsLoggedIn) {
    }
  }
  CheckLogin() {
    this.cs.LoginStatus.subscribe((Status) => {
      this.IsLoggedIn = Status;
      if (Status && this.notLoginRoute.length > 0) {
        this.Router.navigate(this.notLoginRoute);
        this.notLoginRoute = [];
        return;
      }
    });

    this.cs.GameContainerLogin.subscribe((Status) => {
      this.StartGame();

      if (!Status) {
        return;
      }
      if (this.ps2Brands.indexOf(this.BrandId) > -1) {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 8000);
      } else {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
      }
    });
  }
  CloseGame() {
    this.Router.navigateByUrl(`/${this.Type}/${this.BrandId}`);
  }
  setToolbar() {
    this.isOpen = !this.isOpen;
    this.cs.BottomMenu.next(this.isOpen);
  }
  fullScreen() {
    if (this.FullScreenStatus === false) {
      this.FullScreenStatus = true;
      let elem = this.document.getElementById('game-panel');
      let methodToBeInvoked =
        elem.requestFullscreen ||
        elem.webkitRequestFullScreen ||
        elem['mozRequestFullscreen'] ||
        elem['msRequestFullscreen'];
      if (methodToBeInvoked) methodToBeInvoked.call(elem);
    } else {
      this.closeFullscreen();
      this.FullScreenStatus = false;
    }
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
  toggleHeader() {
    this.isHeaderVisible = !this.isHeaderVisible;
    this.stickyService.setSticky(this.isHeaderVisible);
  }
  openInNewWindow() {
    if (this.Embed) {
      var newWindow = window.open('', 'Game', 'width=700px,height=700px');
      newWindow.document.body.innerHTML = this.Embed;
    } else {
      window.open(this.Url, 'Game', 'width=1300px,height=768px');
    }
    this.CloseGame();
  }

  StartGame() {
    if (!this.IsLoggedIn) {
      this.notLoginRoute = [this.Type + '/game/', this.BrandId, this.GameId];
      this.cs.LoginModal.next('on');
      return false;
    }
    let _showAlertd = this.showAlert;
    this.showAlert = false;
    this.Service.PlayerHasPromotion().subscribe((Response) => {
      if (Response) {
        if (Response.Data.HasPromotion) {
          let options = {
            title: this.Translations['modal.content.use.bonus.balance'],
            confirmLabel: this.Translations['modal.content.btn.use.balance'],
            declineLabel: this.Translations['modal.content.btn.use.bonus'],
            ignoreBackdropClick: true,
          };
          this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
            this.showAlert = _showAlertd;
            let Request = new SlotOpenGameRequest();
            Request.GameId = this.GameId;
            Request.Mobile = this.IsMobile;
            Request.IsDemo = this.IsDemo;
            Request.ReturnUrl = window.location.origin;
            Request.UseBonusBalance = !res;
            if (!this.GameId && this.GameName) {
              this.Service.GetDeviceGameId(
                Config.settings.ClientId,
                this.BrandId,
                this.GameName,
                this.IsMobile
              ).subscribe((Response) => {
                if (Response) {
                  if (Response.Success === false && (Response.Status === 424 || Response.Status === 400)) {
                    this.IsPermissionError = false;
                  } else {
                    Request.GameId = Response.Id;
                    this.executeOpenGame(Request);
                  }
                }
              });
            } else {
              this.executeOpenGame(Request);
            }
          });
        } else {
          this.showAlert = _showAlertd;
          let Request = new SlotOpenGameRequest();
          Request.GameId = this.GameId;
          Request.Mobile = this.IsMobile;
          Request.IsDemo = this.IsDemo;
          Request.ReturnUrl = window.location.origin;
          Request.UseBonusBalance = false;
          if (!this.GameId && this.GameName) {
            this.Service.GetDeviceGameId(
              Config.settings.ClientId,
              this.BrandId,
              this.GameName,
              this.IsMobile
            ).subscribe((Response) => {
              if (Response) {
                if (Response.Success === false && (Response.Status === 424 || Response.Status === 400)) {
                  this.IsPermissionError = false;
                } else {
                  Request.GameId = Response.Id;
                  this.executeOpenGame(Request);
                }
              }
            });
          } else {
            this.executeOpenGame(Request);
          }
        }
      }
    });
  }

  private executeOpenGame(Request: SlotOpenGameRequest) {
    this.Service.SlotOpenGame(Request).subscribe((Response: any) => {
      if (Response.UseBonusBalanceFailed === true) {
        this.toastr.warning(this.Translations['game.container.use.bonus.balance.failed']);
      }
      if (Response === null) {
        this.Error = true;
        return;
      }
      if (Response.IsError === true) {
        switch (Response.Status) {
          case 'ACCOUNT_LOCKED':
            this.toastr.warning(this.Translations['game.container.error.account.locked']);
            this.OpenGameErrorDesc = this.Translations['game.container.error.account.locked'];
            break;
          case 'GAME_NOT_ACTIVE':
            this.toastr.warning(this.Translations['game.container.error.game.not.active']);
            this.OpenGameErrorDesc = this.Translations['game.container.error.game.not.active'];
            break;
          // case 'UNKNOWN_ERROR':
          //   this.toastr.warning(this.Translations['game.container.error.account.locked']);
          //   break;
          case 'CURRENCY_IS_NULL':
            this.toastr.warning(this.Translations['game.container.error.currency.is.null']);
            this.OpenGameErrorDesc = this.Translations['game.container.error.currency.is.null'];
            break;
        }
        this.Error = true;
        return;
      }
      if (Response.url) {
        this.Url = Response.url;
        this.Iframe = this.sanitizer.bypassSecurityTrustResourceUrl(Response.url);
      }
      if (Response.success) {
        if (Response.data.url) {
          let Url: string = Response.data.url;
          if (Url.indexOf('https') === -1) {
            Url = Url.replace('http', 'https');
          }
          let UrlArray = Url.split('exit=');
          let ct = UrlArray.length > 1;
          UrlArray[1] = `exit=${this.WindowService.nativeWindow.location.origin}/${this.Type}`;
          Url = UrlArray.join(ct ? '' : '&');
          this.Url = Url;
          this.Iframe = this.sanitizer.bypassSecurityTrustResourceUrl(Url);
        } else if (Response.data.importCode) {
          this.Embed = Response.data.importCode;
        } else if (Response.data.runScript) {
          this.RunScript = true;
          setTimeout(() => {
            postscribe('#betGames', `${Response.data.runScript}`);
          }, 500);
        }
      } else {
        if (Response.Status === 424 || Response.Status === 400) {
          this.IsPermissionError = false;
        } else if (Response.Status === 422) {
          this.IsCurrencyError = true;
        }
      }
    });
  }

  get IsMobile() {
    const ua = (window.navigator.userAgent || '').toLowerCase();
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 4)
      );
    return isMobile;
  }

  //   IsMobile() {
  //     const ua = (window.navigator.userAgent || '').toLowerCase();
  //     const isMobile = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(ua) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(ua.substr(0, 4));
  //     return isMobile;
  //  }

  // frameOpener: boolean = false
  // bsFrameOpener() {
  //   this.frameOpener ?
  //     this.cs.bsFrame.next(false) :
  //     this.cs.bsFrame.next(true)

  // }

  // closeMenu() {
  //   var main = document.querySelector('#main');
  //   var gameMenuToggler = document.querySelector('.nav-toggle')

  //   main.classList.remove('active');
  //   gameMenuToggler.classList.add('hamburger')
  //   gameMenuToggler.classList.remove('arrow-down')
  // }

  // bgImage: number;
  // getRandomNumber() {
  //   this.bgImage = Math.floor(Math.random() * 10);
  // }

  //   SetFavorite() {
  //     let Favorites: SlotGameList[] = this.StorageService.get(`${this.Type}Favorites`) || [];
  //     let Check = Favorites.filter(x => x.Id === this.GameId);
  //     if (Check && Check.length) {
  //        Favorites = Favorites.filter(x => x.Id !== this.GameId);
  //     } else {
  //        Favorites.push(game);
  //     }
  //     this.StorageService.set(`${this.Type}Favorites`, Favorites);
  //     this.ActiveStatus(game);
  //  }
  //  FavActive: boolean = false;

  //  ActiveStatus(game:any) {
  //   let Favorites: SlotGameList[] = this.StorageService.get(`${this.Type}Favorites`) || [];
  //   let Check = Favorites.filter(x => x.Id === game.Id);
  //   if (Check && Check.length) {
  //      this.FavActive = true;
  //   } else {
  //      this.FavActive = false;
  //   }
  // }
}
