<div class="maintenance">
  <div class="mt-content">
    <i class="fad fa-cogs mb-2"></i>
    <div class="title">
      {{ 'text.maintenance.header' | translate }}
    </div>
    <div class="text mb-5">
      {{ 'text.maintenance.detail' | translate }}
    </div>
    <a [routerLink]="['/']" class="btn base mt-btn">{{ 'button.navigate-homepage' | translate }}</a>
  </div>
</div>
