<ng-template #Template>
  <div class="modal-content w-100 d-none">
    <div class="modal-header">
      <h5 class="modal-title" id="languageModalLabel">
        <i class="fas fa-phone-alt me-2"></i>
        {{ 'title.call-me' | translate }}
      </h5>
      <button type="button" class="close" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3 text-center">
      <div class="p-3 text-light" [innerHTML]="'desc.call-me' | translate"></div>
      <div class="row">
        <div class="col-12">
          <form [formGroup]="Form" (submit)="CreateContactMessage()" class="login-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                formControlName="NameSurname"
                placeholder="{{ 'account.form.label.name' | translate }} {{ 'account.form.label.surname' | translate }}"
                [class.is-invalid]="f.NameSurname.invalid && (f.NameSurname.dirty || f.NameSurname.touched)" />
              <div
                *ngIf="f.NameSurname.invalid && (f.NameSurname.dirty || f.NameSurname.touched)"
                class="invalid-feedback text-left">
                {{ 'general.text.required' | translate }}
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                formControlName="PhoneNumber"
                class="form-control"
                id=""
                placeholder="90 500 000 00 00"
                [class.is-invalid]="f.PhoneNumber.invalid && (f.PhoneNumber.dirty || f.PhoneNumber.touched)" />
              <div
                *ngIf="f.PhoneNumber.invalid && (f.PhoneNumber.dirty || f.PhoneNumber.touched)"
                class="invalid-feedback text-left">
                {{ 'general.text.required' | translate }}
              </div>
            </div>

            <button type="submit" [disabled]="Form.invalid" (click)="ModalRef.hide()" class="btn btn-theme mt-3">
              <span>{{ 'btn.call-me' | translate }}</span>
            </button>
            <a
              href="javascript:window.open('{{
                'btn.url.livehcat' | translate
              }}', '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,width=380,height=600')"
              (click)="ModalRef.hide()"
              class="btn btn-outline-success btn-block d-none">
              <span>{{ 'btn.live_support' | translate }}</span>
            </a>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-content">
    <div class="modal-header d-flex justify-content-between">
      <div class="justify-content-start">
        <i class="bw-i-call me-2"></i>
        {{ 'title.call-me' | translate }}
      </div>
      <div class="justify-content-end">
        <button type="button" class="btn btn-close ms-2" (click)="Close()"><i class="fa fa-close"></i></button>
      </div>
    </div>
    <div class="modal-body">
      <div class="mb-2" [innerHTML]="'desc.call-me' | translate"></div>
      <form [formGroup]="Form" (submit)="CreateContactMessage()" class="login-form">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control mb-1"
            id="NameSurname"
            formControlName="NameSurname"
            placeholder="{{ 'account.form.label.name' | translate }} {{ 'account.form.label.surname' | translate }}"
            [class.is-invalid]="f.NameSurname.invalid && (f.NameSurname.dirty || f.NameSurname.touched)" />
          <label for="NameSurname">
            {{ 'account.form.label.name' | translate }} {{ 'account.form.label.surname' | translate }}
          </label>
        </div>
        <div
          *ngIf="f.NameSurname.invalid && (f.NameSurname.dirty || f.NameSurname.touched)"
          class="invalid-feedback text-left">
          {{ 'general.text.required' | translate }}
        </div>
        <div class="form-floating">
          <input
            type="text"
            formControlName="PhoneNumber"
            class="form-control mb-1"
            id="floatingPassword"
            placeholder="90 500 000 00 00"
            [class.is-invalid]="f.PhoneNumber.invalid && (f.PhoneNumber.dirty || f.PhoneNumber.touched)" />
          <label for="floatingPassword">{{ 'account.label.phone' | translate }}</label>
        </div>
        <div
          *ngIf="f.PhoneNumber.invalid && (f.PhoneNumber.dirty || f.PhoneNumber.touched)"
          class="invalid-feedback text-left">
          {{ 'general.text.required' | translate }}
        </div>

        <div class="w-100 text-center">
          <button type="submit" [disabled]="Form.invalid" (click)="ModalRef.hide()" class="btn btn-theme mt-3">
            <span>{{ 'btn.call-me' | translate }}</span>
          </button>
        </div>
        <a
          href="javascript:window.open('{{
            'btn.url.livehcat' | translate
          }}', '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,width=380,height=600')"
          (click)="ModalRef.hide()"
          class="btn btn-outline-success btn-block d-none">
          <span>{{ 'btn.live_support' | translate }}</span>
        </a>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="openMobileFab()">
        <i class="bw-i-live-chat me-1"></i>
        {{ 'btn.support' | translate }}
      </button>
      <button type="button" class="btn btn-base" (click)="Close()">{{ 'btn.close' | translate }}</button>
    </div>
  </div>
</ng-template>
