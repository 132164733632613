<ng-template #Template>
  <div class="modal-content">
    <div class="modal-header d-flex justify-content-between pt-1 pb-2">
      <div class="start">
        <h5 class="text-light" *ngIf="ActiveNotification">{{ ActiveNotification.Title }}</h5>
      </div>
      <div class="end">
        <a href="javascript:void(0)" (click)="Close()" class="btn btn-close"><i class="fa fa-close"></i></a>
      </div>
    </div>
    <div class="modal-body text-center" *ngIf="ActiveNotification" [innerHTML]="ActiveNotification.Content"></div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="openMobileFab()">
        <i class="bw-i-live-chat me-2"></i>
        {{ 'btn.support' | translate }}
      </button>
      <button (click)="Close()" type="button" class="btn btn-base" data-bs-dismiss="modal">
        {{ 'btn.close' | translate }}
      </button>
    </div>
  </div>
</ng-template>
