import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GameErrorComponent } from './game-error.component';
import { PipesModule } from 'src/app/helpers/pipes/pipes.module';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [GameErrorComponent],
  imports: [CommonModule, PipesModule, RouterModule],
  exports: [GameErrorComponent],
})
export class GameErrorModule {}
