<ul class="tab-buttons" style="overflow: auto">
  <li class="d-inline-block" [class.active]="activeType === CASINO" (click)="getGameList(CASINO)">
    <a class="btn btn-base p-2 me-1 mb-1" [class.current]="activeType === CASINO">
      {{ 'mainmenu.casino' | translate }}
    </a>
  </li>
  <li class="d-inline-block" [class.active]="activeType === LIVE_CASINO" (click)="getGameList(LIVE_CASINO)">
    <a class="btn btn-base p-2 me-1 mb-1" [class.current]="activeType === LIVE_CASINO">
      {{ 'mainmenu.livecasino' | translate }}
    </a>
  </li>
  <li class="d-inline-block" [class.active]="activeType === FAST_GAMES" (click)="getGameList(FAST_GAMES)">
    <a class="btn btn-base p-2 me-1 mb-1" [class.current]="activeType === FAST_GAMES">
      {{ 'mainmenu.fastgames' | translate }}
    </a>
  </li>
  <li class="d-inline-block" [class.active]="activeType === SCRATCH" (click)="getGameList(SCRATCH)">
    <a class="btn btn-base p-2 me-1 mb-1" [class.current]="activeType === SCRATCH">
      {{ 'mainmenu.scratch' | translate }}
    </a>
  </li>
  <li class="d-inline-block" [class.active]="activeType === VIRTUAL_SPORTS" (click)="getGameList(VIRTUAL_SPORTS)">
    <a class="btn btn-base p-2 me-1 mb-1" [class.current]="activeType === VIRTUAL_SPORTS">
      {{ 'mainmenu.virtualsports' | translate }}
    </a>
  </li>
</ul>

<div *ngIf="activeType" class="tab">
  <div class="inner">
    <div class="bw-games-all p-1">
      <ng-container *ngIf="!LOADER">
        <ng-container *ngFor="let Game of GameList | search : 'Name' : Search">
          <div class="bw-game-item p-0" [ngClass]="showType ? showType : ''">
            <app-live-casino-game
              [showType]="showType"
              [Type]="activeType"
              [Game]="Game"
              [CanDismiss]="true"></app-live-casino-game>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
